import React, { useEffect, useState, useContext } from "react";
import QuestionBankService from "../../services/QuestionBankService";
import { FaTrashAlt, FaEdit, FaEye } from "react-icons/fa";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from 'react-router-dom';
import MUIDataTable from "mui-datatables";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
// import Tooltip from "@material-ui/core/Tooltip";
import { AppContext } from "../../services/ContextProvider";
import Global from "../../services/Global";
import BulkUpload from './BulkUpload';
import { FaFileUpload, FaFileDownload } from "react-icons/fa";
import { saveAs } from "file-saver";
import { TableCell, Tooltip } from '@mui/material';
import {
    Encrypt,
    Decrypt,
} from "../../services/CommonService";
import { makeStyles } from '@mui/styles';
import QuestionPreview from "./QuestionPreview";

const useStyles = makeStyles((theme) => ({
    customTableCell: {
        '&.MuiTableCell-root': { // Use a more specific selector
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '20ch',
            textDecoration: 'none',
            borderBottom: 'none',
            paddingLeft: '0% !important', // Add the !important flag
        },
    },
}));
const _questionBankService = new QuestionBankService();
const ViewQuestion = () => {
    const [isContainerFluid, setIsContainerFluid] = useState(true);
    const location = useLocation();

    const [sectionID, setSectionID] = useState(location.state?.sId ?? 0);

    const classes = useStyles();
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [questionList, setquestionList] = useState([]);
    const [filteredList, setFilteredList] = useState([]);
    const [ParentSectionList, setParentSectionList] = useState([]);
    const [priviledge, setpriviledge] = useState("");
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredItems, setFilteredItems] = useState([]);
    const [preview, setPreview] = useState(false);
    const [sectionName, setSectionName] = useState('QuestionList');
    const [questionID, setQuestionID] = useState(0);
    const navigate = useNavigate();
    const { getCookies, setCookies, showLoading, hideLoading, cookies, tempModulePrivileage } =
        useContext(AppContext);
    const [IsBack, setIsBack] = useState(location.state?.isback ?? false);

    const navigateToDelete = () => {
        debugger;
        navigate("/QuestionBank", {

            state: { sId: sectionID },
        });
    };

    const navigateToedit = (qId) => {

        navigate("/QuestionBank", {
            state: { qId: qId, sId: sectionID },
        });
    }
    const navigatetoPreview = (qId) => {
        setPreview(true);
        setQuestionID(qId);
    }
    const handleExcelIconClick = () => {


        setIsPopupOpen(true);
    };

    useEffect(() => {
        debugger;
        if (IsBack) {
            showLoading();
            if (sectionID != 0 && questionList.length > 0 && IsBack) {
                debugger;
                FilterQuestionList(sectionID);
                setIsBack(false);

            }
        }

    }, [questionList]);
    useEffect(() => {
        var ModulePrivileges =
            getCookies(Global.COOKIES.modulePrivileage) != null
                ? getCookies(Global.COOKIES.modulePrivileage)
                : "";

        let timeout = setTimeout(setpriviledge(ModulePrivileges), 3000);

        GetQuestionBank();
        let CompanyID = Decrypt(localStorage.getItem("CompanyID"));
        _questionBankService.QuestionBankGetParentSectionList(CompanyID).then((res) => {
            console.log(res);
            if (res != null && res.length > 0) {

                setParentSectionList(res);
                setFilteredItems(res);
            }
        }).catch((exception) => {
            console.log(exception);
        });

    }, []);
    function isHTML(str) {
        const pattern = /<[a-z][\s\S]*>/i;
        return pattern.test(str);
    }

    function removeHTMLTags(str) {
        const parser = new DOMParser();
        const parsedDocument = parser.parseFromString(str, 'text/html');
        return parsedDocument.body.innerText;
    }

    const GetQuestionBank = () => {
        let CompanyID = Decrypt(localStorage.getItem("CompanyID"));
        _questionBankService.GetQuestionbank(CompanyID).then((res) => {
            console.log(res);
            if (res != null && res.length > 0) {
                const cleanedQuestionList = res.map((item) => ({
                    ...item,
                    answerValue: removeHTMLTags(item.answerValue),
                    question: removeHTMLTags(item.question),
                }));

                setquestionList(cleanedQuestionList);

            }
        }).catch((exception) => {
            console.log(exception);

        });
    }
    const FilterQuestionList = (id) => {
        debugger;
        if (questionList?.length > 0) {

            const sectionList = questionList.filter(x => x.sectionId === id);
            if (sectionList.length !== 0) {
                setSectionName(sectionList[0].sectionName);
            }
            debugger;
            setFilteredList(sectionList);
            setSectionID(sectionList[0]?.sectionId);
            document.getElementById("QuestionList").style.display = "";
            document.getElementById("ParentSectionList").style.display = "none";
            hideLoading();
        }
        else {
            setFilteredList(questionList != null ? questionList : []);
            document.getElementById("QuestionList").style.display = "";
            document.getElementById("ParentSectionList").style.display = "none";
            hideLoading();
        }

    }
    const GetBack = () => {

        document.getElementById("QuestionList").style.display = "none";
        document.getElementById("ParentSectionList").style.display = "";


    }
    // SearchComponent.js

    const DownloadQuestionTemplate = () => {
        _questionBankService.DownloadQuestionTemplate().then((res) => {

            const contentType = res.headers["content-type"];
            saveAs(new Blob([res.data], { type: contentType }), "QuestionTemplate.xlsx");


        }).catch((exception) => {
            console.log(exception);
        });
    }
    const handleSearch = (event) => {
        const query = event.target.value;

        setSearchQuery(query);
        console.log(ParentSectionList);
        const filteredList = ParentSectionList.filter((item) =>
            item.parentSectionName.toLowerCase().includes(query.toLowerCase()) ||
            item.sectionName.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredItems(filteredList);
    }
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 750,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });

    function DeleteQuestion(selectedQuestionId) {
        Swal.fire({
            title: "Are you sure?",
            text: "Do You Want to Delete this Question?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                _questionBankService.RemoveQuestion(selectedQuestionId).then((res) => {
                    if (res.data == 1) {
                        Toast.fire({
                            icon: "success",
                            title: "Delete Success",
                        });
                        setTimeout(function () {
                            window.location.reload();
                        }, 750);

                        debugger;
                        navigate("/ViewQuestion", {

                            state: { sId: sectionID, isback: true },
                        });
                    } else {
                        Toast.fire({
                            icon: "error",
                            title: "Delete Failed!",
                        });
                    }
                });
            }
        });
    }
    const HeaderElements = () => (
        <>
            {priviledge.includes("QUESCREATE") ?
                <Tooltip title="Add Question">

                    <label htmlFor="icon-button-file">
                        <IconButton className="HoverDefaultIcon" onClick={navigateToDelete}>
                            <AddIcon > </AddIcon>

                        </IconButton>
                    </label>
                </Tooltip> : ""
            }
        </>
    );
    const columns = [
        { name: "questionID", label: "QuestionID" },
        {
            name: "question", label: "Question",

            options: {
                filter: false,
                sort: true,
                empty: true,

                responsive: true,
                customBodyRender: (value) => (
                    <Tooltip title={value}>
                        <TableCell className={classes.customTableCell}>
                            {value}
                        </TableCell>
                    </Tooltip>

                ),
            },
        },
        {
            name: "answerValue", label: "Correct Answer",
            //  options: {
            //     filter: false,
            //     sort: true,
            //     empty: true,

            //     responsive: true,
            //     customBodyRender: (value) => (
            //       <Tooltip title={value}>
            //         <TableCell style={{
            //           whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '25ch', textDecoration: 'none',
            //         borderBottom:'none'
            //         }}>{value}</TableCell>
            //       </Tooltip>

            //     ),
            //   },
            options: {
                filter: false,
                sort: true,
                empty: true,

                responsive: true,
                customBodyRender: (value) => (
                    <Tooltip title={value}>
                        <TableCell className={classes.customTableCell}>
                            {value == "null" ? "" : value}
                        </TableCell>
                    </Tooltip>

                ),
            },
        },
        {
            name: "tags", label: "Tags", options: {
                display: "excluded", // Hide the column
            },
        },
        {
            label: "OPTIONS",
            name: "questionID",

            options: {

                filter: true,
                sort: false,
                empty: true,

                responsive: true,


                customBodyRender: (values, tableMeta) => {

                    const rowIndex = tableMeta.rowIndex;
                    const QuestionID = filteredList[rowIndex].questionID;
                    // const value = tableMeta.rowData[3];
                    return (
                        <div className="ico-sec">
                            {priviledge.includes("QUESEDIT") ?
                                <Tooltip title="Edit Question">
                                    <IconButton
                                        aria-label="Edit"
                                        data-toggle="modal"
                                        data-target="#addRowModal"
                                        onClick={() => navigateToedit(QuestionID)}
                                    >
                                        <FaEdit style={{ color: "#0d6efd" }} />{" "}
                                    </IconButton>
                                </Tooltip> : ""
                            }      {priviledge.includes("QUESDELETE") ?
                                <Tooltip title="Delete Question">
                                    <IconButton aria-label="Delete"
                                        className="text-danger"
                                        onClick={() => DeleteQuestion(QuestionID)}
                                    >

                                        <DeleteIcon />

                                    </IconButton>
                                </Tooltip> : ""
                            }

                            <Tooltip title="Preview Question">
                                <IconButton
                                    aria-label="Edit"
                                    data-toggle="modal"
                                    data-target="#addRowModal"
                                    onClick={() => navigatetoPreview(QuestionID)}
                                >
                                    <FaEye />{" "}
                                </IconButton>
                            </Tooltip>
                            {/* <TrashFill   /> */}
                        </div>
                    );
                },
            },
        },
    ];
    const customSearch = (searchQuery, currentRow, columns) => {

        let isFound = false;

        if (currentRow && columns) {
            // Iterate through all columns
            for (let i = 0; i < columns.length; i++) {
                const column = columns[i];
                // Exclude the "OPTIONS" column from the search
                if (column.name !== "OPTIONS") {

                    const cellData = currentRow[i];
                    // Ensure that cellData is defined and not null/undefined
                    if (cellData && cellData.toString().toLowerCase().includes(searchQuery.toLowerCase())) {

                        isFound = true;
                        break; // Exit the loop if a match is found in any column
                    }
                }
            }
        }

        return isFound;
    };

    const options = {
        customSearch,
        responsive: true,
        download: false,
        print: false,
        rowsPerPageOptions: [10, 50, 100],
        customToolbar: () => (
            <>

                <HeaderElements />
                <h3>
                    <input type="button" value="Back" title="Back" className="back-button" onClick={() => GetBack()} />
                </h3>
            </>
        ),
        selectableRows: false,
        filter: false,
        viewColumns: false,
    };
    function groupSectionsByParent(sections) {
        const sectionsByParent = {};
        sections.forEach((section) => {
            const { parentSectionID } = section;
            if (!sectionsByParent[parentSectionID]) {
                sectionsByParent[parentSectionID] = [];
            }
            sectionsByParent[parentSectionID].push(section);
        });
        return sectionsByParent;
    }

    const sectionsByParent = groupSectionsByParent(filteredItems);
    const toggleContainerClass = () => {
        setIsContainerFluid((prevState) => !prevState);
    };
    return (
        <>

            <main className="main-content-wrap" id="ParentSectionList">
                <div className="contact-list-area" >
                    <div className='container-fluid'>
                        <div className="row">
                            <div className={`${isContainerFluid ? "col-md-12" : "col-md-9"}`}>
                                <div className="card-box-style animatedwidth" id="QuView">
                                    <div className="fullwidthbuttonbox " onClick={toggleContainerClass}>
                                        <span className="fullwidthbutton" >
                                            {isContainerFluid ? <i className="fa fa-angle-double-left"></i> : <i className="fa fa-angle-double-right"></i>}

                                        </span>
                                    </div>
                                    <h4 className="MuiTypography-h6 mb-4" >Section List



                                        <Tooltip title="Bulk Upload">
                                            <IconButton
                                                type="button"
                                                className="text-primary"
                                                data-original-title="Edit"
                                                data-toggle="modal"
                                                data-target="#addRowModal"
                                                style={{ float: 'right' }}
                                            >
                                                <FaFileUpload
                                                    className="excel-icon"
                                                    onClick={handleExcelIconClick}
                                                    style={{ cursor: "pointer" }}
                                                />
                                            </IconButton>

                                        </Tooltip>


                                        <Tooltip title="Download Template">
                                            <IconButton
                                                type="button"
                                                className="text-primary"
                                                data-original-title="Edit"
                                                data-toggle="modal"
                                                data-target="#addRowModal"
                                                style={{ float: 'right' }}
                                            >
                                                <FaFileDownload onClick={DownloadQuestionTemplate} style={{ cursor: "pointer" }} />
                                            </IconButton>
                                        </Tooltip>

                                        {isPopupOpen && <BulkUpload setIsPopupOpen={setIsPopupOpen} />}
                                        {/* <input type="button" value="Back" title="Back" className="back-button backbutton-end" onClick={() => navigate(-1)} /> */}
                                    </h4>

                                    <div class="col-3 mb-4">
                                        <input
                                            type="text"
                                            className="form-control text-right"
                                            value={searchQuery}
                                            onChange={(e) => handleSearch(e)}
                                            placeholder="Search..."
                                        />

                                    </div>
                                    <div class="row justify-content-cenetr">
                                        {Object.values(sectionsByParent).map((sections, index) => (
                                            <div key={index} className="col-lg-3 col-sm-6">
                                                <h3 className="questionCard">
                                                    {sections[0]?.parentSectionName}

                                                </h3>

                                                <div className="single-pricing-style-two">



                                                    {sections.map((section, innerIndex) => (
                                                        <ul key={innerIndex}>
                                                            <li>

                                                                <div className="row">
                                                                    <div className="col-6" style={{ cursor: 'pointer', fontWeight: 'bold' }} onClick={() => FilterQuestionList(section?.sectionId)}>{section?.sectionName} </div>
                                                                    <div className="col-6 examcountno">Questions : {section?.questionCount}</div>
                                                                </div>
                                                            </li>
                                                            {/* <li>{section.description}<i className="ri-close-fill" /></li> */}
                                                        </ul>
                                                    ))}
                                                    {/* <button className="default-btn radius-btn active" fdprocessedid="htagm">
                                                <span>
                                                    Questions : {sections[0]?.questionCount}
                                                    <i className="flaticon-next" />
                                                </span>
                                            </button> */}
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                </div>
                            </div>

                            {!isContainerFluid ? <div className="col-md-3">

                                {/* <div className="card-box-style"></div> */}
                            </div> : null}
                        </div>
                    </div>
                </div>
            </main>


            <main class="main-content-wrap" id="QuestionList" style={{ display: "none" }}>
                <div className="contact-list-area" id="QuView">
                    <div className='container-fluid'  >
                        <div className="row">
                            <div className={`${isContainerFluid ? "col-md-12" : "col-md-9"}`}>
                                <div className="card-box-style animatedwidth">
                                    <div className="fullwidthbuttonbox " onClick={toggleContainerClass}>
                                        <span className="fullwidthbutton" >
                                            {isContainerFluid ? <i className="fa fa-angle-double-left"></i> : <i className="fa fa-angle-double-right"></i>}

                                        </span>
                                    </div>
                                    <MUIDataTable
                                        data={filteredList}
                                        title={sectionName != null ? sectionName : 'QuestionList'}
                                        columns={columns}
                                        options={options}
                                    />
                                </div>
                            </div>
                            {!isContainerFluid ? <div className="col-md-3">

                                {/* <div className="card-box-style"></div> */}
                            </div> : null}
                        </div>


                    </div>
                </div>
            </main>

            {preview ? <QuestionPreview questID={questionID} questList={filteredList} setPreview={setPreview} sectionID={sectionID} /> : null}
        </>
    );
};
export default ViewQuestion;