import React, { useState, useEffect, useContext } from "react";
import CanditateServices from "../../services/CanditateService";
import { showErrorMsg, showSaveSuccess } from "../ToastMsg";
import { Decrypt } from "../../services/CommonService";
import { AppContext } from "../../services/ContextProvider";
import Swal from "sweetalert2";
import ExamService from "../Exam/ExamService";
import Select from "react-select";

var Canditateobj = new CanditateServices();
var ExamServiceObj = new ExamService();
function EditCandidate({ id, setShowEditModal, candidateList, setActiveTab, LinkcandidateList }) {

    const { showLoading, hideLoading, setCookies, tempModulePrivileage, isAuth, SetisAuth } =
        useContext(AppContext);
    const [candidate, setCandidate] = useState({
        firstName: "",
        lastName: "",
        email: "",
        mobileNumber: "",
        address: "",
        pincode: "",
        gender: "",
        candidateGroupID: ""
    });
    const [errors, setErrors] = useState({});
    const [value, setvalue] = useState([]);
    const [candidateGroup, setcandidateGroup] = useState([]);
    const [numberValue, setNumberValue] = useState('');
    const [pincodeValue, setpincodeValue] = useState('');
    const [searchQuery, setSearchQuery] = useState('');


    useEffect(() => {
        Canditateobj.getListById(id).then((res) => {
            if (res != null && res?.data != null) {

                setCandidate(res?.data);
            }
            setTimeout(() => {
                hideLoading();
            }, 200)
        });

        Canditateobj.getGenderDropdown().then((res) => {
            if (res != null && res?.data?.length > 0) {
                setvalue(res?.data);
            }
        });

        ExamServiceObj.GetExamParameterList().then((res) => {
            if (res != null) {
                const candidateGroup = res.parameterList.filter(
                    (option) => option.parameterCode === "CANDIDATEGROUPS"
                );
                setcandidateGroup(candidateGroup);
            }
        }).catch((err) => {
            console.log(err);
        });
        var a = document.getElementById("candidateView");
        a.style.opacity = "30%";
    }, []);


    const onCloseModal = () => {
        setShowEditModal(false);
        var a = document.getElementById("candidateView");
        a.style.opacity = "inherit";
    };
    const validateForm = (e) => {

        const { name, value } = e.target;
        let valid = true;
        const errors = {};
        if (name == 'firstName') {
            if (!candidate.firstName) {
                errors.firstName = "First Name is required";
                valid = false;
            }
        }

        // if (name == 'candidateGroupID') {
        //     if (candidate.candidateGroupID === "0") {
        //         errors.candidateGroupID = "Select Candidate Group";
        //         valid = false;
        //     }
        // }
        if (name == 'email') {
            if (!candidate.email) {
                errors.email = "Email is required";
                valid = false;
            } else if (!isValidEmail(candidate.email)) {
                errors.email = "Invalid Email Address";
                valid = false;
            }
        }

        if (name == 'mobileNumber') {
            if (!candidate.mobileNumber) {
                errors.mobileNumber = "Mobile Number is required";
                valid = false;
            }
            else if (candidate.mobileNumber.length < 10) {
                errors.mobileNumber = "Mobile Number should be at least 10 digits";
                valid = false;
            }
        }


        setErrors(errors);
        return valid;
    };

    const submitForm = () => {
        let valid = true;
        const errors = {};
        if (!candidate.firstName) {
            errors.firstName = "First Name is required";
            valid = false;
        }

        if (!candidate.email) {
            errors.email = "Email is required";
            valid = false;
        } else if (!isValidEmail(candidate.email)) {
            errors.email = "Invalid Email Address";
            valid = false;
        }

        if (!candidate.mobileNumber) {
            errors.mobileNumber = "Mobile Number is required";
            valid = false;
        }
        else if (candidate.mobileNumber.length < 10) {
            errors.mobileNumber = "Mobile Number should be at least 10 digits";
            valid = false;
        }
        setErrors(errors);
        return valid;
    };

    const isValidEmail = (email) => {
        // Email validation logic
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });
    const onSubmit = (e) => {
        e.preventDefault();
        const formValid = submitForm();
        if (formValid) {
            const genderElement = document.querySelector("input[type='radio'][name=gender]:checked");
            const data = {
                ...candidate,
                candidateName: document.getElementById("firstName").value + " " + document.getElementById("lastName").value,
                gender: genderElement !== null ? parseInt(genderElement.value) : 0,
                status: "AC",
                candidateGroupID: candidate.candidateGroupID,
                parameter: 'AC'
            };
            showLoading();
            // if (candidate.examID == 0) {

            Canditateobj.BulkSaveCanditate(data).then((res) => {
                if (res.data > 0) {
                    onCloseModal();
                    hideLoading();

                    Toast.fire({
                        icon: "success",
                        title: "Updated Successfully",
                    });

                    if (setActiveTab === "candidateList") {
                        candidateList();
                    }
                    else {
                        LinkcandidateList();
                    }
                } else {
                    hideLoading();
                    Toast.fire({
                        icon: "error",
                        title: "Failed to Save",
                    });
                }
            });

        }
    };
    const handleInputChange = (e) => {

        const { name, value } = e.target;
        if (name === "mobileNumber") {
            const numericValue = value.replace(/[^0-9]/g, "");
            console.log(numericValue);
            if (numericValue === "") {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [name]: "Enter numbers only",
                }));
            } else {
                console.log('else');
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [name]: "",
                }));
            }
            setNumberValue(numericValue);
            setCandidate((prevState) => ({
                ...prevState,
                [name]: numericValue,
            }));
        } else if (name === "pincode") {
            const numericValue = value.replace(/[^0-9]/g, "");
            if (value !== numericValue) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [name]: "Enter numbers only",
                }));
            } else {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [name]: "",
                }));
            }
            setpincodeValue(numericValue);
            setCandidate((prevState) => ({
                ...prevState,
                [name]: numericValue,
            }));
        } else {
            setCandidate((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };


    return (
        <>
            <div className="modal fade show" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-modal="true" role="dialog" style={{ display: 'flex', alignItems: 'center' }}>
                <div className="modal-dialog" style={{ margin: 'auto' }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title MuiTypography-h6" id="staticBackdropLabel">Edit Candidate</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" fdprocessedid="ttcp2q" onClick={onCloseModal}
                            />
                        </div>
                        <div className="modal-body">
                            {candidate && (

                                <form className="row g-3" onSubmit={onSubmit} autoComplete="off">
                                    <div className="col-sm-5">
                                        <label htmlFor="firstName">First Name<span className="text-danger">*</span></label>
                                        <input
                                            type="text"
                                            id="firstName"
                                            name="firstName"
                                            value={candidate.firstName}
                                            onChange={handleInputChange}
                                            onBlur={validateForm}
                                            className="form-control"
                                        />
                                        {errors.firstName && (
                                            <span className="text-danger">{errors.firstName}</span>
                                        )}
                                    </div>

                                    <div className="col-sm-5">
                                        <label htmlFor="lastName">Last Name</label>
                                        <input
                                            type="text"
                                            id="lastName"
                                            name="lastName"
                                            value={candidate.lastName}
                                            onChange={handleInputChange}
                                            onBlur={validateForm}
                                            className="form-control"
                                        />
                                        {/* {errors.LastName && (
                                            <span className="text-danger">{errors.LastName}</span>
                                        )} */}
                                    </div>

                                    <div className="col-sm-5">
                                        <label htmlFor="email">Email<span className="text-danger">*</span></label>
                                        <input
                                            type="text"
                                            id="email"
                                            name="email"
                                            value={candidate.email}
                                            onChange={handleInputChange}
                                            onBlur={validateForm}
                                            className="form-control"
                                        />
                                        {errors.email && <span className="text-danger">{errors.email}</span>}
                                    </div>

                                    <div className="col-6">
                                        <label htmlFor="role">Select Candidate Group</label>
                                        <Select
                                            options={[
                                                { value: 0, label: "-- Select Option --" },
                                                ...candidateGroup.map((c) => ({
                                                    value: c.parameterValueID,
                                                    label: c.value,
                                                }))
                                            ]}
                                            value={{
                                                value: candidate.candidateGroupID,
                                                label: candidateGroup.find((c) => c.parameterValueID === candidate.candidateGroupID)?.value,
                                            }}
                                            onChange={(selectedOption) => {
                                                setCandidate((prevCandidate) => ({
                                                    ...prevCandidate,
                                                    candidateGroupID: selectedOption.value,
                                                }));
                                            }}
                                            isSearchable={true}
                                            placeholder="-- Select Option --"
                                        />
                                        {errors.candidateGroupID && (
                                            <span className="text-danger">{errors.candidateGroupID}</span>
                                        )}
                                    </div>

                                    <div className="col-sm-5">
                                        <label htmlFor="mobileNumber">Mobile Number<span className="text-danger">*</span></label>
                                        <input
                                            type="text"
                                            id="mobileNumber"
                                            name="mobileNumber"
                                            value={candidate.mobileNumber}
                                            onChange={handleInputChange}
                                            onBlur={validateForm}
                                            className="form-control"
                                            maxLength={10}
                                        />
                                        {errors.mobileNumber && (
                                            <span className="text-danger">{errors.mobileNumber}</span>
                                        )}
                                    </div>

                                    <div className="col-sm-5">
                                        <label htmlFor="gender">Gender</label>
                                        <div className="form-check" style={{ display: '-webkit-box', paddingLeft: '0px' }}>
                                            {value.map((option, index) => (
                                                <div key={index} style={{ marginRight: '4px' }}>
                                                    <input
                                                        type="radio"
                                                        className="form-check-input"
                                                        id={`gender_${option.DataFieldValueID}`}
                                                        name="gender"
                                                        value={option.DataFieldValueID}
                                                        checked={option.DataFieldValueID == candidate.gender ? true : false} // Set checked attribute if the valueID matches the candidate's gender
                                                        onChange={handleInputChange}
                                                    />
                                                    <label htmlFor={"gender"}>{option.DataFieldValue}</label>

                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-sm-5">
                                        <label htmlFor="address">Address</label>
                                        <input
                                            type="text"
                                            id="address"
                                            name="address"
                                            value={candidate.address}
                                            onChange={handleInputChange}
                                            onBlur={validateForm}

                                            className="form-control"
                                        />
                                        {errors.address && (
                                            <span className="text-danger">{errors.address}</span>
                                        )}
                                    </div>

                                    <div className="col-sm-5">
                                        <label htmlFor="pincode">Pincode</label>
                                        <input
                                            type="text"
                                            id="pincode"
                                            name="pincode"
                                            value={candidate.pincode}
                                            onChange={handleInputChange}
                                            onBlur={validateForm}
                                            maxLength={6}
                                            className="form-control"
                                        />
                                        {errors.pincode && (
                                            <span className="text-danger">{errors.pincode}</span>
                                        )}
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={onCloseModal} fdprocessedid="t27w74">Close</button>
                                        <button type="submit" className="btn btn-primary" fdprocessedid="nu3mc7">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            )}
                        </div>

                    </div>
                </div>
            </div>



        </>
    );
}

export default EditCandidate;
