import React from 'react';
import { useEffect, useState, useContext, useRef } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { AppContext } from "../../services/ContextProvider";
import 'chart.js/auto';
import 'chartjs-plugin-datalabels';
import ExamService from '../Exam/ExamService';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';



const _examservice = new ExamService();
const DetailReport = () => {

    const [showPopupModal, setShowPopupModal] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [cId, setcId] = useState(location.state?.cId ?? 0);
    const { showLoading, hideLoading, isAuth } =
        useContext(AppContext);
    const [ReportList, setReportList] = useState([]);
    const [selectedcandidateinfo, setselectedcandidateinfo] = useState([]);
    // const [mark, setmark] = useState([]);
    const [ansChangeCount, setansChangeCount] = useState([]);
    const [sectionCount, setsectionCount] = useState([]);
    const [CorrectCount, setCorrectCount] = useState(0);
    const [InCorrectCount, setInCorrectCount] = useState(0);
    const [TotalCount, setTotalCount] = useState(0);
    const [Examid, setExamid] = useState(location.state?.examID ?? 0);
    const [UnusualActivities, setUnusualActivities] = useState([]);
    const [examAttendBrowserDetails, setExamAttendBrowserDetails] = useState({});
    const reviewExamContentRef = useRef(null);
    const [show, setshow] = useState(false);
    const [PartialCorrectCount, setPartialCorrectCount] = useState(0)
    const [unAnsweredCount, setunAnsweredCount] = useState(0);
    const [marks, setMarks] = useState(0);
    const [negativeMarkPercentage, setNegativeMarkPercenatge] = useState(0);
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.toLocaleString('default', { month: 'short' });
    const day = currentDate.getDate();
    const gethours = currentDate.getHours();
    const getminutes = currentDate.getMinutes();
    const ampm = gethours >= 12 ? 'pm' : 'am';
    const currenthours = gethours % 12 || 12;
    const currentminutes = getminutes.toString().padStart(2, '0');
    const getReportCurrentTime = month + " " + day + " " + year + " " + currenthours + ":" + currentminutes + " " + ampm;
    var file;
    var loader;



    useEffect(() => {
        // loader = document.querySelector(".loader-div");
        showLoading();
        _examservice.GetReportDetails(cId, Examid).then((res) => {

            setReportList(res);
            setMarks(res[0]?.score === null ? 0 : res[0]?.score + '/' + res[0]?.totalScore)
            console.log(res[0]?.score === null ? 0 : res[0]?.score + '/' + res[0]?.totalScore);


        })
        // _examservice.GetReportDetailsExam(Examid).then((res) => {

        // })
        _examservice.GetReportDetailsforSelectedCandidate(cId, Examid).then((res) => {

            setselectedcandidateinfo(res);
        })
        // _examservice.GetReportDetailsforSelectedCandidateMark(cId, Examid).then((res) => {

        //     setmark(res);
        // })
        _examservice.GetReportDetailsforSelectedCandidateMSection(cId, Examid).then((res) => {

            if (Array.isArray(res) && res.length > 0) {
                const percentageRegex = /\d+(\.\d+)?%/;
                const match = res[0]?.value.match(percentageRegex);

                const extractedPercentage = match ? match[0] : '0%';
                setNegativeMarkPercenatge(extractedPercentage);
            }

            let correntcount = 0;
            let incorrentcount = 0;
            let Noquestion = 0;
            let partialCorrectCount = 0;
            let unAnsweredCount = 0;
            res.map((i) => {
                correntcount = correntcount + i.correctCount;
                incorrentcount = incorrentcount + i.incorrectCount;
                Noquestion = Noquestion + i.totalCount;
                partialCorrectCount = partialCorrectCount + i.partialCorrectCount;
                unAnsweredCount = unAnsweredCount + i.unAnsweredCount;
            })
            setInCorrectCount(incorrentcount);
            setCorrectCount(correntcount);
            setTotalCount(Noquestion);
            setsectionCount(res);
            setPartialCorrectCount(partialCorrectCount);
            setunAnsweredCount(unAnsweredCount)
        })
        _examservice.GetChangedAnswerByID(cId, Examid).then((res) => {
            console.log(res, 1);
            setansChangeCount(res);
        })

        _examservice.GetUnusualActivitiesByCandidateID(cId, Examid).then((res) => {
            console.log(res.Table1, 'T');

            setUnusualActivities(res.Table);
            setExamAttendBrowserDetails(res.Table1);
        })
        hideLoading();


    }, []);
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });


    const precentage = ReportList.map((res) => res.percentage);
    const point = (Number(ReportList[0]?.score) / Number(ReportList[0]?.totalScore)) * 100
    const result = ReportList.every((res) => res.result === 'Fail');
    const labels = result ? ['Fail'] : ['Pass'];
    const durationInMs = ReportList[0]?.duration;
    const durationInMinutes = Math.floor(durationInMs / (1000 * 60));
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = durationInMinutes % 60;
    const sectionLabels = sectionCount.map((res) => res.sectionId);
    const sectiondata = sectionCount.map((res) => res.totalCount);
    const sectionCorrectCount = sectionCount.map((res) => res.correctCount);
    const sectionInCorrectCount = sectionCount.map((res) => res.incorrectCount);
    const sectionNames = sectionCount.map((res) => res.sectionName);

    const dataSection = {
        labels: sectionNames,
        datasets: [
            {
                label: 'Correct',
                backgroundColor: 'rgba(34,139,34,0.5)',
                stack: 'Stack 1', // Add a stack identifier for stacking bars
                data: sectionCorrectCount, // Replace with your correct data counts
            },
            {
                label: 'Incorrect',
                backgroundColor: 'rgba(255,0,0,0.5)',
                stack: 'Stack 1', // Add the same stack identifier to stack bars together
                data: sectionInCorrectCount, // Replace with your incorrect data counts
            },
        ],
    };

    const optionsSection = {
        scales: {
            x: {
                stacked: true, // Enable stacking on the X-axis
            },
            y: {
                stacked: true,
                beginAtZero: true, // Ensure the Y-axis starts at 0
                // Enable stacking on the Y-axis
            },
        },
        plugins: {
            legend: {
                display: true,
            },
        },
    };

    const data = {
        labels: labels,
        datasets: [
            {
                data: [Number(precentage).toFixed(2), 100],
                backgroundColor: result == true ? '#FF0000' : '#03C04A',
                hoverBackgroundColor: result == true ? '#FF6384' : '#36A2EB',
                barThickness: 180,
            },
        ],
    };
    const dataforPie = {
        labels: ['Correct', 'Incorrect', 'Unanswered'],
        datasets: [
            {
                data: [CorrectCount, InCorrectCount, unAnsweredCount],
                backgroundColor: ['#03C04A', '#FF0000', '#FA8128'],
                hoverBackgroundColor: ['#03C04A', '#FF0000', '#FA8128'],
                borderWidth: 0, // Add this line to remove the border
            },
        ],
    };

    const chartData = {
        labels: sectionNames,
        datasets: [
            {
                // label: 'Historical Data',
                // data: [65, 59, 80, 81, 56, 55],
                data: sectiondata,
                fill: false,
                backgroundColor: '#03C04A',
                barThickness: 100,
            },
        ],
    };
    const Baroptions = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    display: false,
                },
            },
            y: {
                grid: {
                    display: false,
                },
                ticks: {
                    display: false,
                },
            },
        },
    };


    const options = {
        responsive: true,

        plugins: {
            legend: {
                position: 'bottom',
            },
            datalabels: {
                color: '#fff',
                formatter: (value, ctx) => {
                    let sum = 0;
                    let dataArr = ctx.chart.data.datasets[0].data;
                    dataArr.map((data) => {
                        sum += data;
                        return data;
                    });
                    let percentage = ((value * 100) / sum).toFixed(2) + '%';
                    return percentage;
                },
                labels: {
                    title: null,
                },
            },
        },
        cutoutPercentage: 70,
    };
    const centerText = {
        display: true,
        text: Math.round(precentage) + "%", // Replace this with your desired center text
    };
    const Sectionoptions = {
        plugins: {
            legend: {
                display: false,
            },
            tooltips: {
                enabled: false,
            },
            datalabels: {
                anchor: 'end',
                align: 'end',
                color: 'black',
                font: {
                    weight: 'bold',
                },
                formatter: function (value, context) {
                    const dataIndex = context.dataIndex;
                    const sectionName = sectionNames[dataIndex];
                    return sectionName;
                },
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                grid: {
                    color: 'rgba(0, 0, 0, 0.1)',
                },
            },
        },
    };




    const navigatetopreview = (cId, Examid, Qid, timetaken) => {
        navigate("/CandidatePreview", {
            state: { CandidateId: cId, examID: Examid, qid: Qid, TimeTaken: timetaken },
        });
    }


    return (
        <>

            <main className="main-content-wrap">
                <div className="page-title-area" style={{ paddingBottom: '12px' }}>
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="other-title row">
                                <h4 className="col-12">Vaanam Technologies Private Limited
                                </h4>
                                <h4 className="col-6">Score Card |{ReportList && ReportList.length > 0 ? ReportList[0].exam : ''}
                                </h4>
                                <span className="col-6 hide-in-pdf">
                                    <input type="button" value="Back" title="Back" id='backButton' className="back-button backbutton-end" style={{ float: 'right' }} onClick={() => navigate(-1)} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card shadow bg-white rounded" style={{ margin: '10px' }}>
                    <div className="card-body">
                        {ReportList && ReportList.length > 0 ? (
                            <div className="row" key={ReportList[0].candidateid}>
                                <div className="col">
                                    <p>Name:</p>
                                    <p>Email:</p>
                                    <p>Time Taken:</p>

                                </div>
                                <div className="col">
                                    {ReportList[0].candidateName ? (
                                        <p>{ReportList[0].candidateName}</p>
                                    ) : (
                                        <p></p>
                                    )} {ReportList[0].email ? (
                                        <p>{ReportList[0].email}</p>
                                    ) : (
                                        <p></p>
                                    )}
                                    {ReportList[0].timeTaken ? (
                                        <p>                                       {ReportList[0].timeTaken >= 60
                                            ? _examservice.convertSecondsToMinutes(ReportList[0].timeTaken) + ' Minutes'
                                            : ReportList[0].timeTaken + ' Seconds'}
                                        </p>

                                    ) : (
                                        <p></p>
                                    )}


                                </div>
                                <div className="col">
                                    <p>Start Time:</p>
                                    <p>End Time:</p>
                                    <p style={{ marginTop: '30px' }}>Exam Duration:</p>
                                </div>
                                <div className="col mg-3">
                                    <div>
                                        <p style={{ fontWeight: 'bold', margin: '0' }}>
                                            {ReportList[0]?.examStarted && new Date(ReportList[0].examStarted.split("T")[0]).toLocaleDateString('en-US', {
                                                day: '2-digit',
                                                month: 'short',
                                                year: 'numeric'
                                            }).replace(/,/g, '')}
                                        </p>
                                        <span style={{ fontSize: '11px', marginLeft: '5px' }}>
                                            {ReportList[0]?.examStarted && new Date(ReportList[0].examStarted).toLocaleTimeString('en-US', {
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                hour12: true
                                            })}
                                        </span>
                                    </div>
                                    <div>
                                        <p style={{ fontWeight: 'bold', margin: '0' }}>
                                            {ReportList[0]?.examEnd && new Date(ReportList[0].examEnd.split("T")[0]).toLocaleDateString('en-US', {
                                                day: '2-digit',
                                                month: 'short',
                                                year: 'numeric'
                                            }).replace(/,/g, '')}
                                        </p>
                                        <span style={{ fontSize: '11px', marginLeft: '5px' }}>
                                            {ReportList[0]?.examEnd && new Date(ReportList[0].examEnd).toLocaleTimeString('en-US', {
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                hour12: true
                                            })}
                                        </span>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <p style={{ margin: '0' }}>{_examservice.MillisecondsToMinutes(ReportList[0]?.duration)} Minutes</p>
                                    </div>
                                </div>




                            </div>
                        ) : (
                            <p>No candidate information available.</p>
                        )}

                    </div>
                </div>

                {ReportList && ReportList[0]?.resultType === 0 ? (


                    <div className='row justify-content-center'>
                        <h5 className="card-title" style={{ textAlign: 'center' }}>
                            Score  <span>{(ReportList[0]?.score === null ? 0 : ReportList[0]?.score) + '/' + ReportList[0]?.totalScore}</span>
                        </h5>
                        <div className='col-6'>
                            <      div class="progress" style={{ height: '300%' }}>
                                <div className={`progress-bar w-${point}`} role="progressbar" aria-label="Basic example" aria-valuenow={point} aria-valuemin="0" aria-valuemax="100" style={{ backgroundColor: '#03C04A', width: point + "%", alignItems: "center", display: "flex" }}>

                                    <span style={{ visibility: "hidden" }}> {point}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                ) : (
                    <div className='row justify-content-center'>
                        <h5 className="card-title" style={{ textAlign: 'center' }}>
                            Score  <span>{Math.round(precentage)}%</span>
                        </h5>
                        <div className='col-6'>
                            <      div class="progress" style={{ height: '300%' }}>
                                <div className={`progress-bar w-${Math.round(precentage).toString()}`} role="progressbar" aria-label="Basic example" aria-valuenow={Math.round(precentage).toString()} aria-valuemin="0" aria-valuemax="100" style={{ backgroundColor: result == true ? '#FF0000' : '#03C04A', width: Math.round(precentage).toString() + "%", alignItems: "center", display: "flex" }}>


                                    <span class="label bg-warning" style={{ width: result == true ? "25%" : "15%", borderRadius: "3px" }}>{result == true ? 'Fail' : 'Pass'}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="row justify-content-center" style={{ margin: '10px' }}>
                    {/* <div className="col-lg-6 col-sm-6  mb-4 hide-in-pdf">
                            <div className="card shadow bg-white rounded" style={{ border: 'none' }}>
                                <div className="card-body">
                                    {ReportList.map((rep) => (
                                        <div key={rep.candidateid}>
                                            <h5 className="card-title" style={{ textAlign: 'center' }}>
                                                Score <span style={{ color: getColorBasedOnPercentage(rep.percentage) }}>{Number(rep.percentage).toFixed(2)}%</span>
                                            </h5>
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '290px' }}>

                                                <Bar data={data} options={Baroptions} />

                                            </div>

                                        </div>
                                    ))}
                                </div>

                            </div>
                        </div> */}
                    <div className="col-lg-12 col-sm-12 mt-5">
                        <div className='row'>


                            <div className='col-4 reviewreportcard reviewreportcardleft'>
                                <p>{TotalCount}</p>
                                <span>Total Questions</span>
                                <hr></hr>

                                <p>{marks}</p>
                                <span>Marks</span>
                                <hr></hr>
                                <p>{negativeMarkPercentage}</p>
                                <span>Negative Marks</span>
                                <hr></hr>
                                <p>
                                    {ReportList.length > 0 ? ReportList[0]?.timeTaken >= 60
                                        ? _examservice.convertSecondsToMinutes(ReportList[0].timeTaken) + ' Minutes'
                                        : ReportList[0].timeTaken + ' Seconds' : ''}
                                </p>
                                <span>Time Taken</span>
                                <hr></hr>

                            </div>
                            <div className='col-4'>
                                {ReportList.map((rep) => (
                                    <div key={rep.candidateid}>

                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '290px' }} className='doughnut-chart-container'>
                                            <div style={{ width: '300px', position: 'relative' }}>
                                                <Doughnut data={dataforPie} options={options} />
                                                {centerText.display && (
                                                    <div
                                                        style={{
                                                            position: 'absolute',
                                                            top: '40%',
                                                            left: '50%',
                                                            transform: 'translate(-50%, -50%)',
                                                            fontSize: '24px',
                                                            fontWeight: 'bold',
                                                            color: '#000',
                                                        }}
                                                    >
                                                        {centerText.text}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                ))}

                            </div>
                            <div className='col-4 reviewreportcard reviewreportcardright'>
                                <p>{CorrectCount}</p>
                                <span>Correct</span>
                                <hr></hr>

                                <p>{PartialCorrectCount}</p>
                                <span>Partially Correct</span>
                                <hr></hr>
                                <p>{InCorrectCount}</p>
                                <span>Incorrect</span>
                                <hr></hr>
                                <p>{unAnsweredCount}</p>
                                <span>Unanswered</span>
                                <hr></hr>
                            </div>
                        </div>
                    </div>

                    {/* <div class="html2pdf__page-break"></div> */}

                    <div className="contact-list-area mt-1" >
                        <div className="card shadow bg-white rounded" style={{ border: 'none' }}>
                            <div
                                className="simplebar-content"
                                style={{ padding: 30 }}
                            >
                                <div className="others-title col-12 row hide-in-pdf">
                                    <h3 className="col-6">Attend Questions</h3>

                                </div>
                                <table className="table align-middle mt-2 hide-in-pdf">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>QID</th>
                                            <th>Status</th>
                                            <th>Difficulty</th>
                                            <th>Marks</th>
                                            <th>Section</th>
                                            <th>Time Spent</th>
                                           
                                            {/* <th>Time Spent</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedcandidateinfo.map((info, index) => (
                                            <tr key={info.questionID}>
                                                <td>{index + 1}</td> {/* Add S.No column */}

                                                <td>
                                                    <div className="info">
                                                        <h6>{info.questionID}</h6>
                                                    </div>
                                                </td>
                                                <td>

                                                    {
                                                        info.isCorrect === 'Yes' ? <i className="fa fa-check-circle" style={{ color: 'green' }} />
                                                            : <i className="fa fa-times-circle" style={{ color: 'red' }} />
                                                    }


                                                </td>
                                                <td>
                                                    <span className="location">{info.difficultLevel}</span>
                                                </td>
                                                <td>
                                                    <p>
                                                        {info.mark}
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>{info.sectionName}</p>
                                                </td>
                                                <td>


                                                    <p>  {info.timeTaken >= 60
                                                        ? _examservice.convertSecondsToMinutes(info.timeTaken) + ' Minutes'
                                                        : info.timeTaken + ' Seconds'}</p>
                                                </td>
                                            
                                            </tr>
                                        ))}

                                    </tbody>
                                </table>      

                            </div>
                        </div>
                    </div>
                </div>

            </main>

        </>
    );

};
export default DetailReport;