import React, { useRef } from "react";
import DashBoard from "../Dashboard/DashBoard";
import { useNavigate } from "react-router";
import loginlogo from "../../assets/images/max-e.png";
import LoginService from "./AuthService";
import { useState, useContext, useEffect } from "react";
import { COLORS, GetNumberOfDaysInSec } from "../../services/CommonService";
import { AppContext } from "../../services/ContextProvider";
import { showErrorMsg } from "../../Component/ToastMsg";
import Global from "../../services/Global";
import { Link } from "react-router-dom";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import useThirdPartyCookiesEnabled from '../Layout/Thirdparty';
import Swal from "sweetalert2";
import {
  Encrypt,
  Decrypt,
} from "../../services/CommonService";
function Login() {

  const areThirdPartyCookiesEnabled = useThirdPartyCookiesEnabled();
  const [rememberMe, setRememberMe] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [errorpassword, seterrorpassword] = useState(false);
  const [errorusername, seterrorusername] = useState(false);
  const currentYear = new Date().getFullYear();
  const [userIconColor, setuserIconColor] = useState(COLORS.grey);
  const [passwdIconColor, setPasswdIconColor] = useState(COLORS.grey);
  const { showLoading, hideLoading, setCookies, tempModulePrivileage, isAuth, SetisAuth } =
    useContext(AppContext);
  const Email = useRef();
  const Password = useRef();
  const navigate = useNavigate();
  const _loginService = new LoginService();
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  const onSubmit = async (e) => {
    e.preventDefault();
    let flag = 0;
    if (Password.current.value == null || Password.current?.value === '') {
      seterrorpassword(true);
      flag = 1;
    }
    else {
      seterrorpassword(false);
    }
    if (Email.current.value == null || Email.current?.value === '') {
      seterrorusername(true);
      flag = 1;
    }
    else {
      seterrorusername(false);
    }
    if (flag === 0) {
      const data = {
        Username: Email.current?.value,
        Password: Password.current?.value
      }

      showLoading();
      await _loginService.Login(data).then((response) => {

        hideLoading();
        console.log(response.employeeDetails);
        if (!response.response.isSuccess) {
          Toast.fire({
            icon: "error",
            title: "Login Failed",
          });

        } else {
          //if rememeber me is enable, cookie expires on after 30 days, otherwise expires on 1 day
          //var noOfDays = rememberMe ? 30 : 7;

          //set expiry date for auth cookiey

          // var cookieExpiryOn = GetNumberOfDaysInSec(noOfDays);
          // setCookies(Global.COOKIES.Jwt, response.token, cookieExpiryOn);      


          if (
            response?.employeeDetails != null
          ) {

            SetisAuth(true);
            localStorage.setItem(Global.COOKIES.IsAuth, 1);
            setCookies("UserName", response.employeeDetails[0].Name);
            setCookies("EmployeeCode", response?.employeeDetails[0]?.EmployeeCode);
            setCookies("Password", Password.current?.value);
            setCookies(Global.COOKIES.modulePrivileage, response.employeeDetails[0]?.UserPrivileges);
            setCookies(
              "EmployeeID",
              response.employeeDetails[0]?.EmployeeID
            );
            setCookies(
              "RoleID",
              response.employeeDetails[0]?.RoleID
            );
            localStorage.setItem(
              "Password",
              Encrypt(Password.current?.value)
            );
            localStorage.setItem(
              "EmployeeID",
              Encrypt(response.employeeDetails[0]?.EmployeeID)
            );
            setCookies("UserDetails", response.employeeDetails);
            localStorage.setItem(
              "UserDetails",
              Encrypt(response.employeeDetails)
            );
            localStorage.setItem(
              "RoleID",
              Encrypt(response.employeeDetails[0]?.RoleID)
            );
            localStorage.setItem(
              "Name",
              Encrypt(response.employeeDetails[0]?.Name)
            );
            localStorage.setItem(
              "UserPrivileges",
              Encrypt(response.employeeDetails[0]?.UserPrivileges)
            );
            localStorage.setItem(
              "EmailID",
              Encrypt(response.employeeDetails[0]?.EmailID)
            );
            localStorage.setItem(
              "CompanyID",
              Encrypt(response.employeeDetails[0]?.CompanyID)
            );
            localStorage.setItem(
              "RoleName",
              Encrypt(response.employeeDetails[0]?.RoleName)
            );
          }
          navigate("/DashBoard", { replace: true });
          // document.getElementById("Redirect").click();
          // navigate("/DashBoard");



        }
      }).catch((exception) => {
        console.log(exception);
      });
    }
  };

  function responseGoogle(r) {

    console.log(r);
  }
  function EmployeeReg() {
    navigate("/EmpCreate");
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "Username") {
      if (value === '' || value === null) {
        seterrorusername(true);
      }
      else {
        seterrorusername(false);
      }
    }
    else if (name === "password") {
      if (value === '' || value === null) {
        seterrorpassword(true);
      }
      else {
        seterrorpassword(false);
      }
    }
  };
  return (
    <>
      <div className="LoginDiv" style={{ background: "linear-gradient(90deg, #1765FD,#4FCB8D)" }}>
        <div className="account-area" >
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container">
                <div className="account-content">
                  <div className="account-header">
                    <a href="https://www.vaanamtech.com/">
                      <img src={loginlogo} alt="main-logo" style={{ width: "70%" }} />
                    </a>
                    {/* <h3>Login</h3>  */}
                  </div>

                  <form className="account-wrap" onSubmit={onSubmit}>
                    <div className="form-group mb-24">
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon1">
                          <i
                            className="fa fa-user login-icon"
                            style={{ fontWeight: "600", color: 'rgb(128 128 128)' }}
                          ></i>
                        </span>
                        <input type="text" ref={Email} className="form-control" name="Username" placeholder="Username" aria-describedby="basic-addon1" onChange={handleChange} />
                      </div>
                      {/* <i className="fa fa-user" style={{ margin: " -7px 0px 0px 0px", color: "grey" }}></i> */}

                      {errorusername && (
                        <p className="text-danger mt-1">Username is required</p>
                      )}
                    </div>
                    <div className="form-group mb-24">
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon1">
                          <i
                            className="fa fa-unlock-alt login-icon"
                            style={{ fontWeight: "600", color: passwdIconColor }}
                          ></i>
                        </span>
                        <input
                          name="password"
                          type={showPassword ? "text" : "password"}
                          className="form-control"
                          placeholder="Password"
                          aria-label="Password"
                          onChange={handleChange}
                          // onFocus={seterrorpassword(false)}
                          aria-describedby="basic-addon1"
                          ref={Password}
                        />
                        <span class="input-group-text">
                          <a
                            className="login-icon eye-icon"
                            href="#"
                            onClick={(e) => setShowPassword(!showPassword)}
                          >
                            <i
                              className={
                                showPassword ? "fa fa-eye" : "fa fa-eye-slash	"
                              }
                            ></i>
                          </a>
                        </span>
                      </div>
                      {errorpassword && (
                        <p className="text-danger mt-1">Password is required</p>
                      )}
                    </div>



                    <div className="form-group mb-24">
                      <a href="/ForgotPassword" className="forgot">Forgot Password?</a>
                    </div>
                    <div className="form-group mb-24">
                      <button type="submit" className="default-btn" >Log In</button>
                    </div>
                    <div className="text-center mt-lg"><small class="" style={{ textAlign: "center" }}>© Copyright {currentYear}. All rights reserved.</small></div>
                  </form>
                  {/* <div className="form-group mb-24" style={{ display: "none" }}>
                    <Link id="Redirect" to="/DashBoard"></Link>
                  </div> */}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Login;