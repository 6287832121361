import React from "react";
import AxiosService from "../../services/AxiosService";
const Auth = new AxiosService();

class EmailTemplateServices {
    MailCheckTable_AddMails = async (ExamID, EmailID, IsTaken) => {
        return Auth.post(`EmailTemplate/MailCheckTable_AddMails?ExamID=${ExamID}&EmailID=${EmailID}&IsTaken=${IsTaken}`);
    }

    ReExam = async (ExamID) => {
        return Auth.get(`EmailTemplate/ReExam?ExamID=${ExamID}`);
    }

    ReExam_Submit = async (ExamID, CandidateID) => {
        return Auth.post(`EmailTemplate/ReExam_Submit?ExamID=${ExamID}&CandidateID=${CandidateID}`);
    }
    GetSHAREEXAMTemplate = async () => {
        return Auth.get(`EmailTemplate/GetSHAREEXAMTemplate`);
    }
    Check_mailForSendTest = async (EmailIds, ExamID) => {
        return Auth.get(`EmailTemplate/Check_mailForSendTest?EmailIds=${EmailIds}&ExamID=${ExamID}`);
    }
}

export default EmailTemplateServices;