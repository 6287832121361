import React, { useEffect, useState } from 'react';
import CandidateQuestionView from '../../services/CandidateQuestionView'
import axios from 'axios';
import ExamService from '../Exam/ExamService';
const MalpracticeActivity = ({ examID, candidateID, handleSubmit, ExamAttendID }) => {


  const _CandidateQuestionView = new CandidateQuestionView();
  var allowMalPracticeCount = null;

  const [malPracticeCount, setMalPracticeCount] = useState(0)
  useEffect(() => {

    _CandidateQuestionView.GetUnusualBehaviourCountBYExam(examID, candidateID, ExamAttendID)
      .then((response) => {

        if (response != null) {
          allowMalPracticeCount = response[0].UnusualBehaviourCount;
          response[0].Count !== null ? setMalPracticeCount(response[0].Count) : <></>;
        }

      })

      .catch((exception) => {
        console.log(exception);
      });

    const handleDelayFunction = () => {
      window.addEventListener('resize', handleWindowEvent);
      document.addEventListener('visibilitychange', handleWindowEvent);
      window.addEventListener('beforeunload', handleWindowEvent);
      window.addEventListener('keydown', handleWindowEvent);
      window.addEventListener('keyup', handleWindowEvent);

    };
    const timeout = setTimeout(handleDelayFunction, 1000);

    return () => {
      clearTimeout(timeout);
      window.removeEventListener('resize', handleWindowEvent);
      document.removeEventListener('visibilitychange', handleWindowEvent);
      window.removeEventListener('beforeunload', handleWindowEvent);
      window.removeEventListener('keydown', handleWindowEvent);
      window.removeEventListener('keyup', handleWindowEvent);
    };
  }, [])
  const detectBrowser = () => {
    const userAgent = navigator.userAgent;
    let browserName;

    if (userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1) {
      browserName = 'Opera';
    } else if (userAgent.indexOf('Edg') > -1) {
      browserName = 'Microsoft Edge';
    } else if (userAgent.indexOf('Chrome') > -1) {
      browserName = 'Google Chrome';
    } else if (userAgent.indexOf('Safari') > -1) {
      browserName = 'Safari';
    } else if (userAgent.indexOf('Firefox') > -1) {
      browserName = 'Mozilla Firefox';
    } else if (userAgent.indexOf('MSIE') > -1 || !!document.documentMode === true) {
      browserName = 'Internet Explorer';
    } else {
      browserName = 'Unknown';
    }

    return browserName;
  };
  const fetchIpAddress = async () => {
    try {
      const response = await axios.get('https://api.ipify.org/?format=json');
      const data = response.data;
      return data.ip

    } catch (error) {
      console.error('Error fetching IP address:', error);
      return null;
    }
  };
  const saveUnusualBehaviourCount = async (count, activity = '') => {

    if (allowMalPracticeCount != null) {

      if (count > allowMalPracticeCount) {
        handleSubmit('terminate');

      }
      else {
        const browserName = await detectBrowser();
        const IP = await fetchIpAddress();

        _CandidateQuestionView.SaveUpdateUnusualBehaviourCountBYCandidate(count, candidateID, examID, activity, browserName, IP, ExamAttendID)
          .then((response) => {
            if (response != null) {
              setMalPracticeCount(response[0].UnusualBehaviourCount);
              if (response[0].UnusualBehaviourCount == allowMalPracticeCount) {
                handleSubmit('terminate');
              }
            }
          })

          .catch((exception) => {
            console.log(exception);
          });
      }
    }


  };
  const handleWindowEvent = (event) => {

    switch (event.type) {

      case 'resize':

        setMalPracticeCount(prev => {
          const updatedCount = prev + 1;

          saveUnusualBehaviourCount(updatedCount, 'Browser Resized'); // Save the updated count immediately
          return updatedCount; // Update the state with the new count
        });


        break;

      case 'keydown':
        if (event.ctrlKey) {
          if (event.key === 'c') {


            setMalPracticeCount(prev => {
              const updatedCount = prev + 1;

              saveUnusualBehaviourCount(updatedCount, 'Copy'); // Save the updated count immediately
              return updatedCount; // Update the state with the new count
            });


          } else if (event.key === 'v') {

            setMalPracticeCount(prev => {
              const updatedCount = prev + 1;

              saveUnusualBehaviourCount(updatedCount, 'Paste'); // Save the updated count immediately
              return updatedCount; // Update the state with the new count
            });

          }
          else if (event.key === 'F12') {

            setMalPracticeCount(prev => {
              const updatedCount = prev + 1;

              saveUnusualBehaviourCount(updatedCount, 'Inspect Opened'); // Save the updated count immediately
              return updatedCount; // Update the state with the new count
            });

          }

        }
        else if (event.key === 'Tab') {
          // Check for Ctrl+Tab
          setMalPracticeCount(prev => {
            const updatedCount = prev + 1;
            saveUnusualBehaviourCount(updatedCount, 'Ctrl+Tab'); // Save the updated count immediately
            return updatedCount; // Update the state with the new count
          });
        }

        else {
          if (
            event.key === 'Meta' ||
            (event.key === 's' || event.key === 'S')
          ) {

            setMalPracticeCount(prev => {
              const updatedCount = prev + 1;

              saveUnusualBehaviourCount(updatedCount, 'Screenshot'); // Save the updated count immediately
              return updatedCount; // Update the state with the new count
            });
          }
        }
        break;

      case 'visibilitychange':

        if (document.hidden) {
          // The document is not visible, indicating the user switched to another tab or application
          setMalPracticeCount(prev => {
            const updatedCount = prev + 1;
            saveUnusualBehaviourCount(updatedCount, 'Browser Minimized');
            return updatedCount;
          });
        }
        break;


      case 'beforeunload':
        setMalPracticeCount(prev => {
          const updatedCount = prev + 1;

          saveUnusualBehaviourCount(updatedCount, 'Page Reload'); // Save the updated count immediately
          return updatedCount; // Update the state with the new count
        });
        break;
      case 'keyup':
        if (event.key === 'PrintScreen') {

          setMalPracticeCount(prev => {
            const updatedCount = prev + 1;

            saveUnusualBehaviourCount(updatedCount, 'Screenshot'); // Save the updated count immediately
            return updatedCount; // Update the state with the new count
          });
        }
        break;
      default:
        break;
    }
  };

  ;

  return (
    <div>

    </div>
  );
};

export default MalpracticeActivity;
