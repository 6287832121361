
import React, { useEffect, useState, useContext } from "react";
import CandidateQuestionViewServices from "../../services/CandidateQuestionViewService";
import { useLocation } from "react-router-dom";
import { AppContext } from "../../services/ContextProvider";
import { IMAGE_URL } from '../../env';
import $ from "jquery";

var candidateQuestionServ = new CandidateQuestionViewServices();

const QuestionPaperDownload = ({ examID, examName, setShowQuestionPaper, showQuestionPaper }) => {

    debugger;
    const { showLoading, hideLoading, getCookies } =
        useContext(AppContext);
    const [questionList, setQuestionList] = useState([]);
    const [answerLists, setAnswerList] = useState([]);
    const location = useLocation();
    const [candidateID, setCandidateID] = useState(location.state?.candidateID ?? 0);
    const [CandidateName, setCandidateName] = useState(location.state?.canditateName ?? null);
    const [currentQuestion, setcurrentQuestion] = useState({});
    const [currentAnswers, setcurrentAnswers] = useState([]);
    let examAttendID = 0;
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

    useEffect(() => {
        showLoading();
        document.body.style.overflow = "hidden";
        const fetchData = async () => {

            try {
                Getlist();
            } catch (err) {
                console.log(err);
            }

        };
        fetchData();

        setTimeout(() => {
            exportPDF();

        }, 2500);

    }, []);
    const getPlainText = (htmlString) => {
        const element = document.createElement("div");
        element.innerHTML = htmlString;
        return element.innerText;
    };

    const Getlist = async () => {

        if (examID > 0) {
            debugger;
            await candidateQuestionServ.questionList(candidateID, examID, examAttendID).then((res) => {
                if (res != null) {

                    console.log(res.data.questionList);
                    localStorage.setItem('questionCount', res?.data?.questionList);
                    const { questionList, answerList } = res?.data;
                    if (questionList?.length > 0) {
                        setQuestionList([...questionList]);
                        setcurrentQuestion(questionList[currentQuestionIndex]);
                        var currentQues = questionList[currentQuestionIndex].questionID;
                        if (answerList?.length > 0) {
                            debugger;
                            setAnswerList([...answerList]);
                            const curroption = answerList.filter((answer) => answer.questionID === currentQues);
                            setcurrentAnswers([...curroption]);
                        }
                    }
                }
            }).catch((err) => {
                console.log(err);
            });
        }

    };
    const exportPDF = () => {
        kendo.drawing
            .drawDOM($("#reviewexamcontent"))
            .then(function (group) {
                return kendo.drawing.exportPDF(group, {
                    paperSize: "auto",
                    margin: "1cm",
                });
            })
            .done(function (data) {
                // Save the PDF file
                kendo.saveAs({
                    dataURI: data,
                    fileName: `${examName}_Questions.pdf`,
                    proxyURL: "https://demos.telerik.com/kendo-ui/service/export",
                });
            });
        setShowQuestionPaper(false);
        document.body.style.overflow = "auto";

        hideLoading();
    };


    return (
        <>
            {showQuestionPaper ? <div className="all-section-area" id="allQuestion">
                <main className="main-content-wrap">
                    <div className="overview-content-area" id="reviewexamcontent">
                        <div className="wrapper col-md-8 " >
                            <div className="wrapper_inner">
                                <div className="content">
                                    <div className="headbox">
                                        <div className="headleft">
                                            {/* <img id="logo" src="https://admin.speedexam.net/logo/633a9c10-ac7d-428a-a779-685213dbb85a.png" style={{ borderWidth: '0px', display: 'none' }} /> */}
                                            <div className="examhead">
                                                <span id="orgname" className="orgname">Vaanam Technologies Private Limited</span>
                                                <div data-div="examname" className="examname">{examName}</div>
                                            </div>
                                            <div className="extraoptionbox">
                                                <div className="extraoptions" data-div="candidatename">
                                                    <span className="text">Name:</span><span className="fortext" />
                                                </div>
                                                <div className="extraoptions" data-div="examdate">
                                                    <span>Date:</span><span className="fortext" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="headright">
                                            <div className="headrightinner">
                                                <div data-div="examid">
                                                    <span className="examid">Exam ID: {examID}</span>
                                                </div>
                                                <div data-div="examscorebox">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="questioncotent" className="smallfont">
                                        {questionList.map((currentQuestion, index) => (
                                            <div className="questioncontentbox">
                                                <div className="questioncontentboxinner" >
                                                    <div className="questioncontent_head">
                                                        <div style={{ float: 'left', width: '100%', paddingBottom: '3px' }}>

                                                            <span className="quenumber">Question:
                                                                <span className="number">{index + 1}</span> of {questionList.length}</span>

                                                        </div>

                                                    </div>
                                                    <div className="questioncontent">
                                                        <div className="questioncontentinner">
                                                            <div className="questiontextbox">
                                                                <span className="questiontext">
                                                                    {currentQuestion.question != null ? getPlainText(currentQuestion.question) : ''}</span>
                                                            </div>

                                                            <div className="answerbox" >
                                                                {answerLists
                                                                    .filter((option) => option.questionID === currentQuestion.questionID)
                                                                    .map((option, index) => {
                                                                        return (
                                                                            <div className="anscontent">
                                                                                <div className="ansnobox">
                                                                                    <span className="radiostyle" />
                                                                                </div>
                                                                                <div className="anstext">{getPlainText(option.answerValue)}</div>
                                                                            </div>
                                                                        )

                                                                    })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>


                                    <div className="col-md-12 text-center form-group"><span style={{ display: 'block', fontSize: '12px', color: '#777' }}>--- END OF ANSWER KEY ---</span></div>

                                </div>
                            </div>

                        </div>
                    </div>
                </main>

            </div> : <></>}
        </>

    )
}

export default QuestionPaperDownload;

