import { useState, useEffect } from 'react';

const useThirdPartyCookiesEnabled = () => {
    
  const [areThirdPartyCookiesEnabled, setThirdPartyCookiesEnabled] = useState(null);

  useEffect(() => {
    const checkThirdPartyCookies = () => {
      setThirdPartyCookiesEnabled(navigator.cookieEnabled);
    };

    checkThirdPartyCookies();

    // Add event listener to detect changes in cookie status
    window.addEventListener('storage', checkThirdPartyCookies);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener('storage', checkThirdPartyCookies);
    };
  }, []);

  return areThirdPartyCookiesEnabled;
};

export default useThirdPartyCookiesEnabled;
