import React, { useState, useEffect, useContext } from "react";
import CanditateServices from "../../services/CanditateService";
import { showErrorMsg, showSaveSuccess } from "../ToastMsg";
import { Decrypt } from "../../services/CommonService";
import { AppContext } from "../../services/ContextProvider";
import Swal from "sweetalert2";
import ExamService from "../Exam/ExamService";
import Select from "react-select";
import { error } from "jquery";


var Canditateobj = new CanditateServices();
var ExamServiceObj = new ExamService();
function CreateCandidate({ setShowCreateModal, candidateList }) {
    const { showLoading, hideLoading, setCookies, tempModulePrivileage, isAuth, SetisAuth } =
        useContext(AppContext);
    const [candidate, setCandidate] = useState({
        firstName: "",
        lastName: "",
        email: "",
        mobileNumber: "",
        address: "",
        pincode: "",
        gender: "",
        candidateGroupID: ""
    });
    const [errors, setErrors] = useState({});
    const [value, setvalue] = useState([]);
    const [candidateGroup, setcandidateGroup] = useState([]);
    const [numberValue, setNumberValue] = useState('');
    const [pincodeValue, setpincodeValue] = useState('');

    useEffect(() => {

        Canditateobj.getGenderDropdown().then((res) => {
            if (res != null && res?.data?.length > 0) {
                setvalue(res?.data);
            }
        });
        ExamServiceObj.GetExamParameterList().then((res) => {
            if (res != null) {
                const candidateGroup = res.parameterList.filter(
                    (option) => option.parameterCode === "CANDIDATEGROUPS"
                );
                setcandidateGroup(candidateGroup);
            }
        }).catch((err) => {
            console.log(err);
        });

        // var a = document.getElementById("candidateView");
        // a.style.opacity = "30%";
    }, []);
    const onCloseModal = () => {
        setShowCreateModal(false);
        var a = document.getElementById("candidateView");
        a.style.opacity = "inherit";
    };
    const validateForm = (e) => {

        const { name, value } = e.target;
        let valid = true;
        const errors = {};
        if (name == 'firstName') {
            if (!candidate.firstName) {
                errors.firstName = "First Name is required";
                valid = false;
            }
            else {
                errors.firstName = "";
            }
        }

        // if (name == 'candidateGroupID') {
        //     if (candidate.candidateGroupID === "0") {
        //         errors.candidateGroupID = "Select Candidate Group";
        //         valid = false;
        //     }
        //     else {
        //         errors.candidateGroupID = "";
        //     }
        // }
        if (name == 'email') {
            if (!candidate.email) {
                errors.email = "Email is required";
                valid = false;
            } else if (!isValidEmail(candidate.email)) {
                errors.email = "Invalid Email Address";
                valid = false;
            } else {
                errors.email = "";
            }
        }

        if (name == 'mobileNumber') {
            if (!candidate.mobileNumber) {
                errors.mobileNumber = "Mobile Number is required";
                valid = false;
            }
            else if (candidate.mobileNumber.length < 10) {
                errors.mobileNumber = "Mobile Number should be at least 10 digits";
                valid = false;
            }
            else {
                errors.mobileNumber = "";
            }
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            ...errors,
        }));
        return valid;
    };

    const submitForm = () => {
        let valid = true;
        const errors = {};
        if (!candidate.firstName) {
            errors.firstName = "First Name is required";
            valid = false;
        }
        
        if (!candidate.email) {
            errors.email = "Email is required";
            valid = false;
        } else if (!isValidEmail(candidate.email)) {
            errors.email = "Invalid Email Address";
            valid = false;
        }

        if (!candidate.mobileNumber) {
            errors.mobileNumber = "Mobile Number is required";
            valid = false;
        }
        else if (candidate.mobileNumber.length < 10) {
            errors.mobileNumber = "Mobile Number should be at least 10 digits";
            valid = false;
        }
        setErrors(errors);
        return valid;
    };

    const isValidEmail = (email) => {
        // Email validation logic
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });
    const onSubmit = (e) => {

        e.preventDefault();
        const formValid = submitForm();
        if (formValid) {
            const genderElement = document.querySelector("input[type='radio'][name=gender]:checked");
            const data = {
                firstName: document.getElementById("firstName").value,
                lastName: document.getElementById("lastName").value,
                candidateName: document.getElementById("firstName").value + " " + document.getElementById("lastName").value,
                gender: genderElement !== null ? parseInt(genderElement.value) : 0,
                status: "AC",
                mobileNumber: document.getElementById("mobileNumber").value,
                address: document.getElementById("address").value,
                pincode: document.getElementById("pincode").value,
                email: document.getElementById("email").value,
                candidateGroupID: candidate.candidateGroupID,
                parameter: 'AC'
            };
            showLoading();

            // if (candidate.examID == 0) {
            Canditateobj.BulkSaveCanditate(data).then((res) => {
                if (res.data > 0) {
                    onCloseModal();
                    hideLoading();
                    Toast.fire({
                        icon: "success",
                        title: "Created Successfully",
                    });
                    candidateList();
                } else {
                    hideLoading();
                    Toast.fire({
                        icon: "error",
                        title: "Failed to Save",
                    });
                }
            });

        }
    };
    const handleInputChange = (e) => {
        if (e.value != null) {
            if (e.value === 0) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    candidateGroupID: "Select Candidate Group"
                }));
            }
            else {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    candidateGroupID: ""
                }));

                setCandidate((prevState) => ({
                    ...prevState,
                    candidateGroupID: e.value,
                }));
            }
        }
        else {
            const { name, value } = e.target;
            if (name === "mobileNumber") {
                const numericValue = value.replace(/[^0-9]/g, "");
                console.log(numericValue);
                if (numericValue === "") {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        [name]: "Enter numbers only",
                    }));
                } else {
                    console.log('else');
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        [name]: "",
                    }));
                }
                setNumberValue(numericValue);
                setCandidate((prevState) => ({
                    ...prevState,
                    [name]: numericValue,
                }));
            } else if (name === "pincode") {
                const numericValue = value.replace(/[^0-9]/g, "");
                if (value !== numericValue) {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        [name]: "Enter numbers only",
                    }));
                } else {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        [name]: "",
                    }));
                }
                setpincodeValue(numericValue);
                setCandidate((prevState) => ({
                    ...prevState,
                    [name]: numericValue,
                }));
            } else {
                setCandidate((prevState) => ({
                    ...prevState,
                    [name]: value,
                }));
            }

        }
    };


    return (
        <>
            <div className="modal fade show" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-modal="true" role="dialog" style={{ display: 'flex', alignItems: 'center' }}>
                <div className="modal-dialog" style={{ margin: 'auto' }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title MuiTypography-h6" id="staticBackdropLabel">Create Candidate</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" fdprocessedid="ttcp2q" onClick={onCloseModal}
                            />
                        </div>
                        <div className="modal-body">


                            <form className="row g-3" onSubmit={onSubmit} autoComplete="off">
                                <div className="col-6">
                                    <label htmlFor="firstName" className="form-label">First Name<span className="text-danger">*</span></label>
                                    <input
                                        type="text"
                                        id="firstName"
                                        name="firstName"
                                        onChange={handleInputChange}
                                        onBlur={(e) => validateForm(e)}
                                        className="form-control"
                                    />
                                    {errors.firstName && (
                                        <span className="text-danger">{errors.firstName}</span>
                                    )}
                                </div>

                                <div className="col-6">
                                    <label htmlFor="lastName" className="form-label">Last Name</label>
                                    <input
                                        type="text"
                                        id="lastName"
                                        name="lastName"
                                        onChange={handleInputChange}
                                        onBlur={(e) => validateForm(e)}
                                        className="form-control"
                                    />
                                    {/* {errors.LastName && (
                                            <span className="text-danger">{errors.LastName}</span>
                                        )} */}
                                </div>

                                <div className="col-6">
                                    <label htmlFor="email" className="form-label">Email<span className="text-danger">*</span></label>
                                    <input
                                        type="text"
                                        id="email"
                                        name="email"
                                        onChange={handleInputChange}
                                        onBlur={(e) => validateForm(e)}
                                        className="form-control"
                                    />
                                    {errors.email && <span className="text-danger">{errors.email}</span>}
                                </div>


                                <div className="col-6">
                                    <label htmlFor="role" className="form-label">Select Candidate Group</label>
                                    <Select
                                        id="candidateGroupID"
                                        name="candidateGroupID"
                                        options={[
                                            { value: 0, label: "-- Select Option --" },
                                            ...candidateGroup.map((c) => ({
                                                value: c.parameterValueID,
                                                label: c.value,
                                            }))
                                        ]}
                                        value={
                                            candidate.candidateGroupID !== 0
                                                ? { value: candidate.candidateGroupID, label: candidateGroup.find((c) => c.parameterValueID === candidate.candidateGroupID)?.value }
                                                : null
                                        }
                                        onBlur={(e) => validateForm(e)}
                                        // onChange={(selectedOption) => {
                                        //     setCandidate((prevCandidate) => ({
                                        //         ...prevCandidate,
                                        //         candidateGroupID: selectedOption.value,
                                        //     }));
                                        // }}
                                        onChange={(e) => handleInputChange(e)}
                                        isSearchable={true}
                                        placeholder="-- Select Option --"
                                    />

                                    {/* {errors.candidateGroupID && (
                                        <span className="text-danger">{errors.candidateGroupID}</span>
                                    )} */}
                                </div>
                                <div className="col-6">
                                    <label htmlFor="mobileNumber" className="form-label">Mobile Number<span className="text-danger">*</span></label>
                                    <input
                                        type="text"
                                        id="mobileNumber"
                                        value={numberValue}
                                        name="mobileNumber"
                                        onChange={handleInputChange}
                                        onBlur={validateForm}
                                        className="form-control"
                                        maxLength={10}
                                    />
                                    {errors.mobileNumber && (
                                        <span className="text-danger">{errors.mobileNumber}</span>
                                    )}
                                </div>
                                <div className="col-6">
                                    <label htmlFor="gender" className="form-label">Gender</label>
                                    <div className="form-check" style={{ display: '-webkit-box', paddingLeft: '0px' }}>
                                        {value.map((option, index) => (
                                            <div key={index} style={{ marginRight: '4px' }}>
                                                <input
                                                    type="radio"
                                                    className="form-check-input"
                                                    id={`gender_${option.DataFieldValueID}`}
                                                    name="gender"
                                                    value={option.DataFieldValueID}
                                                    onChange={handleInputChange}
                                                />
                                                <label htmlFor={"gender"}>{option.DataFieldValue}</label>

                                            </div>
                                        ))}
                                    </div>
                                </div>



                                <div className="col-6">
                                    <label htmlFor="address" className="form-label">Address</label>
                                    <input
                                        type="text"
                                        id="address"
                                        name="address"
                                        value={candidate.address}
                                        onChange={handleInputChange}
                                        onBlur={validateForm}

                                        className="form-control"
                                    />
                                    {errors.address && (
                                        <span className="text-danger">{errors.address}</span>
                                    )}
                                </div>

                                <div className="col-6">
                                    <label htmlFor="pincode" className="form-label">Pincode</label>
                                    <input
                                        type="text"
                                        id="pincode"
                                        name="pincode"
                                        value={pincodeValue}
                                        onChange={handleInputChange}
                                        onBlur={validateForm}
                                        className="form-control"
                                        maxLength={6}
                                    />
                                    {errors.pincode && (
                                        <span className="text-danger">{errors.pincode}</span>
                                    )}
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={onCloseModal} fdprocessedid="t27w74">Close</button>
                                    <button type="submit" className="btn btn-primary" fdprocessedid="nu3mc7">
                                        Submit
                                    </button>
                                </div>
                            </form>

                        </div>

                    </div>
                </div>
            </div>



        </>
    );
}

export default CreateCandidate;
