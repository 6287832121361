module.exports = {
  COOKIES: {
    Jwt: "JWT",
    MyJwt:"MyJwt",
    PeriodicStationID: "PeriodicStationID",
    IOTParams: "IOT",
    UserName: "username",
    Password: "drowssap",
    modulePrivileage: "modulePrivileage",
    EmployeeID: "EmployeeID",
    CalendarMonth: "CalendarMonth",
    UserID: "UserID",
    UserDetails: "UserDetails",
    UserGoodID: "UserGoodID",
    Privilege: "Privilege",
    IsAuth: "IsAuth",
    ID : "ID"
  },
};
