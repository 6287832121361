import React, { useState, useEffect } from "react";
import EmailTemplateServices from "../EmailTemplate/EmailTemplateService";
import Swal from "sweetalert2";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

const _emailService = new EmailTemplateServices();
export default function ReExam({ examID, setReExam, reExam, getData }) {
    debugger;
    console.log(getData);
    console.log(examID);
    const [list, setList] = useState([]);
    const [candidateID, setCandidateID] = useState();
    const [selectedOptions, setSelectedOptions] = useState([]);
    const navigate = useNavigate();


    useEffect(() => {
        _emailService.ReExam(examID).then((res) => {
            if (res != null) {
                setList(res?.data)
            }
        }).catch((err) => {

        });
    }, [])
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });

    const onCloseModal = () => {
        setReExam(false);
    }
    const onSubmit = async (e) => {
        e.preventDefault();
        try {

            for (const value of selectedOptions) {
                const res = await _emailService.ReExam_Submit(examID, value?.value);
                if (res != null) {
                    debugger;
                    onCloseModal();

                    Toast.fire({
                        icon: "success",
                        title: "Submit Successfully",
                    });
                    navigate("/ExamSummary", {
                        state: { examSummaryModel: getData, navigate: -1 },
                    });
                } else {
                    Toast.fire({
                        icon: "error",
                        title: "failed to submit",
                    });
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    // const handleChange = (event) => {

    //     const selectedIndex = event?.target.selectedIndex;
    //     const selectedCandidateID = parseInt(event?.target.options[selectedIndex]?.getAttribute("data-candidateid"));
    //     setCandidateID(selectedCandidateID);
    // }
    const handleChange = (selected) => {

        setSelectedOptions(selected);
    };
    const customStyles = {
        container: (provided) => ({
            ...provided,
            width: "100%",
            marginBottom: "1rem",
        }),
        control: (provided, state) => ({
            ...provided,
            border: state.isFocused ? "2px solid #5a8dee" : "1px solid #ced4da",
            boxShadow: state.isFocused ? "0 0 0 0.15rem rgba(90, 141, 238, 0.25)" : null,
            "&:hover": {
                border: "1px solid #adb5bd",
            },
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: "#5a8dee",
            borderRadius: "4px",
            color: "white",
            marginRight: "4px",
            marginBottom: "4px",
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color: "white",
            paddingLeft: "6px",
            paddingRight: "4px",
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            color: "white",
            ":hover": {
                backgroundColor: "#5a8dee",
                color: "white",
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "#5a8dee" : null,
            color: state.isSelected ? "white" : "black",
            "&:hover": {
                backgroundColor: "#f8f9fa",
            },
        }),
    };


    return (

        <>
            {reExam ? <form className="row g-3" autoComplete="off" onSubmit={(e) => onSubmit(e)}>
                <div className="modal fade show" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-modal="true" role="dialog" style={{ display: 'flex', alignItems: 'center', margin: '0' }}>
                    <div className="modal-dialog">
                        <div className="modal-content my-auto">
                            <div className="modal-header">
                                <h5 className="modal-title MuiTypography-h6" id="staticBackdropLabel">Re Exam</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" fdprocessedid="ttcp2q" onClick={onCloseModal}
                                />
                            </div>
                            <div className="modal-body">


                                <div className="row">
                                    <label

                                        htmlFor="formGroupExampleInput"
                                        className="form-label col-md-6"
                                    >
                                        Candidate List
                                        <span className="text-danger"> *</span>
                                    </label>
                                    {list ? <Select
                                        options={list.map(option => ({ value: option.CandidateID, label: option.Email }))}
                                        value={selectedOptions}
                                        onChange={handleChange}
                                        isMulti
                                        styles={customStyles}
                                        placeholder="Select candidates..."
                                    /> : <></>}


                                </div>
                            </div>
                            <div className="modal-footer mailtemplatefooter">
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={onCloseModal} fdprocessedid="t27w74">Close</button>
                                <button type="submit" className="btn btn-primary" fdprocessedid="nu3mc7">
                                    Submit
                                </button>
                            </div>

                        </div>
                    </div>
                </div>

            </form> : <></>}
        </>

    );
}