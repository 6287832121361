import React, { useEffect, useState, useContext } from "react";
import { showErrorMsg } from "../ToastMsg";
import EmployeeService from "./EmployeeService";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import { useNavigate, useLocation } from "react-router-dom";
import { AppContext } from "../../services/ContextProvider";
import { Decrypt } from "../../services/CommonService";
import Swal from "sweetalert2";
import { FaEye, FaEyeSlash } from "react-icons/fa";


var empObj = new EmployeeService();
function EmpCreate() {
    const { showLoading, hideLoading, setCookies, tempModulePrivileage, isAuth, SetisAuth } =
        useContext(AppContext);
    const location = useLocation();
    const navigate = useNavigate();
    const empId = location.state?.empId ?? 0;
    const [model, setModel] = useState({});
    const [startDateandTime, setStartDateandTime] = useState(new Date());
    const [errors, setErrors] = useState({});
    const [role, setrole] = useState([]);
    const [Company, setcompany] = useState([]);
    const currentDate = new Date();
    const [showPassword, setShowPassword] = useState(false);
    const [oldpassword, setoldpassword] = useState(null);
    const [empcode, Setempcode] = useState(null);

    const [candidate, setCandidate] = useState({
        firstName: "",
        lastName: "",
        password: "",
        employeeCode: "",
        emailID: "",
        dateOfBirth: "",
        contactNumber: "",
        address: "",
        pincode: "",
        roleID: "",
        companyID: ""
    });

    useEffect(() => {

        empObj.EmployeeDropdown().then((res) => {
            if (res != null) {
                const { CompanyList, RoleList } = res.data;
                if (CompanyList.length > 0) {
                    setcompany([...CompanyList])
                    if (RoleList.length > 0) {
                        setrole([...RoleList]);
                    }
                }
            }
        })
    }, []);
    useEffect(() => {

        if (empId !== null) {
            showLoading();
            empObj.GetEmpListById(empId).then((res) => {

                if (res != null && res?.data != null) {

                    setoldpassword(res?.data?.password);
                    Setempcode(res?.data?.employeeCode);
                    const { dateOfBirth, ...rest } = res?.data;
                    const dateOfBirthObj = dateOfBirth ? new Date(dateOfBirth) : null;

                    setCandidate({ ...rest, dateOfBirth: dateOfBirthObj });
                }
                setTimeout(() => {
                    hideLoading();
                }, 200)
            }).catch((err) => {
                console.log(err);
            });
        }

    }, [empId]);


    const validateForm = () => {

        let valid = true;
        const errors = {};
        if (!candidate.firstName?.trim()) {
            errors.firstName = "First Name is required";
            valid = false;
        } else if (!/^[A-Za-z\s]+$/.test(candidate.firstName)) {
            errors.firstName = "First Name should only contain letters";
            valid = false;
        }

        if (!candidate.lastName?.trim()) {
            errors.lastName = "Last Name is required";
            valid = false;
        } else if (!/^[A-Za-z\s]+$/.test(candidate.lastName)) {
            errors.lastName = "Last Name should only contain letters";
            valid = false;
        }


        if (!candidate.password) {
            errors.password = "Password is required";
            valid = false;
        } else {
            const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/;
            if (!passwordRegex.test(candidate.password)) {
                if (candidate.password.length < 8) {
                    errors.password = "Password must be at least 8 characters long";
                } else {
                    errors.password =
                        "Password must contain at least one capital letter, one number digit, and one special character";
                }
                valid = false;
            }
        }

        if (!candidate.emailID) {
            errors.emailID = "Email is required";
            valid = false;
        } else if (!isValidEmail(candidate.emailID)) {
            errors.emailID = "Invalid Email Address";
            valid = false;
        }
        if (!candidate.contactNumber) {
            errors.contactNumber = "Contact Number is required";
            valid = false;
        } else {
            const numericValue = String(candidate.contactNumber).replace(/\D/g, "");
            if (candidate.contactNumber != numericValue) {
                errors.contactNumber = "Enter Number Only";
                valid = false;
            } else if (numericValue.length !== 10) {
                errors.contactNumber = "Contact Number should have 10 digits";
                valid = false;
            }
        }

        if (!candidate.dateOfBirth) {
            errors.dateOfBirth = "Date of Birth is required";
            valid = false;
        }

        if (!candidate.roleID) {
            errors.roleID = "Role is required";
            valid = false;
        } else if (candidate.roleID === '0') {
            errors.roleID = "Role is required";
            valid = false;
        }

        if (!candidate.companyID) {
            errors.companyID = "Company is required";
            valid = false;
        } else if (candidate.companyID === '0') {
            errors.companyID = "Company is required";
            valid = false;
        }

        setErrors(errors);
        return valid;
    };

    const isValidEmail = (email) => {
        // Email validation logic
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });
    const onSubmit = (e) => {

        e.preventDefault();
        if (validateForm()) {
            const data = {
                employeeID: empId == 0 ? 0 : empId,
                firstName: candidate.firstName,
                lastName: candidate.lastName,
                password: candidate.password,
                emailID: candidate.emailID,
                pincode: candidate.pincode,
                isActive: true,
                employeeCode: candidate.employeeCode,
                contactNumber: candidate.contactNumber,
                address: candidate.address,
                DateOfBirth: candidate.dateOfBirth,
                createdBy: Decrypt(localStorage.getItem("EmployeeID")),
                roleID: candidate.roleID,
                companyID: candidate.companyID,
                oldPassword: oldpassword,
                isPasswordChanged: candidate.password === oldpassword ? 0 : 1
            };
            showLoading();
            empObj.EmployeePost(data)
                .then((res) => {
                    if (res.data > 0) {
                        // hideLoading();
                        if (empId === 0) {
                            Toast.fire({
                                icon: "success",
                                title: "Employee Created Successfully",
                            });
                        } else {
                            Toast.fire({
                                icon: "success",
                                title: "Employee Updated Successfully",
                            });
                        }
                        navigate("/EmpList");
                    } else {
                        hideLoading();
                        Toast.fire({
                            icon: "error",
                            title: "Failed To Save !",
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };
    const handleInputChange = (e) => {

        const { name, value } = e.target;
        let truncatedValue = value;
        let valid = true;
        const myerror = { ...errors };

        if (name === "firstName" || name === "lastName") {
            const displayName = name.replace(/([A-Z])/g, " $1"); // Add space before capital letters
            if (!/^[A-Za-z\s]+$/.test(truncatedValue)) {
                myerror[name] = `${displayName.charAt(0).toUpperCase() + displayName.slice(1)} should only contain letters and spaces`;
                valid = false;
            } else {
                myerror[name] = "";
            }
        }

        if (name === "password") {
            const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/;
            if (!passwordRegex.test(truncatedValue)) {
                myerror[name] = 'Password must contain at least one capital letter, one number digit, and one special character';
                valid = false;
            }
            if (truncatedValue.length < 8) {
                myerror[name] = 'Password must be at least 8 characters long';
                valid = false;
            }
            else {
                myerror[name] = "";
            }
        }

        if (name === "pincode" || name === "contactNumber") {
            if (typeof value === "string") {
                const numericValue = value.replace(/\D/g, "");
                if (numericValue.length > 0) {
                    truncatedValue = numericValue;
                    if (name === "contactNumber" && truncatedValue.length > 10) {
                        // truncatedValue = truncatedValue.slice(0, 10);
                        myerror[name] = "Contact Number should have 10 digits";
                        valid = false;
                    } else if (name === "pincode" && truncatedValue.length > 6) {
                        // truncatedValue = truncatedValue.slice(0, 6);
                        myerror[name] = "Pincode should have 6 digits";
                        valid = false;
                    } else {
                        myerror[name] = "";
                    }
                } else {
                    myerror[name] = "Enter Number Only";
                    valid = false;
                }
            } else {

            }
        }

        if (name === "emailID") {
            if (name === "emailID" && !isValidEmail(value)) {
                myerror[name] = 'Invalid Email Address';
                valid = false;
            } else {
                myerror[name] = "";
            }
        }
        if (name === "roleID") {
            if (truncatedValue === "0") {
                myerror[name] = 'Select Role';
                valid = false;
            }
            else {
                myerror[name] = "";
            }
        }
        if (name === "companyID") {
            if (truncatedValue === "0") {
                myerror[name] = 'Select Company';
                valid = false;
            }
            else {
                myerror[name] = "";
            }
        }

        if (!value.trim()) {
            const displayName = name.replace(/([A-Z])/g, " $1");
            myerror[name] = `${displayName.charAt(0).toUpperCase() + displayName.slice(1)} is required`;
        }

        setCandidate((prevState) => ({
            ...prevState,
            [name]: truncatedValue
        }));

        setErrors((prevErrors) => ({ ...prevErrors, ...myerror }));
    };



    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };
    return (
        <>
            <main className="main-content-wrap">
                <div className="container-fluid">
                    <div className="card-box-style" style={{ marginBottom: "0px", padding: '22px' }}>

                        <div className="others-title row" style={{ marginBottom: "20px" }}>
                            <h3 className="col-6">{empId != 0 ? "Edit Employee" : "Employee Registration"}
                            </h3>
                            <span className="col-6">
                                <input type="button" value="Back" title="Back" style={{ float: 'right' }} className="back-button backbutton-end" onClick={() => navigate(-1)} />
                            </span>
                        </div>

                        <form className="row " onSubmit={onSubmit} autoComplete="off">
                            <div className="col-md-6">
                                <label htmlFor="employeeCode" className="form-label">Employee Code</label>
                                <input
                                    type="text"
                                    id="employeeCode"
                                    name="employeeCode"
                                    value={candidate.employeeCode}
                                    className="form-control"
                                    disabled
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="firstName" className="form-label">First Name <span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    id="firstName"
                                    name="firstName"
                                    value={candidate.firstName}
                                    onChange={handleInputChange}
                                    className="form-control"
                                />
                                {errors.firstName && (
                                    <span className="text-danger">{errors.firstName}</span>
                                )}
                            </div>

                            <div className="col-md-6">
                                <label htmlFor="lastName" className="form-label">Last Name <span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    id="lastName"
                                    name="lastName"
                                    value={candidate.lastName}
                                    onChange={handleInputChange}
                                    className="form-control"
                                />
                                {errors.lastName && (
                                    <span className="text-danger">{errors.lastName}</span>
                                )}
                            </div>

                            <div className="col-md-6">
                                <label htmlFor="password" className="form-label">Password <span className="text-danger">*</span></label>

                                <div className="input-group">
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        id="password"
                                        name="password"
                                        defaultValue={candidate.password}
                                        onBlur={handleInputChange}
                                        className="form-control"
                                    />
                                    <button
                                        type="button"
                                        className="input-group-text"
                                        onClick={handleTogglePassword}
                                    >
                                        {showPassword ? <FaEye /> : <FaEyeSlash />}
                                    </button>
                                </div>

                                {errors.password && (
                                    <span className="text-danger">{errors.password}</span>
                                )}
                            </div>

                            <div className="col-md-6">
                                <label htmlFor="emailID" className="form-label">Email <span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    id="emailID"
                                    name="emailID"
                                    defaultValue={candidate.emailID}
                                    onBlur={handleInputChange}
                                    className="form-control"
                                />
                                {errors.emailID && (
                                    <span className="text-danger">{errors.emailID}</span>
                                )}
                            </div>

                            <div className="col-md-6">
                                <label htmlFor="dateOfBirth" className="form-label">Date of Birth <span className="text-danger">*</span></label>
                                <DateTimePicker
                                    id="dateOfBirth"
                                    name="dateOfBirth"
                                    className="form-control"
                                    format="dd/MM/yyyy"
                                    value={candidate.dateOfBirth ? candidate.dateOfBirth : new Date(new Date().getFullYear() - 18, 0, 1)}
                                    selected={candidate.dateOfBirth}
                                    onChange={(date) => {
                                        const birthDate = new Date(date);
                                        let age = currentDate.getFullYear() - birthDate.getFullYear();
                                        const monthDiff = currentDate.getMonth() - birthDate.getMonth();

                                        // Check if the age is less than 18
                                        if (age < 18 || (age === 18 && monthDiff < 0)) {
                                            setErrors((prevErrors) => ({
                                                ...prevErrors,
                                                dateOfBirth: "You must be at least 18 years old to apply.",
                                            }));
                                        } else {
                                            setCandidate((prevState) => ({
                                                ...prevState,
                                                dateOfBirth: date,
                                            }));
                                            setErrors((prevErrors) => {
                                                const newErrors = { ...prevErrors };
                                                delete newErrors.dateOfBirth; // Clear error message for dateOfBirth field
                                                return newErrors;
                                            });
                                        }
                                    }}
                                    yearDropdownItemNumber={4}
                                    scrollableYearDropdown={true}
                                    // maxDate={new Date(new Date().getFullYear() - 18, 0, 1)} // Set maxDate to current year minus 18 years
                                    monthPlaceholder="mm"
                                    yearPlaceholder="yyyy"
                                    dayPlaceholder="dd" />

                                {errors.dateOfBirth && (
                                    <span className="text-danger">{errors.dateOfBirth}</span>
                                )}
                            </div>


                            <div className="col-md-6">
                                <label htmlFor="contactNumber" className="form-label">Contact Number <span className="text-danger">*</span></label>
                                <input
                                    type="number"
                                    id="contactNumber"
                                    name="contactNumber"
                                    value={candidate.contactNumber}
                                    onChange={(e) => {
                                        const input = e.target.value.slice(0, 10); // Limit the input to 10 characters
                                        handleInputChange({ target: { name: 'contactNumber', value: input } });
                                    }}
                                    className="form-control"
                                    maxLength={10}
                                />
                                {errors.contactNumber && (
                                    <span className="text-danger">{errors.contactNumber}</span>
                                )}
                            </div>

                            <div className="col-md-6">
                                <label htmlFor="role" className="form-label">Select Role <span className="text-danger">*</span></label>
                                <select className="form-control" id="roleID" name="roleID" value={candidate.roleID} onChange={handleInputChange}>
                                    <option value="0">-- Select Option --</option>
                                    {role.map((roleItem, index) => (
                                        <option key={index} value={roleItem.RoleID}>
                                            {roleItem.RoleName}
                                        </option>
                                    ))}


                                </select>
                                {errors.roleID && (
                                    <span className="text-danger">{errors.roleID}</span>
                                )}
                            </div>

                            <div className="col-md-6">
                                <label htmlFor="role" className="form-label">Select Company <span className="text-danger">*</span></label>
                                <select className="form-control" id="companyID" value={candidate.companyID} name="companyID" onChange={handleInputChange}>
                                    <option value="0">-- Select Option --</option>
                                    {Company.map((roleItem, index) => (
                                        <option key={index} value={roleItem.CompanyID}>
                                            {roleItem.CompanyName}
                                        </option>
                                    ))}
                                </select>
                                {errors.companyID && (
                                    <span className="text-danger">{errors.companyID}</span>
                                )}
                            </div>

                            <div className="col-md-6">
                                <label htmlFor="address" className="form-label">Address</label>
                                <input
                                    type="text"
                                    id="address"
                                    name="address"
                                    value={candidate.address}
                                    onChange={handleInputChange}
                                    className="form-control"
                                // placeholder="Enter Your Address"
                                />

                            </div>

                            <div className="col-md-6">
                                <label htmlFor="pincode" className="form-label">Pincode</label>
                                <input
                                    type="text"
                                    id="pincode"
                                    name="pincode"
                                    value={candidate.pincode}
                                    onChange={handleInputChange}
                                    className="form-control"
                                // placeholder="Enter Your Pincode"
                                />

                            </div>

                            <div className="">
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    style={{ float: 'right', marginLeft: '-1rem' }}
                                >
                                    Submit
                                </button>
                            </div>
                        </form>

                    </div>
                </div>
            </main>
        </>
    );
}

export default EmpCreate;
