import Axios from "../../services/AxiosService";

const axios = new Axios();

export default class AuthService {
  async Login(data) {

    return await axios.post("Auth/Login", data).then((response)=>{
      return response.data.result
    }).catch((err)=>{
      console.log(err);
    })

     
  }
  ForgotPassword(Username) {
    return axios
      .get(`Auth/ForgotPassword?username=${Username}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
}
