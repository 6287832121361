import React, { useState, useContext } from "react";
import CanditateServices from "../../services/CanditateService";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../services/ContextProvider";
import { useEffect } from "react";
import CandidateQuestionView from "../Candidate_QuestionView/CandidateQuestionView";
import CandidateQuestionViewServices from "../../services/CandidateQuestionViewService";
import ExamService from "../Exam/ExamService";

var candidateServ = new CanditateServices();
var candidateQuestionServ = new CandidateQuestionViewServices();
var examServicesobj = new ExamService();

function RejoinTest({ setIsModalOpen, cID, ExamID, isModalOpen, open }) {
    const { showLoading, hideLoading, setCookies, tempModulePrivileage, isAuth, SetisAuth } =
        useContext(AppContext);
    const [textBoxValue, setTextBoxValue] = useState("");
    const [examId, setexamID] = useState(null);
    const [candidateID, setCandidateID] = useState(null);
    const navigate = new useNavigate();
    const [negativeMarkScore, setnegativeMarkScore] = useState('');
    const [validationError, setValidationError] = useState('');



    useEffect(() => {
        document.body.style.overflow = isModalOpen ? 'hidden' : 'auto';

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isModalOpen]);

    const onCloseModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {

        candidateQuestionServ.NegativeMarks_Score(ExamID).then((x) => {
            if (x != null) {
                const NegativeMarkID = x?.data[0]?.NegativeMarkID;
                examServicesobj.GetExamParameterList().then((res) => {

                    if (res != null) {
                        const candidateGroup = res.parameterList.filter(
                            (option) => option.parameterValueID == NegativeMarkID
                        );
                        setnegativeMarkScore(candidateGroup[0].value);

                    }
                });
            }
        }).catch((err) => {
            console.log(err);
        });
    }, []);
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });
    const onRejoinClick = async () => {
        debugger;
        if (!textBoxValue.trim()) {
            setValidationError('Please enter the code.');
            return;
        } else {
            debugger;
            var rejoincode = localStorage.getItem("Rejoincode");
            var trim = textBoxValue.trim();
            if (trim == rejoincode || rejoincode == null || rejoincode == undefined) {
                showLoading();
                await candidateServ.Exam_RejoincodeMatch(trim, ExamID).then((res) => {
                    debugger;
                    if (res?.data?.Table[0]?.Result == 1) {

                        const list = (res?.data?.Table1[0]);
                        const id = list.ExamID;
                        const Cid = list.CandidateID;
                        const Cname = list.CandidateName;
                        const ExamAttendID = list.ID;
                        const EmailID = list.Email;
                        hideLoading();
                        navigate("/CandidateQuestion", {
                            state: { candidateID: Cid, examID: id, canditateName: Cname, examAttendID: ExamAttendID, emailID: EmailID },
                            replace: true,
                        });
                    }
                    else if (res?.data?.Table[0]?.Result == 0) {
                        const data = (res?.data?.Table1[0]);
                        const examID = data.ExamID;
                        const candidateID = data.CandidateID;
                        const EmailID = data.Email;
                        const ExamAttendID = data.ID;
                        const Data = {
                            examID: examID,
                            candidateID: candidateID,
                            score: 0,
                            negativeMarks: negativeMarkScore === 'Apply 10% negative marks for this exam' ? 10 : negativeMarkScore === 'Apply 12.5% negative marks for this exam' ? 12.5
                                : negativeMarkScore === 'Apply 20% negative marks for this exam' ? 20 : negativeMarkScore === 'Apply 25% negative marks for this exam' ? 25 : negativeMarkScore === 'Apply 33% negative marks for this exam' ? 33 :
                                    negativeMarkScore === 'Apply 50% negative marks for this exam' ? 50 : negativeMarkScore === 'Apply 75% negative marks for this exam' ? 75 : negativeMarkScore === 'Apply 100% negative marks for this exam' ? 100 : 1,
                            email: EmailID != null ? EmailID : null,
                            examAttendID: ExamAttendID,
                            autoSubmit: 1
                        }
                        candidateQuestionServ.CandidateSumbitAnswer(Data).then((res) => {
                            debugger;
                            if (res != null) {

                                hideLoading();
                                navigate('/Terminate', { replace: true });

                            }
                        }).catch((err) => {
                            console.log(err);
                            hideLoading();
                        });
                        hideLoading();

                        Toast.fire({
                            icon: "warning",
                            title: "You could not take Retest!",
                        });
                    }
                    else if (res.data.Table[0].Result == 2) {
                        debugger;
                        // const data = (res?.data?.Table1[0]);
                        // const examID = data.ExamID;
                        // const candidateID = data.CandidateID;
                        // const EmailID = data.Email;
                        // const ExamAttendID = data.ID;
                        // const Data = {
                        //     examID: examID,
                        //     candidateID: candidateID,
                        //     score: 0,
                        //     negativeMarks: negativeMarkScore === 'Apply 10% negative marks for this exam' ? 10 : negativeMarkScore === 'Apply 12.5% negative marks for this exam' ? 12.5
                        //         : negativeMarkScore === 'Apply 20% negative marks for this exam' ? 20 : negativeMarkScore === 'Apply 25% negative marks for this exam' ? 25 : negativeMarkScore === 'Apply 33% negative marks for this exam' ? 33 :
                        //             negativeMarkScore === 'Apply 50% negative marks for this exam' ? 50 : negativeMarkScore === 'Apply 75% negative marks for this exam' ? 75 : negativeMarkScore === 'Apply 100% negative marks for this exam' ? 100 : 1,
                        //     email: EmailID != null ? EmailID : null,
                        //     examAttendID: ExamAttendID,
                        //     autoSubmit: 1
                        // }
                        // candidateQuestionServ.CandidateSumbitAnswer(Data).then((res) => {
                        //     debugger;
                        //     if (res != null) {
                        //         hideLoading();
                        //         navigate('/Terminate', { replace: true });

                        //     }
                        // }).catch((err) => {
                        //     console.log(err);
                        //     hideLoading();
                        // });
                        hideLoading();
                        Toast.fire({
                            icon: "warning",
                            title: "Invalid Code !",
                        });
                    }
                    else {

                        const data = (res?.data?.Table1[0]);
                        const examID = data.ExamID;
                        const candidateID = data.CandidateID;
                        const EmailID = data.Email;
                        const ExamAttendID = data.ID;
                        const Data = {
                            examID: examID,
                            candidateID: candidateID,
                            score: 0,
                            negativeMarks: negativeMarkScore === 'Apply 10% negative marks for this exam' ? 10 : negativeMarkScore === 'Apply 12.5% negative marks for this exam' ? 12.5
                                : negativeMarkScore === 'Apply 20% negative marks for this exam' ? 20 : negativeMarkScore === 'Apply 25% negative marks for this exam' ? 25 : negativeMarkScore === 'Apply 33% negative marks for this exam' ? 33 :
                                    negativeMarkScore === 'Apply 50% negative marks for this exam' ? 50 : negativeMarkScore === 'Apply 75% negative marks for this exam' ? 75 : negativeMarkScore === 'Apply 100% negative marks for this exam' ? 100 : 1,
                            email: EmailID != null ? EmailID : null,
                            examAttendID: ExamAttendID,
                            autoSubmit: 1
                        }
                        candidateQuestionServ.CandidateSumbitAnswer(Data).then((res) => {
                            debugger;
                            if (res != null) {

                                hideLoading();
                                navigate('/Terminate', { replace: true });

                            }
                        }).catch((err) => {
                            console.log(err);
                            hideLoading();
                        });
                        hideLoading();

                        Toast.fire({
                            icon: "warning",
                            title: "You could not take Retest!",
                        });
                    }
                }).catch((err) => {
                    console.log(err);
                    hideLoading();
                })
            } else {
                Toast.fire({
                    icon: "warning",
                    title: "Invalid Code !",
                });
            }

        }

    };

    const handleTextBoxChange = (event) => {
        setTextBoxValue(event.target.value);
        setValidationError('');
    };

    return (
        <>

            <div
                className="modal fade show"
                id="staticBackdrop"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                aria-modal="true"
                role="dialog"
                style={{ display: "flex", alignItems: "center" }}
            >
                <div className="modal-dialog" style={{ margin: "auto" }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title MuiTypography-h6" id="staticBackdropLabel">
                                Rejoin Test
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                fdprocessedid="ttcp2q"
                                onClick={onCloseModal}
                                disabled={open}
                            />
                        </div>
                        <div className="modal-body">
                            <form className="row g-3" autoComplete="off">
                                <div className="input-outline">
                                    <input type="text" className="form-control" placeholder="Enter Code" value={textBoxValue}
                                        onChange={handleTextBoxChange} />
                                </div>
                                {validationError && <span className="text-danger">{validationError}</span>}

                                <div className="text-center">
                                    <button type="button" className="btn btn-danger col-md-12" onClick={onRejoinClick}>
                                        Rejoin
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default RejoinTest;
