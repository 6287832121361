import React from "react";
import AxiosService from "./AxiosService";

const Auth = new AxiosService();

class CandidateQuestionViewServices {

  questionList = async () => {
    return Auth.get('CandidateQuestionView/Candidate_QuestionList')
  }
  candidateAnswer = async (Canditate) => {
    return Auth.post('CandidateQuestionView/CandidateAnswer', Canditate)
  }


  GetUnusualBehaviourCountBYExam = async (ExamID, candidateID, ExamAttendID) => {
    return Auth
      .get(`CandidateQuestionView/GetUnusualBehaviourCountBYExam?ExamID=${ExamID}&candidateID=${candidateID}&ExamAttendID=${ExamAttendID}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
  SaveUpdateUnusualBehaviourCountBYCandidate = async (count, candidateID, examID, activity, browserName, IP, ExamAttendID) => {


    return Auth
      .get(`CandidateQuestionView/SaveUpdateUnusualBehaviourCountBYCandidate?count=${count}&candidateID=${candidateID}&examID=${examID}&activity=${activity}&browserName=${browserName}&IP=${IP}&ExamAttendID=${ExamAttendID}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
}
export default CandidateQuestionViewServices;