import React from "react";
import AxiosService from "./AxiosService";

const Auth = new AxiosService();
class GeneralConfigService {

    SettingsGET = async () => {
        return Auth.get('Settings/SettingsGET').then((response)=>{
            return response;
        }).catch((error)=>{
            console.log(error);
        })
    }
    SettingsSave = async (obj) => {
        return Auth.post('Settings/SettingsSave',obj).then((response)=>{
            ;
            return response;
        }).catch((error)=>{
            console.log(error);
        })
    }

}
export default GeneralConfigService;