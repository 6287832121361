import React, { useState, useEffect, useContext } from "react";
import Swal from "sweetalert2";
import { AppContext } from "../../services/ContextProvider";
import GeneralConfigService from '../../services/GeneralConfigService'
import { useNavigate } from "react-router-dom";

var _GeneralConfigService = new GeneralConfigService();
const GeneralConfig = () => {
    const [GeneralSettingsValues, SetGeneralSettingsValues] = useState([]);
    const [isContainerFluid, setIsContainerFluid] = useState(true);

    const navigate = useNavigate();
    const [GeneralSettingsSaveValues, SetGeneralSaveSettingsValues] = useState([]);
    const { showLoading, hideLoading, setCookies, tempModulePrivileage, isAuth, SetisAuth, getCookies } =
        useContext(AppContext);
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });
    useEffect(() => {
        _GeneralConfigService.SettingsGET().then((response) => {
            SetGeneralSettingsValues(response.data);
        });
    }, []);
    const onSubmit = (e) => {

        e.preventDefault();
        showLoading();
        GeneralSettingsValues?.map((x, index) => {
            const obj = {
                "Setting_Code": x.setting_Code,
                "Setting_Value": document.getElementById(x.setting_Code)?.value
            }

            _GeneralConfigService.SettingsSave(obj).then((response) => {
                ;
                if (response.data === 1) {
                    Toast.fire({
                        icon: "success",
                        title: "Saved Succcessfully",
                    });
                }
                else {
                    Toast.fire({
                        icon: "error",
                        title: "Failed to save",
                    });
                }
            });
        });
        hideLoading();
    }
    const toggleContainerClass = () => {
        setIsContainerFluid((prevState) => !prevState);
    };
    return (
        <>
            <main className="main-content-wrap">
                <div className="contact-list-area">
                    <div className='container-fluid'  >

                        <div className="row">
                            <div className={`${isContainerFluid ? "col-md-12" : "col-md-9"}`}>
                                <div className="card-box-style animatedwidth">
                                    <div className="fullwidthbuttonbox " onClick={toggleContainerClass}>
                                        <span className="fullwidthbutton" >
                                            {isContainerFluid ? <i className="fa fa-angle-double-left"></i> : <i className="fa fa-angle-double-right"></i>}

                                        </span>
                                    </div>
                                    <div className="others-title">
                                        <h3>General Configuration

                                            {/* <input type="button" value="Back" title="Back" className="back-button backbutton-end" onClick={() => navigate(-1)}  /> */}
                                        </h3>
                                    </div>
                                    <form className="row g-3" onSubmit={onSubmit} autoComplete="off">
                                        {GeneralSettingsValues?.map((x, index) => {

                                            return (
                                                <div className="col-md-6" key={index}>
                                                    <label htmlFor={x.setting_Code} className="form-label">
                                                        {x.settingsdisplayName}
                                                    </label>


                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id={x.setting_Code}
                                                        fdprocessedid="7fccac"
                                                        defaultValue={x.setting_Value}

                                                    />

                                                </div>
                                            );

                                        })

                                        }
                                        <div className="col-12">
                                            <button type="submit" className="btn btn-primary" fdprocessedid="nu3mc7">
                                                Submit
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            {!isContainerFluid ? <div className="col-md-3">
                                {/* 
                                <div className="card-box-style"></div> */}
                            </div> : null}
                        </div>

                    </div>

                </div>

            </main>
        </>
    );
}

export default GeneralConfig;