import Axios from "../../services/AxiosService";
import { Decrypt } from "../../services/CommonService";
const axios = new Axios();

export default class ExamService {
  GetExamParameterList() {
    var CompanyID = Decrypt(localStorage.getItem("CompanyID"));
    return axios
      .get(`Exam/GetExamParameterList?CompanyID=${CompanyID ? CompanyID : 0}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
  CreateUpdate = async (data) => {
    return axios.post(`Exam/ExamCreateUpdate`, data);
  };

  GetExamList(CompanyID) {
    return axios
      .get(`Exam/GetExamList?CompanyID=${CompanyID}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
  DeleteExam(id) {
    return axios
      .get(`Exam/DeleteExam?id=${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
  GetReportDetails(candidateId, examId, ExamAttendID) {
    return axios
      .get(`Report/GetReportDetails?candidateID=${candidateId}&examID=${examId}&ExamAttendID=${ExamAttendID}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
  GetReportDetailsExam(examId) {
    return axios
      .get(`Report/GetReportDetailsForExam?examID=${examId}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
  GetReportDetailsforSelectedCandidate(candidateId, examId, ExamAttendID) {
    return axios
      .get(`Report/GetReportDetailsforSelectedCandidate?candidateID=${candidateId}&examID=${examId}&ExamAttendID=${ExamAttendID}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
  GetReportDetailsforSelectedCandidateMark(candidateId, examId) {
    return axios
      .get(`Report/GetReportDetailsforSelectedCandidateMark?candidateID=${candidateId}&examID=${examId}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
  GetChangedAnswerByID(candidateId, examId) {
    return axios
      .get(`Report/GetChangedAnswerByID?candidateID=${candidateId}&examID=${examId}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
  GetReportDetailsforSelectedCandidateMSection(candidateId, examId, ExamAttendID) {
    return axios
      .get(`Report/GetReportDetailsforSelectedCandidateMSection?candidateID=${candidateId}&examID=${examId}&ExamAttendID=${ExamAttendID}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
  MillisecondsToMinutes = (ms) => {
    try {
      if (ms != null && ms > 0 && ms != '') {

        const minutes = ms / 60000;

        return minutes;
      }
      return 0;
    }
    catch {
      return 0;
    }
  };
  convertSecondsToMinutes = (seconds) => {

    try {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      const formattedSeconds = remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds;
      return minutes + ":" + formattedSeconds;

    }
    catch {
      return 0;
    }

  };
  GetSectionListByExamID(id) {
    return axios
      .get(`Exam/GetSectionListByExamID?ID=${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });

  }
  DeleteSection(id) {
    return axios
      .get(`Exam/DeleteSection?ID=${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
  GetDataFieldListByExamID(id) {
    return axios
      .get(`Exam/GetDataFieldListByExamID?ID=${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });

  }
  GetExamSummaryByExamID(id) {
    return axios
      .get(`Exam/GetExamSummaryByExamID?id=${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });

  }
  EndExam(id, para) {
    return axios
      .get(`Exam/EndExam?id=${id}&para=${para}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });

  }
  DuplicateEXam(id, CreatedBY) {
    return axios
      .get(`Exam/DuplicateEXam?id=${id}&CreatedBY=${CreatedBY}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });

  }
  SendMailtoCandidate(emailId, message, subject) {
    return axios
      .get(`Exam/SendMailtoCandidate?emailId=${emailId}&message=${message}&subject=${subject}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });

  }
  GetExamMeritListByID(id) {
    return axios
      .get(`Exam/GetExamMeritListByID?examID=${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });

  }
  GetExamStatistics(examID) {

    return axios
      .get(`Exam/GetExamStatistics?examID=${examID}`)
      .then((response) => {

        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });

  }
  GetSHAREEXAMTemplate() {

    return axios
      .get(`Exam/GetSHAREEXAMTemplate`)
      .then((response) => {

        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });

  }
  GetCandidateRemainingTime = async (CandidateID, ExamID) => {

    return axios
      .get(`CandidateQuestionView/GetCandidateRemainingTime?CandidateID=${CandidateID}&ExamID=${ExamID}`)
      .then((response) => {

        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });

  }
  GetQuestionIDS(id) {
    return axios
      .get(`Exam/GetQuestionIDS?examID=${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });

  }
  GetCandidateEmailsAgainstGroup(candidateGroupID) {

    return axios
      .get(`Exam/GetCandidateEmailsAgainstGroup?candidateGroupID=${candidateGroupID}`)
      .then((response) => {

        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });

  }
  GetUnusualActivitiesByCandidateID = async (CandidateID, ExamID, ExamAttendID) => {

    return axios
      .get(`Report/GetUnusualActivitiesByCandidateID?ExamID=${ExamID}&CandidateID=${CandidateID}&ExamAttendID=${ExamAttendID}`)
      .then((response) => {

        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });

  }


}
