import React, { useEffect, useState, useContext, useRef, useReducer } from "react";
import CandidateQuestionViewServices from "../../services/CandidateQuestionViewService";
import { FaTimes, FaEraser, FaFlag } from 'react-icons/fa';
import ExamLiveHeader from "./ExamLiveHeader";
import ExamLiveSideNav from "./ExamLiveSideNav";
import "../../assets/js/custom.js";
import '../../assets/css/customizeSideMenu.css';
import '../../assets/css/metismenu.min.css';
import '../../assets/css/simplebar.min.css';
import '../../../node_modules/metismenu/dist/metisMenu.min.js'
import '../../../node_modules/simplebar/dist/simplebar.min.js';
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../../services/ContextProvider";
import ExamLiveMalpractice from "./ExamLiveMalpractice";
import { Refresh } from "iconsax-react";
import CanditateServices from "../../services/CanditateService";
import FlagCount from "../Candidate_QuestionView/FlagCount";


var candidateQuestionServ = new CandidateQuestionViewServices();
var candidateservice = new CanditateServices();
export default function ExamLive() {
    const { showLoading, hideLoading, setCookies, tempModulePrivileage, isAuth, SetisAuth, removeCookies } =
        useContext(AppContext);
    const [questionList, setQuestionList] = useState([]);
    const [answerLists, setAnswerList] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [currentAnswers, setcurrentAnswers] = useState([]);
    const [selectedAnswers, setSelectedAnswers] = useState([]);
    const [currentQuestion, setcurrentQuestion] = useState({});
    const [currentQuestionId, setcurrentQuestionId] = useState(0);
    const location = useLocation();
    debugger;
    const [candidateID, setCandidateID] = useState(location.state?.candidateID ?? 0);
    const [CandidateName, setCandidateName] = useState(location.state?.canditateName ?? null);
    const [examID, setexamID] = useState(location.state?.examID ?? 0);
    const navigate = new useNavigate();
    const [ExamAttendID, setExamAttendID] = useState(location.state?.ExamAttendID ?? 0);

    const questionidRef = useRef(null);
    const [flag, setflag] = useState(false);
    const [answer, setAnswer] = useState('');
    const [Refesh, setRefesh] = useReducer((x) => x + 1, 0);
    const [redCount, setredCount] = useState(0);
    const [greenCount, setgreenCount] = useState(0);
    const [progress, setProgress] = useState(0);
    const [answerCount, setanswercount] = useState([]);
    const [reload, setReload] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            try {
                let questioncount = await Getlist();
                let answercount = await getanswerlist();
                setValue(answercount, questioncount);
            } catch (err) {
                console.log(err);
            }

        };
        fetchData();

        // document.getElementById("submit").addEventListener("click", function () {
        //     document.querySelector(".loader-div").style.height = "100vh";
        //     document.body.style.overflow = "hidden";
        // });
    }, [reload]);

    useEffect(() => {
        getanswerlist();
        getQuestionColor();
    }, [reload]);


    useEffect(() => {
        candidateQuestionServ.getFlagCount(candidateID, examID, ExamAttendID).then((res) => {
            if (res != null) {
                setredCount(res?.data?.RedCount[0]?.RedCount);
                setgreenCount(res?.data?.GreenCount[0]?.GreenCount);
            }
        }).catch((err) => {
            console.log(err);
        });
    }, [reload]);

    const Getlist = async () => {
        let Questioncount = 0;
        if (candidateID > 0 && examID > 0) {
            await candidateQuestionServ.questionList(candidateID, examID, ExamAttendID).then((res) => {
                const { questionList, answerList } = res.data;
                if (questionList?.length > 0) {
                    debugger;
                    setQuestionList([...questionList]);
                    setcurrentQuestion(questionList[currentQuestionIndex])
                    var currentQues = questionList[currentQuestionIndex].questionID;
                    if (answerList?.length > 0) {
                        setAnswerList([...answerList]);
                        const curroption = answerList.filter((answer) => answer.questionID === currentQues);
                        setcurrentAnswers([...curroption]);
                    }
                    Questioncount = questionList?.length;
                }
                hideLoading();
            }).catch((err) => {
                console.log(err);
            });
            return Questioncount;
        }
        else {
            navigate("/error", { replace: true });
        }
    };

    const getanswerlist = async () => {
        let Answercount = 0;
        await candidateQuestionServ.getAnswerHistory(candidateID, examID, ExamAttendID).then((res) => {
            if (res?.data?.length > 0) {
                console.log(res.data);
                const mappedData = res?.data.map(model => ({
                    questionid: model.questionID,
                    answer: model.finalAnswer
                }));
                setanswercount(mappedData);
                setSelectedAnswers(res?.data);
                Answercount = mappedData?.length;
            }
        }).catch((err) => {
            console.log(err);
        });
        return Answercount;
    };

    const getQuestionColor = async (currentQuestionID, currentFlag) => {
        // const data = {
        //     candidateID: candidateID,
        //     examID: examID,
        //     questionID: currentQuestionID,
        //     isSeen: null,
        //     flag: currentFlag,
        // };
        // await candidateQuestionServ.CandidateAnsFlag(data).then((res) => {
        //     if (res.data > 0) {
        //         console.log(currentFlag);
        //     }
        // }).catch((res) => {
        //     console.log(res);
        // });
    };

    const getPlainText = (htmlString) => {
        // const element = document.createElement("div");
        // element.innerHTML = htmlString;
        return `${htmlString}`;
    };

    //Next page and validation
    const showNextQuestion = () => {
        setReload(!reload)
        // setcurrentQuestionId(currentQuestion.questionID);
        // var color;
        // const element = document.getElementById(`background${currentQuestion.questionID}`);
        // if (answer !== "" && answer != null || selectedAnswers.some(answer => answer.questionID === currentQuestion.questionID)) {
        //     answercount(currentQuestion.questionID, selectedAnswers[currentQuestionIndex]?.finalAnswer);
        //     color = element.style.background = '#4fcb8d';
        //     element.style.color = 'white';

        // }
        // else {
        //     color = element.style.background = 'gray';
        //     element.style.color = 'white';
        // }
        // getQuestionColor(currentQuestion.questionID, color);
        setAnswer('');

        const nextQuestionIndex = currentQuestionIndex + 1;
        if (nextQuestionIndex < questionList?.length) {
            setcurrentQuestion(questionList[nextQuestionIndex]);
            setCurrentQuestionIndex(nextQuestionIndex);
            const nextQuestionID = questionList[nextQuestionIndex].questionID;
            const currentOptions = answerLists.filter(
                (answer) => answer.questionID === nextQuestionID
            );
            setcurrentAnswers([...currentOptions]);

        } else {
            console.log("something went wrong");
        }
    };

    //previous Page
    const showPrevQuestion = () => {
        setReload(!reload)
        // setcurrentQuestionId(currentQuestion.questionID);
        // var color;
        // const element = document.getElementById(`background${currentQuestion.questionID}`);
        // if (answer !== "" && answer != null || selectedAnswers.some(answer => answer.questionID === currentQuestion.questionID)) {
        //     color = element.style.background = '#4fcb8d';
        //     element.style.color = 'white';
        //     answercount(currentQuestion.questionID, selectedAnswers[currentQuestionIndex]?.finalAnswer);
        // }
        // else {
        //     color = element.style.background = 'gray';
        //     element.style.color = 'white';
        // }
        // getQuestionColor(currentQuestion.questionID, color);
        setAnswer('');
        const prevquesIndex = currentQuestionIndex - 1;
        setcurrentQuestion(questionList[prevquesIndex]);
        setCurrentQuestionIndex(prevquesIndex);
        const prevQuestionID = questionList[prevquesIndex].questionID;
        const curroption = answerLists.filter((answer) => answer.questionID === prevQuestionID);
        setcurrentAnswers([...curroption]);
    };

    //sidenav click and navigate to the question    
    const handleQuestionClick = (questionIndex) => {
        setcurrentQuestionId(currentQuestion.questionID);
        // var color;
        // const element = document.getElementById(`background${currentQuestion.questionID}`);
        // if (selectedAnswers[currentQuestionIndex]?.finalAnswer != null || answer !== "" && answer != null) {
        //     color = element.style.background = '#4fcb8d';
        //     element.style.color = 'white';

        // }
        // else {
        //     color = element.style.background = 'gray';
        //     element.style.color = 'white';
        // }
        // getQuestionColor(currentQuestion.questionID, color);
        setCurrentQuestionIndex(questionIndex - 1);
        setcurrentQuestion(questionList[questionIndex - 1]);
        var currentQues = questionList[questionIndex - 1].questionID;
        const curroption = answerLists.filter((answer) => answer.questionID === currentQues);
        setcurrentAnswers([...curroption]);
    };

    // const handleAnswerChange = (questionID, eve) => {
    //     setAnswer(eve);
    //     setSelectedAnswers((prevAnswers) => {
    //         const updatedAnswers = [...prevAnswers];
    //         updatedAnswers[currentQuestionIndex] = {
    //             questionID: questionID,
    //             finalAnswer: eve,
    //         };
    //         return updatedAnswers;
    //     });

    //     const data = {
    //         candidateID: candidateID,
    //         examID: examID,
    //         sectionID: currentQuestion.sectionId,
    //         questionID: questionID,
    //         // previousAnswer: eve !== null ? eve : null,
    //         finalAnswer: eve !== null ? eve : null,
    //     };

    //     candidateQuestionServ.candidateAnswer(data).then((res) => {
    //         if (res.data > 0) {
    //             console.log("success");
    //         } else {
    //             console.log("failed to save");
    //         }
    //     });
    //     setRefesh();

    // };

    // //Clear the Answer
    // const handleClearAnswer = () => {
    //     setSelectedAnswers((prevAnswers) => {
    //         const updatedAnswers = [...prevAnswers];
    //         updatedAnswers[currentQuestionIndex] = {
    //             questionID: currentQuestion.questionID,
    //             finalAnswer: null,
    //         };
    //         return updatedAnswers;
    //     });
    //     setAnswer('');
    //     answercount(currentQuestion.questionID, null);

    //     const element = document.getElementById(`background${currentQuestion.questionID}`);
    //     element.style.background = 'gray';
    //     element.style.color = 'white';

    //     handleAnswerChange(currentQuestion.questionID, null);
    //     const data = {
    //         candidateID: candidateID,
    //         examID: examID,
    //         questionID: currentQuestion.questionID,
    //         isSeen: null,
    //         flag: 'gray',
    //     };
    //     candidateQuestionServ.CandidateAnsFlag(data)
    //         .then((res) => {
    //             if (res.data > 0) {
    //                 console.log("Flag updated to gray");
    //             }
    //         })
    //         .catch((res) => {
    //             console.log(res);
    //         });
    // };

    const handleSubmit = () => {

        setcurrentQuestionId(currentQuestion.questionID);
        var color;
        const element = document.getElementById(`background${currentQuestion.questionID}`);
        if (answer !== "" || selectedAnswers[currentQuestionIndex]?.finalAnswer) {
            if (element) {
                color = element.style.background = '#4fcb8d';
                element.style.color = 'white';
            }
        }
        else {
            if (element) {
                color = element.style.background = 'gray';
                element.style.color = 'white';
            }
        }
        // getQuestionColor(currentQuestion.questionID, color);
        // const Data = {
        //     examID: examID,
        //     candidateID: candidateID,
        //     score: 0,
        // }
        if (candidateID > 0) {


            navigate("/Thanks", { replace: true });




        }
    };

    //     const setFlag = (flag) => {
    //         var flagcode="NULL";
    //         if (questionidRef.current) {

    //             const id = currentQuestion.questionID;

    //             const element = document.getElementById(`questionno${id}`);
    //             if (element) {
    //                 const display = window.getComputedStyle(element).display;
    //                 const color = window.getComputedStyle(element).color;

    //                 if (flag === 'F') {
    //                     if (display === 'none') {
    //                         element.style.display = 'block';
    //                         element.style.color = "red";
    // flagcode='F';
    //                     } else if (display === 'block' && color === 'rgb(0, 128, 0)') {
    //                         element.style.color = "red";
    //                         flagcode='F';
    //                     } else if (display === 'block') {
    //                         element.style.display = 'none';
    //                         flagcode="NULL";
    //                     }
    //                 } else if (flag === 'G') {
    //                     if (display === 'none') {
    //                         element.style.display = 'block';
    //                         element.style.color = "green";
    //                         flagcode='G';


    //                     } else if (display === 'block' && color === 'rgb(255, 0, 0)') {
    //                         element.style.color = "green";
    //                         flagcode='G';
    //                     } else if (display === 'block') {
    //                         element.style.display = 'none';
    //                         flagcode="NULL";
    //                     }
    //                 }
    //             }
    //         }
    //         // const Data = {
    //         //     examID: examID,
    //         //     candidateID: candidateID,
    //         //     questionID: currentQuestion.questionID,
    //         //     flagSymbol: flagcode
    //         // }

    //         // if (candidateID > 0) {
    //         //     candidateQuestionServ.CandidateFlagButton(Data).then((res) => {
    //         //         if (res != null) {
    //         //             setflag(res?.data);
    //         //         }
    //         //     }).catch((err) => {
    //         //         console.log(err);
    //         //     });
    //         // }
    //         // setRefesh();


    //     };

    const answercount = (Qid, finalAnswer) => {
        let length = 0;
        if (answerCount.length === 0) {
            setanswercount([{ 'questionid': Qid, 'answer': finalAnswer }]);
            length = finalAnswer === null ? 0 : 1;
        } else {
            const filteredAnswers = answerCount.filter((e) => e.questionid === Qid);
            if (filteredAnswers.length > 0) {
                const updatedAnswerCount = answerCount.map((e) =>
                    e.questionid === Qid ? { ...e, answer: finalAnswer } : e
                );
                const filteredUpdatedAnswers = updatedAnswerCount.filter((e) => e.answer !== null);
                length = filteredUpdatedAnswers.length;
                setanswercount(updatedAnswerCount);
            } else {
                const newAnswer = { 'questionid': Qid, 'answer': finalAnswer };
                setanswercount([...answerCount, newAnswer]);
                length = finalAnswer === null ? answerCount.length : answerCount.length + 1;
            }
        }
        setValue(length, questionList?.length);
    };


    const setValue = (l, questioncount) => {
        const nextProgress = (l / questioncount) * 100;
        const roundnumber = Math.round(nextProgress);
        setProgress(roundnumber);
    };

    return (
        <div className="all-section-area">
            <ExamLiveHeader
                ExamId={examID}
                handleSubmit={handleSubmit}
                candidateID={candidateID}
                candidateName={CandidateName}

            />
            <ExamLiveSideNav
                questionList={questionList}
                handleQuestionClick={handleQuestionClick}
                currentQuestionIndex={currentQuestionIndex}
                candidateID={candidateID}
                examID={examID}
                reload={reload}
                examAttendID={ExamAttendID}
                currentQuestionID={currentQuestionId}
            />
            <main className="main-content-wrap style-two" style={{ height: '0px', overflow: 'auto', display: 'block' }}>
                <div className="overview-content-area">
                    <div className="container-fluid" style={{ height: '28rem' }}>
                        <div className="fixed-content">
                            <div className="row align-items-center">
                                <div className="col-8">
                                    <div className="page-title">
                                        <h4 style={{ paddingLeft: '25px', fontWeight: 'bold' }}>Question {currentQuestionIndex + 1} / {questionList.length}</h4>
                                    </div>
                                </div>
                                <div className="col-4 ">
                                    <div className="col-12">
                                        <span style={{ fontWeight: 'bold' }}>Section: {currentQuestion.sectionName}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card-box-style" >
                            <div className="" style={{ marginBottom: '0px' }}>
                                <pre>
                                    <h5 id={`${currentQuestionIndex + 1}`} ref={questionidRef} dangerouslySetInnerHTML={{ __html: getPlainText(currentQuestion.question) }}></h5>
                                </pre>
                            </div>
                            <div className="row">
                                {currentAnswers?.map((option, index) => {

                                    var result = selectedAnswers?.length > 0 && selectedAnswers?.filter((e) => e?.questionID == currentQuestion?.questionID);
                                    return (<div className="col-lg-12 col-sm-12" key={index}>
                                        <div className='single-audience d-flex justify-content-between align-items-center' >
                                            <div className="audience-content">
                                                <label>
                                                    <input
                                                        type="radio"
                                                        name={`answer-${currentQuestion.questionID}`}
                                                        id={`answer-${currentQuestion.questionID}-${index}`}
                                                        value={option.questionOptionID}
                                                        checked={option.questionOptionID == result[0]?.finalAnswer}
                                                        onChange={() => { }}
                                                    />
                                                    {' '}  {getPlainText(option.answerValue)}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    );
                                })}

                            </div>
                            <div className="OptionSection">
                                <div className="row">
                                    <div className="col-4">
                                        <div className="NextPrevClass">
                                            {currentQuestionIndex > 0 && (
                                                <button
                                                    className="btn btn-sm btn-primary"
                                                    style={{ marginRight: '10px' }}
                                                    onClick={() => showPrevQuestion()}
                                                >
                                                    « Prev
                                                </button>
                                            )}

                                            {currentQuestionIndex === questionList.length - 1 ? (
                                                <></>
                                                // <button
                                                //     className="btn btn-sm btn-primary"
                                                //     onClick={() => handleSubmit()}
                                                //     style={{ marginLeft: '10px' }}
                                                // >
                                                //     Submit
                                                // </button>
                                            ) : (
                                                <button
                                                    className="btn btn-sm btn-primary"
                                                    style={{ marginLeft: '10px' }}
                                                    onClick={() => showNextQuestion()}
                                                >
                                                    Next »
                                                </button>
                                            )}
                                        </div>

                                    </div>
                                    <div className="col-4 mt-2">
                                        <div className="progress ">
                                            <div className="progress-bar progress-bar-striped progress-bar-animated " aria-valuemin={20} aria-valuemax={20} style={{ width: `${progress}%`, background: '#49c199' }}>
                                                {progress}%
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="ClearClass" style={{ float: "right" }}>
                                            <button
                                                className="btn btn-primary shadow bg-white rounded"
                                                style={{ marginRight: '1px', cursor: "auto" }}
                                            // onClick={() => handleClearAnswer()}

                                            >
                                                <FaEraser color="black" />
                                            </button>

                                            <button
                                                className="btn  btn-primary shadow bg-white rounded"
                                                style={{ color: 'red', marginRight: '1px', cursor: "auto" }}

                                            // onClick={() => setFlag('F')}
                                            >
                                                <span><FlagCount count={redCount} /></span>

                                            </button>

                                            <button
                                                className="btn  btn-primary shadow bg-white rounded"
                                                style={{ marginRight: '1px', color: 'green', cursor: "auto" }}

                                            // onClick={() => setFlag('G')}
                                            >
                                                <span><FlagCount count={greenCount} /></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <ExamLiveMalpractice examID={examID} candidateID={candidateID} handleSubmit={handleSubmit}
                examAttendID={ExamAttendID}
            />
        </div>
    );
}



