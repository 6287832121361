import React from "react";
import AxiosService from "../../services/AxiosService";

const Auth = new AxiosService();

class DashBoardService {

    DashBoardApex = (timePeriod) => {
        try {
            return Auth.get(`/DashBoard/Dashboard_ApexChart?Timeperiod=${timePeriod}`);

        } catch (error) {
            console.log(error);
        }
    }

    DashBoardExamMonitor = (timePeriod) => {
        try {
            return Auth.get(`/DashBoard/Dashboard_ExamMonitor?Timeperiod=${timePeriod}`);

        } catch (error) {
            console.log(error);
        }
    }
    DashBoardCount = () => {
        try {
            return Auth.get(`/DashBoard/DashBoard_Count`);

        } catch (error) {
            console.log(error);
        }
    }

    Candidate_Suspend = (Flag, ExamID, CandidateID,ExamAttendID) => {
        try {
            return Auth.post(`/DashBoard/Candidate_suspended?Flag=${Flag}&ExamID=${ExamID}&CandidateID=${CandidateID}&ExamAttendID=${ExamAttendID}`);

        } catch (error) {
            console.log(error);
        }
    }

    ReTake_Add = (ExamAttendID) => {
        try {
            return Auth.post(`/DashBoard/ReTake_Add?ExamAttendID=${ExamAttendID}`);

        } catch (error) {
            console.log(error);
        }
    }
}
export default DashBoardService;