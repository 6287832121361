import React, { useEffect, useState, useContext } from "react";
import CanditateServices from "../../services/CanditateService";
import EditCanditate from "./EditCanditate";
import Swal from "sweetalert2";
import { FaCog, FaFileDownload, FaFileExcel, FaFileUpload, FaPlusSquare, FaUpload } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { Dropdown } from 'react-bootstrap';
import './candidate.css';
import BulkUploadCandidate from './BulkUploadCandidate';
import Tooltip from "@material-ui/core/Tooltip";
import { AppContext } from "../../services/ContextProvider";
import { saveAs } from "file-saver";
import { FaDownload } from "react-icons/fa";
import CreateCandidate from "./CreateCandidate";
import { IconButton } from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import * as XLSX from 'xlsx';


var Canditateobj = new CanditateServices();
function AdminCanditate() {
    const { showLoading, hideLoading, setCookies, tempModulePrivileage, isAuth, SetisAuth } =
        useContext(AppContext);
    const location = useLocation();
    const navigate1 = useNavigate();
    const [canditatesList, setcanditateList] = useState([]);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [selectedCandidateId, setSelectedCandidateId] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [refresh, setrefresh] = useState(false);
    const tab = location.state?.tab ?? null;
    const [activeTab, setActiveTab] = useState(tab === null ? "candidateList" : tab === "candidateList" ? "candidateList" : "linkList");
    const [linkData, setLinkData] = useState([]);
    const [isContainerFluid, setIsContainerFluid] = useState(true);


    useEffect(() => {
        showLoading();
        if (activeTab === "candidateList") {
            candidateList();
        } else if (activeTab === "linkList") {
            LinkcandidateList();
        }
    }, [activeTab, refresh]);

    const candidateList = () => {
        Canditateobj.canditateList().then((res) => {
            if (res != null) {

                setcanditateList(res.data.CandidateList);
                setTimeout(() => {
                    hideLoading();
                }, 200)
            }

        });
        hideLoading();

    }

    const LinkcandidateList = () => {
        Canditateobj.canditateList().then((res) => {
            if (res != null) {

                setLinkData(res.data.LinkCandidateList);
                setTimeout(() => {
                    hideLoading();
                }, 200)
            }

        });
        hideLoading();
    }
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });

    const handleExcelIconClick = () => {
        setIsPopupOpen(!isPopupOpen);
    };
    const [isDropdownOpen, setIsDropdownOpen] = useState(Array(canditatesList?.length).fill(false));

    useEffect(() => {
        if (showEditModal || showCreateModal) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [showEditModal, showCreateModal]);

    const navigatetopreview = (id, examid, ExamAttendID) => {
        navigate1("/CandidatePreview", {
            state: { CandidateId: id, examID: examid, tab: activeTab, ExamAttendID: ExamAttendID },
        });

    }
    const toggleDropdown = (index) => {
        const updatedDropdowns = [...isDropdownOpen];
        updatedDropdowns[index] = !updatedDropdowns[index];
        setIsDropdownOpen(updatedDropdowns);
    };

    const columns = [
        {
            name: "CandidateName",
            label: "Candidate Name",
            options: {
                customBodyRender: (value, tableMeta) => {


                    const rowIndex = tableMeta.rowIndex;
                    const examID = activeTab == 'candidateList' ? canditatesList[rowIndex].ExamID : linkData[rowIndex].ExamID;
                    // const candidateName = examID != null && examID === 0 ? value : canditatesList[rowIndex].ModifiedFirstName;
                    const candidateName = value;
                    if (examID === 0) {
                        return (
                            <Tooltip title={examID === 0 ? 'Not Attend' : 'Attend'}>
                                <span>
                                    {candidateName}
                                    <span className="text-danger">*</span>
                                </span>
                            </Tooltip>
                        );
                    } else {
                        return candidateName;
                    }
                },
            },

        },
        {
            name: "Email", label: "EMAIL ID",
            options: {
                customBodyRender: (value) => {
                    return (
                        <a href={`mailto:${value}`} target="_blank" rel="noopener noreferrer">
                            {value}
                        </a>
                    );
                },
            },
        },
        { name: "MobileNumber", label: "Mobile Number" },
        {
            name: "Value",
            label: "Candidate Group",
            options: {
                display: activeTab === "candidateList" ? "true" : "false",
            },
        },
        {
            label: "OPTIONS",
            name: "employeeID",
            options: {
                filter: true,
                sort: false,
                empty: true,
                responsive: true,

                customBodyRender: (value, tableMeta) => {
                    const candidateID = activeTab === 'candidateList' ? canditatesList[tableMeta.rowIndex].CandidateID : linkData[tableMeta.rowIndex]?.CandidateID;
                    const examID = activeTab === 'candidateList' ? canditatesList[tableMeta.rowIndex]?.ExamID : linkData[tableMeta.rowIndex]?.ExamID;
                    const examAttendID = activeTab === 'candidateList' ? canditatesList[tableMeta.rowIndex].ExamAttendID : linkData[tableMeta.rowIndex]?.ExamAttendID;

                    const i = tableMeta.rowIndex;
                    const handleEdit = (event) => {
                        event.preventDefault();
                        setSelectedCandidateId(candidateID);
                        showLoading();
                        setShowEditModal(true);
                    };

                    const handleDelete = () => {
                        Swal.fire({
                            title: "Are you sure?",
                            text: "Do you want to delete this candidate?",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Yes, delete it!",
                        }).then((result) => {
                            if (result.isConfirmed) {
                                Canditateobj.DeleteCanditate(candidateID)
                                    .then((res) => {
                                        if (res.data === 1) {
                                            Toast.fire({
                                                icon: "success",
                                                title: "Delete Success",
                                            });
                                            if (activeTab == "candidateList") {
                                                candidateList();

                                            } else {
                                                LinkcandidateList();
                                            }
                                        } else {
                                            Toast.fire({
                                                icon: "error",
                                                title: "Delete Failed!",
                                            });
                                        }
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                    });
                            }
                        });
                    };

                    return (

                        <Dropdown key={i} show={isDropdownOpen[i]} onToggle={() => toggleDropdown(i)}>
                            <Dropdown.Toggle id={i} onClick={() => toggleDropdown(i)}>
                                <FaCog style={{ color: "#67748e" }} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item
                                    onClick={() => {
                                        if (examID !== 0) {
                                            navigatetopreview(candidateID, examID, examAttendID);
                                        }
                                    }}
                                    disabled={examID === 0}
                                >
                                    Candidate Exam Preview
                                </Dropdown.Item>
                                <Dropdown.Item onClick={handleEdit}>Edit</Dropdown.Item>
                                <Dropdown.Item onClick={handleDelete}>Delete</Dropdown.Item>

                            </Dropdown.Menu>
                        </Dropdown>
                    );
                },
            },
        },
    ];

    const handleCreate = (event) => {
        event.preventDefault();
        setSelectedCandidateId(0);
        setShowCreateModal(true);
    };

    const clickToDownload = () => {
        Canditateobj.DownloadCandidateTemplate().then((res) => {
            const contentType = res.headers["content-type"];
            saveAs(new Blob([res.data], { type: contentType }), "CandidateTemplate.xlsx");

        }).catch((exception) => {
            console.log(exception);
        });
    }
    const generateExcelFile = (data, tab) => {
        // Create a new workbook
        const workbook = XLSX.utils.book_new();

        // Create a new worksheet
        const worksheet = XLSX.utils.json_to_sheet(data);

        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        // Generate the Excel file buffer
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        // Convert the buffer to a Blob
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Save the file using FileSaver.js
        saveAs(blob, tab == "candidateList" ? 'CandidateGroupList.xlsx' : 'Candidate_NonGroup_List.xlsx');
    };

    const handleDownload = () => {

        var apiData;
        Canditateobj.Candidate_DataDownload(activeTab).then((res) => {

            if (res != null) {

                if (res?.data != null) {
                    apiData = res?.data;
                    generateExcelFile(apiData, activeTab);
                }
                else {
                    Toast.fire({
                        icon: "error",
                        title: "Failed to Download!",
                        text: "No Records"
                    });

                }

            }
        }).catch((err) => {
            console.log(err);
        });

    };


    const HeaderElements = () => (
        <>
            <Tooltip title="Add Candidate">
                <label htmlFor="icon-button-file">
                    <IconButton className="HoverDefaultIcon" component="span" onClick={handleCreate}>
                        <AddIcon > </AddIcon>
                    </IconButton>
                </label>
            </Tooltip>
            {isPopupOpen && <BulkUploadCandidate
                setIsPopupOpen={setIsPopupOpen}
                setrefresh={setrefresh}
                candidateList={candidateList}
                LinkcandidateList={LinkcandidateList} />}

        </>
    );
    const toggleContainerClass = () => {
        setIsContainerFluid((prevState) => !prevState);
    };
    return (
        <>
            <main class="main-content-wrap">
                <div className="contact-list-area " id="candidateView">
                    <div className='container-fluid'  >
                        <div className="row">
                            <div className={`${isContainerFluid ? "col-md-12" : "col-md-9"}`}>

                                <div className="card-box-style animatedwidth">
                                    <div className="fullwidthbuttonbox " onClick={toggleContainerClass}>
                                        <span className="fullwidthbutton" >
                                            {isContainerFluid ? <i className="fa fa-angle-double-left"></i> : <i className="fa fa-angle-double-right"></i>}

                                        </span>
                                    </div>
                                    <MUIDataTable
                                        data={activeTab === "candidateList" ? canditatesList : linkData}
                                        title={<ul class="nav nav-tabs" id="myTab" role="tablist">
                                            <li class="nav-item" role="presentation">
                                                <button className="nav-link active" style={{ color: activeTab === "candidateList" ? "white" : "black", background: activeTab === "candidateList" ? "#0d6efd" : "" }}
                                                    onClick={() => setActiveTab("candidateList")} id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true"><h3 className="tab-heading" >Candidate Group List</h3></button>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <button className="nav-link" id="profile-tab" style={{ color: activeTab === "linkList" ? "white" : "black", background: activeTab === "linkList" ? "#0d6efd" : "" }}
                                                    onClick={() => setActiveTab("linkList")} data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false"><h3 className="tab-heading"> Non-Candidate Group List</h3></button>
                                            </li>

                                        </ul>}
                                        columns={columns}
                                        options={{
                                            responsive: true,
                                            download: false,
                                            print: false,
                                            rowsPerPageOptions: [10, 50, 100], // Set the available rows per page options

                                            customToolbar: () => (
                                                <>
                                                    <HeaderElements />
                                                    <Tooltip title="Download Template">
                                                        <IconButton
                                                            type="button"
                                                            className="text-primary"
                                                            data-original-title="Edit"
                                                            data-toggle="modal"
                                                            data-target="#addRowModal"
                                                        >
                                                            <FaFileDownload
                                                                className="edit-icon"
                                                                onClick={clickToDownload}
                                                                style={{ cursor: "pointer" }}

                                                            />
                                                        </IconButton>
                                                    </Tooltip>

                                                    <Tooltip title="Bulk Upload Candidate">
                                                        <IconButton
                                                            type="button"
                                                            className="text-primary"
                                                            data-original-title="Edit"
                                                            data-toggle="modal"
                                                            data-target="#addRowModal"
                                                        >
                                                            <FaFileUpload
                                                                className="edit-icon"
                                                                onClick={handleExcelIconClick}
                                                                style={{ cursor: "pointer" }}


                                                            />
                                                        </IconButton>

                                                    </Tooltip>

                                                    <Tooltip title="Candidate Data Download">
                                                        <IconButton
                                                            type="button"
                                                            className="text-primary"
                                                            data-original-title="Edit"
                                                            data-toggle="modal"
                                                            data-target="#addRowModal"
                                                        >
                                                            <FaDownload
                                                                className="edit-icon"
                                                                style={{ cursor: "pointer" }}
                                                                onClick={handleDownload}

                                                            />
                                                        </IconButton>

                                                    </Tooltip>
                                                </>
                                            ),
                                            filter: false,
                                            selectableRows: false,
                                            viewColumns: false,
                                        }}
                                    />
                                </div>
                            </div>

                            {!isContainerFluid ? <div className="col-md-3">

                                {/* <div className="card-box-style"></div> */}
                            </div> : null}
                        </div>



                    </div>
                </div>
                {showEditModal && (
                    <EditCanditate
                        id={selectedCandidateId}
                        setShowEditModal={setShowEditModal}
                        candidateList={candidateList}
                        LinkcandidateList={LinkcandidateList}
                        setActiveTab={activeTab}
                    />
                )}

                {showCreateModal && (
                    <CreateCandidate
                        setShowCreateModal={setShowCreateModal}
                        candidateList={candidateList}
                        LinkcandidateList={LinkcandidateList}
                        setActiveTab={activeTab}
                    />
                )}
            </main >
        </>
    );
}
export default AdminCanditate;