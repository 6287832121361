import React, { useState, useRef, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../Exam/Exam.css';
import ExamService from '../Exam/ExamService';
import Swal from "sweetalert2";
import EmailTemplateServices from './EmailTemplateService';
import { Decrypt } from '../../services/CommonService';
import { read, utils } from "xlsx";

export default function EmailTemplate({ setShowPopupModal, examLink, checkShareGroup, examName, ExamName }) {

  const _examService = new ExamService();
  const _emailService = new EmailTemplateServices();
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const [toMails, setToMails] = useState([]);
  const [mailValue, setMailValue] = useState('');
  const toMailRef = useRef(null);
  const [message, setMessage] = useState();
  const [subject, setSubject] = useState('');
  const [candidateGroupList, setCandidateGroupList] = useState([]);
  const [candidateGroupID, setCandidateGroupID] = useState();
  const [uniqueEmails, setUniqueEmails] = useState(new Set());
  const [checkMails, setCheckMails] = useState("");

  const onCloseModal = () => {

    setShowPopupModal(false);
  };

  useEffect(() => {
    toMailRef.current.focus();
    _examService.GetSHAREEXAMTemplate()
      .then((response) => {

        var subject = response[0]?.Subject;
        subject = subject.replace("{Exam.ExamName}", "( " + (examName != null ? examName : ExamName.trim()) + " )");
        setSubject(subject);
        var body = response[0].Body;
        body = body.replace("{Exam.ExamLink}", examLink)

        setMessage(body);

      })

      .catch((exception) => {
        console.log(exception);
      });
    if (checkShareGroup) {
      _examService
        .GetExamParameterList()
        .then((response) => {
          const candidateGroupList = response.parameterList.filter(
            (option) => option.parameterCode === "CANDIDATEGROUPS"
          );
          setCandidateGroupList(candidateGroupList);
        })
        .catch((exception) => {
          console.log(exception);
        });
    }
  }, []);

  const addToMails = (e) => {

    if (e.key === 'Enter' || e.key === 'Tab') {
      e.preventDefault();
      if (mailValue.trim() !== '') {
        var commsSplitMails = mailValue.split(',');
        var validEmails = [];
        var invalidEmails = [];
        var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        commsSplitMails.forEach((email) => {
          if (emailRegex.test(email.trim())) {
            if (toMails.includes(email.trim())) {
              // Email already exists in the toMails list, display error
              Toast.fire({
                icon: "error",
                title: email.trim() + " (already exists)",
              });
            } else {
              validEmails.push(email.trim());
            }
          }
          else {
            invalidEmails.push(email.trim());
          }
        });
        if (invalidEmails.length > 0) {

          Toast.fire({
            icon: "warning",
            title: "Please enter valid email",
          });
        }
        setToMails((prevList) => [...prevList, ...validEmails]);
        setMailValue('');
        document.getElementById("search_input").value = "";

      }


      toMailRef.current.focus();
    }

  };

  const mailOnChange = (e) => {
    debugger;
    const emails = e.target.value;
    const emailArray = emails.split(' ');
    const updatedEmailString = emailArray.join(',');
    setCheckMails(updatedEmailString);

    if (emailArray && emailArray.length > 0) {
      debugger;
      setMailValue(updatedEmailString)
    }
    else {
      setMailValue(e.target.value);

    }
  };
  const RemoveMail = (item) => {
    debugger
    const updatedMailsList = toMails.filter((toMails) => toMails !== item);
    setToMails(updatedMailsList);
  }

  const handleBlur = () => {
    toMailRef.current.focus();
  };

  const sendMail = async (e) => {
    debugger;
    e.preventDefault();
    const splitData = examLink.split('=')[1];
    var id = Decrypt(splitData);
    var IsTaken = false;
    var mails = toMails.map(item => item.toString()).join(';');
    var subject = document.getElementById("subject").value;
    var body = message;
    debugger;
    console.log(checkMails);
    // const result = await _emailService.Check_mailForSendTest(checkMails, id);
    // if (result.data != "") {
    //   var updatedMailsList = [...toMails];
    //   if (result != null) {
    //     debugger;
    //     var checkmails = result?.data?.map(item => item.Email).join(',');

    //     for (let i = 0; i < result?.data?.length; i++) {
    //       const SplitEmail = result?.data[i]?.Email;

    //       // Filter out the SplitEmail from updatedMailsList
    //       updatedMailsList = updatedMailsList?.filter((toMail) => toMail !== SplitEmail);
    //     }

    //     // Set toMails to the updatedMailsList after the loop

    //     setToMails(updatedMailsList);
    //     console.log(checkmails);
    //     // Toast.fire({
    //     //   icon: "error",
    //     //   title: "Failed to save",
    //     //   html: `${checkmails} is already used for this exam`
    //     // });
    //     alert(`${checkmails} is already used for this exam`);
    //   }
    //   try {
    //     debugger;
    //     if (updatedMailsList?.length > 0) {
    //       for (const email of updatedMailsList) {
    //         const res = await _emailService.MailCheckTable_AddMails(id, email, IsTaken);
    //         if (res != null) {

    //           console.log(`Email sent for: ${email}`);
    //         }
    //       }

    //       if (mails === null || mails === '') {

    //         Toast.fire({
    //           icon: "warning",
    //           title: "Please enter atleast one email",
    //         });
    //         return false;
    //       }
    //       if (subject === null || subject === '') {
    //         Toast.fire({
    //           icon: "warning",
    //           title: "Please enter subject",
    //         });
    //         return false;
    //       }
    //       if (body === '<p><br></p>') {
    //         Toast.fire({
    //           icon: "warning",
    //           title: "Please enter message body",
    //         });
    //         return false;
    //       }

    //       onCloseModal();
    //       const response = await _examService.SendMailtoCandidate(mails, body, subject);
    //       debugger;
    //       if (response === true) {

    //         Toast.fire({
    //           icon: "success",
    //           title: "Email sent successfully!",
    //         });
    //       }

    //       else {
    //         Toast.fire({
    //           icon: "error",
    //           title: "Failed to send email",
    //         });
    //       }
    //     }
    //     // else {
    //     //   Toast.fire({
    //     //     icon: "warning",
    //     //     title: "please give email id",
    //     //   });
    //     // }
    //   }
    //   catch (exception) {
    //     console.log(exception);
    //   }
    // }

    try {
      for (const email of toMails) {
        const res = await _emailService.MailCheckTable_AddMails(id, email, IsTaken);
        if (res != null) {

          console.log(`Email sent for: ${email}`);
        }
      }

      if (mails === null || mails === '') {

        Toast.fire({
          icon: "warning",
          title: "Please enter atleast one email",
        });
        return false;
      }
      if (subject === null || subject === '') {
        Toast.fire({
          icon: "warning",
          title: "Please enter subject",
        });
        return false;
      }
      if (body === '<p><br></p>') {
        Toast.fire({
          icon: "warning",
          title: "Please enter message body",
        });
        return false;
      }

      onCloseModal();
      const response = await _examService.SendMailtoCandidate(mails, body, subject);
      debugger;
      if (response === true) {

        Toast.fire({
          icon: "success",
          title: "Email sent successfully!",
        });
      } else {
        Toast.fire({
          icon: "error",
          title: "Failed to send email",
        });
      }
    }
    catch (exception) {
      console.log(exception);
    }



  }

  const msgOnChange = (value) => {
    setMessage(value);
  };

  const GetCandidateEmailsAgainstGroup = (e) => {
    setToMails([]);
    if (e.target.value !== '') {
      _examService.GetCandidateEmailsAgainstGroup(parseInt(e.target.value))
        .then((response) => {

          var mails = response.Table[0].CandidateEmails;
          if (mails?.trim() !== '') {
            var commsSplitMails = mails.split(',');
            var validEmails = [];
            var invalidEmails = [];
            var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            commsSplitMails.forEach((email) => {
              if (emailRegex.test(email.trim())) {
                if (toMails.includes(email.trim())) {
                  // Email already exists in the toMails list, display error
                  Toast.fire({
                    icon: "error",
                    title: email.trim() + " (already exists)",
                  });
                } else {
                  validEmails.push(email.trim());
                }
              }
              else {
                invalidEmails.push(email.trim());
              }
            });
            if (invalidEmails.length > 0) {

              Toast.fire({
                icon: "warning",
                title: "Please enter valid email",
              });
            }
            setToMails((prevList) => [...prevList, ...validEmails]);
            setMailValue('');
            document.getElementById("search_input").value = "";

          }
          toMailRef.current.focus();
        })
        .catch((exception) => {
          console.log(exception);
        });
    }
  }
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const emailAddresses = utils.sheet_to_json(worksheet, {
        header: 1,
        defval: "",
      });

      // Extract email addresses from the parsed Excel data
      const extractedEmails = emailAddresses
        .map((row) => row[0]) // Assuming email addresses are in the first column
        .filter((email) => isValidEmail(email));

      // Convert the extracted email addresses to a Set to filter out duplicates
      const uniqueEmailSet = new Set([...uniqueEmails, ...extractedEmails]);

      // Set the unique email addresses in the input field and update the state
      setUniqueEmails(uniqueEmailSet);
      setToMails([...uniqueEmailSet]);
    };

    reader.readAsBinaryString(file);
  }
  const isValidEmail = (email) => {
    // Add your email validation logic here
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  return (
    <>
      <form className="row g-3" autoComplete="off" onSubmit={sendMail}>
        <div className="modal fade show" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-modal="true" role="dialog" style={{ display: 'flex', alignItems: 'center', margin: 0 }}>
          <div className="modal-dialog modal-lg" style={{ margin: 'auto' }}>
            <div className="modal-content my-auto">
              <div className="modal-header">

                <div className='col-md-6'>
                  <h5 className="modal-title MuiTypography-h6" id="staticBackdropLabel">Share Test</h5>

                </div>
                <div className='col-md-5'>
                  <div class="form-group">
                    <label for="form-label" style={{ fontWeight: '600' }}>Upload Emails</label>
                    <input
                      type="file"
                      id="fileInput"
                      accept=".xlsx, .xls"
                      className='form-control-file'
                      onChange={handleFileUpload}

                    />
                  </div>

                </div>

                <div className='col-md-1'>


                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" fdprocessedid="ttcp2q" onClick={onCloseModal}
                  />
                </div>
              </div>
              <div className="modal-body">



                <div className="row">
                  <label

                    htmlFor="formGroupExampleInput"
                    className="form-label col-md-6"
                  >
                    Send Email To (Comma Separated)
                    <span className="text-danger"> *</span>
                  </label>
                  {checkShareGroup ? <select

                    class="form-select form-control shareCandidateGroupDropdown col-md-6"

                    onChange={GetCandidateEmailsAgainstGroup}
                  >
                    {" "}
                    <option value="">
                      Select Group
                    </option>
                    {candidateGroupList.map((option) => (
                      <option
                        key={option.parameterValueID}
                        value={option.parameterValueID}
                      >
                        {option.value}
                      </option>
                    ))}
                  </select> : <></>}


                  <div class="input-group mb-3">
                    <div class="multiselect-container multiSelectContainer  multiSelectContainer" id="multiselectContainerReact" onClick={handleBlur}>
                      <div className="search-wrapper searchWrapper scrollable-container">
                        {toMails.map((item, index) => (
                          <span className="chip  false false">
                            {item}
                            <img onClick={() => RemoveMail(item)}
                              className="icon_cancel closeIcon"
                              src="data:image/svg+xml,%3Csvg%20height%3D%22512px%22%20id%3D%22Layer_1%22%20style%3D%22enable-background%3Anew%200%200%20512%20512%3B%22%20version%3D%221.1%22%20viewBox%3D%220%200%20512%20512%22%20width%3D%22512px%22%20xml%3Aspace%3D%22preserve%22%20%20%20%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20%20%20%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%20%20%20%20%3Cstyle%20type%3D%22text%2Fcss%22%3E%20%20%20%20%20%20%20%20.st0%7B%20%20%20%20%20%20%20%20%20%20%20%20fill%3A%23fff%3B%20%20%20%20%20%20%20%20%7D%20%3C%2Fstyle%3E%20%20%20%20%3Cpath%20class%3D%22st0%22%20d%3D%22M443.6%2C387.1L312.4%2C255.4l131.5-130c5.4-5.4%2C5.4-14.2%2C0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7%2C0-7.2%2C1.5-9.8%2C4%20%20L256%2C197.8L124.9%2C68.3c-2.6-2.6-6.1-4-9.8-4c-3.7%2C0-7.2%2C1.5-9.8%2C4L68%2C105.9c-5.4%2C5.4-5.4%2C14.2%2C0%2C19.6l131.5%2C130L68.4%2C387.1%20%20c-2.6%2C2.6-4.1%2C6.1-4.1%2C9.8c0%2C3.7%2C1.4%2C7.2%2C4.1%2C9.8l37.4%2C37.6c2.7%2C2.7%2C6.2%2C4.1%2C9.8%2C4.1c3.5%2C0%2C7.1-1.3%2C9.8-4.1L256%2C313.1l130.7%2C131.1%20%20c2.7%2C2.7%2C6.2%2C4.1%2C9.8%2C4.1c3.5%2C0%2C7.1-1.3%2C9.8-4.1l37.4-37.6c2.6-2.6%2C4.1-6.1%2C4.1-9.8C447.7%2C393.2%2C446.2%2C389.7%2C443.6%2C387.1z%22%2F%3E%3C%2Fsvg%3E"
                            />
                          </span>
                        ))}

                        <input
                          type="text"
                          className="searchBox "
                          id="search_input"
                          name="particulars_input"
                          placeholder=""
                          autoComplete="off"
                          defaultValue={mailValue}
                          ref={toMailRef}

                          onKeyDown={addToMails}
                          onChange={mailOnChange}
                        />
                      </div>
                    </div>
                  </div>

                </div>

                <div className="row">
                  <label
                    htmlFor="formGroupExampleInput"
                    className="form-label"
                  >
                    Subject
                    <span className="text-danger"> *</span>
                  </label>

                  <div class="input-group mb-3">
                    <input type="text" class="form-control" id="subject" defaultValue={subject} />

                  </div>
                </div>

                <div className="row">
                  <label
                    htmlFor="formGroupExampleInput"
                    className="form-label"
                  >
                    Message Body
                    <span className="text-danger"> *</span>
                  </label>

                  <div class="input-group mb-3">
                    <ReactQuill value={message} className="quill-editor " rows="5" onChange={msgOnChange} />

                  </div>
                </div>






              </div>
              <div className="modal-footer mailtemplatefooter">
                <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={onCloseModal} fdprocessedid="t27w74">Close</button>
                <button type="submit" className="btn btn-primary" fdprocessedid="nu3mc7">
                  Send Email
                </button>
              </div>

            </div>
          </div>
        </div>

      </form>

    </>
  );
}