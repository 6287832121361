import React from "react";
import { Outlet } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../../services/ContextProvider";
import { ToastMsg } from "../ToastMsg";

function WithoutNav (){
  const { loadingStatus } = useContext(AppContext);

  var loadingStyle = !loadingStatus ? { display: "none" } : {};

  return (
    <div>
      {/* Loader */}
      <div class="loader-Wrapper">
      <div className="loader-div" style={loadingStyle}>
        <div className="preloader-style-seventeen">
            <div className="pl-flip-1 pl-flip-2">

            </div>
        </div>
      </div>
      </div>

      {/* Content */}
      <div>
        <ToastMsg />
        <Outlet />
      </div>
    </div>
  );
}
export default WithoutNav;      