import React from "react";
import { useState,useEffect } from "react";


function Error(){
    const [show, setShow] = useState(false);
    useEffect(() => {
        let timer1 = setTimeout(() => setShow(true),0);
        return () => {
          clearTimeout(timer1);
        };
    
      });
    return(
        <>
        {show && <div class="error-area">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="error-content card-box-style">
                            <h1>404</h1>
                            <h3>Oops! Page Not Found</h3>
                            <p>The page you were looking for could not be found.</p>
                            <a class="btn btn btn-primary" href="website-analytics.html">Return To Home Page</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
}
        </>
    );
}
export default Error;