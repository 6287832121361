import React, { useEffect, useState, useContext, useRef } from "react";
import CanditateServices from "../../services/CanditateService";
import Swal from "sweetalert2";
import { Decrypt } from "../../services/CommonService";
import { useNavigate } from "react-router-dom";
import RejoinTest from "./RejoinTest";
import { Encrypt } from "../../services/CommonService";
import { AppContext } from "../../services/ContextProvider";
import vaanam from '../../assets/images/loginlogo.png';
import $ from 'jquery';
import { FaCopy, FaFlag, FaHandPointer } from 'react-icons/fa';
import './candidate.css';
import axios from 'axios';


var Canditateobj = new CanditateServices();
function Canditate() {
    const { showLoading, hideLoading, setCookies, tempModulePrivileage, isAuth, SetisAuth, getCookies } =
        useContext(AppContext);
    const [canditate, setcanditate] = useState([]);
    const [datafield, setdatafield] = useState([]);
    const navigate = new useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [error, setformError] = useState({});
    const [examID, setexamID] = useState(null);
    const [result, setres] = useState();
    const [rejoincode, setrejoincode] = useState('');
    const [numberValue, setNumberValue] = useState();
    const textBoxRef = useRef(null);
    const copyRef = useRef(null);
    const [isDivAVisible, setIsDivAVisible] = useState(true);
    const [open, setIopen] = useState(true);// State to control the visibility of the first div (a)
    var cID;
    var candidateName;
    var email;
    
    useEffect(() => {
        // Generate the rejoin code only once when the component mounts
        setrejoincode(rejoinCode_Create());
    }, []);
    useEffect(() => {
        showLoading();

        const fullUrl = window.location.href;
        const splitData = fullUrl.split('=')[1];
        var a = document.body.style.backgroundColor = '#f8faff';

        var id = Decrypt(splitData);
        setexamID(id);
        if (examID !== null) {
            debugger;
            Canditateobj.view(examID).then((res) => {
                if (res?.data?.error != null && res?.data?.error != 'InvalidURL') {
                    debugger;
                    navigate('/error', {
                        state: { examID: examID, error: res?.data?.error },
                        replace: true,
                    });

                }
                else if (res?.data?.error === 'InvalidURL') {
                    debugger;
                    var a = document.getElementById("a");
                    a.style.opacity = "20%";

                    setIopen(true)
                    setIsModalOpen(true);
                    hideLoading();
                }
                else {
                    debugger;
                    var a = document.getElementById("a");
                    a.style.opacity = "inherit";
                    setIopen(false);
                    if (res != null && res?.data?.examRegistrations?.length > 0) {
                        setcanditate(res.data.examRegistrations);
                    }
                    if (res != null && res?.data?.dataFields?.length > 0) {
                        setdatafield(res.data.dataFields);
                    }
                    hideLoading();
                }
            });
        }
        // document.getElementById("submit").addEventListener("click", function () {
        //     document.querySelector(".loader-div").style.height = "100vh";
        //     document.body.style.overflow = "hidden";
        // });
        // hideLoading();

    }, [examID]);
    const detectBrowser = () => {
        const userAgent = navigator.userAgent;
        let browserName;

        if (userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1) {
            browserName = 'Opera';
        } else if (userAgent.indexOf('Edg') > -1) {
            browserName = 'Microsoft Edge';
        } else if (userAgent.indexOf('Chrome') > -1) {
            browserName = 'Google Chrome';
        } else if (userAgent.indexOf('Safari') > -1) {
            browserName = 'Safari';
        } else if (userAgent.indexOf('Firefox') > -1) {
            browserName = 'Mozilla Firefox';
        } else if (userAgent.indexOf('MSIE') > -1 || !!document.documentMode === true) {
            browserName = 'Internet Explorer';
        } else {
            browserName = 'Unknown';
        }

        return browserName;
    };
    const fetchIpAddress = async () => {
        try {
            debugger;
            const response = await axios.get('https://api.ipify.org/?format=json');
            const data = response.data;
            return data.ip

        } catch (error) {
            console.error('Error fetching IP address:', error);
            return null;
        }
    };
    const handleClick = (event) => {
        event.preventDefault();
        setIsModalOpen(true);
    };

    const handleInputChange = (e, fieldName, displayname) => {
        const fieldValue = e.target.value;
        const updatedErrors = { ...error };

        const field = canditate.find((x) => x.dataFieldName === fieldName);
        if (field && field.dataFieldRequired && !fieldValue.trim()) {
            updatedErrors[displayname] = `${displayname} is required`;
        } else if (fieldName === "FirstName" || fieldName === "LastName") {
            const nonNumericValue = fieldValue.replace(/\d/g, "");
            if (fieldValue !== nonNumericValue) {
                e.target.value = nonNumericValue;

                updatedErrors[displayname] = `${displayname} should only contain letters`;
            } else {
                updatedErrors[displayname] = "";
            }
        } else if (fieldName === "MobileNumber" || fieldName === "AlternateMobileNumber") {
            const numericValue = fieldValue.replace(/[^0-9]/g, '');
            setNumberValue(numericValue);
            if (numericValue === "") {
                updatedErrors[displayname] = "Enter number only";
            } else if (numericValue.length > 10) {
                updatedErrors[displayname] = "Mobile Number must be 10 digits long";
            } else {
                updatedErrors[displayname] = "";
            }
        } else if (fieldName === "Email") {
            const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
            const isValidEmail = emailPattern.test(fieldValue);
            if (!isValidEmail) {
                updatedErrors[displayname] = "Invalid Email";
            } else {
                updatedErrors[displayname] = "";
            }
        } else if (fieldName === "Gender") {
            if (fieldValue === "0") {
                updatedErrors[displayname] = "Please Enter Gender";
            } else {
                updatedErrors[displayname] = "";
            }
        }
        // Clear the value of MobileNumber field when it is empty
        if (fieldName === "MobileNumber" && fieldValue.trim() === "") {
            setNumberValue(""); // Set the value to empty string
        }
        setformError((prevErrors) => ({ ...prevErrors, ...updatedErrors }));
    };

    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });


    const onSubmit = (e) => {
        e.preventDefault();
        const myerror = {};
        let flag = true;
        canditate.forEach((x) => {
            const fieldValue = document.getElementById(x.dataFieldName)?.value || "";
            const formattedFieldName = x.displayname;

            if (x.dataFieldRequired && !fieldValue.trim()) {
                myerror[x.displayname] = `${formattedFieldName} is required`;
                flag = false;
            } else if (x.dataFieldName === "FirstName" || x.dataFieldName === "LastName") {
                const nonNumericValue = fieldValue.replace(/\d/g, "");
                if (fieldValue !== nonNumericValue) {
                    myerror[x.displayname] = `${formattedFieldName} should only contain letters`;
                    flag = false;
                }
            } else if (x.dataFieldName === "MobileNumber" || x.dataFieldName === "AlternateMobileNumber") {
                const numericValue = fieldValue.replace(/[^0-9]/g, '');
                setNumberValue(numericValue);
                if (fieldValue !== numericValue) {
                    myerror[x.displayname] = "Enter number only";
                    flag = false;
                } else if (numericValue.length !== 10) {
                    myerror[x.displayname] = "Mobile Number must be 10 digits long";
                    flag = false;
                }
            } else if (x.dataFieldName === "Email") {
                const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
                const isValidEmail = emailPattern.test(fieldValue);
                if (!isValidEmail) {
                    myerror[x.displayname] = "Invalid Email";
                    flag = false;
                }
            } else if (x.dataFieldName === "Gender") {
                if (fieldValue === "0") {
                    myerror[x.displayname] = "Please Enter Gender";
                    flag = false;
                }
            }
        });

        setformError(myerror);

        if (flag) {
            setIsDivAVisible(!isDivAVisible);

            const divA = document.getElementById('a');
            const divB = document.getElementById('b');

            if (divA && divB) {
                // Get the current display style of divA
                const isDivAVisible = divA.style.display === 'block';

                // Toggle display for divA and divB
                divA.style.display = isDivAVisible ? 'none' : 'block';
                divB.style.display = isDivAVisible ? 'block' : 'none';

                const animatedBox = document.getElementById('b');
                if (animatedBox) {
                    animatedBox.classList.add(isDivAVisible ? 'show' : '');
                }
            }

        }
    };
    // const handleEscapeKey = (event) => {
    //     debugger;
    //     if (event.key === 'Escape') {
    //         event.preventDefault();
    //     }
    // }
    // function toggleFullScreen(elem) {

    //     debugger
    //     if (!document.fullscreenElement) {
    //         document.documentElement.requestFullscreen();
    //     }
    //     // else if (document.exitFullscreen) {
    //     //     document.exitFullscreen();
    //     // }




    //     // if ((document.fullScreenElement !== undefined && document.fullScreenElement === null) || (document.msFullscreenElement !== undefined && document.msFullscreenElement === null) || (document.mozFullScreen !== undefined && !document.mozFullScreen) || (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen)) {
    //     //     document.addEventListener('keydown', handleEscapeKey);
    //     //     debugger;
    //     //     if (elem.requestFullScreen) {

    //     //         elem.requestFullScreen();

    //     //     } else if (elem.mozRequestFullScreen) {

    //     //         elem.mozRequestFullScreen();

    //     //     } else if (elem.msRequestFullscreen) {

    //     //         elem.msRequestFullscreen();

    //     //     }

    //     // }
    //     // else {

    //     //     if (document.cancelFullScreen) {

    //     //         document.cancelFullScreen();

    //     //     } else if (document.mozCancelFullScreen) {

    //     //         document.mozCancelFullScreen();

    //     //     } else if (document.webkitCancelFullScreen) {

    //     //         document.webkitCancelFullScreen();

    //     //     } else if (document.msExitFullscreen) {

    //     //         document.msExitFullscreen();

    //     //     }

    //     // }

    // }



    const candidateData = async () => {
        document.querySelector(".loader-div").style.height = "100vh";
        // document.querySelector("#sidebar-menu").style.display = "none";
        document.body.style.overflow = "hidden";
        showLoading();

        debugger;
        const browserName = await detectBrowser();
        const IP = await fetchIpAddress();
        const data = canditate.reduce((formData, x) => {
            formData[x.dataFieldName] = document.getElementById(x.dataFieldName)?.value || "";
            formData.CandidateName = document.getElementById("FirstName").value + " " + (document.getElementById("LastName") ? document.getElementById("LastName").value : '');
            candidateName = formData.CandidateName;
            const genderElement = document.querySelector("input[type='radio'][name=Gender]:checked");
            formData.Gender = genderElement !== null ? parseInt(genderElement.value) : 0;
            formData.status = "AC";
            formData.createdBy = 1;
            formData.examID = examID;
            formData.candidateGroupID = 0;
            formData.parameter = 'DEL'
            email = formData.Email;
            formData.browserName = browserName;
            formData.iPAddress = IP;

            return formData;
        }, {});

        console.log(email);
        debugger;
        Canditateobj.post(data).then((res) => {
            // toggleFullScreen(document.body);
            setres(res.data?.length);

            if (res != null && res.data != 0) {
                debugger;
                cID = res?.data?.CandidateID[0]?.CandidateID;
                var ExamAttendID = res?.data?.ExamAttendID[0]?.ExamAttendID;
                const subject = 'Rejoin Code';
                const body = `Hi ${candidateName},Note: Please Find The Code For Rejoin Your Test: ${rejoincode}.`;
                const rejoinData = {
                    linkGenreated: rejoincode,
                    candidateID: res?.data?.CandidateID[0]?.CandidateID,
                    examID: examID,
                    examAttendID: ExamAttendID
                }
                debugger;
                localStorage.setItem('Rejoincode', rejoincode);

                Canditateobj.Exam_Rejoincode(rejoinData).then((res) => {
                    if (res != null) {
                        document.body.style.overflow = "auto";
                        console.log('sucess');
                    }
                })
                Canditateobj.SendMailtoCandidate(email, rejoincode, candidateName).then((response) => {
                    if (response === true) {
                        document.body.style.overflow = "auto";
                        console.log('email sent');
                        hideLoading();
                        localStorage.removeItem('remainingTime');

                        navigate('/CandidateQuestion', {
                            state: { candidateID: res?.data?.CandidateID[0]?.CandidateID, examID: examID, canditateName: data.CandidateName, emailID: email, examAttendID: ExamAttendID },
                            replace: true,
                           
                        });
                    } else {
                        document.body.style.overflow = "auto";
                        console.log('email reject ');
                        hideLoading();
                        localStorage.removeItem('remainingTime');

                        navigate("/CandidateQuestion", {
                            state: { candidateID: res?.data?.CandidateID[0]?.CandidateID, examID: examID, canditateName: data.CandidateName, emailID: email, examAttendID: ExamAttendID },
                            replace: true,
                        });
                    }
                }).catch((err) => {
                    console.log(err);
                })

            } else {
                document.body.style.overflow = "auto";
                hideLoading();
                Toast.fire({
                    icon: "warning",
                    title: "Invalid Details Please contact Admin",
                });
            }
        }).catch((err) => {
            console.log(err);
        });
    }
    const handleCopyClick = (ref) => {
        if (ref.current) {
            const range = document.createRange();
            range.selectNodeContents(ref.current);
            const selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            document.execCommand('copy');
        }
    };
    return (
        <>

            {/* Start Main Content Area */}
            <div className="container mt-3" id="a" style={{ display: 'block' }}>
                <div className="row justify-content-center  align-items-center">
                    <div className="col-sm-8">
                        <div className="card h-100 mb-3">
                            <div className="card-body" >
                                <div className="row justify-content-center">
                                    <div className="col-12 text-center"> {/* Add text-center class */}
                                        <img src={vaanam} alt="Vaanam" style={{ height: '100px' }} />
                                        <p>Vaanam Technologies Private Limited</p>
                                        <span className="font" style={{ fontWeight: 'bold' }}>Start Your Test now</span>
                                        <div className="row justify-content-center">
                                            <div className="col-10">
                                                <span className="form-control font" style={{ backgroundColor: 'lightgray' }}>
                                                    Your Rejoin Code is{' '}
                                                    <span className="text-danger" ref={textBoxRef}>
                                                        {rejoincode}
                                                    </span>{' '}
                                                    in case you lose connectivity during the Test.
                                                    <FaCopy onClick={(event) => handleCopyClick(textBoxRef)} />
                                                </span>


                                            </div>
                                        </div>
                                        {/* Rejoin code : <a>{rejoincode}</a></p> */}
                                    </div>
                                </div>
                                <div className="table table-card mb-3">
                                    {/* Add text-center class */}
                                    <thead className="font">
                                        <tr>
                                            <th className="text-center">Exam Instructions (Please read carefully)</th></tr>
                                    </thead>
                                    <tbody className="bg-f8faff font">
                                        <tr>
                                            <td>  <FaHandPointer color="black" size={10} style={{ transform: 'rotate(90deg)' }} /> The timer has been set for the exam, and a countdown will display the remaining time. When the timer runs out, the exam will end automatically. No further action will be required after that</td> </tr>
                                        <tr>
                                            <td>  <FaHandPointer color="black" size={10} style={{ transform: 'rotate(90deg)' }} /> You can flag any question that you want</td> </tr>
                                        <tr>
                                            <td > <FaHandPointer color="black" size={10} style={{ transform: 'rotate(90deg)' }} /> If you don't want to submit any of your provided answers, you can clear them</td> </tr>
                                        <tr>
                                            <td > <FaHandPointer color="black" size={10} style={{ transform: 'rotate(90deg)' }} /> If you choose to drop this exam, it will not be submitted, and no result will be generated</td> </tr>
                                        <tr>
                                            <td > <FaHandPointer color="black" size={10} style={{ transform: 'rotate(90deg)' }} /> Once you finish the exam, you cannot resume it</td> </tr>
                                        <tr>
                                            <td > <FaHandPointer color="black" size={10} style={{ transform: 'rotate(90deg)' }} /> Use <FaFlag color="green"></FaFlag> to mark a question that you have answered correctly</td></tr>
                                        <tr>
                                            <td > <FaHandPointer color="black" size={10} style={{ transform: 'rotate(90deg)' }} /> Use <FaFlag color="red"></FaFlag> to mark a question that you want to review later</td></tr>


                                    </tbody>


                                </div>

                                <form className="row g-3 font" autoComplete="off">
                                    {canditate.map((x, index) => {

                                        const errorText = error[x.displayname] || "";
                                        return (
                                            <div className="col-md-6" key={index}>
                                                <label htmlFor={x.displayname} className="form-label">
                                                    {x.displayname}
                                                </label>
                                                {x.dataFieldRequired ? <span className="text-danger"> *</span> : null}

                                                {x.fieldType === "TextBox" ? (
                                                    <input
                                                        type="text"
                                                        className="form-control candidateRegistration"

                                                        id={x.dataFieldName}
                                                        value={x.dataFieldName === "MobileNumber" ? numberValue : undefined}
                                                        fdprocessedid="7fccac"
                                                        onBlur={(e) => handleInputChange(e, x.dataFieldName, x.displayname)}
                                                        onChange={(e) => x.dataFieldName === "MobileNumber" || x.dataFieldName === "FirstName" || x.dataFieldName === "LastName" ? handleInputChange(e, x.dataFieldName, x.displayname) : undefined}
                                                        maxLength={x.dataFieldName === "MobileNumber" ? 10 : undefined}

                                                    />

                                                ) : x.fieldType === "CheckBox" ? (
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="inputCheckBox"
                                                        fdprocessedid="7fccac"
                                                    />
                                                ) : x.fieldType === "DropDown" ? (
                                                    <select className="form-select" id={x.dataFieldName} fdprocessedid="7fccac" onChange={(e) => handleInputChange(e, x.dataFieldName)} >
                                                        <option value="0">-- Select Option --</option>
                                                        {datafield.map((y, yIndex) => {
                                                            if (y.dataFieldID === x.dataFieldID) {
                                                                return (
                                                                    <option value={y.dataFieldValueID} key={yIndex}>
                                                                        {y.dataFieldValue}
                                                                    </option>
                                                                );
                                                            } else {
                                                                return null;
                                                            }
                                                        })}
                                                    </select>
                                                ) : x.fieldType === "RadioButton" ? (
                                                    <>
                                                        <div className="radio-button-container">
                                                            {datafield.map((y, yIndex) => {
                                                                if (y.dataFieldID === x.dataFieldID) {
                                                                    return (
                                                                        <div className="form-check" key={yIndex} onChange={(e) => handleInputChange(e, x.dataFieldName)} style={{ paddingLeft: '0px' }}>
                                                                            <input
                                                                                style={{ borderColor: 'black' }}
                                                                                type="radio"
                                                                                className="form-check-input"
                                                                                id={`${x.dataFieldName}-${yIndex}`}
                                                                                name={x.displayname}
                                                                                value={y.dataFieldValueID}
                                                                            />
                                                                            <label
                                                                                htmlFor={x.displayname}
                                                                                className="form-check-label"
                                                                            >
                                                                                &nbsp;{y.dataFieldValue}
                                                                            </label>
                                                                        </div>
                                                                    );
                                                                } else {
                                                                    return null;
                                                                }
                                                            })}
                                                        </div>
                                                    </>

                                                ) : null}


                                                {errorText && <span className="text-danger">{errorText}</span>}


                                            </div>
                                        );
                                    })}
                                    <div className="text-center">
                                        <button type="button" id="submit" className="btn btn-primary col-8" fdprocessedid="nu3mc7" onClick={onSubmit}>
                                            Start Test
                                        </button>
                                    </div>

                                    <p className="text-center">OR</p>
                                    <div className="text-center">
                                        <button type="submit" className="btn col-8" fdprocessedid="nu3mc7" style={{ backgroundColor: 'gray', color: 'white' }} onClick={handleClick}>
                                            Rejoin Test
                                        </button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <div className={`container mt-3 animated-box ${isDivAVisible ? 'show' : 'hide'}`} id="b" style={{ display: 'none' }}>
                <div className="row justify-content-center  align-items-center">
                    <div className="col-sm-8">
                        <div className="card h-100 mb-3">
                            <div className="card-body" >
                                <div className="row justify-content-center">
                                    <div className="col-12 text-center"> {/* Add text-center class */}
                                        <img src={vaanam} alt="Vaanam" style={{ height: '100px' }} />
                                        <p >Vaanam Technologies Private Limited</p>
                                        <span className="font" style={{ fontWeight: 'bold' }}>Start Your Test now</span>
                                        <div className="row justify-content-center">
                                            <div className="col-10">
                                                <span className="form-control font" style={{ backgroundColor: 'lightgray' }}>
                                                    Your Rejoin Code is{' '}
                                                    <span className="text-danger font" ref={copyRef}>
                                                        {rejoincode}
                                                    </span>{' '}
                                                    in case you lose connectivity during the Test.
                                                    <FaCopy onClick={(event) => handleCopyClick(copyRef)} />
                                                </span>

                                            </div>
                                        </div>
                                        {/* Rejoin code : <a>{rejoincode}</a></p> */}
                                    </div>
                                </div>

                                <div className="table table-borderless mb-3 row justify-content-center" style={{ display: 'grid' }}>
                                    {/* Add text-center class */}
                                    <thead className="font">
                                        <tr>
                                            <th >Malpractice Instructions (Please read carefully)</th></tr>
                                        <tr>
                                            <th className="text-danger">Exam will be terminated when the unusual activity happens</th></tr>
                                        <tr>
                                            <th className="text-danger">What is unusual behaviour?</th></tr>
                                    </thead>
                                    <tbody className="bg-f8faff font">
                                        <tr>
                                            <td>  <FaHandPointer color="black" size={10} style={{ transform: 'rotate(90deg)' }} /> Minimizing the Browser</td> </tr>
                                        <tr>
                                            <td>  <FaHandPointer color="black" size={10} style={{ transform: 'rotate(90deg)' }} /> Resizing the Browser</td> </tr>
                                        <tr>
                                            <td > <FaHandPointer color="black" size={10} style={{ transform: 'rotate(90deg)' }} /> Open a New Tab</td> </tr>
                                        <tr>
                                            <td > <FaHandPointer color="black" size={10} style={{ transform: 'rotate(90deg)' }} /> Open a New Program</td> </tr>
                                        <tr>
                                            <td > <FaHandPointer color="black" size={10} style={{ transform: 'rotate(90deg)' }} /> Taking a Screenshot</td> </tr>
                                        <tr>
                                            <td > <FaHandPointer color="black" size={10} style={{ transform: 'rotate(90deg)' }} /> Pressing Ctrl+C</td> </tr>

                                        <tr>
                                            <td > <FaHandPointer color="black" size={10} style={{ transform: 'rotate(90deg)' }} /> Pressing Ctrl+V</td> </tr>
                                        <tr>
                                            <td > <FaHandPointer color="black" size={10} style={{ transform: 'rotate(90deg)' }} /> Pressing Printing Screen</td> </tr>

                                        <tr>
                                            <td > <FaHandPointer color="black" size={10} style={{ transform: 'rotate(90deg)' }} /> Pressing F12</td> </tr>

                                    </tbody>


                                </div>
                                <div className="text-center">
                                    <button type="button" id="submit" className="btn btn-primary col-8" fdprocessedid="nu3mc7" onClick={candidateData}>
                                        Continue
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div >
            {isModalOpen && (
                <RejoinTest
                    setIsModalOpen={setIsModalOpen}
                    cID={cID}
                    ExamID={examID}
                    isModalOpen={isModalOpen}
                    open={open}
                />
            )
            }
        </>
    );

}

export default Canditate;
function rejoinCode_Create() {
    const characters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];
    const combinedArray = characters.concat(numbers);

    const generateRandomChar = () => {
        const randomIndex = Math.floor(Math.random() * combinedArray.length);
        return combinedArray[randomIndex];
    };

    const generateRandomString = () => {
        let randomString = '';
        while (randomString.length < 6) {
            const isCharacter = Math.random() < 0.5;
            if (isCharacter) {
                randomString += generateRandomChar();
            }
        }
        return randomString;
    };

    const randomString = generateRandomString();
    return randomString;
}

