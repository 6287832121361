import React, { useEffect, useState, useContext } from "react";
import { Pie } from 'react-chartjs-2';
import "./Exam.css";
import ExamService from "./ExamService";

import { useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from "../../services/ContextProvider";
import { BorderBottom } from "@mui/icons-material";
export default function ExamStatistics() {
  const location = useLocation();
  const navigate = new useNavigate();
  const { showLoading, hideLoading, } =
    useContext(AppContext);
  const [examID, setexamid] = useState(location.state?.examID ?? 0);
  const _examService = new ExamService();
  const [ExamStatistics, setExamStatistics] = useState({});
  const [ExamStatisticsList, setExamStatisticsList] = useState([]);
  const [isContainerFluid, setIsContainerFluid] = useState(true);

  useEffect(() => {

    showLoading();

    _examService
      .GetExamStatistics(examID)
      .then((response) => {
        hideLoading();
        console.log(response.Table);
        setExamStatisticsList(response.Table1)
        setExamStatistics(response.Table)

      })

      .catch((exception) => {
        console.log(exception);
      });


  }, []);
  const data = {
    labels: ExamStatistics && ExamStatistics[0] ? [
      `Pass ${ExamStatistics[0].PassPercentage == null ? 0 : ExamStatistics[0].PassPercentage}%` + ` (${ExamStatistics[0].PassCount == null ? 0 : ExamStatistics[0].PassCount})`,
      `Fail ${ExamStatistics[0].FailPercentage == null ? 0 : ExamStatistics[0].FailPercentage}% ` + `(${ExamStatistics[0].FailCount == null ? 0 : ExamStatistics[0].FailCount})`,
    ] : [],
    datasets: [
      {
        data: ExamStatistics && ExamStatistics[0] ? [
          ExamStatistics[0].PassPercentage,
          ExamStatistics[0].FailPercentage
        ] : [],
        backgroundColor: ['#4fcb8d', '#ff0000'],
        hoverBackgroundColor: ['#4fcb8d', '#ff0000'],
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        position: 'bottom', // Change the position of the legend labels (e.g., 'top', 'bottom', 'left', 'right')
      },
    },
  };
  const QuestionPreview = (questionID) => {
    navigate("/ExamPreview", {
      state: {
        examID: examID,
        questionID: questionID
      },
    });
  };

  const toggleContainerClass = () => {
    setIsContainerFluid((prevState) => !prevState);
  };
  return (
    <>
      <main class="main-content-wrap">
        <div className="contact-list-area ">
          <div className={`${isContainerFluid ? 'container-fluid' : 'container-sm'} animatedwidth`}>

            <div className="fullwidthbuttonbox hidden-sm hidden-xs" onClick={toggleContainerClass}>
              <span className="fullwidthbutton" >
                <i className="fa fa-angle-double-right"></i>
              </span>
            </div>   <div className="card-box-style">
              <div className="others-title row">
                <h3 className="col-6">Exam Statistics
                </h3>
                <span className="col-6">
                  <input type="button" value="Back" title="Back" style={{ float: 'right' }} className="back-button backbutton-end" onClick={() => navigate(-1)} />
                </span>
              </div>
              <div className="device-content card-box-style row justify-content-center">
                <div className="col-md-6 d-flex  align-items-center">
                  <ul className="list">
                    <li className="mb-3">
                      <h4>{ExamStatistics && ExamStatistics[0] ? `${ExamStatistics[0].AvgPercentage == null ? 0 : ExamStatistics[0].AvgPercentage}%` : <>0</>}</h4>
                      <p>Average Percentage</p>
                    </li>
                    <li className="mb-3">
                      <h4>{ExamStatistics && ExamStatistics[0] ? ExamStatistics[0].AvgScore == null ? 0 : ExamStatistics[0].AvgScore : <>0</>}</h4>
                      <p>Average Score</p>
                    </li>
                    <li className="mb-3">
                      <h4>{ExamStatistics && ExamStatistics[0] ? `${ExamStatistics[0].AvgTimeTakenMinutes == null ? 0 :
                        ExamStatistics[0].AvgTimeTakenMinutes >= 60
                          ? _examService.convertSecondsToMinutes(ExamStatistics[0].AvgTimeTakenMinutes) + ' Minutes'
                          : ExamStatistics[0].AvgTimeTakenMinutes + ' Seconds'
                        } ` : <>0</>}</h4>
                      <p>Average Time Taken</p>
                    </li>
                    <li className="mb-3">
                      <h4>{ExamStatistics && ExamStatistics[0] ? ExamStatistics[0].ExamAttempts : <>0</>}</h4>
                      <p>Exam Attempts</p>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6 d-flex  align-items-center piechart">
                  <Pie data={data} options={options} />
                </div>
              </div>
              <table className="table align-middle mb-0">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>QuestionID</th>
                    <th>% Correct</th>
                    <th>Correct</th>

                    <th>Partially Correct</th>
                    <th>InCorrect</th>
                    <th>Unanswered</th>
                    <th>Avg Time</th>
                    <th>Question Attempts</th>
                  </tr>
                </thead>
                <tbody>
                  {ExamStatisticsList != null && ExamStatisticsList.length > 0 ? (
                    ExamStatisticsList.map((v, i) => {
                      return (
                        <tr key={i}>
                          {" "}
                          <td>{i + 1}</td>
                          <td style={{ cursor: "pointer", color: "#2782dd" }} onClick={() => QuestionPreview(v.QuestionID)}> {v.QuestionID}

                          </td>
                          <td>
                            <p
                              className="location"
                              style={{
                                width: `${v.PassPercentage}%`,
                                borderBottom: "7px solid green",
                              }}
                            />

                          </td>
                          <td>
                            {v.Correct}
                          </td>
                          <td>
                            {v.PartialCorrect}
                          </td>
                          <td>
                            {v.Wrong}
                          </td>
                          <td>
                            {v.UnanswerdCount}
                          </td>
                          <td>
                            {v.TimeTaken >= 60
                              ? _examService.convertSecondsToMinutes(v.TimeTaken) + ' Minutes'
                              : v.TimeTaken === null ? 0 : v.TimeTaken + ' Seconds'}

                          </td>
                          <td>
                            {v.NoOfAttempts
                            }
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
    </>
  );

}