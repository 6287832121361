import ExamService from './ExamService';
import { useEffect } from 'react';
import { useState } from 'react';
import { FaTrashAlt, FaCog } from "react-icons/fa";
import { useNavigate, useLocation } from 'react-router-dom';

const _examservice = new ExamService();

const ExamHistory = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const navigateToReview = (cId) => {
        navigate("/ReviewExam", {
            state: { cId: cId },
        });
    }
    const [cId, setqId] = useState(location.state?.cId ?? 0);
    const [Examid, setExamid] = useState(location.state?.examID ?? 0);
    const [ReportList, setReportList] = useState([]);

    useEffect(() => {
        _examservice.GetReportDetails(cId, Examid).then((res) => {
            setReportList(res);
            console.log(res);
        })
    }, []);

    return (

        <main class="main-content-wrap">
            <div className="contact-list-area ">
                <div className="container-fluid">
                    <div className="table-responsive" data-simplebar="init">
                        <div className="simplebar-wrapper" style={{ margin: "-30px" }}>
                            <div className="simplebar-height-auto-observer-wrapper">
                                <div className="simplebar-height-auto-observer" />
                            </div>
                            <div className="simplebar-mask">
                                <div
                                    className="simplebar-offset"
                                    style={{ right: "-17px", bottom: 0 }}
                                >
                                    <div
                                        className="simplebar-content-wrapper"
                                        style={{ height: "100%", overflow: "hidden scroll" }}
                                    >
                                        <div
                                            className="simplebar-content"
                                            style={{ padding: 30 }}
                                        >
                                            <div className="others-title col-12 row">
                                                <h3 className="col-6">Exam History



                                                    <input type="button" value="Back" title="Back" className="back-button" onClick={() => navigate(-1)} />

                                                </h3>

                                            </div>
                                            <table className="table align-middle mb-0">
                                                <thead>
                                                    <tr>
                                                        <th> Canditate</th>
                                                        <th style={{ textAlign: "left" }}> Date</th>
                                                        <th> Time Taken</th>
                                                        <th>Result</th>
                                                        <th></th> {/* Add an empty header column for the three-dot buttons */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {ReportList.map((rep) => (
                                                        <tr key={rep.candidateid}>
                                                            <td>
                                                                <div className="info">
                                                                    <h6>{rep.candidateName}</h6>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <p style={{ fontWeight: 'bold' }}>
                                                                    {new Date(rep.examEnd.split("T")[0]).toLocaleDateString('en-US', {
                                                                        day: '2-digit',
                                                                        month: 'short',
                                                                        year: 'numeric'
                                                                    }).replace(/,/g, '')}
                                                                </p>
                                                                <p style={{ fontSize: '11px' }}>
                                                                    {new Date(rep.examEnd).toLocaleTimeString('en-US', {
                                                                        hour: '2-digit',
                                                                        minute: '2-digit',
                                                                        hour12: true
                                                                    })}
                                                                </p> {/* Displaying the current time in AM/PM format By Mari */}
                                                            </td>
                                                            <td>
                                                                <span className="location">{rep.timeTaken} Minutes</span>
                                                            </td>
                                                            <td>
                                                                <p className="location" style={{ borderBottom: rep.result === "Fail" ? '7px solid red' : '7px solid green', width: `${rep.percentage}%`, }}>
                                                                    {rep.result}
                                                                </p>
                                                                <p>{Number(rep.percentage).toFixed(2)}%</p>
                                                            </td>
                                                            <td>
                                                                <div className="dropdown">
                                                                    <button className="dropdown-toggle" type="button" id={`dropdown-${rep.candidateid}`} data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                        <FaCog />
                                                                    </button>
                                                                    <div className="dropdown-menu" aria-labelledby={`dropdown-${rep.candidateid}`}>
                                                                        {/* Dropdown items */}
                                                                        <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => navigateToReview(rep.candidateID)}>ReviewExam</a>
                                                                        <a className="dropdown-item" href="#">Action 2</a>
                                                                        <a className="dropdown-item" href="#">Action 3</a>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="simplebar-placeholder"
                                style={{ width: "auto", height: 762 }}
                            />
                        </div>
                        <div
                            className="simplebar-track simplebar-horizontal"
                            style={{ visibility: "hidden" }}
                        >
                            <div
                                className="simplebar-scrollbar"
                                style={{
                                    transform: "translate3d(0px, 0px, 0px)",
                                    display: "none",
                                }}
                            />
                        </div>
                        <div
                            className="simplebar-track simplebar-vertical"
                            style={{ visibility: "visible" }}
                        >
                            <div
                                className="simplebar-scrollbar"
                                style={{
                                    height: 464,
                                    transform: "translate3d(0px, 0px, 0px)",
                                    display: "block",
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </main >

    );
};
export default ExamHistory;