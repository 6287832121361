import React, { useState, useEffect, useContext } from 'react';
import '../Exam/Exam.css';
import ExamService from '../Exam/ExamService';
import Swal from "sweetalert2";
import QuestionBankService from '../../services/QuestionBankService';
import { Tooltip } from '@mui/material';
import {
  Encrypt,
  Decrypt,
} from "../../services/CommonService";
import { AppContext } from "../../services/ContextProvider";
import TablePagination from '@mui/material/TablePagination';


export default function SpecifySelectQuestions({ setShowPopupModal, sectionID, setQuestionIDS, questionIDs, setspecifyselectsectionList, specifyselectsectionList }) {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1500,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  const { showLoading, hideLoading, setCookies, tempModulePrivileage, isAuth, SetisAuth } =
    useContext(AppContext);
  const [questionList, setquestionList] = useState([]);
  const _questionBankService = new QuestionBankService();
  const [total, setTotal] = useState('');
  const [selectCount, setSelectCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [questionIdSearch, setQuestionIdSearch] = useState('');
  const [difficultyLevelSearch, setDifficultyLevelSearch] = useState('Select');
  const [tagsSearch, setTagsSearch] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [FilteredQuestionList, setFilteredQuestionList] = useState([]);

  const onCloseModal = () => {

    setShowPopupModal(false);
    var a = document.getElementById("examCreateUpdate");
    a.style.opacity = "inherit";


  };
  const paginatedData = FilteredQuestionList?.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0); // Reset to the first page when rows per page changes
  };
  function removeHTMLTags(str) {
    const parser = new DOMParser();
    const parsedDocument = parser.parseFromString(str, 'text/html');
    return parsedDocument.body.innerText;
  }

  useEffect(() => {
    var a = document.getElementById("examCreateUpdate");
    a.style.opacity = "40%";
    let CompanyID = Decrypt(localStorage.getItem("CompanyID"));

    _questionBankService.GetQuestionbank(CompanyID).then((res) => {

      if (res != null && res.length > 0) {




        const questionIDSList = questionIDs?.length > 0 ? questionIDs.filter(item => item.isDeleted === 0) : [];

        const cleanedQuestionList = res?.filter(item => item.sectionId === sectionID).map(item => ({
          ...item,
          question: removeHTMLTags(item.question),
          isChecked: questionIDSList?.some(question => question.questionID === item.questionID) === true ? true : false,
        }));
        setquestionList(cleanedQuestionList);
        setFilteredQuestionList(cleanedQuestionList);

        specifyselectsectionList.map(item => {
          if (item.sectionId === sectionID) {
            setSelectCount(item.SelectCount);
            setTotal(item.noQuestion)
          }

        });
      }
    }).catch((exception) => {
      console.log(exception);
    });

    // document.querySelector(".loader-div").style.height = "100vh";
    // document.body.style.overflow = "hidden";
  }, [setShowPopupModal]);




  // const filteredQuestionList = filterQuestions();
  const QuestionsPick = (e, questionID) => {

    if (e.target.checked) {

      if (Number(total) <= selectCount) {

        var remaingmsg = Number(total) == 1 ? 'question' : 'questions';
        Toast.fire({
          icon: "warning",
          title: 'You can only choose a maximum of ' + total + ' ' + remaingmsg,
        });

      }
      else {
        if (questionIDs && questionIDs.questionID && questionIDs.questionID.includes(questionID)) {

          const updatedQuestionList = questionIDs.map(item => {
            if (item.questionID === questionID) {
              return {
                ...item,
                isDeleted: 0,

              };
            }
            return item;
          });

          setQuestionIDS(updatedQuestionList);

        }
        else {
          const newModel = { id: 0, questionID: questionID, isDeleted: 0, sectionId: sectionID }; // Create a new model object with desired values
          setQuestionIDS((prevList) => [...prevList, newModel]);

        }
        const updatedSpecifySectionList = specifyselectsectionList.map(item => {

          if (item.sectionId === sectionID) {

            setSelectCount(item.SelectCount + 1);
            return {
              ...item,
              SelectCount: item.SelectCount + 1
            };
          }
          return item;
        });

        setspecifyselectsectionList(updatedSpecifySectionList);
        const updatedQuestionIDS = questionList.map(item => {
          if (item.questionID === questionID) {
            return {
              ...item,
              isChecked: true,

            };
          }
          return item;
        });

        setquestionList(updatedQuestionIDS);
        setFilteredQuestionList(updatedQuestionIDS);

      }

    }
    else {

      const updatedQuestionList = questionIDs.map(item => {
        if (item.questionID === questionID) {
          return {
            ...item,
            isDeleted: 1
          };
        }
        return item;
      });

      setQuestionIDS(updatedQuestionList);
      const updatedSpecifySectionList = specifyselectsectionList.map(item => {

        if (item.sectionId === sectionID) {
          setSelectCount(item.SelectCount - 1);
          return {
            ...item,
            SelectCount: item.SelectCount - 1
          };
        }
        return item;
      });

      setspecifyselectsectionList(updatedSpecifySectionList);
      const updatedQuestionIDS = questionList.map(item => {
        if (item.questionID === questionID) {
          return {
            ...item,
            isChecked: false,

          };
        }
        return item;
      });

      setquestionList(updatedQuestionIDS);
      setFilteredQuestionList(updatedQuestionIDS);
    }
  };

  const handleSearch = () => {

    const filteredList = questionList?.filter((item) => {

      let lowerCaseQuery;
      if (searchQuery != "" || questionIdSearch != "" || difficultyLevelSearch !== "Select" || tagsSearch != "") {
        lowerCaseQuery = (searchQuery != "" ? searchQuery : "" || questionIdSearch != "" ? questionIdSearch : "" || difficultyLevelSearch !== "Select" ? difficultyLevelSearch : "" || tagsSearch != "" ? tagsSearch : "").toLowerCase();
      }

      const containsText = item.question.toLowerCase().includes(lowerCaseQuery);
      const containsID = item.questionID.toString().includes(lowerCaseQuery);
      const containsDifficultLevel = item.diffcultLevelValue.toString() === difficultyLevelSearch;
      const containstags = item.tags != null ? item.tags.toLowerCase().includes(lowerCaseQuery) : false;


      return containsText || containsID || containsDifficultLevel || containstags;
    });

    // Update the filtered question list

    console.log(filteredList);
    setFilteredQuestionList(filteredList);
  };


  const clearSearch = () => {
    setSearchQuery('');
    setQuestionIdSearch('');
    setDifficultyLevelSearch('Select');
    setTagsSearch('');
    setFilteredQuestionList(questionList);
  };


  return (
    <>
      <main className="main-content-wrap">
        <form className="row g-3" autoComplete="off">
          <div className="modal fade show" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-modal="true" role="dialog" style={{ display: 'flex', margin: 0 }}>
            <div className="modal-dialog modal-xl" >
              <div className="modal-content my-auto" style={{ width: "100%" }}>
                <div className="modal-header">
                  <h5 className="modal-title MuiTypography-h6" id="staticBackdropLabel">Pick Questions for this exam</h5>

                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    fdprocessedid="ttcp2q"
                    onClick={onCloseModal}
                  />
                </div>

                <div className="modal-body sectionbody">
                  <div className="row">
                    <div className="col-md-3">
                      <label className='form-label'>QID</label>
                      <input
                        type="number"
                        className="form-control"
                        id="qsequence"
                        value={questionIdSearch}
                        onChange={(e) => setQuestionIdSearch(e.target.value)}
                      />
                    </div>
                    <div className="col-md-3">
                      <label className='form-label'>Question Text</label>
                      <input
                        type="text"
                        className="form-control"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        id="qtext"
                      />
                    </div>
                    <div className="col-md-3">
                      <label className='form-label'>Difficulty Level</label>
                      <select
                        id="difficultylevelforpick"
                        className="form-control"
                        value={difficultyLevelSearch}
                        onChange={(e) => setDifficultyLevelSearch(e.target.value)}
                      >
                        <option value="Select">Select</option>
                        <option value="Easy">Easy</option>
                        <option value="Average">Average</option>
                        <option value="Hard">Hard</option>
                      </select>
                    </div>
                    <div className="col-md-3">
                      <label className='form-label'>Tags</label>
                      <input
                        type="text"
                        className="form-control"
                        id="qtext"
                        onChange={(e) => setTagsSearch(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-12 text-end">
                      <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        id="qsearch"
                        onClick={handleSearch}
                      >
                        <span className="fa fa-search" /> Search
                      </button>


                      <button
                        type="button"
                        className="btn btn-sm btn-primary ms-2"
                        id="qsearch"
                        onClick={clearSearch}
                      >
                        <span className="fa fa-reset" /> Reset
                      </button>
                    </div>
                  </div>

                  <table className="table align-middle mb-0 mt-3">
                    <thead>
                      <tr>
                        <th>
                          S.no
                        </th>

                        <th>
                          QuestionID
                        </th>
                        <th>
                          Question
                        </th>
                        <th>
                          Difficulty
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {FilteredQuestionList != null &&
                        paginatedData?.length > 0 ? (
                        paginatedData?.map((v, i) => {

                          return (
                            <tr key={i} id={i}>
                              <td>{i + 1}</td>

                              <td>
                                <input
                                  type="checkbox"

                                  checked={v.isChecked}
                                  onChange={(e) => QuestionsPick(e, v.questionID)}
                                />
                                <span className="q_icon_box dlebeleasy ms-2">
                                  <span className="datatooptip">
                                    <span className="fa fa-dot-circle-o">
                                    </span>

                                    {/* <div className="divtooptip">Multiple Choice (Radiobutton)</div> */}
                                  </span>
                                  <span className="datatooptip">{v.questionID}
                                    <Tooltip
                                      title={v.diffcultLevelValue}
                                    />
                                    {/* <div className="divtooptip">{v.diffcultLevelValue}</div> */}
                                  </span>
                                </span>
                              </td>
                              <td
                                style={{
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  maxWidth: '12ch',
                                  textDecoration: 'none',
                                }}
                              >
                                <Tooltip title={v.question}>{v.question}</Tooltip>
                              </td>
                              <td>{v.diffcultLevelValue}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <p>No records Found....</p>
                      )}
                    </tbody>
                  </table>
                </div>


                {/* <div className="modal-footer mailtemplatefooter">
                
                <label >Total {selectCount}/{total}</label>
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={onCloseModal} fdprocessedid="t27w74">Close</button>
            

              </div> */}
                <div className="modal-footer mailtemplatefooter" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div class="pull-left" style={{ fontSize: '12px', color: "#999" }}  ><span class="queselectcount">{selectCount}/{total}</span> Question(s) Selected</div>
                  {/* <button type='button' class="btn btn-secondary">Total </button> */}
                  <button type="button" className="btn btn-danger col-md-2" data-bs-dismiss="modal" onClick={onCloseModal} fdprocessedid="t27w74" style={{ width: "10%" }}>Close</button>

                </div>

                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  count={FilteredQuestionList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count}`}

                />
              </div>
            </div>
          </div>

        </form >
      </main>

    </>
  );
}