import React from 'react';
import { FaExclamationTriangle, FaTimesCircle } from 'react-icons/fa';

export default function InvalidLink() {
    return (
        <>
            <div class="error-area">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="error-content card-box-style">
                                <h1><FaExclamationTriangle color="red" /></h1>

                                <h4>Exam Link Error.</h4> <p>Please reach Admin for further details.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
}
