import React from "react";
import { useState, useEffect, useContext } from "react";
import RejoinTest from "../Canditate/RejoinTest";
import { useLocation } from "react-router-dom";
import { FaExclamationTriangle, FaTimesCircle } from 'react-icons/fa';
import { AppContext } from "../../services/ContextProvider";


function CandidateError() {
    const [show, setShow] = useState(false);
    const location = useLocation();
    const [examID, setexamID] = useState(location.state?.examID ?? 0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [error, seterror] = useState(location.state?.error ?? null);
    const { showLoading, hideLoading, setCookies, tempModulePrivileage, isAuth, SetisAuth, getCookies } =
        useContext(AppContext);
    console.log(error);
    const [loading, setLoading] = useState(true);
    hideLoading();

    // useEffect(() => {
    //     // Simulate a delay for demonstration purposes (replace with actual loading logic)
    //     const timer = setTimeout(() => {
    //         debugger; 
    //         hideLoading();
    //         setLoading(false);
    //     }, 3000); // Adjust the delay as needed

    //     // Clear the timer on unmount to avoid memory leaks
    //     return () => clearTimeout(timer);
    // }, []);
    const onClick = (event) => {
        event.preventDefault();
        setIsModalOpen(true);
    }
    return (
        <>
            <div class="error-area">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="error-content card-box-style">
                                <h1><FaExclamationTriangle color="yellow" /></h1>

                                {error != null && error === 'InvalidURL' ? <><h4>Exam Link Error.</h4> <p>Use rejoin code to enable the exam.</p></> : ''}
                                {error != null && error === 'StartExam' ? <><h4>Greetings!!! </h4> <p>Your Exam Portal will be activated according to the schedule. </p><p>Please click the link once the scheduled start time commences.</p></> : ""}
                                {error != null && error === 'EndExam' ? <><h4>Exam Link has been Expired.</h4> <p>Kindly contact Admin.</p></> : ""}

                                {/* <h3>Oops! Page Not Found</h3>
                              
                              <p>The page you were looking for could not be found.</p> */}
                                {/* {error === "InvalidURL" ?
                                    <div className="text-center">
                                        <button className="btn btn-primary" onClick={(event) => onClick(event)}>
                                            Rejoin
                                        </button>
                                    </div> : <></>
                                } */}
                            </div>


                            {/* {isModalOpen && (
                                <RejoinTest
                                    setIsModalOpen={setIsModalOpen}
                                    ExamID={examID}
                                    isModalOpen={isModalOpen}
                                />
                            )
                            } */}
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
}
export default CandidateError;