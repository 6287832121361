import React, { useEffect, useState } from 'react';
import CandidateQuestionView from '../../services/CandidateQuestionView'
const ExamLiveMalpractice = ({ examID, candidateID, handleSubmit, examAttendID }) => {
  const _CandidateQuestionView = new CandidateQuestionView();
  var allowMalPracticeCount = null;
  const [malPracticeCount, setMalPracticeCount] = useState(0)
  useEffect(() => {
    const timer = setInterval(() => {
      _CandidateQuestionView.GetUnusualBehaviourCountBYExam(examID, candidateID, examAttendID)
        .then((response) => {

          if (response != null) {

            response[0].UnusualBehaviourCount === null ? response[0].UnusualBehaviourCount = 0 : response[0].UnusualBehaviourCount = response[0].UnusualBehaviourCount;
            if (response[0].Count === response[0].UnusualBehaviourCount) {
              handleSubmit();

            }
          }

        })

        .catch((exception) => {
          console.log(exception);
        });
    }, 1000);

    // Clean up function
    return () => {
      clearInterval(timer); // Clear the interval when the component unmounts
    };



  }, [])



  return (
    <div>

    </div>
  );
};

export default ExamLiveMalpractice;
