// export const BASE_URL = "https://localhost:7028/api/";
// var value = "";
// export const IMAGE_URL = `https://localhost:7028/${value}`;

//QA
//export const BASE_URL = "http://vtspeedtestqaapi.vaanamtechdemo.com/api/";
// var value = "";
// export const IMAGE_URL = `http://vtspeedtestqaapi.vaanamtechdemo.com/${value}`;
//UAT
//export const BASE_URL = "http://vtspeedtestuatapi.vaanamtechdemo.com/api/";
// var value = "";
// export const IMAGE_URL = `http://vtspeedtestuatapi.vaanamtechdemo.com/${value}`;

//Prod
export const BASE_URL = "https://max-eapi.azurewebsites.net/api/";
var value = "";
export const IMAGE_URL = `https://max-eapi.azurewebsites.net/${value}`;