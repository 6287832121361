import React, { useState, useContext, useEffect } from 'react';
import QuestionBankService from '../../services/QuestionBankService';
import { showSaveSuccess } from '../ToastMsg';
import { Decrypt } from "../../services/CommonService";
import Swal from "sweetalert2";
import CanditateServices from "../../services/CanditateService";
import { AppContext } from "../../services/ContextProvider";

var Canditateobj = new CanditateServices();
const BulkUploadCandidate = ({ setIsPopupOpen, setrefresh, candidateList, LinkcandidateList }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [isError, setIsError] = useState(false);


    useEffect(() => {
        const elementsWithStaticId = document.querySelectorAll("[id='static']");

        elementsWithStaticId.forEach((element) => {
            element.style.zIndex = "0"; // Set the z-index to 0 for each matching element
        });
    }, []);

    const handleCloseButtonClick = () => {
        setIsPopupOpen(false);

        const elementsWithStaticId = document.querySelectorAll("[id='static']");

        elementsWithStaticId.forEach((element) => {
            element.style.zIndex = "2"; // Set the z-index to 0 for each matching element
        });


    };
    const { showLoading, hideLoading, setCookies, tempModulePrivileage, isAuth, SetisAuth } =
        useContext(AppContext);

    const onFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsError(false);
    };


    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 750,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });
    const onSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append("CompanyID", Decrypt(localStorage.getItem("CompanyID")));
        if (!selectedFile) {
            setIsError(true);
            return;
        }
        try {
            showLoading();
            const res = await Canditateobj.CandidateUploadFile(formData);
            if (res.data > 0) {
                handleCloseButtonClick();
                hideLoading();
                Toast.fire({
                    icon: "success",
                    title: "Candidate Created Successfully",
                });

                LinkcandidateList();
            } else {
                Swal.fire({
                    icon: "warning",
                    title: "Please provide CandidateList with a formatted template.",
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        setIsPopupOpen && (
            <div className="modal fade show" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-modal="true" role="dialog" style={{ display: 'flex', alignItems: 'center' }}>
                <div className="modal-dialog" style={{ margin: 'auto' }}>
                    <div className="modal-content" style={{ width: '400px' }}>
                        <div className="modal-header">
                            <h5 className="modal-title " style={{ fontWeight: 'bold', color: '#2279e7' }}>Candidate Bulk Upload</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" fdprocessedid="ttcp2q"
                                onClick={handleCloseButtonClick}
                            />
                        </div>
                        <div className='modal-body'>
                            <form onSubmit={onSubmit}>
                                <input
                                    type="file"
                                    className={`form-control mb-3 ${isError ? 'error' : ''}`}
                                    id='uploadfile'
                                    onChange={onFileChange}
                                    accept=".xlsx, .xls/*"
                                />
                                <div className='text-center'>
                                    <button type="submit" className="btn btn-primary">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};

export default BulkUploadCandidate;
