import React, { useEffect, useState } from "react";
import loginlogo from '../../assets/images/max-e-light.png';
import ExamService from "../Exam/ExamService";
import "../../assets/js/custom.js";
import CandidateQuestionViewServices from "../../services/CandidateQuestionViewService";
import '../../assets/css/customizeHeader.css';


const Header = ({ ExamId, candidateName, handleSubmit, candidateID, SubmitBefortime, examAttendID }) => {

    const examservice = new ExamService();
    const [remainingTime, setRemainingTime] = useState(10);
    const [rejoinCount, setRejoinCount] = useState(false);

    var candidateQuestionServ = new CandidateQuestionViewServices();

    useEffect(() => {
        candidateQuestionServ.Candidate_Exam_RejoinCountCheck(ExamId, candidateID, examAttendID).then((res) => {
            if (res != null) {
                var value1 = res.data[0].RetakeCount;
                var value2 = res.data[0].RejoinCount;
                const areEqual = value1 === value2;
                setRejoinCount(areEqual);
            }
        }).catch((err) => {
            console.log(err);
        });
    });

    useEffect(() => {
        const fetchExamData = async () => {
            try {
                const res = await candidateQuestionServ.Candidate_Exam_List(ExamId);
                if (res != null) {

                    const exam = res.data[0]?.Duration;
                    if (exam) {
                        const storedTime = localStorage.getItem('remainingTime');
                        if (storedTime !== null) {
                            setRemainingTime(parseInt(storedTime));

                            candidateQuestionServ.UpdateRemainingTime(ExamId, candidateID, parseInt(storedTime), examAttendID).then((res) => {

                            }).catch((err) => {
                                console.log(err);
                            });
                        } else {
                            setRemainingTime(exam);
                            localStorage.setItem('remainingTime', exam.toString());
                            candidateQuestionServ.UpdateRemainingTime(ExamId, candidateID, parseInt(exam), examAttendID).then((res) => {

                            }).catch((err) => {
                                console.log(err);
                            });
                        }

                        // Set up the timer after setting the initial remainingTime
                        const timer = setInterval(() => {
                            setRemainingTime((prevTime) => {
                                const newTime = prevTime - 1000;
                                const updatedTime = newTime >= 0 ? newTime : 0;
                                localStorage.setItem('remainingTime', updatedTime.toString());
                                candidateQuestionServ.UpdateRemainingTime(ExamId, candidateID, parseInt(updatedTime), examAttendID).then((res) => {

                                }).catch((err) => {
                                    console.log(err);
                                });
                                return updatedTime;
                            });

                        }, 1000);

                        return () => {
                            clearInterval(timer);
                        };
                    }
                }
            } catch (err) {
                console.log(err);
            }
        };

        fetchExamData();
    }, [ExamId]);


    const getRemainingHours = (milliseconds) => {
        const hours = Math.floor(milliseconds / 3600000);
        return `${hours}`;
    };
    const getRemainingMinutes = (milliseconds) => {
        const minutes = Math.floor((milliseconds % 3600000) / 60000);
        return `${minutes.toString().padStart(2, '0')}`;
    };
    const getRemainingSeconds = (milliseconds) => {
        const seconds = Math.floor((milliseconds % 60000) / 1000);
        return `${seconds.toString().padStart(2, '0')}`;
    };


    return (
        <div className="container-fluid">
            <div className="row">
                <div className="header-area header-style-three examView">
                    <div className="header-content-wrapper">
                        <div className="header-content d-flex justify-content-between">
                            <div className="header-left-content d-flex align-items-center">
                                <div className="main-logo">
                                    <a href="#">
                                        <img src={loginlogo} alt="main-logo" style={{ height: '55px' }} />
                                    </a>
                                </div>
                                <div className="header-right-option dropdown profile-nav-item pt-0 pb-0">
                                    <div className="d-none d-lg-block d-md-block">
                                        <h4 style={{ color: '#ffffff' }}>{candidateName}</h4>
                                        <span style={{ color: '#ffffff' }}>Vaanam Technologies - Online Test {new Date().getFullYear()}</span>
                                    </div>
                                </div>
                                <div className="option-item for-mobile-devices d-block d-lg-none">
                                    <i className="search-btn ri-search-line" />
                                    <i className="close-btn ri-close-line" />
                                    <div className="search-overlay search-popup">
                                        <div className="search-box">
                                            <form className="search-form">
                                                <input className="search-input" name="search" placeholder="Search" type="text" />
                                                <button className="search-button" type="submit">
                                                    <i className="ri-search-line" />
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="header-right-content d-flex align-items-center">
                                <div className="header-right-option template-option">
                                    <div className="time-counter background-color">
                                        <div id="timer" className="flex-wrap d-flex justify-content-center">
                                            {remainingTime > 0 ? (
                                                <>
                                                    <div id="hrs" className="align-items-center flex-column d-flex justify-content-center align-items-center">
                                                        {getRemainingHours(remainingTime)}<span>hours</span>
                                                    </div>

                                                    <div id="minutes" className="align-items-center flex-column d-flex justify-content-center align-items-center">
                                                        {getRemainingMinutes(remainingTime)}<span>minutes</span>
                                                    </div>
                                                    <div
                                                        id="seconds"
                                                        className={`align-items-center flex-column d-flex justify-content-center align-items-center ${getRemainingHours(remainingTime) === 0 && getRemainingSeconds(remainingTime) <= 10 ? 'text-danger' : ''
                                                            }`}
                                                    >
                                                        {getRemainingSeconds(remainingTime)}<span>seconds</span>
                                                    </div>
                                                </>

                                            ) : (
                                                // handleSubmit()
                                                SubmitBefortime === true || rejoinCount === true ? handleSubmit() : null
                                            )}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
