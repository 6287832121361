import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import { AppContext } from "../../services/ContextProvider";
import { ToastMsg } from "../ToastMsg";
function WithNav() {
    const { loadingStatus } = useContext(AppContext);
    var loadingStyle = !loadingStatus ? { display: "none" } : {};
    return (
        <>
            {/* Loader */}
            <div className="body-bg">
                <div class="loader-Wrapper">
                    <div className="loader-div" style={loadingStyle}>
                        <div className="preloader-style-seventeen">
                            <div className="pl-flip-1 pl-flip-2">

                            </div>
                        </div>
                    </div>
                </div>
                <div className="all-section-area">
                    <Navbar />

                    <ToastMsg />
                    <Outlet />
                </div>
            </div>
        </>
    );
}
export default WithNav;