import '../Employee/Monitor.css';
import { FaCheckCircle, FaLaptop, FaArrowRight } from "react-icons/fa";
import { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useNavigate } from "react-router-dom";
import DashBoardService from "./DashBoardService";
import ExamService from '../Exam/ExamService';
import CandidateQuestionViewServices from "../../services/CandidateQuestionViewService";
import CanditateServices from "../../services/CanditateService";
import CandidateQuestionView from '../Candidate_QuestionView/CandidateQuestionView';
import Swal from 'sweetalert2';

var dash = new DashBoardService();
var candidateQuestionServ = new CandidateQuestionViewServices();
var examServicesobj = new ExamService();
var candidateServ = new CanditateServices();

function DashBoard() {
    const navigate = new useNavigate();
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [negativeMarkScore, setnegativeMarkScore] = useState('');
    const [exmaID, setExamID] = useState();
    const [candidateID, setCandidateID] = useState();

    const navigatetoExams = () => {
        navigate("/Exams");
    }
    const navigatetoQuestions = () => {
        navigate("/ViewQuestion");
    }
    const navigatetocandidate = () => {
        navigate("/AdminCanditate");
    }
    const _examservice = new ExamService();
    const [activeButton, setActiveButton] = useState('7days');
    const [monitorButton, setmonitorButton] = useState('24hrs');
    const [questionList, setquestionList] = useState([]);
    const [sectionList, setsectionList] = useState([]);
    const [candidateList, setcandidateList] = useState([]);
    const [examList, setExamList] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [labels, setLabels] = useState([]);
    const [examMonitor, setexamMonitor] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredExamMonitors, setFilteredExamMonitors] = useState(examMonitor);

    const examMonitorClick = async (timeperiod) => {
        setmonitorButton(timeperiod);
        try {
            const res = await dash.DashBoardExamMonitor(timeperiod);
            debugger;
            setFilteredExamMonitors(res.data);
            if (res?.data != null) {
                console.log(res.data)
                setexamMonitor(res.data);
            }
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        dash.DashBoardCount().then((res) => {
            if (res != null) {

                const { QuestionList, CandidateList, SectionList, ExamList } = res.data;
                if (QuestionList?.length > 0) {
                    setquestionList(QuestionList[0]);
                }
                if (SectionList?.length > 0) {
                    setsectionList(SectionList[0]);
                }
                if (CandidateList?.length > 0) {
                    setcandidateList(CandidateList[0]);
                }
                if (ExamList?.length > 0) {
                    setExamList(ExamList[0]);
                }
            }
        }).catch((err) => {
            console.log(err);
        });


    }, []);
    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (Array.isArray(examMonitor)) {

            const filtered = examMonitor && examMonitor.filter((e) =>
                e.CandidateName && e.CandidateName.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setFilteredExamMonitors(filtered);
            setCandidateID(filtered[0]?.CandidateID);
            setExamID(filtered[0]?.ExamID);
        }
    }, [searchQuery, examMonitor]);


    useEffect(() => {
        handleButtonClick(activeButton);
    }, [activeButton]);

    useEffect(() => {
        examMonitorClick(monitorButton);
    }, [monitorButton]);

    useEffect(() => {

        candidateQuestionServ.NegativeMarks_Score(exmaID).then((x) => {
            if (x != null) {
                const NegativeMarkID = x?.data[0]?.NegativeMarkID;
                examServicesobj.GetExamParameterList().then((res) => {
                    if (res != null) {
                        const candidateGroup = res.parameterList.filter(
                            (option) => option.parameterValueID == NegativeMarkID
                        );
                        setnegativeMarkScore(candidateGroup[0].value);

                    }
                });
            }
        }).catch((err) => {
            console.log(err);
        });
    }, []);

    const handleButtonClick = async (timePeriod) => {

        setActiveButton(timePeriod);

        try {
            const res = await dash.DashBoardApex(timePeriod);
            if (res?.data) {

                const examCounts = res.data;
                const data = [];
                let labels = [];

                if (timePeriod === '24hrs') {
                    const timeData = [
                        { Time: "00:00 AM", Count: 0 },
                        { Time: "01:00 AM", Count: 0 },
                        { Time: "02:00 AM", Count: 0 },
                        { Time: "03:00 AM", Count: 0 },
                        { Time: "04:00 AM", Count: 0 },
                        { Time: "05:00 AM", Count: 0 },
                        { Time: "06:00 AM", Count: 0 },
                        { Time: "07:00 AM", Count: 0 },
                        { Time: "08:00 AM", Count: 0 },
                        { Time: "09:00 AM", Count: 0 },
                        { Time: "10:00 AM", Count: 0 },
                        { Time: "11:00 AM", Count: 0 },
                        { Time: "12:00 PM", Count: 0 },
                        { Time: "13:00 PM", Count: 0 },
                        { Time: "14:00 PM", Count: 0 },
                        { Time: "15:00 PM", Count: 0 },
                        { Time: "16:00 PM", Count: 0 },
                        { Time: "17:00 PM", Count: 0 },
                        { Time: "18:00 PM", Count: 0 },
                        { Time: "19:00 PM", Count: 0 },
                        { Time: "20:00 PM", Count: 0 },
                        { Time: "21:00 PM", Count: 0 },
                        { Time: "22:00 PM", Count: 0 },
                        { Time: "23:00 PM", Count: 0 }
                    ];
                    timeData.forEach((timeObj) => {
                        const matchingData = examCounts.find((item) => {
                            const dataTime = item.Time.split(":"); // Split the data time string into hours and minutes
                            const dataHour = parseInt(dataTime[0]);
                            const dataMinute = parseInt(dataTime[1]);
                            const timeTime = timeObj.Time.split(":"); // Split the timeData time string into hours and minutes
                            const timeHour = parseInt(timeTime[0]);
                            const timeMinute = parseInt(timeTime[1]);
                            return dataHour === timeHour && dataMinute === timeMinute;
                        });

                        const count = matchingData ? matchingData.Count : 0;
                        labels.push(timeObj.Time);
                        data.push(count);
                    });
                }
                else if (timePeriod === '7days') {
                    labels = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                    labels.forEach((label) => {
                        const countObj = examCounts.find((item) => item.Day === label);
                        const count = countObj ? countObj.Count : 0;
                        data.push(count);
                    });
                } else if (timePeriod === '30days') {
                    const currentDate = new Date();
                    const currentMonth = currentDate.getMonth() + 1;

                    const weekCount = (year, month_number) => {
                        const firstOfMonth = new Date(year, month_number - 1, 1);
                        const lastOfMonth = new Date(year, month_number, 0);
                        const used = firstOfMonth.getDay() + lastOfMonth.getDate();
                        return Math.ceil(used / 7);
                    };

                    const currentYear = currentDate.getFullYear();
                    const numWeeks = weekCount(currentYear, currentMonth);

                    for (let i = 1; i <= numWeeks; i++) {
                        labels.push(`Week ${i}`);
                        const countObj = examCounts.find((item) => item.Week === i && item.Month === currentMonth);
                        const count = countObj ? countObj.examCount : 0;
                        data.push(count);
                    }
                }

                setLabels(labels);
                setChartData(data);
                setState((prevState) => ({
                    ...prevState,
                    options: {
                        ...prevState.options,
                        xaxis: {
                            ...prevState.options.xaxis,
                            categories: labels,
                            offsetX: timePeriod === '24hrs' ? 0 : 10,
                        },
                    },
                    series: [
                        {
                            name: "Exam Taken",
                            data: data,
                        },
                    ],
                }));
            }
        } catch (err) {
            console.log(err);
        }
    };

    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });

    const [state, setState] = useState({
        series: [{
            name: "Exam",
            data: chartData
        }],
        options: {
            chart: {
                height: 350,
                type: 'area',
                zoom: {
                    enabled: false
                }
            },

            yaxis: {
                show: false,
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            title: {
                text: 'Exam Taken',
                align: 'left'
            },
            xaxis: {
                categories: labels,
                labels: {
                    show: true,
                },

            }
        },
    });
    const ExamLive = (ExamID, CandidateID, CandidateName, status, examAttendID) => {
        if (status === "Ongoing") {
            navigate("/ExamLive", {
                state: {
                    examID: ExamID,
                    candidateID: CandidateID,
                    canditateName: CandidateName,
                    ExamAttendID: examAttendID
                },
            });
        }
        else {

            navigate("/CandidatePreview", {
                state: {
                    examID: ExamID,
                    CandidateId: CandidateID,
                    canditateName: CandidateName,
                    ExamAttendID: examAttendID
                },
            });
        }

    }

    const navigateToReview = (cId, examID, eId) => {
        debugger;
        navigate("/ReviewExam", {
            state: { cId: cId, examID: examID, ExamAttendID: eId },
        });
    }

    const handleSuspend = async (Flag, examID, candidateID, ExamAttendID) => {

        await dash.Candidate_Suspend(Flag, examID, candidateID, ExamAttendID).then((res) => {
            if (res.data == 1) {
                Toast.fire({
                    icon: "success",
                    title: "Suspend Successfully",
                });
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const reTake = async (id) => {

        await dash.ReTake_Add(id).then((res) => {
            if (res.data == 1) {
                Toast.fire({
                    icon: "success",
                    title: "ReTake Given Successfully",
                });
            }
        }).catch((err) => {
            console.log(err);
        })
    }


    return (
        <>
            <main class="main-content-wrap ">
                <div className="overview-area">
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className={screenWidth <= 768 ? "col-lg-12" : "col-lg-7"}>
                                <div className="overview-content-wrap card-box-style shadow bg-white rounded">
                                    <div className="overview-content d-flex justify-content-between align-items-center" style={{ margin: '0px' }}>
                                        <div className="overview-title">

                                        </div>
                                        <ul className="total-overview justify-content-end">
                                            <li>
                                                <button
                                                    className={`today ${activeButton === '24hrs' ? 'active' : ''}`}
                                                    onClick={() => {
                                                        handleButtonClick('24hrs');
                                                    }}
                                                >
                                                    24 Hrs
                                                </button>
                                            </li>
                                            <li>
                                                <button
                                                    className={`today ${activeButton === '7days' ? 'active' : ''}`}
                                                    onClick={() => {
                                                        handleButtonClick('7days');
                                                    }}
                                                >
                                                    7 Days
                                                </button>
                                            </li>
                                            <li>
                                                <button
                                                    className={`today ${activeButton === '30days' ? 'active' : ''}`}
                                                    onClick={() => {
                                                        handleButtonClick('30days');
                                                    }}
                                                >
                                                    30 Days
                                                </button>
                                            </li>
                                        </ul>

                                    </div>
                                    <div className="audience-content-wrap">
                                        <div className="row justify-content-center">

                                        </div>
                                        <div className="audience-chart">
                                            <ReactApexChart options={state.options} series={state.series} type="area" height={210} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={screenWidth <= 768 ? "col-lg-12" : "col-lg-5"}>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6" style={{ width: `${screenWidth <= 768 ? 50 : <></>}%`, cursor: "pointer", important: "true" }} onClick={navigatetoExams} >
                                        {/* <div className="traffic-content card-box-style shadow p-3 mb-5 bg-white rounded" style={{ height: '120px', width: '150px' }}>
                                            <div className='row'>
                                                <h4 className='col-md-6'>{examList.ExamList}

                                                </h4>
                                                <FaArrowRight className="arrow-icon col-md-6" />

                                            </div>


                                            <p>Exams</p>
                                        </div> */}
                                        {/* <div className="card single-widgets text-center border-0">
                                            <div className="card-body p-0">
                                                <i className="bx bx-world text-primary" />
                                                <span className="d-block">Exams</span>
                                                <h4 className="card-title">{examList.ExamList}</h4>
                                            </div>
                                        </div> */}

                                        <div className="card single-widgets-two border-0">
                                            <div className="card-body p-0">
                                                <div className="d-flex align-items-center">
                                                    <div className="text-primary">
                                                        <h4 className="mb-0 text-primary">{examList.ExamList}</h4>
                                                        <p className="text-dark mb-0">Exams</p>
                                                    </div>
                                                    <div className="ms-auto">
                                                        <i class='bx bx-file text-primary'></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div className="col-lg-6 col-md-6" style={{ width: `${screenWidth <= 768 ? 50 : <></>}%`, cursor: "pointer", important: "true" }} onClick={navigatetocandidate}>
                                        {/* <div className="traffic-content card-box-style shadow p-3 mb-5 bg-white rounded" style={{ height: '120px', width: '150px' }}>
                                            <div className='row'>
                                                <h4 className='col-md-6'>{candidateList.CandidateList}</h4>

                                                <FaArrowRight className="arrow-icon col-md-6" />

                                            </div>


                                            <p>Candidates</p>
                                        </div> */}
                                        <div className="card single-widgets-two border-0">
                                            <div className="card-body p-0">
                                                <div className="d-flex align-items-center">
                                                    <div className="text-primary">
                                                        <h4 className="mb-0 text-primary">{candidateList.CandidateList}</h4>
                                                        <p className="text-dark mb-0">Candidates</p>
                                                    </div>
                                                    <div className="ms-auto">
                                                        <i className="bx bx-user text-primary" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6" style={{ width: `${screenWidth <= 768 ? 50 : <></>}%`, important: "true" }}>
                                        {/* <div className="traffic-content card-box-style shadow p-3 mb-5 bg-white rounded" style={{ height: '120px', width: '150px' }}>


                                            <h4>{sectionList.SectionList}</h4>
                                            <p>Sections</p>
                                        </div> */}
                                        <div className="card single-widgets-two border-0">
                                            <div className="card-body p-0">
                                                <div className="d-flex align-items-center">
                                                    <div className="text-primary">
                                                        <h4 className="mb-0 text-primary">{sectionList.SectionList}</h4>
                                                        <p className="text-dark mb-0">Sections</p>
                                                    </div>
                                                    <div className="ms-auto">
                                                        <i class='bx bx-food-menu text-primary' ></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6" style={{ width: `${screenWidth <= 768 ? 50 : <></>}%`, cursor: "pointer", important: "true" }} onClick={navigatetoQuestions}>
                                        {/* <div className="traffic-content card-box-style shadow p-3 mb-5 bg-white rounded" style={{ height: '120px', width: '150px' }}>

                                            <div className='row'>
                                                <h4 className='col-md-6'>{questionList.QuestionList}</h4>

                                                <FaArrowRight className="arrow-icon col-md-6" />

                                            </div>


                                            <p>Questions</p>
                                        </div> */}

                                        <div className="card single-widgets-two border-0">
                                            <div className="card-body p-0">
                                                <div className="d-flex align-items-center">
                                                    <div className="text-primary">
                                                        <h4 className="mb-0 text-primary">{questionList.QuestionList}</h4>
                                                        <p className="text-dark mb-0">Questions</p>
                                                    </div>
                                                    <div className="ms-auto">
                                                        <i class='bx bx-question-mark text-primary'></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {screenWidth <= 768 ? (
                                <div className='col-md-12'>
                                    <div className=" card-box-style  shadow p-2 mb-5 bg-white rounded" style={{ padding: '0px' }}>

                                        <div className="row">
                                            <div className="col-lg-9 col-md-9 col-sm-9">

                                                <div className="overview-title">
                                                    <h3>Exam Monitor </h3>
                                                </div>
                                            </div>
                                            <div className='col-md-3 col-lg-3 col-sm-3 '>
                                                <input
                                                    type="text"
                                                    value={searchQuery}
                                                    onChange={(e) => setSearchQuery(e.target.value)}
                                                    placeholder="Search..."
                                                    className='form-control'
                                                />


                                            </div>

                                        </div>

                                        <section className="body mt-4">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="container py-3">
                                                        <div className="row ">
                                                            <div className="col-md-12" style={{ display: "flex" }}>
                                                                <button
                                                                    className={`${monitorButton === '24hrs' ? 'btn btn-warning' : 'btn btn-secondary'
                                                                        } mx-1 `}
                                                                    onClick={() => examMonitorClick('24hrs')}
                                                                >
                                                                    Today
                                                                </button>
                                                                <button
                                                                    className={`${monitorButton === '7days' ? 'btn btn-warning' : 'btn btn-secondary'
                                                                        }  mx-1 `}
                                                                    onClick={() => examMonitorClick('7days')}
                                                                >
                                                                    Last 7 days
                                                                </button>
                                                                <button
                                                                    className={`${monitorButton === '30days' ? 'btn btn-warning' : 'btn btn-secondary'
                                                                        }  mx-1 `}
                                                                    onClick={() => examMonitorClick('30days')}
                                                                >
                                                                    Last 30 days
                                                                </button>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <table class="table table-borderless mt-3 p-3 mb-5 bg-white rounded">
                                                                    <thead >
                                                                        <tr style={{ borderBottom: '1px solid #ddd' }}>
                                                                            <th>
                                                                                <p>{filteredExamMonitors.length > 0 ? "Recent Activity" : "No Activity"}</p>
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            {filteredExamMonitors.length > 0 ? filteredExamMonitors?.map((e, index) => (
                                                                                <td key={index} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                    <li>
                                                                                        <FaCheckCircle color="green" />
                                                                                        <span className="px-2" style={{ fontSize: '16px' }}>{e.CandidateName}</span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span>


                                                                                            {e.Time_Taken === null || e.Time_Taken === '' ? "" : e.Time_Taken >= 60
                                                                                                ? _examservice.convertSecondsToMinutes(e.Time_Taken) + ' Minutes'
                                                                                                : e.Time_Taken + ' Seconds'}
                                                                                        </span>

                                                                                    </li>
                                                                                </td>
                                                                            )) : <></>}
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="container">
                                                        <ul className="timeline examline">
                                                            {examMonitor && Array.isArray(examMonitor) && (
                                                                filteredExamMonitors.map((e, index) => (
                                                                    <li key={index}>
                                                                        <div className="timeline-time" style={{ width: "26%", important: "true" }}>
                                                                            <span className="small text-muted">{e.ExamStarted.split("T")[0]}</span>
                                                                        </div>
                                                                        <div className="timeline-icon" style={{ left: "25%", important: 'true' }} onClick={() => ExamLive(e.ExamID, e.CandidateID, e.CandidateName, e.ExamStatus, e.ExamAttendID)}>
                                                                            <a href="javascript:;"
                                                                                style={{
                                                                                    background: e.ExamStatus === "Completed" ? "#4fcb8d" : e.ExamStatus ===
                                                                                        "Terminated" || e.ExamStatus === "CountOver" || e.ExamStatus == "Suspend" ? "#ff0000" :
                                                                                        e.ExamStatus ===
                                                                                            "Ongoing" ? "#ffff00" :
                                                                                            <></>,
                                                                                    border:
                                                                                        e.ExamStatus === "Completed" ? "5px solid #4fcb8d" : e.ExamStatus ===
                                                                                            "Terminated" || e.ExamStatus === "CountOver" || e.ExamStatus == "Suspend" ? "5px solid #ff0000" :
                                                                                            e.ExamStatus ===
                                                                                                "Ongoing" ? "5px solid #ffff00" :
                                                                                                <></>
                                                                                }}
                                                                            >&nbsp;</a>
                                                                        </div>
                                                                        <div className={`timeline-body ${e.ExamStatus === "Completed" ? " examcompleted" : e.ExamStatus ===
                                                                            "Terminated" || e.ExamStatus === "CountOver" || e.ExamStatus == "Suspend" ? " examterminated" :
                                                                            e.ExamStatus ===
                                                                                "Ongoing" ? " examongoing" :
                                                                                <></>}`}

                                                                            style={{ marginLeft: "35%", marginRight: "0%", important: "true" }}
                                                                        >
                                                                            <div className="card shadow  bg-white rounded" style={{
                                                                                borderLeft:
                                                                                    e.ExamStatus === "Completed" ? "4px solid #4fcb8d" : e.ExamStatus ===
                                                                                        "Terminated" || e.ExamStatus === "CountOver" || e.ExamStatus == "Suspend" ? "4px solid #ff0000" :
                                                                                        e.ExamStatus ===
                                                                                            "Ongoing" ? "4px solid #ffff00" :
                                                                                            <></>
                                                                                , '--bs-card-spacer-y': '0px'
                                                                            }}>
                                                                                {/* <div className="card-body" style={{ padding: '5px' }} onClick={() => ExamLive(e.ExamID, e.CandidateID, e.CandidateName, e.ExamStatus)}>
                                                                                <h6>{e.CandidateName}</h6>
                                                                                <span className="small text-muted">{e.ExamName}</span>
                                                                            </div> */}
                                                                                <div className="card-body" style={{ padding: '5px', cursor: "pointer" }} onClick={() => ExamLive(e.ExamID, e.CandidateID, e.CandidateName, e.ExamStatus, e.ExamAttendID)}>
                                                                                    <div className='row'>
                                                                                        <h6 className='col-md-5'>{e.CandidateName}</h6>
                                                                                        <span style={{ lineHeight: '1.1' }} className='col-md-7'>{e.RejoinCode !== '' && e.RejoinCode !== 'NULL' && e.RejoinCode !== null ?
                                                                                            'Rejoin Code : ' + e.RejoinCode : ""
                                                                                        }</span>
                                                                                        <br />

                                                                                    </div>
                                                                                    <div className='row'>
                                                                                        <span className="col-md-5 small text-muted">{e.ExamName}</span>
                                                                                        <p style={{ lineHeight: '1.1' }} className='col-md-7 text-danger'>{e.Suspend != null && e.Suspend == 1 && e.IsRetake == null ? "Suspended" : ""}</p>


                                                                                    </div>

                                                                                    {/* <button className='btn btn-primary'>Suspend</button> */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                ))
                                                            )}

                                                        </ul>

                                                    </div>
                                                </div>

                                            </div>
                                        </section >
                                    </div>
                                </div>



                            ) : (
                                <>
                                    <div className='col-md-12'>
                                        <div className=" card-box-style shadow p-2 mb-5 bg-white rounded" style={{ padding: '0px' }}>

                                            <div className="row">
                                                <div className='col-lg-9 col-md-9 col-sm-9'>
                                                    <div className="overview-title">
                                                        <h3>Exam Monitor </h3>
                                                    </div>
                                                </div>
                                                <div className='col-md-3 col-lg-3 col-sm-3'>

                                                    <input
                                                        type="text"
                                                        value={searchQuery}
                                                        onChange={(e) => setSearchQuery(e.target.value)}
                                                        placeholder="Search..."
                                                        className='form-control'
                                                    />
                                                </div>
                                            </div>

                                            <section className="body mt-4">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="container">
                                                            <ul className="timeline">
                                                                {examMonitor && Array.isArray(examMonitor) && (
                                                                    filteredExamMonitors?.map((e, index) => (
                                                                        <li key={index}>
                                                                            <div className="timeline-time">
                                                                                <span className="small text-muted">{e.ExamStarted.split("T")[0]}</span>
                                                                            </div>
                                                                            <div className="timeline-icon"
                                                                                onClick={() => ExamLive(e.ExamID, e.CandidateID, e.CandidateName, e.ExamStatus, e.ExamAttendID)}
                                                                            >
                                                                                <a href="javascript:;"
                                                                                    style={{
                                                                                        background: e.ExamStatus === "Completed" ? "#4fcb8d" : e.ExamStatus ===
                                                                                            "Terminated" || e.ExamStatus === "CountOver" || e.ExamStatus == "Suspend" ? "#ff0000" :
                                                                                            e.ExamStatus ===
                                                                                                "Ongoing" ? "#ffff00" :
                                                                                                <></>,
                                                                                        border:
                                                                                            e.ExamStatus === "Completed" ? "5px solid #4fcb8d" : e.ExamStatus ===
                                                                                                "Terminated" || e.ExamStatus === "CountOver" || e.ExamStatus == "Suspend" ? "5px solid #ff0000" :
                                                                                                e.ExamStatus ===
                                                                                                    "Ongoing" ? "5px solid #ffff00" :
                                                                                                    <></>
                                                                                    }}
                                                                                >&nbsp;</a>
                                                                            </div>
                                                                            <div className={`timeline-body ${e.ExamStatus === "Completed" ? " examcompleted" : e.ExamStatus ===
                                                                                "Terminated" || e.ExamStatus === "CountOver" || e.ExamStatus == "Suspend" ? " examterminated" :
                                                                                e.ExamStatus ===
                                                                                    "Ongoing" ? " examongoing" :
                                                                                    <></>}`}


                                                                            >
                                                                                <div className="card shadow  bg-white rounded" style={{
                                                                                    borderLeft:
                                                                                        e.ExamStatus === "Completed" ? "4px solid #4fcb8d" : e.ExamStatus ===
                                                                                            "Terminated" || e.ExamStatus === "CountOver" || e.ExamStatus == "Suspend" ? "4px solid #ff0000" :
                                                                                            e.ExamStatus ===
                                                                                                "Ongoing" ? "4px solid #ffff00" :
                                                                                                <></>
                                                                                    , '--bs-card-spacer-y': '0px'
                                                                                }}>
                                                                                    <div className="card-body" style={{ padding: '5px', cursor: "pointer" }} onClick={() => ExamLive(e.ExamID, e.CandidateID, e.CandidateName, e.ExamStatus, e.ExamAttendID)}>
                                                                                        <div className='row'>
                                                                                            <h6 className='col-md-5'>{e.CandidateName}</h6>
                                                                                            <p style={{ lineHeight: '1.1' }} className='col-md-7'>{e.RejoinCode !== '' && e.RejoinCode !== 'NULL' && e.RejoinCode !== null ?
                                                                                                'Rejoin Code : ' + e.RejoinCode : <p style={{ lineHeight: '1.1' }} className='col-md-7 text-danger'>{e.Suspend != null && e.Suspend == 1 && e.IsRetake == null ? "Suspended" : ""}</p>
                                                                                            }</p>

                                                                                        </div>
                                                                                        <div className='row'>
                                                                                            <span className="col-md-5 small text-muted">{e.ExamName}</span>
                                                                                            <p style={{ lineHeight: '1.1' }} className='col-md-7 text-danger'>{e.Suspend != null && e.Suspend == 1 && e.IsRetake == null ? "Suspended" : ""}</p>


                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    ))
                                                                )}
                                                            </ul>

                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="container">
                                                            <div className="row ">
                                                                <div className="col-md-12">
                                                                    <button
                                                                        className={`${monitorButton === '24hrs' ? 'btn btn-warning' : 'btn btn-secondary'
                                                                            } mx-1 `}
                                                                        onClick={() => examMonitorClick('24hrs')}
                                                                    >
                                                                        Today
                                                                    </button>
                                                                    <button
                                                                        className={`${monitorButton === '7days' ? 'btn btn-warning' : 'btn btn-secondary'
                                                                            }  mx-1 `}
                                                                        onClick={() => examMonitorClick('7days')}
                                                                    >
                                                                        Last 7 days
                                                                    </button>
                                                                    <button
                                                                        className={`${monitorButton === '30days' ? 'btn btn-warning' : 'btn btn-secondary'
                                                                            } mx-1 `}
                                                                        onClick={() => examMonitorClick('30days')}
                                                                    >
                                                                        Last 30 days
                                                                    </button>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <table class="table table-borderless mt-3 p-3 mb-5 bg-white rounded">
                                                                        <thead >
                                                                            <tr style={{ borderBottom: '1px solid #ddd' }}>
                                                                                <th>
                                                                                    <p>{filteredExamMonitors.length > 0 ? "Recent Activity" : "No Activity"}</p>
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>

                                                                            {filteredExamMonitors.length > 0 ? filteredExamMonitors?.map((e, index) => (

                                                                                <tr>
                                                                                    <td key={index} >
                                                                                        <div className='row'>
                                                                                            <div className='col-md-4'>
                                                                                                <FaCheckCircle color={e.ExamStatus === "Completed" ? "green" : e.ExamStatus === "Ongoing" ? "yellow" : e.ExamStatus === "Terminated" || e.ExamStatus === "CountOver" || e.ExamStatus === "Suspend" ? "red" : ""} />
                                                                                                <span className="px-2" onClick={() => navigateToReview(e.CandidateID, e.ExamID, e.ExamAttendID)} style={{ cursor: 'pointer', fontSize: '16px' }}>{e.CandidateName}</span>
                                                                                            </div>
                                                                                            <div className='col-md-4'>
                                                                                                <span style={{ fontSize: '16px' }}>
                                                                                                    {e.Time_Taken === null || e.Time_Taken === '' ? "" : e.Time_Taken >= 60
                                                                                                        ? _examservice.convertSecondsToMinutes(e.Time_Taken) + ' Minutes'
                                                                                                        : e.Time_Taken + ' mins'}
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className='col-md-4'>
                                                                                                {e.ExamStatus === "Ongoing" ? (
                                                                                                    <button className='btn btn-outline-primary dashboardButton' onClick={() => handleSuspend(1, e.ExamID, e.CandidateID, e.ExamAttendID)}>Suspend</button>
                                                                                                ) : e.ExamStatus === "CountOver" ? (
                                                                                                    <button className='btn btn-outline-primary dashboardButton' onClick={() => reTake(e.ExamAttendID)}>ReTake</button>
                                                                                                ) : null}
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>

                                                                            )) : <></>}

                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section >
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}
export default DashBoard;

