import React from "react";
import { useNavigate } from "react-router-dom";
import SideBar from "./SideBar";
import "../../assets/css/owl.theme.default.min.css";
import "../../assets/css/owl.carousel.min.css";
import "../../assets/css/animate.min.css";
import "../../assets/css/footer.css";


function Navbar(){
    return(
        <>
          
        <SideBar></SideBar>
      
        </>
    );
}
export default Navbar;