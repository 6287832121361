import React, { useState, useRef, useEffect, useContext } from 'react';
import { AppContext } from '../../services/ContextProvider';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../Exam/Exam.css';
import ExamService from '../Exam/ExamService';
import Swal from "sweetalert2";
import EmailTemplateServices from './EmailTemplateService';
import { Decrypt } from '../../services/CommonService';
import MyPDF from '../Dashboard/Pdf';

export default function ShareScoreCard({ setShowPopupModal, examName, email, shareScorecard, setMain }) {

    console.log(examName);
    const _examService = new ExamService();
    const _emailService = new EmailTemplateServices();
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });

    const [toMails, setToMails] = useState([]);
    const [mailValue, setMailValue] = useState(email);
    const toMailRef = useRef(null);
    const [message, setMessage] = useState();
    const [subject, setSubject] = useState('');
    const [candidateGroupList, setCandidateGroupList] = useState([]);
    const [candidateGroupID, setCandidateGroupID] = useState();
    const [showShare, setShowShare] = useState(false);
    const { showLoading, hideLoading, isAuth } =
        useContext(AppContext);
    var mails;
    var subjects;
    var body;
    const onCloseModal = () => {

        setShowPopupModal(false);
    };

    useEffect(() => {
        // toMailRef.current.focus();
        _emailService.GetSHAREEXAMTemplate()
            .then((response) => {


                var subject = response.data[0]?.Subject;
                subject = subject.replace("{Report.ExamName}", "( " + (examName != null ? examName : "") + " )");
                setSubject(subject);
                var body = response.data[0].Body;
                body = body.replace("{Report.ExamName}", (examName != null ? examName : ""))

                setMessage(body);

            })

            .catch((exception) => {
                console.log(exception);
            });

    }, []);



    const mailOnChange = (e) => {
        setMailValue(email);
    };
    const RemoveMail = (item) => {
        const updatedMailsList = toMails.filter((toMails) => toMails !== item);
        setToMails(updatedMailsList);
    }
    const handleBlur = () => {
        toMailRef.current.focus();
    };

    const sendMail = async (e) => {


        e.preventDefault();
        mails = document.getElementById("email").value;
        subjects = document.getElementById("subject").value;
        body = message;
        setMain([mails, subjects, body]);

        if (subject === null || subject === '') {
            Toast.fire({
                icon: "warning",
                title: "Please enter subject",
            });
            return false;
        }
        if (body === '<p><br></p>') {
            Toast.fire({
                icon: "warning",
                title: "Please enter message body",
            });
            return false;
        }

        try {
            showLoading();
            onCloseModal();

        }
        catch (exception) {
            console.log(exception);
        }
    }

    const msgOnChange = (value) => {
        setMessage(value);
    };


    return (
        <>
            <form className="row g-3" autoComplete="off" onSubmit={sendMail}>
                <div className="modal fade show" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-modal="true" role="dialog" style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="modal-dialog modal-lg" style={{ margin: 'auto' }}>
                        <div className="modal-content my-auto">
                            <div className="modal-header">
                                <h5 className="modal-title MuiTypography-h6" id="staticBackdropLabel">Share Score Card</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" fdprocessedid="ttcp2q" onClick={onCloseModal}
                                />
                            </div>
                            <div className="modal-body">



                                <div className="row">
                                    <label

                                        htmlFor="formGroupExampleInput"
                                        className="form-label col-md-6"
                                    >
                                        Send Email To
                                        <span className="text-danger"> *</span>
                                    </label>
                                    {/* {checkShareGroup ? <select

                                        class="form-select form-control shareCandidateGroupDropdown col-md-6"

                                        onChange={GetCandidateEmailsAgainstGroup}
                                    >
                                        {" "}
                                        <option value="">
                                            Select Group
                                        </option>
                                        {candidateGroupList.map((option) => (
                                            <option
                                                key={option.parameterValueID}
                                                value={option.parameterValueID}
                                            >
                                                {option.value}
                                            </option>
                                        ))}
                                    </select> : <></>} */}


                                    <div class="input-group mb-3">

                                        <input
                                            type="text"
                                            className="form-control"
                                            id="email"
                                            name="particulars_input"
                                            placeholder=""
                                            autoComplete="off"
                                            defaultValue={mailValue}
                                            ref={toMailRef}
                                            readOnly
                                        />


                                    </div>

                                </div>

                                <div className="row">
                                    <label
                                        htmlFor="formGroupExampleInput"
                                        className="form-label"
                                    >
                                        Subject
                                        <span className="text-danger"> *</span>
                                    </label>

                                    <div class="input-group mb-3">
                                        <input type="text" class="form-control" id="subject" defaultValue={subject} />

                                    </div>
                                </div>

                                <div className="row">
                                    <label
                                        htmlFor="formGroupExampleInput"
                                        className="form-label"
                                    >
                                        Message Body
                                        <span className="text-danger"> *</span>
                                    </label>

                                    <div class="input-group mb-3">
                                        <ReactQuill value={message} className="quill-editor " rows="5" onChange={msgOnChange} />

                                    </div>
                                </div>






                            </div>
                            <div className="modal-footer mailtemplatefooter">
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={onCloseModal} fdprocessedid="t27w74">Close</button>
                                <button type="submit" className="btn btn-primary" fdprocessedid="nu3mc7">
                                    Send Email
                                </button>
                            </div>

                        </div>
                    </div>
                </div>

            </form>

        </>
    );
}