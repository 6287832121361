import CryptoJS from "crypto-js";
import { useContext } from "react";
import { AppContext } from "./ContextProvider";
import $ from "jquery";
import { COOKIES } from "./Global";
import Global from "./Global";
import { type } from "@testing-library/user-event/dist/type";

const KEY = "!5623a#de";

export const randomArray = (length, max) =>
  [...new Array(length)].map(() => Math.round(Math.random() * max));

export const Encrypt = (data) => {
  var encryptedData = "";
  if (data?.toString()?.length > 0) {
    encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      KEY
    ).toString();
  }
  return encryptedData;

};
export const COLORS = {
  red: "rgb(255, 99, 132)",
  orange: "rgb(255, 159, 64)",
  yellow: "rgb(255, 205, 86)",
  green: "rgb(75, 192, 192)",
  blue: "rgb(102, 124, 232)",
  purple: "rgb(153, 102, 255)",
  grey: "rgb(201, 203, 207)",
  purple: "rgb(118, 75, 162)",
  pink: "rgb(245, 72, 127)",
  cryon: "rgb(68, 179, 187)",
  lightGrey: "#D3D3D3",
  grey: "#808080",
  tvf: "#93D254",
  ahu: "#DA80E2",
};
export const Decrypt = (data) => {
  var decryptedData = "";
  if (data?.length > 0) {
    var bytes = CryptoJS.AES.decrypt(data, KEY);
    if (bytes != null) {
      decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
  }
  return decryptedData;
};

export const GetToggleButton = (row, col, value, id) => {
  if (value) {
    return `<div class="onoffswitch text-align-start">
    <input type="checkbox" name="onoffswitch" class="onoffswitch-checkbox" id="switch_annual_${id}${row}${col}" checked>
    <label class="onoffswitch-label" for="switch_annual_${id}${row}${col}">
      <span class="onoffswitch-inner"></span>
      <span class="onoffswitch-switch"></span>
    </label>
  </div>`;
  } else {
    return `<div class="onoffswitch text-align-start">
    <input type="checkbox" name="onoffswitch" class="onoffswitch-checkbox" id="switch_annual_${id}${row}${col}" >
    <label class="onoffswitch-label" for="switch_annual_${id}${row}${col}">
      <span class="onoffswitch-inner"></span>
      <span class="onoffswitch-switch"></span>
    </label>
  </div>`;
  }
};

export const GetSelect = (options, value, disabled) => {
  if (disabled) {
    var select = `<select class="form-select form-select  editable-row " disabled  >`;
    if (options != null && options.length > 0) {
      select += `<option selected disabled value="0">--Select--</option>`;
      options.map((v) => {
        if (v.value == value) {
          select += `<option selected value=${v.value}>${v.key}</option>`;
        } else {
          select += `<option value=${v.value}>${v.key}</option>`;
        }
      });
    }
    select += `</select>`;
    return select;
  } else {
    var select = `<select class="form-select form-select  editable-row "  >`;
    if (options != null && options.length > 0) {
      select += `<option selected disabled value="0">--Select--</option>`;
      options.map((v) => {
        if (v.value == value) {
          select += `<option selected value=${v.value}>${v.key}</option>`;
        } else {
          select += `<option value=${v.value}>${v.key}</option>`;
        }
      });
    }
    select += `</select>`;
    return select;
  }
};

export const SumoMultiSelect = (options, value) => {
  var values = value.split(",");
  var values1 = values.map(function (str) {
    return parseInt(str);
  });
  console.log(values1);
  var select = `<select  multiple="multiple" class="Selecttttt2  form-select form-control multiple editable-row " value = ${value}> `;

  if (options != null && options.length > 0) {
    select += `<option disabled value="0">----Select----</option>`;
    options.map((v) => {
      if (values1.includes(parseInt(v.value))) {
        select += `<option selected value=${v.value}>${v.key}</option>`;
      } else {
        select += `<option  value=${v.value}>${v.key}</option>`;
      }
    });
  }
  select += `</select>`;
  return select;
};

export const GetLoginUserID = () => {
  var id = localStorage.getItem(COOKIES.EmployeeID);

  return Decrypt(id);
};

export const getYMDdate = (date) => {
  var d = new Date(date);
  var year = d.getFullYear();
  var month = d.getMonth() + 1;
  var date = d.getDate();
  var monthstr = month <= 9 ? "0" + month : month;
  var dateStr = date <= 9 ? "0" + date : date;
  var res = `${year}-${monthstr}-${dateStr}`;
  return res;
};

export const getDDMMYYdate = (date) => {
  var stringLst = date.split("T")[0].split("-");
  var d = new Date(date);
  // var d = new Date(stringLst[0], stringLst[1], stringLst[2]);
  var year = d.getFullYear();
  var month = d.getMonth() + 1;
  var date = d.getDate();
  var monthstr = month <= 9 ? "0" + month : month;
  var dateStr = date <= 9 ? "0" + date : date;
  var res = `${dateStr}-${monthstr}-${year}`;
  return res;
};
export const GetNumberOfDaysInSec = (noOfDays) => {
  return noOfDays * 24 * 60 * 60;
};

export function convert24hrto12hr(time24) {
  if (time24 != null && time24 != "") {
    var ts = time24;
    var H = +ts.substr(0, 2);
    var h = H % 12 || 12;
    h = h < 10 ? "0" + h : h; // leading 0 at the left for 1 digit hours
    var ampm = H < 12 ? " AM" : " PM";
    ts = h + ts.substr(2, 3) + ampm;
    return ts;
  } else {
    return "";
  }
}

export const getUserDetails = () => {
  var value = localStorage.getItem(COOKIES.UserDetails);
  if (value != null) {
    var res = Decrypt(value);
    return res;
  }
  return "";
};

export const getPrivilege = () => {
  var value = localStorage.getItem(COOKIES.Privilege);
  if (value != null) {
    var res = Decrypt(value);
    return res;
  } else {
    return "";
  }
};
export const makeUnique = (array = [], keys = []) => {
  if (!keys.length || !array.length) return [];

  return array.reduce((list, item) => {
    const hasItem = list.find((listItem) =>
      keys.every((key) => listItem[key] === item[key])
    );
    if (!hasItem) list.push(item);
    return list;
  }, []);
};
export const MakeSingleValueUnique = (array = [], key) => {
  const unique = [...new Set(array.map((item) => item[key]))];
  return unique;
};

export const FileDownLoad = (filename, data, type) => {
  const blob = new Blob([data], { type: type });
  // if(window.navigator.msSaveOrOpenBlob) {
  //     window.navigator.msSaveBlob(blob, filename);
  // }
  // else{
  const elem = window.document.createElement("a");
  elem.href = window.URL.createObjectURL(blob);
  elem.download = filename;
  document.body.appendChild(elem);
  elem.click();
  document.body.removeChild(elem);
  // }
};
export const dynamicsort = (property, order) => {
  var sort_order = 1;
  if (order === "desc") {
    sort_order = -1;
  }
  return function (a, b) {
    // a should come before b in the sorted order
    if (a[property] < b[property]) {
      return -1 * sort_order;
      // a should come after b in the sorted order
    } else if (a[property] > b[property]) {
      return 1 * sort_order;
      // a and b are the same
    } else {
      return 0 * sort_order;
    }
  };
};

export const selectalldatatable = (tableid, checked) => {
  if (checked) {
    var temp = $(`#${tableid} > tbody > tr:has(td)`).find(".checks");
    temp.prop("checked", true);

    var datas = [];
    var tbl = $(`#${tableid} > tbody > tr:has(td)`).map(function (i, v) {
      var td = $("td", this);

      if (td.eq(0).find(".checks").is(":checked")) {
        // console.log(td.eq(1).text());
        return datas.push({
          EquipmentID: td.eq(1).text(),
          JobScheduleID: td.eq(2).text() ? td.eq(2).text() : 0,
        });
      }
    });
  } else {
    var temp = $(`#${tableid} > tbody > tr:has(td)`).find(".checks");
    temp.prop("checked", false);
    var datas = [];
  }

  return datas;
};

export const getCookieExpiry = (cookieName) => {
  const cookies = document.cookie.split(";");
  for (let cookie of cookies) {
    let [name, value] = cookie.split("=");
    name = name.trim();
    if (name === cookieName) {
      console.log(name, value);
      const expiresIndex = value.indexOf("expires=");
      if (expiresIndex !== -1) {
        console.log(value);
        return new Date(value.slice(expiresIndex + 8)).toString();
      }
      return "Session cookie";
    }
  }
  return "Cookie not found";
};
