import React from "react";
import { useEffect, useContext } from "react";
import EmployeeService from "./EmployeeService";
import { useState } from "react";
import { FaTrashAlt, FaEdit } from "react-icons/fa";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { IconButton } from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@material-ui/core/Tooltip";
import { AppContext } from "../../services/ContextProvider";
import DeleteIcon from "@mui/icons-material/Delete";

var empservice = new EmployeeService();
export default function Emplist() {
    const [empList, setempList] = useState([]);
    const [empId, setempId] = useState(0);
    const navigate = new useNavigate();
    const [isContainerFluid, setIsContainerFluid] = useState(true);

    const { showLoading, hideLoading, setCookies, tempModulePrivileage, isAuth, SetisAuth } =
        useContext(AppContext);
    useEffect(() => {
        showLoading();
        empservice.EmployeeView().then((res) => {

            if (res?.data?.length > 0) {
                setempList(res?.data);
            }
            setTimeout(() => {
                hideLoading();
            }, 200)
        }).catch((err) => {
            console.log(err);
        });

    }, []);




    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 750,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });

    function DeleteEmployee(empId) {
        Swal.fire({
            title: "Are you sure?",
            text: "Do You Want to Delete this Employee?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                empservice.DeleteEmployee(empId).then((res) => {
                    if (res.data == 1) {
                        Toast.fire({
                            icon: "success",
                            title: "Delete Success",
                        });
                        setTimeout(function () {
                            window.location.reload();
                        }, 750);
                    } else {
                        Toast.fire({
                            icon: "error",
                            title: "Delete Failed!",
                        });
                    }
                });
            }
        });
    }
    const empCreate = () => {
        navigate("/EmpCreate");

    }
    const HeaderElements = () => (
        <>
            <Tooltip title="Add Employee">
                <label htmlFor="icon-button-file">
                    <IconButton className="HoverDefaultIcon" component="span" onClick={empCreate}>
                        <AddIcon > </AddIcon>
                    </IconButton>
                </label>
            </Tooltip>

        </>
    );

    const columns = [
        { name: "firstName", label: "Employee Name" },
        { name: "contactNumber", label: "Mobile Number" },
        {
            label: "OPTIONS ",
            name: "employeeID",

            options: {
                filter: true,
                sort: false,
                empty: true,
                responsive: true,

                customBodyRender: (values, tableMeta) => {

                    const empId = empList[tableMeta.rowIndex].employeeID;
                    return (
                        <div className="ico-sec">

                            <Tooltip title="Edit Employee">
                                <IconButton
                                    type="button"
                                    title=""
                                    className="text-primary"
                                    data-original-title="Edit"
                                    data-toggle="modal"
                                    data-target="#addRowModal"

                                >
                                    <Link
                                        to="/EmpCreate"
                                        state={{
                                            empId: empId,
                                        }}
                                    > <FaEdit className="edit-icon" /> </Link>

                                </IconButton>
                            </Tooltip>


                            <Tooltip title="Delete Employee">
                                <IconButton
                                    className="text-danger"
                                    aria-label="Delete"
                                    onClick={() =>
                                        DeleteEmployee(empId)
                                    }
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    );
                },
            },
        },
    ];
    const toggleContainerClass = () => {
        setIsContainerFluid((prevState) => !prevState);
    };
    return (
        <>
            <main class="main-content-wrap">
                <div className="contact-list-area" id="EmpView">
                    <div className='container-fluid'  >

                        <div className="row">
                            <div className={`${isContainerFluid ? "col-md-12" : "col-md-9"}`}>
                                <div className="card-box-style animatedwidth">
                                    <div className="fullwidthbuttonbox " onClick={toggleContainerClass}>
                                        <span className="fullwidthbutton" >
                                            {isContainerFluid ? <i className="fa fa-angle-double-left"></i> : <i className="fa fa-angle-double-right"></i>}

                                        </span>
                                    </div>
                                    <MUIDataTable
                                        data={empList}
                                        title="Employee List"
                                        columns={columns}
                                        options={{
                                            responsive: true,
                                            download: false,
                                            print: false,
                                            rowsPerPageOptions: [10, 50, 100], // Set the available rows per page options

                                            customToolbar: () => (
                                                <>
                                                    <HeaderElements />
                                                </>
                                            ),
                                            filter: false,
                                            selectableRows: false,
                                            viewColumns: false,
                                        }}
                                    />
                                </div>
                            </div>
                            {!isContainerFluid ? <div className="col-md-3">

                                {/* <div className="card-box-style"></div> */}
                            </div> : null}
                        </div>

                    </div>
                </div>

            </main>
        </>

    );
}