import React, { useEffect, useState, useContext, useRef } from "react";
import ExamService from "./ExamService";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.module.css';
import TimePicker from 'react-bootstrap-time-picker';

import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import { useNavigate, useLocation, Link } from "react-router-dom";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "./Exam.css";
import { FaEdit, FaCog, FaTrashAlt, FaQuestionCircle, FaCircle, FaHandPointer, FaLongArrowAltLeft } from "react-icons/fa";
import { IconButton, Tooltip } from "@material-ui/core";
import $, { event } from "jquery";
import { Decrypt, Encrypt } from "../../services/CommonService";
import deleteImage from "../../assets/images/icon/trash.svg";
import Swal from "sweetalert2";
import { AppContext } from "../../services/ContextProvider";
import { format } from "date-fns";
import DeleteIcon from "@mui/icons-material/Delete";
import { de } from "date-fns/locale";
import { Edit2 } from "iconsax-react";
import SpecifySelectQuestions from './SpecifySelectQuestions'
export default function ExamCreate() {
  const _examService = new ExamService();
  const [isContainerFluid, setIsContainerFluid] = useState(true);
  let timeoutId = null;
  const location = useLocation();
  const inputRefs = useRef([]);
  const [focusedInputIndex, setFocusedInputIndex] = useState(-1);

  const examConfigModel = location.state?.examConfigModel ?? null;
  console.log(examConfigModel, 'c')
  const examID = location.state?.examID ?? null

  const { showLoading, hideLoading } =
    useContext(AppContext);

  const [open, setOpen] = useState(true);
  const [duration, setDuraton] = useState(
    examConfigModel === null
      ? 30
      : _examService.MillisecondsToMinutes(examConfigModel.duration)
  );
  const [negativeMarks, setNegativeMarks] = useState(
    examConfigModel === null ? 1 : examConfigModel.negativeMarkID
  );
  const [questionPicking, setquestionPicking] = useState(
    examConfigModel === null ? 3 : examConfigModel.questionPickingID
  );
  const [submitBeforeTme, setSubmitBeforeTme] = useState(
    examConfigModel === null ? 1 : examConfigModel.submitBefortime ? 1 : 0
  );
  const [examAvailability, setExamAvailabiblity] = useState(
    examConfigModel === null ? 7 : examConfigModel.examAvailabilityID
  );

  const [examAssignedType, setExamAssignedType] = useState(
    examConfigModel === null ? 9 : examConfigModel.examAssignmenttypeID
  );
  const [partialMarks, setPartialMarks] = useState(
    examConfigModel === null ? 0 : examConfigModel.isPartialMark ? 1 : 0
  );

  const [resultType, setResultType] = useState(
    examConfigModel === null ? 14 : examConfigModel.resultTypeID
  );
  const [scorePercentage, setScorePercentage] = useState(
    examConfigModel === null ? "" : examConfigModel.examClearingValue
  );

  const [chooseMode, setchooseMode] = useState(16);
  const [showResult, setShowResult] = useState(
    examConfigModel === null ? 13 : examConfigModel.resultID
  );
  const [questionNavigation, setQuestionNavigation] = useState(
    examConfigModel === null ? 0 : examConfigModel.questionNav ? 1 : 0
  );

  const [displayResults, setDisplayResults] = useState(
    examConfigModel === null ? 20 : examConfigModel.displayResultID
  );
  const [questionOrder, setquestionOrder] = useState(
    examConfigModel === null ? 22 : examConfigModel.questionOrderID
  );

  const [textHighlighter, setTextHighlighter] = useState(
    examConfigModel === null ? 0 : examConfigModel.textHighlighter ? 1 : 0
  );
  const [questionDisplay, setQuestionDisplay] = useState(
    examConfigModel === null ? 26 : examConfigModel.questionDisplayID
  );
  const [examScreenType, setExamScreenType] = useState(
    examConfigModel === null ? 28 : examConfigModel.examScreenTypeID
  );
  const [attendAllQuestions, setAttendAllQuestions] = useState(
    examConfigModel === null ? 0 : examConfigModel.attendAllQuestion ? 1 : 0
  );

  const [downloadQuestionPaper, setDownloadQuestionPaper] = useState(
    examConfigModel === null ? 0 : examConfigModel.questionDownload ? 1 : 0
  );
  const [finalAnswerShow, setFinalAnswerShow] = useState(
    examConfigModel === null ? 0 : examConfigModel.isFinalAnswerShown ? 1 : 0
  );
  const [unUsualBehaviour, setUnUsualBehaviour] = useState(
    examConfigModel === null ? 1 : examConfigModel.unusualBehaviour ? 1 : 0
  );

  const [defaultSetting, setDefaultSetting] = useState(
    examConfigModel === null ? 1 : examConfigModel.defaultConfig ? 1 : 0
  );
  const [liveChat, setLiveChat] = useState(
    examConfigModel === null ? 0 : examConfigModel.liveChat ? 1 : 0
  );
  const [unUsualBehaviourCount, setUnUsualBehaviourCount] = useState(
    examConfigModel === null ? 25 : examConfigModel.unusualBehaviourCount
  );

  const [negativeMarksOptions, setNegativeMarksOptions] = useState([]);
  const [questionPickingOptions, setQuestionPickingOptions] = useState([]);

  const [startDateandTime, setStartDateandTime] = useState(examConfigModel === null || examConfigModel.startTime == null ? new Date() : new Date(examConfigModel.startTime));

  const [endDateandTime, setEndDateandTime] = useState(examConfigModel === null || examConfigModel.endTime == null ? new Date() : new Date(examConfigModel.endTime));
  const [startTime, setStartTime] = useState(examConfigModel === null ? '' : examConfigModel.startTime);
  const [endTime, setEndTime] = useState('');
  const [examStartEndTimeCardDiv, setexamStartEndTimeCardDiv] = useState(true);
  const [unUsualBehaviourCardDiv, setUnUsualBehaviourCardDiv] = useState(
    examConfigModel === null ? true : examConfigModel.unusualBehaviour
  );

  const [percentageCardDiv, setPercentageCardDiv] = useState(false);
  // const [durationOptions, setDurationOptions] = useState([]);

  const [examAvailabilityOptions, setExamAvailabilityOptions] = useState([]);
  const [examAssignmentTypeOptions, setExamAssignmentTypeOptions] = useState(
    []
  );
  const [sectionModelList, setSectionModelList] = useState([]);

  const [sectionID, setsectionID] = useState(0);
  const [sectionList, setSectionList] = useState([]);
  const [showResultOptions, setShowResultOptions] = useState([]);
  const [resultTypeOptions, setResultTypeOptions] = useState([]);
  const [chooseModeOptions, setChooseModeOptions] = useState([]);

  const [displayResultOptions, setDisplayResultOptions] = useState([]);
  const [questionOrderOptions, setQuestionOrderOptions] = useState([]);

  const [questionDisplayOptions, setQuestionDisplayOptions] = useState([]);
  const [examScreenTypeOptions, setExamScreenTypeOptions] = useState([]);

  // const [scorePercentageOptions, setScorePercentageOptions] = useState([]);
  const [dataFieldList, setDataFieldList] = useState([]);
  const [unUsualBehaviourCountOptions, setUnUsualBehaviourCountOptions] =
    useState([]);
  const [specifyselectsectionList, setspecifyselectsectionList] = useState([]);
  const [examAvailabilityID, setExamAvailabilityID] = useState("Available on specific time");
  const [examRegistrationFormModelList, setExamRegistrationFormModelList] = useState([])
  const [sectionErrors, setSectionErrors] = useState([]);
  const [errors, setErrors] = useState({});
  const [showPopupModal, setShowPopupModal] = useState(false);
  const [questionIDs, setQuestionIDS] = useState([]);
  const [manualPick, setManualPick] = useState(false);
  const [autoPick, setAutoPick] = useState(false);
  const [examAttempt, setexamAttempt] = useState(examConfigModel === null ? '' : examConfigModel.noOfAttempt);
  const [Retake, setRetake] = useState(examConfigModel === null ? 0 : examConfigModel.retake);
  const [withoutConditionRetakeCount, setwithoutConditionRetakeCount] = useState(examConfigModel === null ? "" : examConfigModel.retake === 1 ? examConfigModel.retakeCount : '');
  const [withConditionRetakeCount, setwithConditionRetakeCount] = useState(examConfigModel === null ? "" : examConfigModel.retake === 2 ? examConfigModel.retakeCount : '');
  const [RetakePercentage, setRetakePercentage] = useState(examConfigModel === null ? '' : examConfigModel.retake === 2 ? examConfigModel.retakePercentage : '')
  const manualTotal = specifyselectsectionList
    .filter((value) => value?.isDeleted === 0)
    .reduce((sum, value) => sum + (value?.noQuestion || 0), 0);

  const randomTotal = sectionModelList
    .filter((value) => value?.isDeleted === 0)
    .reduce((sum, value) => sum + (value?.noQuestion || 0), 0);
  const navigate = useNavigate();

  const [visible, setVisible] = useState(examConfigModel === null ? '0' : examConfigModel.visiblePercentage);
  const [visibleList, setVisibleList] = useState([]);


  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });




  useEffect(() => {

    _examService
      .GetExamParameterList()
      .then((response) => {
        if (response != null) {


          const negativeMarksOptions = response.parameterList.filter(
            (option) => option.parameterCode === "NEGATIVEMARKS"
          );

          setNegativeMarksOptions(negativeMarksOptions);



          const questionPickingOptions = response.parameterList.filter(
            (option) => option.parameterCode === "QUESTIONPICKING"
          );
          setQuestionPickingOptions(questionPickingOptions);

          const examAvailabilityOptions = response.parameterList.filter(
            (option) => option.parameterCode === "EXAMAVAILABILITY"
          );
          setExamAvailabilityOptions(examAvailabilityOptions);

          const examAssignmentTypeOptions = response.parameterList.filter(
            (option) => option.parameterCode === "EXAMASSIGNMENTTYPE"
          );
          setExamAssignmentTypeOptions(examAssignmentTypeOptions);

          const showResultOptions = response.parameterList.filter(
            (option) => option.parameterCode === "SHOWRESULT"
          );

          setShowResultOptions(showResultOptions);

          const resultTypeOptions = response.parameterList.filter(
            (option) => option.parameterCode === "RESULTTYPE"
          );
          setResultTypeOptions(resultTypeOptions);

          const chooseModeOptions = response.parameterList.filter(
            (option) => option.parameterCode === "CHOOSEMODE"
          );
          setChooseModeOptions(chooseModeOptions);

          const displayResultOptions = response.parameterList.filter(
            (option) => option.parameterCode === "DISPLAYRESULTS"
          );
          setDisplayResultOptions(displayResultOptions);
          const questionOrderOptions = response.parameterList.filter(
            (option) => option.parameterCode === "QUESTIONORDER"
          );
          setQuestionOrderOptions(questionOrderOptions);

          const questionDisplayOptions = response.parameterList.filter(
            (option) => option.parameterCode === "QUESTIONDISPLAY"
          );
          setQuestionDisplayOptions(questionDisplayOptions);
          const examScreenTypeOptions = response.parameterList.filter(
            (option) => option.parameterCode === "EXAMSCREENTYPE"
          );
          setExamScreenTypeOptions(examScreenTypeOptions);

          const visibelPercentage = response.parameterList.filter(
            (option) => option.parameterCode === "VISIBLEPERCENTAGE"
          );

          setVisibleList(visibelPercentage);
          if (examConfigModel !== null) {

            const matchedItem = response.parameterList.filter(
              (option) =>
                option.parameterValueID === examConfigModel.examAvailabilityID
            );
            setExamAvailabilityID(matchedItem[0].value);
            if (
              matchedItem &&
              matchedItem[0].value === "Available on specific time"
            ) {
              setexamStartEndTimeCardDiv(true);
            } else {
              setexamStartEndTimeCardDiv(false);
            }
            const showresultmode = response.parameterList.filter(
              (option) => option.parameterValueID === examConfigModel.resultID
            );

            if (
              (showresultmode && showresultmode[0].value === "Percentage") ||
              showresultmode[0].value === "Point/Percentage"
            ) {

              setPercentageCardDiv(true);
            } else {
              setPercentageCardDiv(false);
            }
          }
          // setDurationOptions(response.durationList);
          setUnUsualBehaviourCountOptions(response.unusualbehaviourList);
          // setScorePercentageOptions(response.percentageList);

          // setspecifyselectsectionList(response.sectionList.filter(item => item.questionCount > 0));

          // setspecifyselectsectionList((v) => {
          //   return v.map((item) => {
          //     return {
          //       ...item,

          //       SelectCount: 0
          //     };
          //   });
          // });


          setSectionList(response.sectionList.filter(item => item.questionCount > 0));
          setDataFieldList(response.dataFieldList);

          if (examID === null) {

            setDataFieldList((prevDataFieldList) => {
              return prevDataFieldList.map((item) => {
                return {
                  ...item,
                  checked: item.dataFieldName === "First Name" ? true :
                    item.dataFieldName === "Last Name" ? true :
                      item.dataFieldName === "Email" ? true :
                        item.dataFieldName === "Mobile Number" ? true : false
                  ,
                  required: item.dataFieldName === "First Name" ? true : item.dataFieldName === "Email" ? true
                    : item.dataFieldName === "Mobile Number" ? true : false,
                  disabled: item.dataFieldName === "First Name" ? true : item.dataFieldName === "Email" ? true
                    : item.dataFieldName === "Mobile Number" ? true : false
                };
              });
            });


            const updatedDataFieldList = response.dataFieldList.filter((item) => item.dataFieldName === "First Name" ||
              item.dataFieldName === "Last Name" || item.dataFieldName === "Email"
              || item.dataFieldName === "Mobile Number");
            setExamRegistrationFormModelList(
              updatedDataFieldList.map((item) => {
                return {
                  ExamRegistrationID: 0,
                  DataFieldID: item.dataFieldID,
                  DataFieldRequired: item.dataFieldName === "Last Name" ? false : true,

                };
              })
            );
          }
          else {
            _examService.GetDataFieldListByExamID
              (examID)
              .then((response) => {
                if (response != null && response.length > 0) {
                  setDataFieldList((prevDataFieldList) => {


                    return prevDataFieldList.map((item) => {
                      const matchingItem = response.find((examItem) => examItem.dataFieldID === item.dataFieldID);
                      if (matchingItem) {

                        return {
                          ...item,
                          checked: true,
                          required: matchingItem.dataFieldRequired ? true : false,
                          disabled: matchingItem.dataFieldID === 1 ? true : false
                        };
                      } else {
                        return {
                          ...item,
                          checked: false,
                          required: false,
                          disabled: false
                        };
                      }
                    });
                  });

                  setExamRegistrationFormModelList(
                    response.map((item) => {
                      return {
                        ExamRegistrationID: item.examRegistrationID,
                        DataFieldID: item.dataFieldID,
                        DataFieldRequired: item.dataFieldRequired,

                      };
                    })
                  );
                }
              })
              .catch((exception) => {
                console.log(exception);
              });
          }

        }
      })
      .catch((exception) => {
        console.log(exception);
      });

    if (examID > 0 && examID != null) {

      _examService.GetSectionListByExamID
        (examID)
        .then((response) => {


          if (response != null && response.length > 0) {

            if (examConfigModel.questionPickFlag === "MANUAL") {

              const newList = response.map((item, i) => {

                return {
                  id: item.id,
                  sectionId: item.sectionId,
                  SelectCount: item.noQuestion,
                  isDeleted: 0,
                  index: i,
                  noQuestion: item.noQuestion,
                  sectionUsageCount: item.sectionUsageCount

                };
              });
              setspecifyselectsectionList(newList);
              // setspecifyselectsectionList((v) => {
              //   return v.map((item) => {
              //     const matchingResponse = response.find((responseItem) => responseItem.sectionId === item.sectionId);
              //     const selectCount = matchingResponse ? matchingResponse.noQuestion : 0;
              //     const examSectionID = matchingResponse ? matchingResponse.id : 0
              //     return {
              //       ...item,
              //       ExamSectionID: examSectionID,
              //       SelectCount: selectCount,
              //     };
              //   });
              // });
            }
            else {
              setSectionModelList(response);

              setSectionModelList((prevDataFieldList) => {
                return prevDataFieldList.map((item, i) => {
                  return {
                    ...item,
                    isDeleted: 0,
                    index: i,
                  };
                });
              });
            }


          }
        })
        .catch((exception) => {
          console.log(exception);
        });

      if (examConfigModel.questionPickFlag === "MANUAL") {
        _examService.GetQuestionIDS
          (examID)
          .then((response) => {

            if (response != null && response.length > 0) {
              setQuestionIDS(response);
              setQuestionIDS((v) => {
                return v.map((item) => {

                  return {
                    ...item,

                    isDeleted: 0
                  };
                });
              });


            }
          })
          .catch((exception) => {
            console.log(exception);
          });
      }



    }




    if (questionPicking === 3) {
      setManualPick(false);
      setAutoPick(true);

    }
    else {

      setManualPick(true);
      setAutoPick(false);

    }



  }, []);
  useEffect(() => {

    if (examConfigModel !== null && examConfigModel.startTime !== null && examConfigModel.endTime !== null) {

      debugger;
      // Extract hours and minutes from examConfigModel.startTime
      const examStartTime = new Date(examConfigModel.startTime);
      const examEndTime = new Date(examConfigModel.endTime);
      const startHours = examStartTime.getHours().toString().padStart(2, '0');
      const startMinutes = examStartTime.getMinutes().toString().padStart(2, '0');
      const endHours = examEndTime.getHours().toString().padStart(2, '0');
      const endMinutes = examEndTime.getMinutes().toString().padStart(2, '0');

      // Create formatted time strings
      const formattedStartTime = `${startHours}:${startMinutes}`;
      const formattedEndTime = `${endHours}:${endMinutes}`;

      // Set the state variables
      setStartTime(formattedStartTime);
      setEndTime(formattedEndTime);
    } else {

      // Set the state variables to the current time
      const currentTime = new Date();
      const hours = currentTime.getHours().toString().padStart(2, '0');
      const minutes = currentTime.getMinutes().toString().padStart(2, '0');
      const currentTimeString = `${hours}:${minutes}`;
      setStartTime(currentTimeString);
      setEndTime(currentTimeString);
    }


    if (showPopupModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showPopupModal]);

  const handleInputChange = (event) => {

    const { name, value } = event.target;
    // var myerror = errors;

    if (name === "examName") {
      if (value === "" || value === null) {
        // myerror.name = "Please enter name";
        Toast.fire({
          icon: "warning",
          title: "Please enter exam name",
        });
      }
      // else {
      //   myerror.name = "";
      // }
    } else if (name === "duration") {
      if (value === "" || value === null) {
        setDuraton(value);
        // myerror.duration = "Please enter duration";
        Toast.fire({
          icon: "warning",
          title: "Please enter duration",
        });
      }
      else if (value == 0) {
        setDuraton(value);
        // myerror.duration = "Please enter duration must be greater than 0";
        Toast.fire({
          icon: "warning",
          title: "Please enter duration must be greater than 0",
        });

      } else {
        let Val = value.replace(/[^0-9]/g, "");
        setDuraton(Val);
        // myerror.duration = "";
      }
    } else if (name === "negativeMarks") {

      if (value === "" || value === null) {
        setNegativeMarks(value);
        Toast.fire({
          icon: "warning",
          title: "Please choose negative marks",
        });
        // myerror.negativeMraks = "Please choose negative marks";
      } else {
        setNegativeMarks(value);
        // myerror.negativeMraks = "";
      }
    } else if (name === "questionPicking") {
      if (value === "" || value === null) {
        setquestionPicking(value);
        // myerror.questionPicking = "Please choose question picking";
        Toast.fire({
          icon: "warning",
          title: "Please choose question picking",
        });
      } else {
        setquestionPicking(value);
        // myerror.questionPicking = "";
        const nameAttributeValue = "questionPicking" + value;

        const elements = document.getElementsByName(nameAttributeValue);

        if (elements.length > 0) {
          var textContent = elements[0].textContent.toLowerCase().trim();

          if (textContent === 'manual pick') {

            setManualPick(true);
            setAutoPick(false);
          }
          else {
            setManualPick(false);
            setAutoPick(true);


          }
        }
      }
    } else if (name === "submitBeforeTme") {

      if (value === "" || value === null) {
        setSubmitBeforeTme(value);
        // myerror.submitBefortime = "Please choose  exam end of duration";
        Toast.fire({
          icon: "warning",
          title: "Please choose  exam end of duration",
        });
      } else {
        setSubmitBeforeTme(value);
        // myerror.submitBefortime = "";
      }
    } else if (name === "examAvailability") {

      if (value === "" || value === null) {
        setExamAvailabiblity(value);
        // myerror.examAvailabilityID = "Please choose exam availability type";
        Toast.fire({
          icon: "warning",
          title: "Please choose exam availability type",
        });
      } else {
        setExamAvailabiblity(value);
        // myerror.examAvailabilityID = "";
      }
    } else if (name === "examAssignedType") {
      if (value === "" || value === null) {
        setExamAssignedType(value);
        // myerror.examAssignmenttypeID = "Please choose exam assigned type";
        Toast.fire({
          icon: "warning",
          title: "Please choose exam assigned type",
        });
      } else {
        setExamAssignedType(value);
        // myerror.examAssignmenttypeID = "";
      }
    } else if (name === "partialMarks") {
      if (value === "" || value === null) {
        setPartialMarks(value);
        // myerror.partialMarks = "Please choose partial marks mode";
        Toast.fire({
          icon: "warning",
          title: "Please choose partial marks mode",
        });
      } else {
        setPartialMarks(value);
        // myerror.partialMarks = "";
      }
    } else if (name === "showResult") {
      if (value === "" || value === null) {
        setShowResult(value);
        // myerror.showResult = "Please choose show result";
        Toast.fire({
          icon: "warning",
          title: "Please choose show result",
        });
      } else {
        setShowResult(value);
        // myerror.showResult = "";
      }
    } else if (name === "resultType") {
      setResultType(value);

      if (value === "" || value === null) {
        // myerror.resultType = "Please choose result type";
        Toast.fire({
          icon: "warning",
          title: "Please choose result type",
        });
      } else {
        setResultType(value);
        // myerror.resultType = "";
      }
    } else if (name === "chooseMode") {
      if (value === "" || value === null) {
        setchooseMode(value);
        // myerror.chooseMode = "Please choose choose mode";
        Toast.fire({
          icon: "warning",
          title: "Please choose choose mode",
        });
      } else {
        setchooseMode(value);
        // myerror.chooseMode = "";
      }
    } else if (name === "scorePercentage") {

      if (value === "" || value === null) {
        setScorePercentage(value);
      } else {


        let Val = value.replace(/[^0-9]/g, "");


        if (Val > 100 || Val <= 0) {
          setScorePercentage('')
        }
        else {
          setScorePercentage(Val);
        }

      }
    } else if (name === "questionNavigation") {
      if (value === "" || value === null) {
        setQuestionNavigation(value);
        // myerror.questionNavigation =
        //   "Please choose question navigation  option";
        Toast.fire({
          icon: "warning",
          title: "Please choose question navigation  option",
        });
      } else {
        setQuestionNavigation(value);
        // myerror.questionNavigation = "";
      }
    } else if (name === "displayResults") {
      if (value === "" || value === null) {
        setDisplayResults(value);
        // myerror.displayResults = "Please choose display result type ";
        Toast.fire({
          icon: "warning",
          title: "Please choose display result type",
        });
      } else {
        setDisplayResults(value);
        // myerror.displayResults = "";
      }
    } else if (name === "questionOrder") {
      if (value === "" || value === null) {
        setquestionOrder(value);
        // myerror.questionOrder = "Please choose question order";
        Toast.fire({
          icon: "warning",
          title: "Please choose question order",
        });
      } else {
        setquestionOrder(value);
        // myerror.questionOrder = "";
      }
    } else if (name === "examScreenType") {
      if (value === "" || value === null) {
        setExamScreenType(value);
        // myerror.examScreenType = "Please choose exam screen type";
        Toast.fire({
          icon: "warning",
          title: "Please choose exam screen type",
        });
      } else {
        setExamScreenType(value);
        // myerror.examScreenType = "";
      }
    } else if (name === "textHighlighter") {
      if (value === "" || value === null) {
        setTextHighlighter(value);
        // myerror.textHighlighter = "Please choose  texthighlighter option";
        Toast.fire({
          icon: "warning",
          title: "Please choose  texthighlighter option",
        });
      } else {
        setTextHighlighter(value);
        // myerror.textHighlighter = "";
      }
    } else if (name === "questionDisplay") {
      if (value === "" || value === null) {
        setQuestionDisplay(value);
        // myerror.questionDisplay = "Please choose  question display option";
        Toast.fire({
          icon: "warning",
          title: "Please choose  question display option",
        });
      } else {
        setQuestionDisplay(value);
        // myerror.questionDisplay = "";
      }
    } else if (name === "attendAllQuestions") {
      if (value == "" || value === null) {
        setAttendAllQuestions(value);
        // myerror.attendAllQuestions =
        //   "Please choose  attend all question option";
        Toast.fire({
          icon: "warning",
          title: "Please choose  attend all question option",
        });
      } else {
        setAttendAllQuestions(value);
        // myerror.attendAllQuestions = "";
      }
    } else if (name === "downloadQuestionPaper") {
      if (value === "" || value === null) {
        setDownloadQuestionPaper(value);
        // myerror.downloadQuestionPaper =
        //   "Please choose  question download  option";
        Toast.fire({
          icon: "warning",
          title: "Please choose  question download  option",
        });
      } else {
        setDownloadQuestionPaper(value);
        // myerror.downloadQuestionPaper = "";
      }
    } else if (name === "unUsualBehaviourOptions") {
      if (value === "" || value === null) {
        setUnUsualBehaviour(value);
        // myerror.unUsualBehaviourOptions =
        //   "Please choose  unusual behaviour  option";
        Toast.fire({
          icon: "warning",
          title: "Please choose  unusual behaviour  option",
        });
      } else {
        setUnUsualBehaviour(value);
        // myerror.unUsualBehaviourOptions = "";
      }
    } else if (name === "unUsualBehaviourCount") {
      if (value === "" || value === null) {
        setUnUsualBehaviourCount(value);
      } else {
        setUnUsualBehaviourCount(value);
      }
    } else if (name === "liveChat") {
      if (value === "" || value === null) {
        setLiveChat(value);
        // myerror.liveChat = "Please choose  live chat  option";
        Toast.fire({
          icon: "warning",
          title: "Please choose  live chat  option",
        });
      } else {
        setLiveChat(value);
        // myerror.liveChat = "";
      }
    } else if (name === "finalAnswerShow") {
      if (value === "" || value === null) {
        setFinalAnswerShow(value);
        // myerror.finalAnswerShow = "Please choose  final answer option";
        Toast.fire({
          icon: "warning",
          title: "Please choose  final answer option",
        });
      } else {
        setFinalAnswerShow(value);
        // myerror.finalAnswerShow = "";
      }
    } else if (name === "defaultSetting") {
      if (value === "" || value === null) {
        setDefaultSetting(value);
        // myerror.defaultSetting = "Please choose  default config option";
        Toast.fire({
          icon: "warning",
          title: "Please choose  default config option",
        });
      } else {
        setDefaultSetting(value);
        // myerror.defaultSetting = "";
      }
    }
    else if (name === "examRetakePercentage") {
      if (value === "" || value === null) {
        setRetakePercentage(value);

        // myerror.defaultSetting = "Please choose  default config option";
      } else {
        let Val = value.replace(/[^0-9]/g, "");




        if (Val > 100 || Val <= 0) {
          setRetakePercentage('');
        }
        else {
          setRetakePercentage(Val);
        }
        // myerror.defaultSetting = "";
      }
    }

    else if (name === "examRetake") {
      if (value === "" || value === null) {
        setRetake(value);

        // myerror.defaultSetting = "Please choose  default config option";
      } else {

        setRetake(value);
        // myerror.defaultSetting = "";
      }
    }
    else if (name === "examRetakeCount1") {
      if (value === "" || value === null) {
        setwithoutConditionRetakeCount(value);

        // myerror.defaultSetting = "Please choose  default config option";
      } else {
        let Val = value.replace(/[^0-9]/g, "");




        if (Val > 10 || Val <= 0) {
          setwithoutConditionRetakeCount('');
        }
        else {
          setwithoutConditionRetakeCount(Val);
        }
        // myerror.defaultSetting = "";
      }

    }
    else if (name === "examRetakeCount2") {
      if (value === "" || value === null) {
        setwithConditionRetakeCount(value);

        // myerror.defaultSetting = "Please choose  default config option";
      } else {
        let Val = value.replace(/[^0-9]/g, "");




        if (Val > 10 || Val <= 0) {
          setwithConditionRetakeCount('');
        }
        else {
          setwithConditionRetakeCount(Val);
        }
        // myerror.defaultSetting = "";
      }
    }
    else if (name === "examAttempt") {
      if (value === "" || value === null) {
        setexamAttempt(value);
        Toast.fire({
          icon: "warning",
          title: "Please enter no of Attempt",
        });

        // myerror.defaultSetting = "Please choose  default config option";
      }
      else if (value == 0) {
        setexamAttempt(value);
        Toast.fire({
          icon: "warning",
          title: "Please enter no of Attempt must be greater than 0",
        });
      }
      else {
        let Val = value.replace(/[^0-9]/g, "");
        setexamAttempt(Val);




        // myerror.defaultSetting = "";
      }
    } else if (name === "visible") {

      if (value === "" || value === null) {
        setVisible(value);
        Toast.fire({
          icon: "warning",
          title: "Select an option",
        });

        // myerror.defaultSetting = "Please choose  default config option";
      } else {
        setVisible(value);
      }
    }
    // setErrors({ ...myerror });
  };
  // const handleStartDateTime = (value) => {
  //   let selectedStartDateTime;
  //   let selectedEndDateTime;
  //   if (endDateandTime != undefined) {
  //     selectedStartDateTime = new Date(`${startDateandTime.toDateString()} ${startTime}`);
  //     selectedEndDateTime = new Date(`${endDateandTime.toDateString()} ${endTime}`);
  //   }

  //   if (selectedStartDateTime > selectedEndDateTime) {
  //     Toast.fire({
  //       icon: "warning",
  //       title: "Start datetime must be before end datetime",
  //     });
  //   }
  //   // else if (new Date() > new Date(selectedStartDateTime)) {
  //   //   Toast.fire({
  //   //     icon: "warning",
  //   //     title: "Start datetime must be after current datetime",
  //   //   });
  //   // }
  //   
  //   setStartDateandTime(value);



  // }
  // const handleStartTime = (value) => {
  //   setStartTime(value.target.value);
  // }
  // const handleEndDateTime = (value) => {
  //   
  //   let selectedStartDateTime;
  //   let selectedEndDateTime

  //     selectedStartDateTime = new Date(`${startDateandTime.toDateString()} ${startTime}`);
  //     selectedEndDateTime = new Date(`${endDateandTime.toDateString()} ${endTime}`);

  //     if (selectedStartDateTime > selectedEndDateTime) {
  //       Toast.fire({
  //         icon: "warning",
  //         title: "End datetime must be after start datetime",
  //       });
  //     }


  //   // if (selectedStartDateTime == selectedEndDateTime) {
  //   //   Toast.fire({
  //   //     icon: "warning",
  //   //     title: "End datetime should be after the start datetime",
  //   //   });
  //   // }
  //   // else 
  //   setEndDateandTime(value);



  // }

  // const handleEndTime = (value) => {
  //   setEndTime(value.target.value);
  // }
  // Add this variable to track the timeout

  const handleDateTimeChange = (date, time, type) => {
    clearTimeout(timeoutId); // Clear any previous timeouts
    let StartDate;
    let EndDate;
    const selectedDate = new Date(date);
    const selectedTime = time;

    // Add validation to check if selectedTime matches the HH:mm format
    const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
    if (!timeRegex.test(selectedTime)) {
      console.error('Invalid time format:', selectedTime);
      // Handle the invalid time format here, e.g., show an error message to the user
      return;
    }

    // Parse the time input to get hours and minutes
    const [hours, minutes] = selectedTime.split(':').map(Number);

    // Create a new Date object by combining the date and time
    const selectedDateTime = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      selectedDate.getDate(),
      hours,
      minutes
    );

    if (type === 'start') {
      setStartDateandTime(selectedDateTime);
      setStartTime(selectedTime);
      StartDate = selectedDateTime;
    } else {
      setEndDateandTime(selectedDateTime);
      setEndTime(selectedTime);
      EndDate = selectedDateTime;
    }

    timeoutId = setTimeout(() => {
      const currentDateTime = new Date();
      if (currentDateTime > StartDate) {
        Toast.fire({
          icon: "error",
          title: "Start datetime is in the past",
        });
      } else {
        // Clear any previous error messages here if needed
      }
    }, 1000); // Adjust the delay (in milliseconds) as needed
  };




  const unUsualBehaviourCountChange = (event) => {
    setUnUsualBehaviourCount(event.target.value)

  };
  const examAvailabilityChange = (event) => {

    const value = event.target.nextSibling.textContent;
    setExamAvailabilityID(value);
    value === "Available on specific time"
      ? setexamStartEndTimeCardDiv(true)
      : setexamStartEndTimeCardDiv(false);
    handleInputChange(event);
  };
  const unUsualBehaviourChange = (event) => {
    const value = event.target.nextSibling.textContent;

    value === "Enable"
      ? setUnUsualBehaviourCardDiv(true)
      : setUnUsualBehaviourCardDiv(false);
    handleInputChange(event);
  };
  const showResultChange = (event) => {
    const value = event.target.nextSibling.textContent;
    if (value === "Percentage" || value === "Point/Percentage") {
      setPercentageCardDiv(true);
      setResultType(14);
    }
    else {
      setPercentageCardDiv(false);
    }


    handleInputChange(event);
  };

  const stringMinstoMilliSecondsLong = (value) => {
    if (value != null && value != "" && value != "0") {

      const milliseconds = value * 60000;
      return milliseconds;
    }
    else if (value == 0) {
      return 0;
    }
    return "";
  };

  const sectionCreate = (i) => {

    //     var length= sectionModelList.length;
    //     var Sno;
    //     if(length>0)
    //     {
    //       const filteredListDeleted = sectionModelList.filter((item) => item.isDeleted===0);
    //       Sno=filteredListDeleted.length+1;
    //     }
    //     else{
    // Sno=1;
    //     }
    const newModel = { id: 0, sectionId: 0, noQuestion: 0, isDeleted: 0, index: i }; // Create a new model object with desired values
    setSectionModelList((prevList) => [...prevList, newModel]);


  };
  const sectionCreateManual = (i) => {



    const newModel = { id: 0, sectionId: 0, SelectCount: 0, isDeleted: 0, index: i, noQuestion: 0 }; // Create a new model object with desired values
    setspecifyselectsectionList((prevList) => [...prevList, newModel]);


  };



  const deleteSectionManual = (index, sectionID, sid) => {

    const updatedItems = questionIDs.map((item, i) => {
      if (item.sectionId == sid) {
        return {
          ...item,
          isDeleted: 1
        };
      }
      return item;
    });

    setQuestionIDS(updatedItems);
    const ID = examConfigModel === null ? 0 : examConfigModel.examID
    if (sectionID > 0 && ID > 0) {
      _examService.DeleteSection
        (sectionID)
        .then((response) => {


          if (response > 0) {


            document.getElementById(index + "Manual").style.display = 'none';

            const updatedItems = specifyselectsectionList.map((item, i) => {
              if (i === index) {
                return {
                  ...item,
                  isDeleted: 1
                };
              }
              return item;
            });

            setspecifyselectsectionList(updatedItems);
          }
        })
        .catch((exception) => {
          console.log(exception);
        });

    }
    else {

      document.getElementById(index + "Manual").style.display = 'none';

      const updatedItems = specifyselectsectionList.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            isDeleted: 1
          };
        }
        return item;
      });

      setspecifyselectsectionList(updatedItems);

    }






  };
  const deleteSection = (index, sectionID) => {

    const ID = examConfigModel === null ? 0 : examConfigModel.examID
    if (sectionID > 0 && ID > 0) {
      _examService.DeleteSection
        (sectionID)
        .then((response) => {


          if (response > 0) {


            document.getElementById(index).style.display = 'none';

            const updatedItems = sectionModelList.map((item, i) => {
              if (i === index) {
                return {
                  ...item,
                  isDeleted: 1
                };
              }
              return item;
            });

            setSectionModelList(updatedItems);

          }
        })
        .catch((exception) => {
          console.log(exception);
        });

    }
    else {

      document.getElementById(index).style.display = 'none';

      const updatedItems = sectionModelList.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            isDeleted: 1
          };
        }
        return item;
      });

      setSectionModelList(updatedItems);

    }






  };
  const GetQuestions = (sectionId) => {

    setsectionID(sectionId);
    setShowPopupModal(true);

  }
  function assignValuesToSectionModel(index, event) {


    const { name, value } = event.target;
    // const updatedErrors = [...sectionErrors];
    if (name === "sectionId") {
      if (value === "" || value === null) {
        // updatedErrors[index] = {
        //   ...updatedErrors[index],
        //   sectionID: "Please select Section"
        // };
        Toast.fire({
          icon: "warning",
          title: "Please select Section",
        });
        sectionModelList[index].sectionId = Number(0)
      }
      else {
        // updatedErrors[index] = {
        //   ...updatedErrors[index],
        //   sectionID: ""
        // };
        sectionModelList[index].sectionId = Number(value);
      }



    }
    else if (name === "noOfQuestions") {

      if (value === "" || value === null) {
        // updatedErrors[index] = {
        //   ...updatedErrors[index],
        //   noQuestion: "Please enter no of Questuions"
        // };
        Toast.fire({
          icon: "warning",
          title: "Please enter no of Questions",
        });
        sectionModelList[index].noQuestion = "";
      }
      else {

        let Val = value.replace(/[^0-9]/g, "");
        const sectionID = sectionModelList[index].sectionId;

        const section = sectionList.find(item => item.sectionId === sectionID);
        if (section?.questionCount < Val) {

          // updatedErrors[index] = {
          //   ...updatedErrors[index],
          //   noQuestion: section?.questionCount === 0 ? "This section has no questions" : `You can only choose a maximum of ${section?.questionCount} questions.`
          // };
          Toast.fire({
            icon: "warning",
            title: 'You can only choose a maximum of ' + section?.questionCount + ' questions ' + 'in ' + section?.sectionName,
          });
          sectionModelList[index].noQuestion = "";
        }

        else {
          // updatedErrors[index] = {
          //   ...updatedErrors[index],
          //   noQuestion: ""
          // };
          sectionModelList[index].noQuestion = Number(Val);

        }
      }



    }
    // setSectionErrors(updatedErrors);
    setSectionModelList([...sectionModelList]);


  }
  function assignValuesToSectionModelManual(index, event) {


    const { name, value } = event.target;
    // const updatedErrors = [...sectionErrors];
    if (name === "sectionIdManual") {

      var oldSectionID = specifyselectsectionList[index].sectionId;
      if (oldSectionID !== value && oldSectionID > 0) {
        const updatedItems = questionIDs?.map((item, i) => {
          if (item?.sectionId == oldSectionID) {
            return {
              ...item,
              isDeleted: 1
            };
          }
          return item;
        });

        setQuestionIDS(updatedItems);
        specifyselectsectionList[index].SelectCount = 0;
      }

      if (value === "" || value === null) {
        // updatedErrors[index] = {
        //   ...updatedErrors[index],
        //   sectionID: "Please select Section"
        // };
        specifyselectsectionList[index].sectionId = Number(0)
      }
      else {
        // updatedErrors[index] = {
        //   ...updatedErrors[index],
        //   sectionID: ""
        // };
        specifyselectsectionList[index].sectionId = Number(value);
      }



    }
    else if (name === "noOfQuestionsManual") {
      let Val = value.replace(/[^0-9]/g, "");
      if (Val === "" || Val === null) {
        // updatedErrors[index] = {
        //   ...updatedErrors[index],
        //   noQuestion: "Please enter no of Questuions"
        // };
        Toast.fire({
          icon: "warning",
          title: 'Please enter no of Questions',
        });
        specifyselectsectionList[index].noQuestion = "";
      }
      else {
        const sectionID = specifyselectsectionList[index].sectionId;

        const section = sectionList.find(item => item.sectionId === sectionID);
        if (section?.questionCount < Number(Val)) {
          // updatedErrors[index] = {
          //   ...updatedErrors[index],
          //   noQuestion: section?.questionCount === 0 ? "This section has no questions" : `You can only choose a maximum of ${section?.questionCount} questions.`
          // };
          Toast.fire({
            icon: "warning",
            title: 'You can only choose a maximum of ' + section?.questionCount + ' questions ' + 'in ' + section?.sectionName,
          });
          specifyselectsectionList[index].noQuestion = "";
        }

        else {
          // updatedErrors[index] = {
          //   ...updatedErrors[index],
          //   noQuestion: ""
          // };
          specifyselectsectionList[index].noQuestion = Number(Val);

        }
      }



    }
    // setSectionErrors(updatedErrors);
    setspecifyselectsectionList([...specifyselectsectionList]);


  }
  function dataFieldOnCHange(event, itemId) {

    const { name } = event.target;
    if (name === "fieldselect") {
      const updatedDataFieldList = dataFieldList.map((item) => {
        if (item.dataFieldID === itemId) {
          return { ...item, checked: event.target.checked };
        }
        return item;
      });
      setDataFieldList(updatedDataFieldList);
      if (event.target.checked) {
        const newModel = {
          ExamRegistrationID: 0, DataFieldID: itemId,
          DataFieldRequired: document.getElementById(`required${itemId}`).checked ? true : false,
        };
        setExamRegistrationFormModelList((prevList) => [...prevList, newModel]);
      }
      else {

        const updatedList = examRegistrationFormModelList.filter((item) => item.DataFieldID !== itemId);
        setExamRegistrationFormModelList(updatedList);
      }
    }
    else if (name === "requiredselect") {

      const updatedDataFieldList = dataFieldList.map((item) => {
        if (item.dataFieldID === itemId) {
          return { ...item, required: event.target.checked };
        }
        return item;
      });
      setDataFieldList(updatedDataFieldList);
      const existcheck = examRegistrationFormModelList.filter((item) => item.DataFieldID === itemId);
      if (existcheck.length > 0) {

        if (event.target.checked) {
          existcheck[0].DataFieldRequired = true;
        }
        else {
          existcheck[0].DataFieldRequired = false;
        }
        setExamRegistrationFormModelList([...examRegistrationFormModelList]);
      }


    }




  }

  const onSubmit = (res) => {

    res.preventDefault();


    var flag = true;
    var myerror = errors;
    const updatedErrors = [...sectionErrors];
    if (endDateandTime < startDateandTime) {
      console.log('Start datetime is before end datetime');
      Toast.fire({
        icon: "error",
        title: "End datetime is less thean Start datetime",
      });
    }
    var data = {
      ExamID: examConfigModel === null ? 0 : examConfigModel.examID,
      ExamName: document.getElementById("examName").value,
      Duration: stringMinstoMilliSecondsLong(
        duration
      ),
      NegativeMarkID:
        negativeMarks === ""
          ? ""
          : parseInt(negativeMarks),
      QuestionPickingID:
        questionPicking == ""
          ? ""
          : parseInt(questionPicking),

      SubmitBefortime:
        submitBeforeTme === ""
          ? ""
          : submitBeforeTme == 1
            ? true
            : false,

      ExamAvailabilityID:
        examAvailability === ""
          ? ""
          : parseInt(examAvailability),

      StartTime:
        examAvailabilityID === "Available on specific time"
          ? startDateandTime === ""
            ? ""
            : format(startDateandTime, "yyyy/MM/dd hh:mm:ss a")
          : null,
      EndTime:
        examAvailabilityID === "Available on specific time"
          ? endDateandTime === ""
            ? ""
            : format(endDateandTime, "yyyy/MM/dd hh:mm:ss a")
          : null,
      ExamAssignmenttypeID:
        examAssignedType == ""
          ? ""
          : parseInt(examAssignedType),



      ResultID:
        showResult === ""
          ? ""
          : parseInt(showResult),
      ResultTypeID:
        parseInt(showResult) === 12 || parseInt(showResult) === 36


          ? resultType === ""
            ? ""
            : parseInt(resultType)
          : null,
      // showResultOptions.find((option) => option.parameterValueID === showResult)
      //   === "Percentage" || "Point/Percentage"

      //   ? resultType === ""
      //     ? ""
      //     : parseInt(resultType)
      //   : null,
      ExamClearingValue:
        parseInt(showResult) === 12 || parseInt(showResult) === 36


          ? scorePercentage === ""
            ? ""
            : parseInt(scorePercentage)
          : null,
      // showResultOptions.find((option) => option.parameterValueID === showResult)
      //   === "Percentage" || "Point/Percentage"

      //   ? scorePercentage === ""
      //     ? ""
      //     : parseInt(scorePercentage)
      //   : null,

      ModeID:
        chooseMode === ""
          ? ""
          : parseInt(chooseMode),
      QuestionNav:
        questionNavigation === ""
          ? ""
          : questionNavigation == 1
            ? true
            : false,
      DisplayResultID:
        displayResults === ""
          ? ""
          : parseInt(displayResults),
      QuestionOrderID:
        questionOrder === ""
          ? ""
          : parseInt(questionOrder),
      TextHighlighter:
        textHighlighter === ""
          ? ""
          : textHighlighter == 1
            ? true
            : false,
      QuestionDisplayID:
        questionDisplay === ""
          ? ""
          : parseInt(questionDisplay),
      ExamScreenTypeID:
        examScreenType === ""
          ? ""
          : parseInt(examScreenType),
      AttendAllQuestion:
        attendAllQuestions === ""
          ? ""
          : attendAllQuestions == 1
            ? true
            : false,
      QuestionDownload:
        downloadQuestionPaper === ""
          ? ""
          : downloadQuestionPaper == 1
            ? true
            : false,
      UnusualBehaviour:
        unUsualBehaviour === ""
          ? ""
          : unUsualBehaviour == 1
            ? true
            : false,
      UnusualBehaviourCount:
        unUsualBehaviour === ""
          ? ""
          : unUsualBehaviour == 1
            ? parseInt(document.getElementById("unUsualBehaviourCount").value)
            : null,
      LiveChat:
        liveChat === ""
          ? ""
          : liveChat == 1
            ? true
            : false,
      IsPartialMark:
        partialMarks === ""
          ? ""
          : partialMarks == 1
            ? true
            : false,
      CompanyID: Decrypt(localStorage.getItem("CompanyID")),
      CreatedBy:

        Decrypt(localStorage.getItem("EmployeeID")),

      IsFinalAnswerShown:
        finalAnswerShow === ""
          ? ""
          : finalAnswerShow == 1
            ? true
            : false,
      DefaultConfig:
        defaultSetting === ""
          ? ""
          : defaultSetting == 1
            ? true
            : false,

      ExamSectionsLists: sectionModelList.filter((item) => item.isDeleted === 0),
      ExamRegistrationFormModelList: examRegistrationFormModelList,
      QuestionIDS: questionIDs.filter((item) => item.isDeleted === 0),
      questionPickFlag: "",
      retake: parseInt(Retake),
      retakeCount: parseInt(Retake) === 0 ? 0 : parseInt(Retake) === 1 ? parseInt(document.getElementById("examRetakeCount1").value) :
        parseInt(Retake) === 2 ? parseInt(document.getElementById("examRetakeCount2").value) : '',
      retakePercentage: parseInt(Retake) === 2 ? parseInt(RetakePercentage) : 0,
      NoOfAttempt: examAttempt,
      visiblePercentage: visible

    };


    if (data.ExamName === "" || data.ExamName === null) {
      // myerror.name = "Please enter name";
      Toast.fire({
        icon: "warning",
        title: 'Please enter exam name',
      });
      flag = false;
      return false;
    }
    // else {
    //   myerror.name = "";
    // }
    if (data.Duration === "" || data.Duration === null) {
      // myerror.duration = "Please enter duration";
      Toast.fire({
        icon: "warning",
        title: 'Please enter duration',
      });
      flag = false;
      return false;
    }
    else if (data.Duration == 0) {
      // myerror.duration = "Please enter duration must be greater than 0";
      Toast.fire({
        icon: "warning",
        title: 'Please enter duration',
      });
      flag = false;
      return false;
    }
    // else {
    //   myerror.duration = "";
    // }
    if (data.NegativeMarkID === "" || data.NegativeMarkID === null) {
      // myerror.negativeMraks = "Please choose negative marks";
      Toast.fire({
        icon: "warning",
        title: 'Please choose negative marks',
      });
      flag = false;
      return false;
    }

    // else {
    //   myerror.negativeMraks = "";
    // }
    if (data.QuestionPickingID === "" || data.QuestionPickingID === null) {
      // myerror.questionPicking = "Please choose question picking";
      Toast.fire({
        icon: "warning",
        title: 'Please choose question picking',
      });
      flag = false;
      return false;
    }
    // else {
    //   myerror.questionPicking = "";
    // }
    if (data.SubmitBefortime === "" || data.SubmitBefortime === null) {
      // myerror.submitBefortime = "Please choose  exam end of duration";
      Toast.fire({
        icon: "warning",
        title: 'Please choose  exam end of duration',
      });
      flag = false;
      return false;
    }
    // else {
    //   myerror.submitBefortime = "";
    // }
    if (data.ExamAvailabilityID === "" || data.ExamAvailabilityID === null) {
      // myerror.examAvailabilityID = "Please choose exam availability type";
      Toast.fire({
        icon: "warning",
        title: 'Please choose exam availability type',
      });
      flag = false;
      return false;
    }
    // else {
    //   myerror.examAvailabilityID = "";
    // }
    if (
      data.ExamAssignmenttypeID === "" ||
      data.ExamAssignmenttypeID === null
    ) {
      // myerror.examAssignmenttypeID = "Please choose exam assigned type";
      Toast.fire({
        icon: "warning",
        title: 'Please choose exam assigned type',
      });
      flag = false;
      return false;
    }
    // else {
    //   myerror.examAssignmenttypeID = "";
    // }
    if (examAvailabilityID === "Available on specific time") {

      if (data.StartTime === "" || data.StartTime === null) {
        // myerror.startTime = "Please select   start date and time";
        Toast.fire({
          icon: "warning",
          title: 'Please select start datetime',
        });
        flag = false;
        return false;
      }
      else {

        // myerror.startTime = "";
        if (new Date(data.EndTime) < new Date(data.StartTime)) {
          Toast.fire({
            icon: "warning",
            title: "Start datetime must be before end datetime",
          });
          flag = false;
          return false;

        }
        else if (new Date() > new Date(data.StartTime)) {
          Toast.fire({
            icon: "warning",
            title: "Start datetime must be after  current datetime",
          });
          flag = false;
          return false;

        }

      }
      if (data.EndTime === "" || data.EndTime === null) {
        // myerror.endTime = "Please select end datetime";
        Toast.fire({
          icon: "warning",
          title: "Please select end datetime",
        });
        flag = false;
        return false;
      } else {
        myerror.endTime = "";
        if (new Date(data.StartTime) > new Date(data.EndTime)) {
          Toast.fire({
            icon: "warning",
            title: "End datetime must be after start datetime",
          });
          flag = false;
          return false;

        }
        else if (new Date(data.StartTime) == new Date(data.EndTime)) {
          Toast.fire({
            icon: "warning",
            title: "End datetime should be after the start datetime",
          });
          flag = false;
          return false;

        }


      }
    }
    if (parseInt(data.ResultID) === 12 || parseInt(data.ResultID) === 36) {
      if (data.ExamClearingValue === '' || data.ExamClearingValue === null || isNaN(data.ExamClearingValue)) {
        Toast.fire({
          icon: "warning",
          title: "Please enter minimum required score",
        });
        flag = false;
      }
    }
    if (data.IsPartialMark === "" || data.IsPartialMark === null) {
      // myerror.partialMarks = "Please choose partial marks mode";
      Toast.fire({
        icon: "warning",
        title: 'Please choose partial marks mode',
      });
      flag = false;
      return false;
    }
    // else {
    //   myerror.partialMarks = "";
    // }
    if (data.ResultID === "" || data.ResultID === null) {
      // myerror.showResult = "Please choose show result";
      Toast.fire({
        icon: "warning",
        title: 'Please choose show result',
      });
      flag = false;
      return false;
    }
    // else {
    //   myerror.showResult = "";
    // }
    if (
      parseInt(data.ResultID) === 12 || parseInt(data.ResultID) === 36

    ) {
      if (data.ResultTypeID === "" || isNaN(data.ResultTypeID)) {
        // myerror.resultType = "Please choose result type";
        Toast.fire({
          icon: "warning",
          title: 'Please choose result type',
        });
        flag = false;
        return false;
      }
      // else {
      //   myerror.resultType = "";
      // }
    }

    if (data.ModeID === "" || data.ModeID === null) {
      // myerror.chooseMode = "Please choose choose mode";
      Toast.fire({
        icon: "warning",
        title: 'Please choose choose mode',
      });
      flag = false;
      return false;
    }
    // else {
    //   myerror.chooseMode = "";
    // }
    if (data.QuestionNav === "" || data.QuestionNav === null) {
      // myerror.questionNavigation = "Please choose question navigation  option";
      Toast.fire({
        icon: "warning",
        title: 'Please choose question navigation  option',
      });
      flag = false;
      return false;
    }
    //  else {
    //   myerror.questionNavigation = "";
    // }
    if (data.DisplayResultID === "" || data.DisplayResultID === null) {
      // myerror.displayResults = "Please choose display result type ";
      Toast.fire({
        icon: "warning",
        title: 'Please choose display result type',
      });
      flag = false;
      return false;
    }
    // else {
    //   myerror.displayResults = "";
    // }
    if (data.QuestionOrderID === "" || data.QuestionOrderID === null) {
      // myerror.questionOrder = "Please choose question order";
      Toast.fire({
        icon: "warning",
        title: 'Please choose question order',
      });
      flag = false;
      return false;
    }
    // else {
    //   myerror.questionOrder = "";
    // }
    if (data.ExamScreenTypeID === "" || data.ExamScreenTypeID === null) {
      // myerror.examScreenType = "Please choose exam screen type";
      Toast.fire({
        icon: "warning",
        title: 'Please choose exam screen type',
      });
      flag = false;
      return false;
    }
    // else {
    //   myerror.examScreenType = "";
    // }
    if (data.TextHighlighter === "" || data.TextHighlighter === null) {
      // myerror.textHighlighter = "Please choose  texthighlighter option";
      Toast.fire({
        icon: "warning",
        title: 'Please choose  texthighlighter option',
      });
      flag = false;
      return false;
    }
    // else {
    //   myerror.textHighlighter = "";
    // }
    if (data.QuestionDisplayID === "" || data.QuestionDisplayID === null) {
      // myerror.questionDisplay = "Please choose  question display option";
      Toast.fire({
        icon: "warning",
        title: 'Please choose  question display option',
      });
      flag = false;
      return false;
    }
    // else {
    //   myerror.questionDisplay = "";
    // }
    if (data.AttendAllQuestion === "" || data.AttendAllQuestion === null) {
      // myerror.attendAllQuestions = "Please choose attend all question option";
      Toast.fire({
        icon: "warning",
        title: 'Please choose attend all question option',
      });
      flag = false;
      return false;
    }
    // else {
    //   myerror.attendAllQuestions = "";
    // }
    if (data.QuestionDownload === "" || data.QuestionDownload === null) {
      // myerror.downloadQuestionPaper =
      //   "Please choose  question download  option";
      Toast.fire({
        icon: "warning",
        title: 'Please choose question download option',
      });
      flag = false;
      return false;
    }
    //  else {
    //   myerror.downloadQuestionPaper = "";
    // }

    if (data.UnusualBehaviour === "" || data.UnusualBehaviour === null) {
      // myerror.unUsualBehaviourOptions =
      //   "Please choose  unusual behaviour  option";
      Toast.fire({
        icon: "warning",
        title: 'Please choose unusual behaviour option',
      });
      flag = false;
      return false;
    }
    // else {
    //   myerror.unUsualBehaviourOptions = "";
    // }
    if (data.LiveChat === "" || data.LiveChat === null) {
      // myerror.liveChat = "Please choose  live chat  option";
      Toast.fire({
        icon: "warning",
        title: 'Please choose  live chat  option',
      });
      flag = false;
      return false;
    }
    // else {
    //   myerror.liveChat = "";
    // }
    if (data.IsFinalAnswerShown === "" || data.IsFinalAnswerShown === null) {
      // myerror.finalAnswerShow = "Please choose  final answer option";
      Toast.fire({
        icon: "warning",
        title: 'Please choose  final answer option',
      });
      flag = false;
      return false;
    }
    // else {
    //   myerror.finalAnswerShow = "";
    // }
    if (data.DefaultConfig === "" || data.DefaultConfig === null) {
      flag = false;

      // myerror.defaultSetting = "Please choose  default config option";
      Toast.fire({
        icon: "warning",
        title: 'Please choose  default config option',
      });
      return false;
    }
    // else {
    //   myerror.defaultSetting = "";
    // }
    if (data.QuestionPickingID === 3) {
      if (data.ExamSectionsLists.length === 0) {

        Toast.fire({
          icon: "warning",
          title: "Please add atleast one section",
        });
        flag = false;
        return false;
      }

      else {

        {
          data.ExamSectionsLists && data.ExamSectionsLists.length > 0 && (
            data.ExamSectionsLists.map((item, index) => {
              data.questionPickFlag = "RANDOM"

              if (item.sectionId === 0) {
                flag = false;
                // updatedErrors[item.index] = {
                //   ...updatedErrors[item.index],
                //   sectionID: "Please select a section",
                // };
                Toast.fire({
                  icon: "warning",
                  title: 'Please select a section',
                });
                return false;
              } else {
                // updatedErrors[item.index] = {
                //   ...updatedErrors[item.index],
                //   sectionID: "",
                // };
              }

              if (item.noQuestion === "" || item.noQuestion == 0) {
                flag = false;
                // updatedErrors[item.index] = {
                //   ...updatedErrors[item.index],
                //   noQuestion: "Please enter the number of questions",
                // };
                Toast.fire({
                  icon: "warning",
                  title: 'Please enter the number of questions',
                });
                return false;
              }
              else {

                const sectionID = item.sectionId;

                const section = sectionList.find(item => item.sectionId === sectionID);
                if (section?.questionCount < item.noQuestion) {
                  flag = false;
                  // updatedErrors[index] = {
                  //   ...updatedErrors[index],
                  //   noQuestion: section?.questionCount === 0 ? "This section has no questions" : `You can only choose a maximum of ${section?.questionCount} questions.`
                  // };


                  Toast.fire({
                    icon: "warning",
                    title: 'You can only choose a maximum of ' + section?.questionCount + ' questions ' + 'in ' + section?.sectionName,
                  });
                  return false;
                }
                else {
                  // updatedErrors[item.index] = {
                  //   ...updatedErrors[item.index],
                  //   noQuestion: "",
                  // };
                }

              }


            })
          )
        }

      }


    }
    else {
      if (specifyselectsectionList.filter((item) => item.isDeleted === 0).length === 0) {

        Toast.fire({
          icon: "warning",
          title: "Please add atleast one section",
        });
        flag = false;
        return false;

      }
    }


    if (data.retake === 1) {

      if (isNaN(data.retakeCount)) {
        flag = false;
        Toast.fire({
          icon: "warning",
          title: "Please enter retake count",
        });
        return false;

      }
      else if (data.retakeCount === 0) {
        flag = false;
        Toast.fire({
          icon: "warning",
          title: "Please enter retake count greater than 0",
        });
        return false;
      }

    }
    else if (data.retake === 2) {
      if (isNaN(data.retakeCount)) {
        flag = false;
        Toast.fire({
          icon: "warning",
          title: "Please enter retake count",
        });
        return false;
      }
      else if (data.retakeCount === 0) {
        flag = false;
        Toast.fire({
          icon: "warning",
          title: "Please enter retake count greater than 0",
        });
        return false;
      }


      if (isNaN(data.retakePercentage)) {
        flag = false;
        Toast.fire({
          icon: "warning",
          title: "Please enter retake percentage",
        });
        return false;
      }
      else if (data.retakePercentage === 0) {
        flag = false;
        Toast.fire({
          icon: "warning",
          title: "Please enter retake percentage greater than 0",
        });
        return false;
      }
    }
    // setSectionErrors(updatedErrors);
    // setErrors({ ...myerror });

    if (flag === true) {
      if (data.QuestionPickingID === 3) {
        const newModel = [{ id: 0, questionID: 0, isDeleted: 0 }]; // Create a new model object with desired values
        data.QuestionIDS = newModel;
      }
      else {

        data.questionPickFlag = "MANUAL"

        const filteredList = specifyselectsectionList.filter(item => item.isDeleted === 0).map(item => {

          const newModel = { id: examConfigModel === null ? 0 : examConfigModel.examID > 0 ? item.id : 0, sectionId: item.sectionId, noQuestion: item.noQuestion, selectCount: item.SelectCount };
          return newModel;
        });



        filteredList && filteredList.length > 0 && (
          filteredList.map((item, index) => {


            if (item.sectionId === 0) {
              flag = false;
              // updatedErrors[item.index] = {
              //   ...updatedErrors[item.index],
              //   sectionID: "Please select a section",
              // };
              Toast.fire({
                icon: "warning",
                title: 'Please select a section',
              });
              return false;
            }

            if (item.noQuestion === "" || item.noQuestion == 0) {
              flag = false;

              Toast.fire({
                icon: "warning",
                title: 'Please enter the number of questions',
              });
              return false;
            }
            else {
              const sectionID = item.sectionId;

              const section = sectionList.find(item => item.sectionId === sectionID);
              if (section?.questionCount < item.noQuestion) {
                flag = false;
                // updatedErrors[index] = {
                //   ...updatedErrors[index],
                //   noQuestion: section?.questionCount === 0 ? "This section has no questions" : `You can only choose a maximum of ${section?.questionCount} questions.`
                // };


                Toast.fire({
                  icon: "warning",
                  title: 'You can only choose a maximum of ' + section?.questionCount + ' questions ' + 'in ' + section?.sectionName,
                });
                return false;
              }
              else {

                var sectionQuestionCount = questionIDs?.find(item => item?.sectionId === sectionID && item?.isDeleted === 0)

                if (item.selectCount === 0 || item.selectCount === '' || sectionQuestionCount === undefined
                ) {
                  flag = false;
                  Toast.fire({
                    icon: "warning",
                    title: 'Please select questions',
                  });
                  return false;
                }
                else if (item.noQuestion < item.selectCount) {
                  flag = false;
                  Toast.fire({
                    icon: "warning",
                    title: 'You can only choose a maximum of ' + section?.questionCount + ' questions ' + 'in ' + section?.sectionName
                  });
                  return false;
                }
                else if (item.noQuestion > item.selectCount) {
                  let remainingquest = item.noQuestion - item.selectCount;
                  flag = false;
                  Toast.fire({
                    icon: "warning",
                    title: 'Please select remaining' + ' ' + remainingquest + ' ' + ' questions ' + 'in ' + section?.sectionName
                  });
                  return false;
                }
              }


            }


          })
        )
        data.ExamSectionsLists = filteredList;
      }

      if (data.NoOfAttempt === '' || data.NoOfAttempt === null) {
        flag = false;
        Toast.fire({
          icon: "warning",
          title: 'Please enter no of Attempt'
        });
        return false;
      }
      else if (data.NoOfAttempt == 0) {
        flag = false;
        Toast.fire({
          icon: "warning",
          title: 'Please enter no of Attempt must be greater than 0'
        });
        return false;
      }


      if (data.visiblePercentage === '' || data.visiblePercentage === null) {
        flag = false;
        Toast.fire({
          icon: "warning",
          title: 'Please Select Question Visible Percentage'
        });
        return false;
      }
      else if (data.visiblePercentage == 0) {
        flag = false;
        Toast.fire({
          icon: "warning",
          title: 'Please Select Question Visible Percentage'
        });
        return false;
      }


      if (flag === true) {

        showLoading();
        _examService.CreateUpdate(data).then((res) => {

          if (res.data != null) {
            hideLoading();
            Toast.fire({
              icon: "success",
              title: "Exam Saved Success",
            });

            const examid = Encrypt(res.data.examID);


            const fullUrl = window.location.href;
            const url = new URL(fullUrl);
            const baseUrl = `${url.protocol}//${url.host}/`;
            const redirectUrl = `${baseUrl}Canditate?examid=${examid}`;
            const examName = document.getElementById("examName").value;
            const data = { ...res.data, ExamLink: redirectUrl, ExamName: examName };


            navigate("/ExamSummary", {
              state: { examSummaryModel: data, navigate: -2 },
            });
          } else {
            Toast.fire({
              icon: "error",
              title: "Failed to Save  ",
            });
          }

        });
      }
    }
  };

  const handleIconClick = (currentIndex) => {

    if (focusedInputIndex !== -1) {
      inputRefs.current[focusedInputIndex].blur();
    }

    inputRefs.current[currentIndex].focus();
  };


  const toggleContainerClass = () => {
    setIsContainerFluid((prevState) => !prevState);
  };


  return (
    <>
      {/* Start Main Content Area */}
      <main className="main-content-wrap">
        <form id="examCreateUpdate" autoComplete="off" onSubmit={onSubmit}>
          <div className="accordion-area">
            <div className='container-fluid'  >

              <div className="row">
                <div className={`${isContainerFluid ? "col-md-12" : "col-md-9"}`}>
                  <div className="card-box-style animatedwidth">
                    <div className="fullwidthbuttonbox " onClick={toggleContainerClass}>
                      <span className="fullwidthbutton" >
                        {isContainerFluid ? <i className="fa fa-angle-double-left"></i> : <i className="fa fa-angle-double-right"></i>}

                      </span>
                    </div>
                    <div className="others-title row">
                      <h3 className="col-6">{examConfigModel === null ? "Create New Exam" : examConfigModel.examID > 0 ? "Edit Exam" : "Create New Exam"}
                      </h3>
                      <span className="col-6">

                        <input type="button" value="Back" title="Back" className="back-button" style={{ float: 'right' }} onClick={() => navigate(-1)} />
                        <input type="button" value={open ? "Collapse" : "Expand"} title="Collapse" className="back-button backbutton-end" style={{ float: 'right' }} onClick={() => setOpen(!open)} />
                        {/* <input type="button" value="Expand" title="Expand" className="back-button backbutton-end" style={{ float: 'right' }} onClick={() => setOpen(true)} /> */}
                      </span>
                    </div>

                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">

                          <button
                            className={open ? 'accordion-button' : 'accordion-button collapsed'}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="false"
                            aria-controls="collapseOne"
                          >
                            Enter Exam Information
                          </button>
                        </h2>
                        <div
                          id="collapseOne"

                          className={open ? ' accordion-collapse collapse show' : 'accordion-collapse collapse'}
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                          style={{}}
                        >
                          <div className="accordion-body">
                            {/* Start Form Layouts Area */}

                            <div className="form-layouts-area">
                              <div className="container-fluid">
                                {/* <div className="card-box-style"> */}
                                <div>
                                  <div className="row">

                                    <div class="col-md-6 ">
                                      <label
                                        htmlFor="formGroupExampleInput"
                                        className="form-label"
                                      >
                                        Name
                                        <span className="text-danger"> *</span>
                                      </label>

                                      <div class="input-group mb-3">
                                        <input
                                          type="text"
                                          class="form-control"
                                          name="examName"
                                          id="examName"
                                          placeholder="Add Exam Name (Max 100 characters)"
                                          defaultValue={
                                            examConfigModel === null
                                              ? ""
                                              : examConfigModel.examName
                                          }

                                          aria-describedby="basic-addon2"
                                          onChange={handleInputChange}
                                          maxLength={100}
                                        />
                                      </div>
                                      {errors.name && (
                                        <span className="text-danger">
                                          {errors.name}
                                        </span>
                                      )}
                                    </div>
                                    <div class="col-md-6">
                                      <label
                                        for="inputState"
                                        class="form-label"
                                      >
                                        Duration (Minutes)
                                        <span className="text-danger"> *</span>
                                      </label>
                                      <div class="input-group mb-3">
                                        <input
                                          type="text"
                                          class="form-control"
                                          name="duration"
                                          id="duration"

                                          value={duration}
                                          aria-describedby="basic-addon2"
                                          onChange={handleInputChange}
                                          maxLength={3}
                                        />
                                      </div>

                                      {/* <select
                                        id="duration"
                                        name="duration"
                                        class="form-select form-control"
                                        value={duration}
                                        onChange={handleInputChange}
                                      >
                                        {" "}
                                        <option value="">
                                          Select Duration
                                        </option>
                                        {durationOptions.map((option) => (
                                          <option
                                            key={option.text}
                                            value={option.text}
                                          >
                                            {option.text}
                                          </option>
                                        ))}
                                      </select> */}
                                      {errors.duration && (
                                        <span className="text-danger">
                                          {errors.duration}
                                        </span>
                                      )}
                                    </div>





                                    <div className={negativeMarksOptions.length > 0 && negativeMarksOptions[0].fieldType === "RadioButton" ? "col-md-6  mb-3" : "col-md-6  mb-3"}>
                                      <label
                                        for="inputState"
                                        class="form-label"
                                      >
                                        Negative Marks<span className="text-danger"> *</span>
                                      </label>
                                      <div style={{ display: negativeMarksOptions.length > 0 && negativeMarksOptions[0].fieldType === "RadioButton" ? "flex" : "block" }}>

                                        {negativeMarksOptions.length > 0 && negativeMarksOptions[0].fieldType === "RadioButton" ? (
                                          <>
                                            {negativeMarksOptions.map((v, i) => (
                                              <div className="form-check" key={i}>
                                                <input
                                                  type="radio"
                                                  className="form-check-input"
                                                  id={"negativeMarks"}
                                                  name={'negativeMarks'}
                                                  value={v.parameterValueID}
                                                  onChange={handleInputChange}
                                                  checked={v.parameterValueID == negativeMarks}
                                                />
                                                <label className="form-check-label">
                                                  {v.value}
                                                </label>
                                              </div>
                                            ))}
                                          </>
                                        ) : (negativeMarksOptions.length > 0 && negativeMarksOptions[0].fieldType === "DropDown" ? (
                                          <>
                                            <select
                                              id="negativeMarks"
                                              className="form-select form-control"
                                              name="negativeMarks"
                                              value={negativeMarks}
                                              onChange={handleInputChange}

                                            >
                                              <option value="">Select Negative Marks</option>
                                              {negativeMarksOptions.map((option) => (
                                                <option
                                                  key={option.parameterValueID}
                                                  value={option.parameterValueID}
                                                >
                                                  {option.value}
                                                </option>
                                              ))}
                                            </select>
                                          </>
                                        ) : <></>)}








                                        {errors.negativeMraks && (
                                          <span className="text-danger">
                                            {errors.negativeMraks}
                                          </span>
                                        )}
                                      </div>

                                    </div>
                                    <div className={questionPickingOptions.length > 0 && questionPickingOptions[0].fieldType === "RadioButton" ? "col-md-6  mb-3 " : "col-md-6  mb-3"}>
                                      <label
                                        for="inputState"
                                        class="form-label"
                                      >
                                        Question Picking<span className="text-danger"> *</span>
                                      </label>

                                      <div className={questionPickingOptions.length > 0 && questionPickingOptions[0].fieldType === "RadioButton" ? "form-control" : ""} style={{ display: questionPickingOptions.length > 0 && questionPickingOptions[0].fieldType === "RadioButton" ? "flex" : "block" }}>

                                        {questionPickingOptions.length > 0 && questionPickingOptions[0].fieldType === "RadioButton" ? (
                                          <>
                                            {questionPickingOptions.map((v, i) => (
                                              <div className={i === 0 ? "form-check parent" : "form-check"} key={i}>
                                                <input
                                                  type="radio"
                                                  className="form-check-input"
                                                  id={"questionPicking"}
                                                  name={'questionPicking'}
                                                  value={v.parameterValueID}
                                                  onChange={handleInputChange}
                                                  checked={v.parameterValueID == questionPicking}
                                                />
                                                <label className="form-check-label" name={"questionPicking" + v.parameterValueID}>
                                                  {v.value}
                                                </label>
                                              </div>
                                            ))}
                                          </>
                                        ) : (questionPickingOptions.length > 0 && questionPickingOptions[0].fieldType === "DropDown" ? (
                                          <>
                                            <select
                                              id="questionPicking"
                                              class="form-select form-control"
                                              name="questionPicking"
                                              onChange={handleInputChange}
                                              value={questionPicking}
                                            >
                                              <option value="">
                                                Select Question Picking
                                              </option>
                                              {questionPickingOptions.map(
                                                (option) => (
                                                  <option
                                                    key={option.parameterValueID}
                                                    value={option.parameterValueID}
                                                  >
                                                    {option.value}
                                                  </option>
                                                )
                                              )}
                                            </select>
                                          </>
                                        ) : <></>)}









                                        {errors.questionPicking && (
                                          <span className="text-danger">
                                            {errors.questionPicking}
                                          </span>
                                        )}
                                      </div>

                                    </div>
                                    <div className="col-md-6 mb-3">
                                      <label
                                        for="inputState"
                                        class="form-label"
                                      >
                                        Submit the exam before end of duration<span className="text-danger"> *</span>
                                      </label>

                                      <div style={{ display: "flex" }} className="form-control">



                                        <div className="form-check parent" >
                                          <input
                                            type="radio"
                                            className="form-check-input"
                                            id={"submitBeforeTme"}
                                            name={'submitBeforeTme'}
                                            value={1}


                                            onChange={handleInputChange}
                                            checked={1 == submitBeforeTme}
                                          />
                                          <label className="form-check-label">
                                            Yes
                                          </label>
                                        </div>
                                        <div className="form-check ">
                                          <input
                                            type="radio"
                                            className="form-check-input"
                                            id={"submitBeforeTme"}
                                            name={'submitBeforeTme'}
                                            value={0}


                                            onChange={handleInputChange}
                                            checked={0 == submitBeforeTme}
                                          />
                                          <label className="form-check-label">
                                            No
                                          </label>
                                        </div>




                                        {errors.submitBefortime && (
                                          <span className="text-danger">
                                            {errors.submitBefortime}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                      <label
                                        for="inputState"
                                        class="form-label"
                                      >
                                        Exam Availability<span className="text-danger"> *</span>
                                      </label>

                                      <div style={{ display: "flex" }} className="form-control">
                                        {examAvailabilityOptions.map((v, i) => {


                                          return (
                                            <div className={i == 0 ? "form-check parent" : "form-check"} key={i} >
                                              <input
                                                type="radio"
                                                className="form-check-input "
                                                id={"examAvailability"}
                                                name={'examAvailability'}
                                                value={v.parameterValueID}
                                                onChange={examAvailabilityChange}
                                                checked={v.parameterValueID == examAvailability}
                                              />
                                              <label className="form-check-label">
                                                {v.value}
                                              </label>
                                            </div>
                                          );

                                          <></>
                                        })}



                                        {errors.examAvailabilityID && (
                                          <span className="text-danger">
                                            {errors.examAvailabilityID}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    <div
                                      className="examStartEndTimeCardDiv"
                                      style={{
                                        display: examStartEndTimeCardDiv
                                          ? "flex"
                                          : "none",
                                      }}
                                    >
                                      <div className="examStartEndTimeCard">
                                        <div className=" row">
                                          <div className="col-md-6">
                                            <label
                                              htmlFor="formGroupExampleInput"
                                              className="form-label"
                                            >
                                              Start Date & Time<span className="text-danger"> *</span>
                                            </label>
                                            <div className=" row">
                                              <div className="col-md-6">
                                                <div className="dateTimePickerDiv">

                                                  <DatePicker
                                                    selected={startDateandTime}
                                                    onChange={(date) => handleDateTimeChange(date, startTime, 'start')}
                                                    dateFormat="dd-MM-yyyy"
                                                    showYearDropdown
                                                    scrollableYearDropdown
                                                    yearDropdownItemNumber={60}
                                                    showMonthDropdown
                                                    scrollableMonthDropdown
                                                    monthDropdownItemNumber={12}
                                                    className="form-control"
                                                    placeholderText="DD-MM-YYYY"
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-md-6">
                                                <div class="cs-form">
                                                  <input type="time" className="form-control" id="timeInput" value={startTime != "" ? startTime : null} onChange={(e) => handleDateTimeChange(startDateandTime, e.target.value, 'start')} />
                                                </div>
                                              </div>
                                            </div>

                                            {/* <DateTimePicker
                                                name="startDateTime"
                                                onChange={handleStartDateTime}
                                                value={startDateandTime}
                                                format="dd/MM/yyyy HH:mm" // Set your desired date and time format here
                                                showTimeSelect
                                              /> */}

                                            {errors.startTime && (
                                              <span className="text-danger">
                                                {errors.startTime}
                                              </span>
                                            )}
                                          </div>
                                          <div className="col-md-6">
                                            <label
                                              htmlFor="formGroupExampleInput"
                                              className="form-label"
                                            >
                                              End Date & Time<span className="text-danger"> *</span>
                                            </label>
                                            <div className=" row">
                                              <div className="col-md-6">
                                                <div className="dateTimePickerDiv">
                                                  <DatePicker
                                                    selected={endDateandTime}
                                                    onChange={(date) => handleDateTimeChange(date, endTime, 'end')}

                                                    dateFormat="dd-MM-yyyy"
                                                    showYearDropdown
                                                    scrollableYearDropdown
                                                    yearDropdownItemNumber={60}
                                                    showMonthDropdown
                                                    scrollableMonthDropdown
                                                    monthDropdownItemNumber={12}
                                                    className="form-control"
                                                    placeholderText="DD-MM-YYYY"
                                                    name="endDateTime"
                                                  />
                                                  {/* <DateTimePicker
                                                name="endDateTime"
                                                onChange={handleEndDateTime}
                                                value={endDateandTime}
                                                format="dd/MM/yyyy HH:mm" // Set your desired date and time format here
                                                showTimeSelect
                                              /> */}
                                                </div>
                                              </div>
                                              <div className="col-md-6">
                                                <div class="cs-form">
                                                  <input type="time" className="form-control" value={endTime != "" ? endTime : null} id="timeInput" onChange={(e) => handleDateTimeChange(endDateandTime, e.target.value, 'end')}
                                                  />
                                                </div>
                                              </div>
                                            </div>

                                          </div>

                                        </div>
                                      </div>
                                    </div>
                                    <div className={examAssignmentTypeOptions.length > 0 && examAssignmentTypeOptions[0].fieldType === "RadioButton" ? "col-md-12  mb-3 " : "col-md-12  mb-3"}>
                                      <label
                                        for="inputState"
                                        class="form-label"
                                      >
                                        Assign this exam to<span className="text-danger"> *</span>
                                      </label>

                                      <div className={examAssignmentTypeOptions.length > 0 && examAssignmentTypeOptions[0].fieldType === "RadioButton" ? "form-control" : ""} style={{ display: examAssignmentTypeOptions.length > 0 && examAssignmentTypeOptions[0].fieldType === "RadioButton" ? "flex" : "block" }}>

                                        {examAssignmentTypeOptions.length > 0 && examAssignmentTypeOptions[0].fieldType === "RadioButton" ? (
                                          <>
                                            {examAssignmentTypeOptions.map((v, i) => (
                                              <div className={i == 0 ? "form-check parent" : "form-check"} key={i}>
                                                <input
                                                  type="radio"
                                                  className="form-check-input"
                                                  id={"examAssignedType"}
                                                  name={'examAssignedType'}
                                                  value={v.parameterValueID}
                                                  onChange={handleInputChange}
                                                  checked={v.parameterValueID == examAssignedType}
                                                />
                                                <label className="form-check-label">
                                                  {v.value}
                                                </label>
                                              </div>
                                            ))}
                                          </>
                                        ) : (examAssignmentTypeOptions.length > 0 && examAssignmentTypeOptions[0].fieldType === "DropDown" ? (
                                          <>
                                            <select
                                              id="examAssignedType"

                                              class="form-select form-control margin-top-20"
                                              name="examAssignedType"
                                              value={examAssignedType}
                                              onChange={handleInputChange}
                                            >
                                              <option value="">
                                                Select Exam Assigned Type
                                              </option>
                                              {examAssignmentTypeOptions.map(
                                                (option) => (
                                                  <option
                                                    key={option.parameterValueID}
                                                    value={option.parameterValueID}
                                                  >
                                                    {option.value}
                                                  </option>
                                                )
                                              )}
                                            </select>
                                          </>
                                        ) : <></>)}










                                        {errors.examAssignmenttypeID && (
                                          <span className="text-danger">
                                            {errors.examAssignmenttypeID}
                                          </span>
                                        )}
                                      </div>

                                    </div>

                                  </div>
                                </div>
                                {/* </div> */}
                              </div>
                            </div>

                            {/* End Form Layouts Area */}
                          </div>

                        </div>
                      </div>
                      <div className="accordion-item" style={{ display: manualPick ? "block" : "none" }}>
                        <h2 className="accordion-header" id="headingSix">
                          <button

                            className={open ? 'accordion-button' : 'accordion-button collapsed'}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseSix"
                            aria-expanded="false"
                            aria-controls="collapseSix"
                          >
                            Specify Number of Questions.(From Your Question Bank)
                          </button>
                        </h2>
                        <div
                          id="collapseSix"
                          className={open ? ' accordion-collapse collapse show' : 'accordion-collapse collapse'}
                          aria-labelledby="headingSix"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            {/* <div
                          className="card-box-style"> */}
                            <div className="others-title col-12 row">
                              <h3 className="col-6 mt-3" >Question List</h3>
                              <a
                                className="col-6  text-end btn btn-link "
                                onClick={() => sectionCreateManual(specifyselectsectionList.length)}
                              >
                                <i className=" fa fa-plus mt-3"></i>
                              </a>
                            </div>
                            <table className="table align-middle mb-0">
                              <thead>
                                <tr >
                                  <th>
                                    S.No
                                  </th>
                                  <th>
                                    Picked Question
                                  </th>
                                  {/* <th>
                                No of Questions
                              </th> */}
                                  <th>
                                    Sections
                                  </th>
                                  <th>
                                    Total Questions
                                  </th>
                                  <th>
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {specifyselectsectionList != null &&
                                  specifyselectsectionList.length > 0 ? (
                                  specifyselectsectionList.map((v, i) => {
                                    let matchingSection;
                                    if (sectionList.length > 0) {
                                      matchingSection = sectionList.filter(
                                        (section) =>
                                          section.sectionId === specifyselectsectionList[i].sectionId
                                      );
                                    } else {
                                      matchingSection = 0;
                                    }

                                    return (
                                      <tr key={i} id={i +
                                        'Manual'}>
                                        {" "}
                                        <td className="col-md-1">
                                          {" "}
                                          {i + 1}

                                        </td>
                                        <td className="col-md-1">
                                          {matchingSection.length > 0 ? <>  <span class="questioncount">{matchingSection && matchingSection[0].questionCount}</span>

                                            <span class="selectcount pickok"><span class="selectqcount">{specifyselectsectionList[i].SelectCount}</span> Picked</span></> : <></>}
                                        </td>
                                        <td className="col-md-4">



                                          <select
                                            value={v.sectionId}
                                            id="sectionIdManual"
                                            class="form-select form-control"
                                            name="sectionIdManual"

                                            onChange={(event) => assignValuesToSectionModelManual(i, event)}
                                          >
                                            <option value="">
                                              Select Section
                                            </option>
                                            {sectionList.map((option) => (
                                              !specifyselectsectionList.filter((model) => model.sectionId === option.sectionId && model.isDeleted === 0).length > 0 || v.sectionId === option.sectionId ? (
                                                <option key={option.sectionId} value={option.sectionId}>
                                                  {option.sectionName + "(" + option.questionCount + ")"}
                                                  <span style={{ color: 'red', fontWeight: 'bold' }}>{" used in " + option.sectionUsageCount + " exams"}</span>

                                                </option>
                                              ) : (
                                                <></>
                                              )
                                            ))}
                                          </select>
                                        </td>
                                        <td className="col-md-4">

                                          <input
                                            value={v.noQuestion}
                                            type="text"
                                            class="form-control"
                                            name="noOfQuestionsManual"
                                            id="noOfQuestionsManual"
                                            ref={(el) => (inputRefs.current[i] = el)}
                                            onChange={(event) => assignValuesToSectionModelManual(i, event)}

                                            aria-describedby="basic-addon2"
                                            onFocus={() => setFocusedInputIndex(i)}
                                            onBlur={() => setFocusedInputIndex(-1)}
                                          />
                                        </td>
                                        {/* {sectionErrors[i] && sectionErrors[i].sectionID && (
                                        <span className="text-danger">
                                          {sectionErrors[i].sectionID}
                                        </span>
                                      )} */}
                                        {/* <td>
                                      {" "}
                                      {v.SelectCount}

                                    </td> */}
                                        {/* <td>
                                      {v.sectionName}


                                    </td> */}
                                        {/* <td>
                                      {`(${v.questionCount})`}
                                    </td> */}
                                        <td className="col-md-2" style={{ display: "flex", justifyContent: "space-between" }}>


                                          {v.noQuestion > 0 && v.noQuestion != "" && v.sectionId != "" && v.sectionId > 0

                                            ? (


                                              <>
                                                <Tooltip
                                                  title="Question Pick"
                                                >
                                                  <IconButton
                                                    type="button"
                                                    className="text-primary"
                                                    data-original-title="Edit"
                                                    data-toggle="modal"
                                                    data-target="#staticBackdrop"
                                                    // style={{ outlineColor: "green" }}
                                                    onClick={() => GetQuestions(v.sectionId)}
                                                  >
                                                    <FaLongArrowAltLeft className="edit-icon" />
                                                  </IconButton>
                                                </Tooltip>

                                                <Tooltip
                                                  title="Edit Section"
                                                >
                                                  <IconButton
                                                    type="button"
                                                    className="text-primary"
                                                    data-original-title="Edit"
                                                    data-toggle="modal"
                                                    data-target="#addRowModal"
                                                    onClick={() => handleIconClick(i)}
                                                  >
                                                    <FaEdit className="edit-icon" />
                                                  </IconButton>
                                                </Tooltip>

                                              </>) : (<></>)}
                                          <Tooltip
                                            title="Delete Section"
                                          >
                                            <IconButton
                                              className="text-danger"
                                              aria-label="Delete"
                                              onClick={() => deleteSectionManual(i, v.id, v.sectionId)}
                                            >
                                              <DeleteIcon />
                                            </IconButton>
                                          </Tooltip>

                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <></>
                                )}
                              </tbody>
                              <tfoot>
                                {manualTotal > 0 ? (
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td> <button type='button' class="btn btn-primary total-button">
                                      Total
                                      <span class="total-value">{manualTotal}</span>

                                    </button></td>
                                    <td></td>

                                  </tr>) : (<></>)}

                              </tfoot>
                            </table>
                            {/* </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item" style={{ display: autoPick ? "block" : "none" }}>
                        <h2 className="accordion-header" id="headingFour">
                          <button

                            className={open ? 'accordion-button' : 'accordion-button collapsed'}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFour"
                            aria-expanded="false"
                            aria-controls="collapseFour"
                          >
                            Question Bank
                          </button>
                        </h2>
                        <div
                          id="collapseFour"
                          className={open ? ' accordion-collapse collapse show' : 'accordion-collapse collapse'}
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body"> <div className="others-title col-12 row">
                            <h3 className="col-6 mt-3" >Question List</h3>
                            <a
                              className="col-6  text-end btn btn-link "
                              onClick={() => sectionCreate(sectionModelList.length)}
                            >
                              <i className=" fa fa-plus mt-3"></i>
                            </a>
                          </div>
                            {/* <div
                          className="card-box-style"> */}
                            <table className="table align-middle mb-0">
                              <thead>
                                <tr>
                                  <th>S.No</th>
                                  <th>Sections</th>

                                  <th>No of Questions</th>

                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {sectionModelList != null &&
                                  sectionModelList.length > 0 ? (
                                  sectionModelList.map((v, i) => {
                                    return (
                                      <tr key={i} id={i} >
                                        {" "}
                                        <td>{i + 1}</td>
                                        <td>
                                          {" "}
                                          <select
                                            value={v.sectionId}
                                            id="sectionId"
                                            class="form-select form-control"
                                            name="sectionId"

                                            onChange={(event) => assignValuesToSectionModel(i, event)}
                                          >
                                            <option value="">
                                              Select Section
                                            </option>
                                            {sectionList.map((option) => (
                                              !sectionModelList.filter((model) => model.sectionId === option.sectionId && model.isDeleted === 0).length > 0 || v.sectionId === option.sectionId ? (
                                                <option key={option.sectionId} value={option.sectionId}>
                                                  {option.sectionName + "(" + option.questionCount + ")"}
                                                  <span style={{ color: 'red', fontWeight: 'bold' }}>{" used in " + option.sectionUsageCount + " exams"}</span>

                                                </option>

                                              ) : (
                                                <></>
                                              )
                                            ))}
                                          </select>
                                          {sectionErrors[i] && sectionErrors[i].sectionID && (
                                            <span className="text-danger">
                                              {sectionErrors[i].sectionID}
                                            </span>
                                          )}

                                        </td>
                                        <td>

                                          <input
                                            value={v.noQuestion}
                                            type="text"
                                            class="form-control"
                                            name="noOfQuestions"
                                            id="noOfQuestions"
                                            onChange={(event) => assignValuesToSectionModel(i, event)}

                                            aria-describedby="basic-addon2"

                                          />
                                          {sectionErrors[i] && sectionErrors[i].noQuestion && (
                                            <span className="text-danger">
                                              {sectionErrors[i].noQuestion}
                                            </span>
                                          )}

                                        </td>
                                        <td>
                                          <Tooltip
                                            title="Delete Section"
                                          >
                                            <IconButton
                                              className="text-danger"
                                              aria-label="Delete"
                                              onClick={() => deleteSection(i, v.id)}
                                            >
                                              <DeleteIcon />
                                            </IconButton>
                                          </Tooltip>

                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <></>
                                )}
                              </tbody>
                              <tfoot>
                                {randomTotal > 0 ? (
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td> <button type='button' class="btn btn-primary total-button">
                                      Total
                                      <span class="total-value">{randomTotal}</span>
                                    </button></td>
                                    <td></td>

                                  </tr>) : (<></>)}

                              </tfoot>
                            </table>
                            {/* </div> */}
                          </div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                          <button
                            className={open ? 'accordion-button' : 'accordion-button collapsed'}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            Advance Options (Grading & Accessibility)
                          </button>
                        </h2>
                        <div
                          id="collapseTwo"
                          className={open ? ' accordion-collapse collapse show' : 'accordion-collapse collapse'}
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordionExample"
                          style={{}}
                        >
                          <div className="accordion-body">
                            {/* Start Form Layouts Area */}

                            <div className="form-layouts-area">
                              <div className="container-fluid">
                                {/* <div className="card-box-style"> */}
                                {/* <div className="others-title">
                     
                                </div> */}
                                <div>
                                  <div class="row">
                                    <div className="col-md-6  mb-3">
                                      <label
                                        for="inputState"
                                        class="form-label"
                                      >
                                        Allow Partial Marks <span className="text-danger"> *</span>
                                      </label>

                                      <div style={{ display: "flex" }} className="form-control">

                                        <div className="form-check parent" >
                                          <input
                                            type="radio"
                                            className="form-check-input"
                                            id="partialMarks"
                                            name="partialMarks"
                                            value={1}
                                            onChange={handleInputChange}
                                            checked={1 == partialMarks}
                                          />
                                          <label className="form-check-label">
                                            Enable
                                          </label>
                                        </div>
                                        <div className="form-check">
                                          <input
                                            type="radio"
                                            className="form-check-input"
                                            id="partialMarks"
                                            name="partialMarks"
                                            value={0}
                                            onChange={handleInputChange}
                                            checked={0 == partialMarks}
                                          />
                                          <label className="form-check-label">
                                            Disable
                                          </label>
                                        </div>



                                        {errors.partialMarks && (
                                          <span className="text-danger">
                                            {errors.partialMarks}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    <div className={showResultOptions.length > 0 && showResultOptions[0].fieldType === "RadioButton" ? "col-md-6  mb-3" : "col-md-6 mb-3"}>
                                      <label
                                        for="inputState"
                                        class="form-label"
                                      >
                                        Show Result in <span className="text-danger"> *</span>
                                      </label>

                                      <div className="form-control" style={{ display: showResultOptions.length > 0 && showResultOptions[0].fieldType === "RadioButton" ? "flex" : "block" }}>

                                        {showResultOptions.length > 0 && showResultOptions[0].fieldType === "RadioButton" ? (
                                          <>
                                            {showResultOptions.map((v, i) => (
                                              <div className={i == 0 ? "form-check parent" : "form-check"} key={i}>
                                                <input
                                                  type="radio"
                                                  className="form-check-input"
                                                  id={"showResult"}
                                                  name={'showResult'}
                                                  value={v.parameterValueID}
                                                  onChange={showResultChange}
                                                  checked={v.parameterValueID == showResult}
                                                />
                                                <label className="form-check-label">
                                                  {v.value}
                                                </label>
                                              </div>
                                            ))}
                                          </>
                                        ) : (showResultOptions.length > 0 && showResultOptions[0].fieldType === "DropDown" ? (
                                          <>
                                            <select
                                              id="showResult"
                                              name="showResult"
                                              class="form-select form-control"
                                              value={showResult}
                                              onChange={showResultChange}
                                            >
                                              {" "}
                                              <option value="">Select Result </option>
                                              {showResultOptions.map((option) => (
                                                <option
                                                  key={option.parameterValueID}
                                                  value={option.parameterValueID}
                                                >
                                                  {option.value}
                                                </option>
                                              ))}
                                            </select>
                                          </>
                                        ) : <></>)}







                                        {errors.showResult && (
                                          <span className="text-danger">
                                            {errors.showResult}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    <div className={resultTypeOptions.length > 0 && resultTypeOptions[0].fieldType === "RadioButton" ? "col-md-6  mb-3" : "col-md-6  mb-3"} style={{
                                      display: percentageCardDiv
                                        ? "block"
                                        : "none",
                                    }}>
                                      <label for="inputState" class="form-label">
                                        Result Type  <span className="text-danger"> *</span>
                                      </label>
                                      <div className="form-control" style={{ display: resultTypeOptions.length > 0 && resultTypeOptions[0].fieldType === "RadioButton" ? "flex" : "block" }}>

                                        {resultTypeOptions.length > 0 && resultTypeOptions[0].fieldType === "RadioButton" ? (
                                          <>
                                            {resultTypeOptions.map((v, i) => (
                                              <div className={i == 0 ? "form-check parent" : "form-check"} key={i}>
                                                <input
                                                  type="radio"
                                                  className="form-check-input"
                                                  id={"resultType"}
                                                  name={'resultType'}
                                                  value={v.parameterValueID}
                                                  onChange={handleInputChange}
                                                  disabled={v.parameterValueID !== 14 ? true : false}
                                                  checked={v.parameterValueID == resultType}
                                                />
                                                <label className="form-check-label">
                                                  {v.value}
                                                </label>
                                              </div>
                                            ))}
                                          </>
                                        ) : (resultTypeOptions.length > 0 && resultTypeOptions[0].fieldType === "DropDown" ? (
                                          <>
                                            <select
                                              id="resultType"
                                              name="resultType"
                                              value={resultType}
                                              class="form-select form-control"
                                              onChange={handleInputChange}
                                            >
                                              {" "}
                                              <option value="">
                                                Select Result Type
                                              </option>
                                              {resultTypeOptions.map((option) => (
                                                <option
                                                  key={option.parameterValueID}
                                                  value={option.parameterValueID}
                                                >
                                                  {option.value}
                                                </option>
                                              ))}
                                            </select>
                                          </>
                                        ) : <></>)}










                                        {errors.resultType && (
                                          <span className="text-danger">
                                            {errors.resultType}
                                          </span>
                                        )}
                                      </div>
                                    </div>


                                    <div className={resultTypeOptions.length > 0 && resultTypeOptions[0].fieldType === "RadioButton" ? "col-md-6  mb-3" : "col-md-6  mb-3"} style={{
                                      display: percentageCardDiv
                                        ? "block"
                                        : "none",
                                    }}>
                                      <label
                                        for="inputState"
                                        class="form-label"
                                      >
                                        Minimum required score  (Enter Number to Percentage (%))
                                        <span className="text-danger"> *</span>
                                      </label>
                                      <div class="input-group mb-3 ">
                                        <input
                                          type="text"
                                          class="form-control"
                                          name="scorePercentage"
                                          id="scorePercentage"

                                          value={scorePercentage}
                                          aria-describedby="basic-addon2"
                                          onChange={handleInputChange}

                                        />
                                      </div>

                                    </div>

                                    {/* <div className={chooseModeOptions.length > 0 && chooseModeOptions[0].fieldType === "RadioButton" ? "col-md-6  mb-3" : "col-md-6  mb-3"}
                                >
                                  <label for="inputState" class="form-label">
                                    Choose Mode    <span className="text-danger"> *</span>
                                  </label>
                                  <div className="form-control" style={{ display: chooseModeOptions.length > 0 && chooseModeOptions[0].fieldType === "RadioButton" ? "flex" : "block" }}>

                                    {chooseModeOptions.length > 0 && chooseModeOptions[0].fieldType === "RadioButton" ? (
                                      <>
                                        {chooseModeOptions.map((v, i) => (
                                          <div className={i == 0 ? "form-check parent" : "form-check"} key={i}>
                                            <input
                                              type="radio"
                                              className="form-check-input"
                                              id={"chooseMode"}
                                              name={'chooseMode'}
                                              value={v.parameterValueID}
                                              onChange={handleInputChange}
                                              checked={v.parameterValueID == chooseMode}
                                            />
                                            <label className="form-check-label">
                                              {v.value}
                                            </label>
                                          </div>
                                        ))}
                                      </>
                                    ) : (chooseModeOptions.length > 0 && chooseModeOptions[0].fieldType === "DropDown" ? (
                                      <>
                                        <select
                                          id="chooseMode"
                                          class="form-select form-control"
                                          name="chooseMode"
                                          value={chooseMode}
                                          onChange={handleInputChange}
                                        >
                                          <option value="">
                                            Select Choose Mode
                                          </option>
                                          {chooseModeOptions.map((option) => (
                                            <option
                                              key={option.parameterValueID}
                                              value={option.parameterValueID}
                                            >
                                              {option.value}
                                            </option>
                                          ))}
                                        </select>
                                      </>
                                    ) : <></>)}










                                    {errors.chooseMode && (
                                      <span className="text-danger">
                                        {errors.chooseMode}
                                      </span>
                                    )}
                                  </div>
                                </div> */}
                                    <div className="col-md-6 mb-3">
                                      <label
                                        for="inputState"
                                        class="form-label"
                                      >
                                        Question Navigation during the exam<span className="text-danger"> *</span>
                                      </label>

                                      <div className="form-control" style={{ display: "flex" }}>



                                        <div className="form-check parent" >
                                          <input
                                            type="radio"
                                            className="form-check-input"
                                            id="questionNavigation"

                                            name="questionNavigation"

                                            onChange={handleInputChange}
                                            value={1}



                                            checked={1 == questionNavigation}
                                          />
                                          <label className="form-check-label">
                                            Allow going back
                                          </label>
                                        </div>
                                        <div className="form-check">
                                          <input
                                            type="radio"
                                            className="form-check-input"
                                            id="questionNavigation"

                                            value={0}


                                            name="questionNavigation"

                                            onChange={handleInputChange}
                                            checked={0 == questionNavigation}
                                          />
                                          <label className="form-check-label">
                                            Dont allow to go back
                                          </label>
                                        </div>




                                        {errors.questionNavigation && (
                                          <span className="text-danger">
                                            {errors.questionNavigation}
                                          </span>
                                        )}
                                      </div>
                                    </div>

                                    <div className={displayResultOptions.length > 0 && displayResultOptions[0].fieldType === "RadioButton" ? "col-md-6  mb-3" : "col-md-6  mb-3"}
                                    >
                                      <label for="inputState" class="form-label">
                                        Display Results    <span className="text-danger"> *</span>
                                      </label>
                                      <div className="form-control" style={{ display: displayResultOptions.length > 0 && displayResultOptions[0].fieldType === "RadioButton" ? "flex" : "block" }}>

                                        {displayResultOptions.length > 0 && displayResultOptions[0].fieldType === "RadioButton" ? (
                                          <>
                                            {displayResultOptions.map((v, i) => (
                                              <div className={i == 0 ? "form-check parent" : "form-check"} key={i}>
                                                <input
                                                  type="radio"
                                                  className="form-check-input"
                                                  id="displayResults"
                                                  name="displayResults"
                                                  value={v.parameterValueID}
                                                  onChange={handleInputChange}
                                                  checked={v.parameterValueID == displayResults}
                                                />
                                                <label className="form-check-label">
                                                  {v.value}
                                                </label>
                                              </div>
                                            ))}
                                          </>
                                        ) : (displayResultOptions.length > 0 && displayResultOptions[0].fieldType === "DropDown" ? (
                                          <>
                                            <select
                                              id="displayResults"
                                              class="form-select form-control"
                                              name="displayResults"
                                              onChange={handleInputChange}
                                              value={displayResults}
                                            >
                                              <option value="">
                                                Select Display Result Type
                                              </option>
                                              {displayResultOptions.map((option) => (
                                                <option
                                                  key={option.parameterValueID}
                                                  value={option.parameterValueID}
                                                >
                                                  {option.value}
                                                </option>
                                              ))}
                                            </select>
                                          </>
                                        ) : <></>)}










                                        {errors.displayResults && (
                                          <span className="text-danger">
                                            {errors.displayResults}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    <div className={questionOrderOptions.length > 0 && questionOrderOptions[0].fieldType === "RadioButton" ? "col-md-6  mb-3" : "col-md-6  mb-3"}
                                    >
                                      <label for="inputState" class="form-label">
                                        Question Order     <span className="text-danger"> *</span>
                                      </label>
                                      <div className="form-control" style={{ display: questionOrderOptions.length > 0 && questionOrderOptions[0].fieldType === "RadioButton" ? "flex" : "block" }}>

                                        {questionOrderOptions.length > 0 && questionOrderOptions[0].fieldType === "RadioButton" ? (
                                          <>
                                            {questionOrderOptions.map((v, i) => (
                                              <div className={i == 0 ? "form-check parent" : "form-check"} key={i}>
                                                <input
                                                  type="radio"
                                                  className="form-check-input"
                                                  id="questionOrder"
                                                  name="questionOrder"
                                                  value={v.parameterValueID}
                                                  onChange={handleInputChange}
                                                  checked={v.parameterValueID == questionOrder}
                                                />
                                                <label className="form-check-label">
                                                  {v.value}
                                                </label>
                                              </div>
                                            ))}
                                          </>
                                        ) : (questionOrderOptions.length > 0 && questionOrderOptions[0].fieldType === "DropDown" ? (
                                          <>
                                            <select
                                              id="questionOrder"
                                              class="form-select form-control"
                                              name="questionOrder"
                                              onChange={handleInputChange}
                                              value={questionOrder}
                                            >
                                              <option value="">
                                                Select Question Order{" "}
                                              </option>
                                              {questionOrderOptions.map((option) => (
                                                <option
                                                  key={option.parameterValueID}
                                                  value={option.parameterValueID}
                                                >
                                                  {option.value}
                                                </option>
                                              ))}
                                            </select>
                                          </>
                                        ) : <></>)}










                                        {errors.questionOrder && (
                                          <span className="text-danger">
                                            {errors.questionOrder}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                      <label
                                        for="inputState"
                                        class="form-label"
                                      >
                                        Text Highlighter <span className="text-danger"> *</span>
                                      </label>

                                      <div className="form-control" style={{ display: "flex" }}>



                                        <div className="form-check parent" >
                                          <input
                                            type="radio"
                                            className="form-check-input"
                                            id="textHighlighter"
                                            name="textHighlighter"
                                            onChange={handleInputChange}
                                            value={1}



                                            checked={1 == textHighlighter}
                                          />
                                          <label className="form-check-label">
                                            Enable
                                          </label>
                                        </div>
                                        <div className="form-check">
                                          <input
                                            type="radio"
                                            className="form-check-input"
                                            id="textHighlighter"
                                            name="textHighlighter"
                                            onChange={handleInputChange}

                                            value={0}



                                            checked={0 == textHighlighter}
                                          />
                                          <label className="form-check-label">
                                            Disable
                                          </label>
                                        </div>




                                        {errors.textHighlighter && (
                                          <span className="text-danger">
                                            {errors.textHighlighter}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    <div className={questionDisplayOptions.length > 0 && questionDisplayOptions[0].fieldType === "RadioButton" ? "col-md-6  mb-3" : "col-md-6  mb-3"}
                                    >
                                      <label for="inputState" class="form-label">
                                        Question Display      <span className="text-danger"> *</span>
                                      </label>
                                      <div className="form-control" style={{ display: questionDisplayOptions.length > 0 && questionDisplayOptions[0].fieldType === "RadioButton" ? "flex" : "block" }}>

                                        {questionDisplayOptions.length > 0 && questionDisplayOptions[0].fieldType === "RadioButton" ? (
                                          <>
                                            {questionDisplayOptions.map((v, i) => (
                                              <div className={i == 0 ? "form-check parent" : "form-check"} key={i}>
                                                <input
                                                  type="radio"
                                                  className="form-check-input"
                                                  id="questionDisplay"
                                                  name="questionDisplay"
                                                  value={v.parameterValueID}
                                                  onChange={handleInputChange}
                                                  checked={v.parameterValueID == questionDisplay}
                                                />
                                                <label className="form-check-label">
                                                  {v.value}
                                                </label>
                                              </div>
                                            ))}
                                          </>
                                        ) : (questionDisplayOptions.length > 0 && questionDisplayOptions[0].fieldType === "DropDown" ? (
                                          <>
                                            <select
                                              id="questionDisplay"
                                              class="form-select form-control"
                                              name="questionDisplay"
                                              onChange={handleInputChange}
                                              value={questionDisplay}
                                            >
                                              <option value="">
                                                Select Question Display Option
                                              </option>
                                              {questionDisplayOptions.map((option) => (
                                                <option
                                                  key={option.parameterValueID}
                                                  value={option.parameterValueID}
                                                >
                                                  {option.value}
                                                </option>
                                              ))}
                                            </select>
                                          </>
                                        ) : <></>)}










                                        {errors.questionDisplay && (
                                          <span className="text-danger">
                                            {errors.questionDisplay}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    <div className={examScreenTypeOptions.length > 0 && examScreenTypeOptions[0].fieldType === "RadioButton" ? "col-md-6  mb-3" : "col-md-6  mb-3"}
                                    >
                                      <label for="inputState" class="form-label">
                                        Exam Screen Type     <span className="text-danger"> *</span>
                                      </label>
                                      <div className="form-control" style={{ display: examScreenTypeOptions.length > 0 && examScreenTypeOptions[0].fieldType === "RadioButton" ? "flex" : "block" }}>

                                        {examScreenTypeOptions.length > 0 && examScreenTypeOptions[0].fieldType === "RadioButton" ? (
                                          <>
                                            {examScreenTypeOptions.map((v, i) => (
                                              <div className={i == 0 ? "form-check parent" : "form-check"} key={i}>
                                                <input
                                                  type="radio"
                                                  className="form-check-input"
                                                  id="examScreenType"
                                                  name="examScreenType"
                                                  value={v.parameterValueID}
                                                  onChange={handleInputChange}
                                                  checked={v.parameterValueID == examScreenType}
                                                />
                                                <label className="form-check-label">
                                                  {v.value}
                                                </label>
                                              </div>
                                            ))}
                                          </>
                                        ) : (examScreenTypeOptions.length > 0 && examScreenTypeOptions[0].fieldType === "DropDown" ? (
                                          <>
                                            <select
                                              id="examScreenType"
                                              class="form-select form-control"
                                              name="examScreenType"
                                              value={examScreenType}
                                              onChange={handleInputChange}
                                            >
                                              <option value="">
                                                Select Exam Screen Type{" "}
                                              </option>
                                              {examScreenTypeOptions.map((option) => (
                                                <option
                                                  key={option.parameterValueID}
                                                  value={option.parameterValueID}
                                                >
                                                  {option.value}
                                                </option>
                                              ))}
                                            </select>

                                          </>
                                        ) : <></>)}










                                        {errors.examScreenType && (
                                          <span className="text-danger">
                                            {errors.examScreenType}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                      <label
                                        for="inputState"
                                        class="form-label"
                                      >
                                        Mandatory to attend all questions <span className="text-danger"> *</span>
                                      </label>

                                      <div className="form-control" style={{ display: "flex" }}>



                                        <div className="form-check parent" >
                                          <input
                                            type="radio"
                                            className="form-check-input"
                                            id="attendAllQuestions"

                                            name="attendAllQuestions"

                                            onChange={handleInputChange}
                                            value={1}



                                            checked={1 == attendAllQuestions}
                                          />
                                          <label className="form-check-label">
                                            Required
                                          </label>
                                        </div>
                                        <div className="form-check">
                                          <input
                                            type="radio"
                                            className="form-check-input"
                                            id="attendAllQuestions"

                                            value={0}

                                            name="attendAllQuestions"

                                            onChange={handleInputChange}
                                            checked={0 == attendAllQuestions}
                                          />
                                          <label className="form-check-label">
                                            Not Required
                                          </label>
                                        </div>




                                        {errors.attendAllQuestions && (
                                          <span className="text-danger">
                                            {errors.attendAllQuestions}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                      <label
                                        for="inputState"
                                        class="form-label"
                                      >
                                        Allow to download Question Paper<span className="text-danger"> *</span>
                                      </label>

                                      <div className="form-control" style={{ display: "flex" }}>



                                        <div className="form-check parent" >
                                          <input
                                            type="radio"
                                            className="form-check-input"
                                            id="downloadQuestionPaper"

                                            name="downloadQuestionPaper"

                                            onChange={handleInputChange}
                                            value={1}



                                            checked={1 == downloadQuestionPaper}
                                          />
                                          <label className="form-check-label">
                                            Yes
                                          </label>
                                        </div>
                                        <div className="form-check" >
                                          <input
                                            type="radio"
                                            className="form-check-input"
                                            id="downloadQuestionPaper"

                                            name="downloadQuestionPaper"

                                            onChange={handleInputChange}
                                            value={0}



                                            checked={0 == downloadQuestionPaper}
                                          />
                                          <label className="form-check-label">
                                            No
                                          </label>
                                        </div>




                                        {errors.downloadQuestionPaper && (
                                          <span className="text-danger">
                                            {errors.downloadQuestionPaper}
                                          </span>
                                        )}
                                      </div>
                                    </div>

                                    <div className="col-md-6 mb-3">
                                      <label
                                        for="inputState"
                                        class="form-label"
                                      >
                                        Final Answer Show <span className="text-danger"> *</span>
                                      </label>

                                      <div className="form-control" style={{ display: "flex" }}>



                                        <div className="form-check parent" >
                                          <input
                                            type="radio"
                                            className="form-check-input"
                                            id="finalAnswerShow"

                                            name="finalAnswerShow"

                                            onChange={handleInputChange}
                                            value={1}



                                            checked={1 == finalAnswerShow}
                                          />
                                          <label className="form-check-label">
                                            Yes
                                          </label>
                                        </div>
                                        <div className="form-check" >
                                          <input
                                            type="radio"
                                            className="form-check-input"
                                            id="finalAnswerShow"

                                            name="finalAnswerShow"

                                            onChange={handleInputChange}
                                            value={0}



                                            checked={0 == finalAnswerShow}
                                          />
                                          <label className="form-check-label">
                                            No
                                          </label>
                                        </div>



                                        {errors.finalAnswerShow && (
                                          <span className="text-danger">
                                            {errors.finalAnswerShow}
                                          </span>
                                        )}

                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* </div> */}
                              </div>
                            </div>
                            {/* End Form Layouts Area */}
                          </div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                          <button
                            className={open ? 'accordion-button' : 'accordion-button collapsed'}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            Exam Security
                          </button>
                        </h2>
                        <div
                          id="collapseThree"
                          className={open ? ' accordion-collapse collapse show' : 'accordion-collapse collapse'}
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            {/* Start Form Layouts Area */}

                            <div className="form-layouts-area">
                              <div className="container-fluid">
                                {/* <div className="card-box-style"> */}
                                <div>

                                  <div className="row">
                                    <div className="col-md-6 mb-3">
                                      <label
                                        for="inputState"
                                        class="form-label"
                                      >
                                        Terminate the exam when unusual
                                        behaviour found<span className="text-danger"> *</span>
                                      </label>

                                      <div className="form-control" style={{ display: "flex" }}>



                                        <div className="form-check parent" >
                                          <input
                                            type="radio"
                                            className="form-check-input"
                                            id="unUsualBehaviourOptions"

                                            name="unUsualBehaviourOptions"

                                            onChange={unUsualBehaviourChange}
                                            value={1}



                                            checked={1 == unUsualBehaviour}
                                          />
                                          <label className="form-check-label">
                                            Enable
                                          </label>
                                        </div>

                                        <div className="form-check" >
                                          <input
                                            type="radio"
                                            className="form-check-input"
                                            id="unUsualBehaviourOptions"

                                            name="unUsualBehaviourOptions"

                                            onChange={unUsualBehaviourChange}
                                            value={0}



                                            checked={0 == unUsualBehaviour}
                                          />
                                          <label className="form-check-label">
                                            Disable
                                          </label>
                                        </div>


                                        {errors.unUsualBehaviourOptions && (
                                          <span className="text-danger">
                                            {errors.unUsualBehaviourOptions}
                                          </span>
                                        )}

                                      </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                      <label
                                        for="inputState"
                                        class="form-label"
                                      >

                                        Save as my default settings  <span className="text-danger"> *</span>
                                      </label>

                                      <div className="form-control" style={{ display: "flex" }}>



                                        <div className="form-check parent" >
                                          <input
                                            type="radio"
                                            className="form-check-input"
                                            id="defaultSetting"

                                            name="defaultSetting"

                                            onChange={handleInputChange}
                                            value={1}



                                            checked={1 == defaultSetting}
                                          />
                                          <label className="form-check-label">
                                            Yes
                                          </label>
                                        </div>
                                        <div className="form-check" >
                                          <input
                                            type="radio"
                                            className="form-check-input"
                                            id="defaultSetting"

                                            name="defaultSetting"

                                            onChange={handleInputChange}
                                            value={0}



                                            checked={0 == defaultSetting}
                                          />
                                          <label className="form-check-label">
                                            No
                                          </label>
                                        </div>


                                        {errors.defaultSetting && (
                                          <span className="text-danger">
                                            {errors.defaultSetting}
                                          </span>
                                        )}
                                      </div>
                                    </div>

                                    <div className="col-md-12 mb-3">
                                      <label
                                        for="inputState"
                                        class="form-label"
                                      >

                                        Rejoin
                                      </label>

                                      <div className="form-control" style={{ display: "flex" }}>



                                        <div className="form-check parent" >
                                          <input
                                            type="radio"
                                            className="form-check-input"
                                            id="examRetake"

                                            name="examRetake"

                                            onChange={handleInputChange}
                                            value={0}



                                            checked={0 == Retake}
                                          />
                                          <label className="form-check-label">
                                            Do not allow rejoin
                                          </label>
                                        </div>
                                        <div className="form-check" >
                                          <input
                                            type="radio"
                                            className="form-check-input"
                                            id="examRetake"

                                            name="examRetake"

                                            onChange={handleInputChange}
                                            checked={1 == Retake}


                                            value={1}
                                          />
                                          <label className="form-check-label">
                                            Allow rejoin (Times) <input
                                              type="text"
                                              class="form-control"
                                              name="examRetakeCount1"
                                              id="examRetakeCount1"
                                              onChange={handleInputChange}
                                              value={withoutConditionRetakeCount}

                                              aria-describedby="basic-addon2"


                                            />                   in all condition                    </label>
                                        </div>

                                        <div className="form-check" >
                                          <input
                                            type="radio"
                                            className="form-check-input"
                                            id="examRetake"

                                            name="examRetake"

                                            onChange={handleInputChange}
                                            value={2}



                                            checked={2 == Retake}
                                          />
                                          <label className="form-check-label">
                                            Allow rejoin  (Times)<input
                                              type="text"
                                              class="form-control"
                                              name="examRetakeCount2"
                                              id="examRetakeCount2"
                                              onChange={handleInputChange}
                                              value={withConditionRetakeCount}

                                              aria-describedby="basic-addon2"


                                            />                   if the result is less than   (%)  <input
                                              type="text"
                                              class="form-control"
                                              name="examRetakePercentage"
                                              id="examRetakePercentage" onChange={handleInputChange} value={RetakePercentage}

                                              aria-describedby="basic-addon2"


                                            />                         </label>
                                        </div>

                                      </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                      <label
                                        for="inputState"
                                        class="form-label"
                                      >

                                        No of Attempts<span className="text-danger"> *</span>
                                      </label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        name="examAttempt"
                                        id="examAttempt"
                                        onChange={handleInputChange}
                                        value={examAttempt}
                                        maxLength={2}
                                        aria-describedby="basic-addon2"


                                      />

                                    </div>

                                    <div className="col-md-6 mb-3">
                                      <label
                                        for="inputState"
                                        class="form-label"
                                      >
                                        Question Visible Percentage<span className="text-danger">*</span>
                                      </label>
                                      <select
                                        className="form-control"
                                        name="visible"
                                        id="visible"
                                        onChange={handleInputChange}
                                        value={visible}
                                      >
                                        <option value="0">Select an option</option>
                                        {visibleList.map(option => (
                                          <option key={option.parameterValueID} value={option.parameterValueID}>
                                            {option.value}
                                          </option>
                                        ))}
                                      </select>

                                    </div>
                                    {/* <div className="col-md-6 mb-3">
  <label
    for="inputState"
    class="form-label"
  >

  Allow live chat with candidates <span className="text-danger"> *</span> 
  </label>
  
  <div style={{ display:  "flex" }}>          
  
  
  
  <div className="form-check" >
  <input
  type="radio"
  className="form-check-input"
  id="liveChat"
  
  name="liveChat"
     
  onChange={handleInputChange}
  value={1}
  
  
  
  checked={1 == liveChat}
  />
  <label htmlFor={'liveChat'} className="form-check-label">
    Enable
  </label>
  </div>
 
  <div className="form-check" >
    <input
    type="radio"
    className="form-check-input"
    id="liveChat"
    
    name="liveChat"
       
    onChange={handleInputChange}
    value={0}
    
    
    
    checked={0 == liveChat}
    />
    <label htmlFor={'liveChat'} className="form-check-label">
    Disable
    </label>
    </div>
  

  {errors.liveChat && (
    <span className="text-danger">
      {errors.liveChat}
    </span>
  )}
  </div>
  </div>    */}
                                  </div>


                                  <div
                                    className="unUsualBehaviourCardDiv"
                                    style={{
                                      display: unUsualBehaviourCardDiv
                                        ? "flex"
                                        : "none",
                                    }}
                                  >
                                    <div className="card-box-style">
                                      <div className="mb-3 row">
                                        <div className="col-md-12">
                                          <div className="text-start"><label
                                            for="inputState"
                                            class="form-label "
                                          >
                                            {" "}
                                            Terminate exam if unusual behaviour
                                            count is higher than
                                          </label></div>

                                          <select
                                            id="unUsualBehaviourCount"
                                            class="form-select form-control"
                                            name="unUsualBehaviourCount"
                                            value={unUsualBehaviourCount}
                                            onChange={unUsualBehaviourCountChange}
                                          >
                                            {unUsualBehaviourCountOptions.map(
                                              (option) => (
                                                <option
                                                  key={option.value}
                                                  value={option.value}
                                                >
                                                  {option.text}
                                                </option>
                                              )
                                            )}
                                          </select>
                                        </div>
                                        <div
                                          className="col-md-12">

                                          <div className="text-start"><label
                                            for="inputState"
                                            class="form-label mt-3 mb-3 " style={{ fontWeight: '500' }}
                                          >
                                            {" "}
                                            What is unusual behaviour?
                                          </label>
                                            <br></br>
                                            <label
                                              for="inputState"
                                              class="form-label mx-2"
                                            >
                                              {" "}
                                              <FaHandPointer color="black" size={10} style={{ transform: 'rotate(90deg)' }} /> Minimizing the browser
                                            </label>
                                            <br></br>
                                            <label
                                              for="inputState"
                                              class="form-label mx-2 "
                                            >
                                              {" "}
                                              <FaHandPointer color="black" size={10} style={{ transform: 'rotate(90deg)' }} /> Resizing the browser
                                            </label>
                                            <br></br>
                                            <label
                                              for="inputState"
                                              class="form-label mx-2"
                                            >
                                              {" "}
                                              <FaHandPointer color="black" size={10} style={{ transform: 'rotate(90deg)' }} /> Open a new tab
                                            </label>
                                            <br></br>
                                            <label
                                              for="inputState"
                                              class="form-label mx-2"
                                            >
                                              {" "}
                                              <FaHandPointer color="black" size={10} style={{ transform: 'rotate(90deg)' }} />  Open a new program
                                            </label>
                                            <br></br>
                                            <label
                                              for="inputState"
                                              class="form-label mx-2"
                                            >
                                              {" "}
                                              <FaHandPointer color="black" size={10} style={{ transform: 'rotate(90deg)' }} /> Taking a screenshot
                                            </label>
                                            <br></br>
                                            {" "}
                                            <label for="inputState"
                                              class="form-label mx-2 "
                                            >
                                              <FaHandPointer color="black" size={10} style={{ transform: 'rotate(90deg)' }} /> Pressing Ctrl+C
                                            </label>
                                            <br></br>
                                            <label
                                              for="inputState"
                                              class="form-label mx-2"

                                            >
                                              {" "}
                                              <FaHandPointer color="black" size={10} style={{ transform: 'rotate(90deg)' }} /> Pressing Ctrl+V
                                            </label>
                                            <br></br>
                                            <label
                                              for="inputState"
                                              class="form-label mx-2"
                                            >
                                              {" "}
                                              <FaHandPointer color="black" size={10} style={{ transform: 'rotate(90deg)' }} />  Pressing Printing Screen
                                            </label>
                                            <br></br>
                                            <label
                                              for="inputState"
                                              class="form-label mx-2 "
                                            >
                                              {" "}
                                              <FaHandPointer color="black" size={10} style={{ transform: 'rotate(90deg)' }} /> Pressing F12
                                            </label>





                                          </div>

                                        </div>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* </div> */}
                              </div>
                            </div>

                            {/* End Form Layouts Area */}
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFive">
                          <button
                            className={open ? 'accordion-button' : 'accordion-button collapsed'}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFive"
                            aria-expanded="false"
                            aria-controls="collapseFive"
                          >
                            Candidate Registration Fields
                          </button>
                        </h2>
                        <div
                          id="collapseFive"
                          className={open ? ' accordion-collapse collapse show' : 'accordion-collapse collapse'}
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <div className="contact-list-area ">
                              <div className="card-box-style">
                                <table className="table align-middle">
                                  <thead>
                                    <tr>
                                      <th style={{ width: '33.3%' }}>Data Field</th>

                                      <th style={{ width: '33.3%' }}>Required</th>

                                      <th style={{ width: '33.3%' }}>Control Type</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {dataFieldList != null &&
                                      dataFieldList.length > 0 ? (
                                      dataFieldList.map((v, i) => {

                                        return (
                                          <tr key={i} id={i} >
                                            {" "}
                                            <td>

                                              <div class="form-check">
                                                <input class="form-check-input" type="checkbox" id={`Field${v.dataFieldID}`} checked={v.checked} disabled={v.disabled} name="fieldselect" onChange={(event) => dataFieldOnCHange(event, v.dataFieldID)} />
                                                <label class="form-check-label px-1">
                                                  {v.dataFieldName}
                                                </label>
                                              </div>
                                            </td>
                                            <td>
                                              <div class="form-check">
                                                <input class="form-check-input" type="checkbox" id={`required${v.dataFieldID}`} checked={v.required} disabled={v.disabled} name="requiredselect" onChange={(event) => dataFieldOnCHange(event, v.dataFieldID)} />

                                              </div>


                                            </td>
                                            <td>
                                              {v.fieldType}

                                            </td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <></>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item ">
                        <h2 className="accordion-header text-end" >
                          <button type="submit" className="btn btn-primary examButton">
                            Submit
                          </button>
                        </h2>
                      </div>

                    </div>

                  </div>
                </div>
                {/* {!isContainerFluid ? <div className="col-md-3">

                  <div className="card-box-style"></div>
                </div> : null} */}
              </div>


            </div>
          </div>

        </form>
      </main>
      {/* End Main Content Area */}
      {showPopupModal && (
        <SpecifySelectQuestions

          setShowPopupModal={setShowPopupModal}
          sectionID={sectionID}
          questionIDs={questionIDs}
          setQuestionIDS={setQuestionIDS}
          setspecifyselectsectionList={setspecifyselectsectionList}
          specifyselectsectionList={specifyselectsectionList}
        />
      )}
    </>
  );
}
