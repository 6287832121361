import ExamService from './ExamService';
import { useEffect } from 'react';
import { useState } from 'react';
import { FaTrashAlt, FaCog } from "react-icons/fa";
import { useNavigate, useLocation } from 'react-router-dom';
import MUIDataTable from "mui-datatables";
const _examservice = new ExamService();

const ExamHistoryForExam = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [Examid, setExamid] = useState(location.state?.examID ?? 0
    );

    const [isContainerFluid, setIsContainerFluid] = useState(true);

    const navigateToReview = (cId, examAttendID) => {

        navigate("/ReviewExam", {
            state: { cId: cId, examID: Examid, ExamAttendID: examAttendID },
        });
    }
    const [ReportList, setReportList] = useState([]);
    const [tomails, setToMails] = useState([]);

    useEffect(() => {
        _examservice.GetReportDetailsExam(Examid).then((res) => {

            setReportList(res);
            const mails = res
                .filter((e) => e.email)
                .map((e) => e.email);

            setToMails(mails);

            console.log(res);
        }).catch((e) => {
            console.log(e);
        });
    }, []);
    const columns = [
        {
            name: "modifiedName", label: "CANDIDATE",
            options: {
                filter: true,
                sort: true,
                empty: true,

                responsive: true,

                customBodyRender: (values, tableMeta) => {
                    const name = values; // Assuming duration is at index 1
                    return (<p style={{ fontWeight: 'bold' }}>{name}</p>)
                },

            },

        },
        {
            name: "examEnd", label: "DATE",
            options: {
                filter: true,
                sort: true,
                empty: true,

                responsive: true,

                customBodyRender: (values, tableMeta) => {
                    const date = tableMeta.rowData[1]; // Assuming duration is at index 1
                    if (date !== null && date !== undefined) {
                        return (
                            <>               <p style={{ fontWeight: 'bold' }}>
                                {new Date(date.split("T")[0]).toLocaleDateString('en-US', {
                                    day: '2-digit',
                                    month: 'short',
                                    year: 'numeric'
                                }).replace(/,/g, '')}
                            </p>
                                <p style={{ fontSize: '11px' }}>
                                    {new Date(date).toLocaleTimeString('en-US', {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        hour12: true
                                    })}
                                </p>

                            </>

                        );
                    }
                    else {
                        return
                        (
                            <></>
                        );
                    }
                },

            },

        },

        {
            name: "timeTaken", label: "TIME TAKEN",
            options: {
                filter: true,
                sort: false,
                empty: true,

                responsive: true,

                customBodyRender: (values, tableMeta) => {
                    const timeTaken = ReportList[tableMeta.rowIndex].timeTaken;

                    return (
                        <>

                            <span className="location">{timeTaken === '' || timeTaken === null ? "" : timeTaken >= 60 ? _examservice.convertSecondsToMinutes(timeTaken) + ' Minutes' : timeTaken + ' Seconds'} </span>
                        </>




                    );
                },
            }

        },
        {
            name: "RESULT", label: "RESULT",


            options: {
                filter: true,
                sort: false,
                empty: true,

                responsive: true,

                customBodyRender: (values, tableMeta) => {
                    debugger;
                    const score = ReportList[tableMeta.rowIndex].score === null ? 0 : ReportList[tableMeta.rowIndex].score;
                    const result = ReportList[tableMeta.rowIndex].result;
                    const percentage = ReportList[tableMeta.rowIndex].percentage;
                    const resultType = ReportList[tableMeta.rowIndex].resultType;
                    const totalScore = ReportList[tableMeta.rowIndex].totalScore;
                    return (
                        <>

                            {resultType === 0 ? (
                                <p style={{ fontWeight: 'bold' }}>{score + '/' + totalScore}</p>
                            ) : ( /* Otherwise, if resultType is not equal to 0 */
                                <>

                                    <p className="location" style={{ borderBottom: result === "Fail" ? '7px solid red' : '7px solid green', width: `${percentage}%`, display: "inline-flex" }}>
                                        {result}
                                    </p>
                                    <p>{Number(percentage).toFixed(2)}%</p>
                                </>
                            )}
                        </>







                    );
                },
            },
        },
        {
            label: "",
            name: "",

            options: {
                filter: true,
                sort: false,
                empty: true,

                responsive: true,

                customBodyRender: (values, tableMeta) => {
                    const candidateID = ReportList[tableMeta.rowIndex].candidateID;
                    const examAttendID = ReportList[tableMeta.rowIndex].examAttendID

                    return (
                        <div className="dropdown">
                            <button className="dropdown-toggle" type="button" id={`dropdown-${candidateID}`} data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <FaCog />
                            </button>
                            <div className="dropdown-menu" aria-labelledby={`dropdown-${candidateID}`}>
                                {/* Dropdown items */}
                                <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => navigateToReview(candidateID, examAttendID)}>Review Exam</a>

                            </div>
                        </div>




                    );
                },
            },
        },
    ];
    const toggleContainerClass = () => {
        setIsContainerFluid((prevState) => !prevState);
    };
    return (
        <main class="main-content-wrap">
            <div className="contact-list-area ">
                <div className='container-fluid'  >

                    <div className="row">
                        <div className={`${isContainerFluid ? "col-md-12" : "col-md-9"}`}>
                            <div className='card-box-style animatedwidth'>
                                <div className="fullwidthbuttonbox " onClick={toggleContainerClass}>
                                    <span className="fullwidthbutton" >
                                        {isContainerFluid ? <i className="fa fa-angle-double-left"></i> : <i className="fa fa-angle-double-right"></i>}

                                    </span>
                                </div>
                                <MUIDataTable
                                    data={ReportList.length > 0 ? ReportList : []}
                                    title="Exam History"
                                    columns={columns}
                                    options={{
                                        responsive: true,
                                        download: false,
                                        print: false,
                                        rowsPerPageOptions: [10, 50, 100],
                                        customToolbar: () => (
                                            <>
                                                <h3>
                                                    <input type="button" value="Back" title="Back" className="back-button" onClick={() => navigate(-1)} />
                                                </h3>
                                            </>
                                        ),
                                        selectableRows: false,
                                        filter: false,
                                        viewColumns: false,
                                    }}
                                />
                            </div>
                        </div>
                        {!isContainerFluid ? <div className="col-md-3">

                            <div className="card-box-style"></div>
                        </div> : null}
                    </div>



                </div>
            </div>
        </main >

    );
};
export default ExamHistoryForExam;