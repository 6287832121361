import React, { useEffect, useState } from "react";
import loginlogo from '../../assets/images/max-e-light.png';
import ExamService from "../Exam/ExamService";
import "../../assets/js/custom.js";
import CandidateQuestionViewServices from "../../services/CandidateQuestionViewService";
import '../../assets/css/customizeHeader.css';


const FinalAnswerShowHeader = ({ ExamId, candidateName, handleSubmit, candidateID, SubmitBefortime, examAttendID }) => {




    return (
        <div className="header-area" >
            <div className="container-fluid">
                <div className="row">
                    <div className="header-area header-style-three examView">
                        <div className="header-content-wrapper">
                            <div className="header-content d-flex justify-content-between">
                                <div className="header-left-content d-flex align-items-center">
                                    <div className="main-logo">
                                        <a href="#">
                                            <img src={loginlogo} alt="main-logo" style={{ height: '55px' }} />
                                        </a>
                                    </div>
                                    <div className="header-right-option dropdown profile-nav-item pt-0 pb-0">
                                        <div className="d-none d-lg-block d-md-block">
                                            <h4 style={{ color: '#ffffff' }}>{candidateName}</h4>
                                            <span style={{ color: '#ffffff' }}>Vaanam Technologies - Online Test {new Date().getFullYear()}</span>
                                        </div>
                                    </div>
                                    <div className="option-item for-mobile-devices d-block d-lg-none">
                                        <i className="search-btn ri-search-line" />
                                        <i className="close-btn ri-close-line" />
                                        <div className="search-overlay search-popup">
                                            <div className="search-box">
                                                <form className="search-form">
                                                    <input className="search-input" name="search" placeholder="Search" type="text" />
                                                    <button className="search-button" type="submit">
                                                        <i className="ri-search-line" />
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="header-right-content d-flex align-items-center">
                                    <div className="header-right-option template-option">
                                        <div className="time-counter background-color">
                                            <div id="timer" className="flex-wrap d-flex justify-content-center">


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FinalAnswerShowHeader;
