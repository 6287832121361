import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import loginlogo from "../../assets/images/max-e-light.png";
import '../../assets/css/customizeHeader.css';
import userImage from '../../assets/images/avatar.png';
import { Cookies } from "react-cookie";
import { AppContext } from "../../services/ContextProvider";
import $ from "jquery";
import {
    Encrypt,
    Decrypt,
} from "../../services/CommonService";

export default function CandidatepreviewHeader() {
    const { showLoading, hideLoading, setCookies, tempModulePrivileage, isAuth, SetisAuth, getCookies } =
        useContext(AppContext);

    const cookies = new Cookies();
    const navigate = useNavigate();
    function Logout() {
        localStorage.clear();
        const cookieNames = Object.keys(cookies.getAll());

        // Clear each cookie by removing it
        cookieNames.forEach(cookieName => {
            cookies.remove(cookieName, { path: '/' }); // Replace '/' with the appropriate cookie path
        });


        navigate("/Login", { replace: true });

    }

    return (<>
        <div className="header-area" >
            <div className="container-fluid">
                <div className="row">
                    <div className="header-area header-style-three examView ">
                        {/* <!-- Start Sidebar Menu Area --> */}
                        <div className="header-content-wrapperr">
                            <div className="header-content d-flex justify-content-between align-items-center">
                                <div className="header-left-content d-flex">
                                    <div className="responsive-burger-menu d-block d-lg-none">
                                        <span className="top-bar"></span>
                                        <span className="middle-bar"></span>
                                        <span className="bottom-bar"></span>
                                    </div>

                                    <div className="main-logo">
                                        <a href="https://www.vaanamtech.com/">
                                            <img
                                                src={loginlogo}
                                                alt="main-logo"
                                                style={{ height: '55px' }}
                                            />
                                        </a>
                                    </div>
                                    <div className="header-right-option dropdown profile-nav-item pt-0 pb-0">
                                        <div className="d-none d-lg-block d-md-block attenedcandidatename">


                                        </div>
                                    </div>

                                </div>

                                <div className="header-right-content d-flex align-items-center">
                                    <div className="header-right-option dropdown profile-nav-item pt-0 pb-0">
                                        <a className="dropdown-item dropdown-toggle avatar d-flex align-items-center" href="#" id="navbarDropdown-4" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ right: '2px' }}>
                                            <img src={userImage} alt="avatar" />
                                            <div className="d-none d-lg-block d-md-block">
                                                <h3 style={{ color: 'white' }}>{getCookies("EmployeeCode")}</h3>
                                                <span style={{ color: 'white' }}>{Decrypt(localStorage.getItem("RoleName"))}</span>

                                            </div>
                                        </a>

                                        <div
                                            className="dropdown-menu"
                                            style={{
                                                position: "absolute",
                                                inset: "0px auto auto 0px",
                                                margin: "0px",
                                                display: "",
                                                transform: "translate3d(-33.6px, 85.6px, 0px)",
                                            }}
                                            data-popper-placement="bottom-start"
                                        >
                                            <div className="dropdown-header d-flex flex-column align-items-center">
                                                <div className="figure mb-3"></div>

                                                <div className="info text-center">
                                                    <span className="name">{getCookies("UserName")}</span>
                                                    <p className="mb-3 email">
                                                        <a href={`mailto:${Decrypt(localStorage.getItem("EmailID"))}`}>
                                                            {Decrypt(localStorage.getItem("EmailID"))}
                                                        </a>
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="dropdown-wrap">
                                                <ul className="profile-nav p-0 pt-3">
                                                    {/* <li className="nav-item">
                              <a href="profile.html" className="nav-link">
                                <i className="ri-user-line"></i>
                                <span>Profile</span>
                              </a>
                            </li> */}



                                                    <li className="nav-item">
                                                        <Link className="nav-link"
                                                            to="/EmpCreate"
                                                            state={{
                                                                empId: Decrypt(localStorage.getItem("EmployeeID")),
                                                            }}
                                                        >   <i className="ri-edit-box-line"  ></i>
                                                            <span>Edit Profile</span></Link>

                                                    </li>

                                                    <li className="nav-item">
                                                        <Link className="nav-link"
                                                            to="/GeneralConfig"
                                                        >     <i
                                                            className="fa fa-cog"
                                                            style={{ margin: "2% 0% 0% 0%" }}
                                                        ></i>
                                                            <span>Settings</span></Link>

                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="dropdown-footer">
                                                <ul className="profile-nav">
                                                    <li className="nav-item">
                                                        <a href="#" onClick={() => Logout()} className="nav-link">
                                                            <i className="ri-login-circle-line"></i>
                                                            <span>Logout</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="header-right-option template-option">
                                        
                                        <a className="dropdown-item" href="#" data-bs-toggle="offcanvas" data-bs-target="dropdown" aria-controls="offcanvasRight">
                                            <box-icon type='solid' style={{ margin: "15% 0% 0% 0%" }} name='user-circle'></box-icon>
                                        </a>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    );
}