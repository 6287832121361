import React from 'react';

const FlagCount = ({ count }) => {
    return (
        <div className="notification-icon">
            <i className="fa fa-flag"></i>
            {count === 0 ? <></> : <span className="notification-count">{count}</span>}
        </div>
    );
};

export default FlagCount;
