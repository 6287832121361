
import { FaTimesCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

export default function Terminate() {
    const navigate = useNavigate();
    return (
        <>
            <div class="error-area">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="error-content card-box-style">
                                <h1><FaTimesCircle color="red" /></h1>
                                <h2>Sorry</h2>
                                <p style={{ lineHeight: '20px', marginBottom: '15px' }}>Your Exam has been Terminated.</p>
                                <p style={{ lineHeight: '20px' }}>Kindly reach Admin for further process.</p>
                                <button className="btn btn-primary" onClick={() => navigate(-1)} style={{ cursor: 'pointer' }}>Back to Home</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}