import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import ConfigurationService from "../../services/configurationService";
import $ from "jquery";
import IconButton from "@material-ui/core/IconButton";

import { Tooltip } from "@mui/material";
import { DoneAll } from "@mui/icons-material";
import Toast from "../ToastMsg";
import { getCookieExpiry } from "../../services/CommonService";
import { AppContext } from "../../services/ContextProvider";
import Swal from "sweetalert2";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

export default function UserRoleConfig(props) {
  const { showLoading, hideLoading, setCookies, tempModulePrivileage, isAuth, SetisAuth, getCookies } =
    useContext(AppContext);
  const ConfigurationServiceobj = new ConfigurationService();
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
    clearErrors,
    reset,
  } = useForm();

  const onKeyDown = (event) => {
    if (event.code === "Space") event.preventDefault();
  };

  const [ProjectList, setProjectList] = useState([]);

  const [ProjectSelectedList, setProjectselectedList] = useState([]);
  const onSubmit = (data) => {

    var flag = validateCreate();
    if (flag) {
      data.roleID = roleID;
      data.privilegeIDs = $("#privilegeIDs").val();
      data.statusCode = "AC";
      data.saved_By = Number(getCookies("EmployeeID"));

      ConfigurationServiceobj.Register(data)
        .then((response) => {
          $(".closeModal").trigger("click");
          Toast.fire({
            icon: "success",
            title: "Role Saved Succesfully.",
          });
          // Toast("Role Saved Succesfully.", "success");


          tablerefresh(0);
          document.getElementById("RoleConfiguration").reset();

          reset();
        })
        .catch((error) => {
          if (error.response.data === "RoleName Already Exist.") {

            Toast.fire({
              icon: "warning",
              title: error.response.data,
            });
          } else {
            Toast.fire({
              icon: "error",
              title: "Something went wrong",
            });
          }
        });
    }
  };

  const { roleID, modelRefresh, tablerefresh } = props;
  useEffect(() => {
    reset();
    document.getElementById("RoleConfiguration").reset();
    showLoading();

    ConfigurationServiceobj.Create_Role(roleID)
      .then((response) => {
        var modPrivilegeLst = response.data.modPrivilegeLst;
        var roladate = response.data;
        setdata(modPrivilegeLst);

        const temp = {
          roleID: roladate.roleID,
          roleName: roladate.roleName,
          privilegeIDs: roladate.privilegeIDs,
          description: roladate.description,
        };

        Object.keys(temp).map((key, i) => setValue(key, temp[key]));
        // document.getElementById("RoleConfiguration").reset();
        hideLoading();
      })
      .catch((error) => {
        hideLoading();
        //alert(error)
      });
    GetPrivilegeList();
  }, [modelRefresh]);

  function validateCreate() {
    var checked_length = $(
      "#Table_body_Privillages input[type=checkbox]:checked"
    ).length;

    if (checked_length == 0) {
      Toast.fire({
        icon: "error",
        title: "Please Select Privilege",
      });
      //  Toast("Please Select Privilege", "error");
      return false;
    }

    var RoleID = Number($("#RoleID").val());
    if (RoleID == 0) {
      Toast.fire({
        icon: "error",
        title: "Please Enter Role Name",
      });
      //Toast("Please Enter Role Name", "error")

      return false;
    }
    var selPri = "";
    $("[id^=chk]").each(function () {
      if ($(this).is(":checked")) {
        selPri += this.id.replace("chk", "") + ",";
      }
    });
    if (selPri == "") {
      Toast.fire({
        icon: "error",
        title: "Please Select Privilege",
      });
      // Toast("Please Select Privilege", "error");

      return false;
    } else {
      selPri = selPri.substring(0, selPri.length - 1);
      $("#privilegeIDs").val(selPri);
      setValue("PrivilegeIDs", selPri);
      return true;
    }
  }

  const GetPrivilegeList = () => {


    ConfigurationServiceobj.GetPrivilegeList().then(
      (response) => {
        var modPrivilegeLst = response.data.modPrivilegeLst;
        setdata(modPrivilegeLst);
      }
    );
  };

  const [data, setdata] = useState([]);


  const getArray = (menuArray) =>
    menuArray.map((item, index) => {
      return (
        <tr>
          <td>{item.moduleName}</td>
          {item.privilegeList.map((item1, index) => {
            var temp = "chk" + item1.privilegeID;
            // if (item1.selected === 0)
            {
              return (
                <td>
                  <div className="form-group form-check text-center pt-2 ">
                    {temp == "chk0" ?
                      <div className="crossRolediv">
                        <span className="crossRolebtn" aria-hidden="true">X</span>
                      </div>
                      :
                      <input
                        type="checkbox"
                        className="form-check-input mycheck"
                        id={temp}
                        defaultChecked={item1.selected}
                      />
                    }
                  </div>
                </td>
              );
            }

            // else {
            // return (

            //     <td>

            //     <div className="form-group form-check text-center">
            //         <input type="checkbox" className="form-check-input" defaultChecked  id={temp}  />
            //     </div>
            // </td>
            // )
            // }
          })}
        </tr>
      );
    });

  const SelectAll = () => {
    var checkBoxes = $(".mycheck");
    checkBoxes.prop("checked", !checkBoxes.prop("checked"));
  };

  return (
    <div>
      <form
        id="RoleConfiguration"
        onKeyPress={(e) => {
          if (e.key == "Enter") {
            e.preventDefault();
          }
        }}
        onSubmit={(e) => handleSubmit(onSubmit)(e)}
      >
        <div className="row">

          <div className="col-md-4 col-sm-12 col-lg-4 col-xl-4">
            <div className="form-group mb-3">
              <label className="label" for="name">
                Role Name <span className="text-danger">*</span>
              </label>
              <input
                type="hidden"
                id="privilegeIDs"
                value={register.privilegeIDs}
              />

              <input
                type="text"
                className="form-control"
                maxLength={20}
                placeholder="Role Name"
                {...register("roleName", {
                  required: "Role Name is Required",
                })}
                onChange={() => setError("roleName", "")}
                onKeyDown={(event) => onKeyDown(event)}
              />
              {errors.roleName && (
                <span className="text-danger">{errors.roleName.message}</span>
              )}
            </div>
          </div>

          <div className="col-md-4 col-sm-12 col-lg-4 col-xl-4">
            <div className="form-group mb-3">
              <label className="label">
                Description <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                maxLength={100}
                placeholder="Description"
                // {...register("description", {
                {...register("description", {
                  required: "Description is Required",
                })}
                onChange={() => setError("description", "")}
              />
              {errors.description && (
                <span className="text-danger">
                  {errors.description.message}
                </span>
              )}
            </div>
          </div>
        </div>

        <div className=" text-right">
          <Tooltip title="Select All">
            <label htmlFor="icon-button-file">
              <IconButton
                className="HoverDefaultIcon text-right"
                onClick={() => SelectAll()}
                component="span"
              >
                <DoneAll> </DoneAll>
              </IconButton>
            </label>
          </Tooltip>
        </div>

        <table id="request_table1" className="table table-striped custom-table">
          <thead>
            <tr>
              <th>Module Name </th>
              <th>Create</th>
              <th>Edit</th>
              <th>Delete</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody id="Table_body_Privillages">
            {data ? getArray(data) : <></>}
          </tbody>
        </table>
        <div className="row mb-3">
          <div className="col-md-10 col-sm-12 col-lg-10 col-xl-10"></div>
          <div className="col-md-2 col-sm-12 col-lg-2 col-xl-2">
            <div style={{ textAlignLast: "end" }}>
              <button className="btn btn-primary btn-sm" type="submit">
                <i className="la la-save"></i> Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
