import React, { useState, useEffect, useContext } from 'react';
import QuestionBankService from '../../services/QuestionBankService';
import { showErrorMsg, showSaveSuccess } from '../ToastMsg';
import {
    Encrypt,
    Decrypt,
} from "../../services/CommonService";
import Swal from "sweetalert2";
import { AppContext } from "../../services/ContextProvider";
import { useNavigate } from 'react-router-dom';

const _questionBankService = new QuestionBankService();
const BulkUpload = ({ setIsPopupOpen }) => {

    const [sectionList, setSectionList] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [errorsection, seterrorsection] = useState(false);
    const [errorfile, seterrorfile] = useState(false);
    const navigate = useNavigate();
    const { getCookies, setCookies, showLoading, hideLoading, cookies, tempModulePrivileage } =
        useContext(AppContext);
    useEffect(() => {

        _questionBankService.GetSectionList(Decrypt(localStorage.getItem("CompanyID"))).then((res) => {
            setSectionList(res);
        }).catch((exception) => {
            console.log(exception);
        });
    }, [])
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });
    const handleCloseButtonClick = () => {
        setIsPopupOpen(false);
    };

    // if (!isOpen) {
    //     return null;
    // }

    const onFileChange = (event) => {

        const file = event.target.files[0];

        if (file) {
            setSelectedFile(file);
            seterrorfile(false);
        } else {
            setSelectedFile(null);
            seterrorfile(true);
        }
    };


    const handleChange = (event) => {

        if (event.target.value === '' || event.target.value == 0 || event.target.value == null) {
            seterrorsection(true)
        }
        else {
            seterrorsection(false)
        }

    };

    const onSubmit = async (e) => {
        debugger;

        e.preventDefault();

        var a = document.querySelector(".loader-div")
        if (a) {
            a.style.height = "100vh";
            a.style.display = "flex";
        }

        var res = 0;
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append("sectionID", document.getElementById('section').value);
        formData.append("CompanyID", Decrypt(localStorage.getItem("CompanyID")));
        if (formData.get("sectionID") === '' || formData.get("sectionID") === null || formData.get("sectionID") == 0) {
            seterrorsection(true);

            res = 1;
        }
        if (selectedFile === '' || selectedFile === null) {
            seterrorfile(true);
            res = 1;
        }
        if (res == 0) {



            _questionBankService.UploadFile(formData).then((res) => {
                if (res.data > 0) {
                    handleCloseButtonClick();
                    a.style.height = " ";
                    a.style.display = "none";
                    // showSaveSuccess();
                    Toast.fire({
                        icon: "success",
                        title: "Saved Successfully.",
                    });
                    setTimeout(() => {
                        window.location.reload();
                    }, 500);
                    navigate("/ViewQuestion")
                }
                //else {
                //     Toast.fire({
                //         icon: "warning",
                //         title: "Please Question with formatted template.",
                //     });
                //  }
            }).catch((e) => {
                a.style.height = " ";
                a.style.display = "none";
                Toast.fire({
                    icon: "warning",
                    title: "Please Question with formatted template.",
                });

            })

        }
        // a.style.height = " ";
        // a.style.display = "none";
    };

    return (

        <div className="modal fade show" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-modal="true" role="dialog" style={{ display: 'flex', alignItems: 'center' }}>
            <div className="modal-dialog" style={{ margin: 'auto' }}>
                <div className="modal-content" style={{ width: '400px' }}>
                    <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">Bulk Upload</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" fdprocessedid="ttcp2q"
                            onClick={handleCloseButtonClick}
                        />
                    </div>
                    <div className='modal-body'>
                        <form id="QuestionBank" onSubmit={onSubmit}>
                            <div className="form-group col-md-12 mb-3">
                                <label htmlFor="section" className="form-label" style={{ padding: '5px', color: "black" }}>
                                    Section:
                                </label>
                                <select id="section" name='section' className="form-select form-control" onChange={handleChange}>
                                    <option value="">Select</option>
                                    {sectionList.length > 0 && sectionList?.map((section) => (
                                        <option key={section.sectionId} value={section.sectionId}>
                                            {section.sectionName}
                                        </option>
                                    ))}
                                </select>
                                {errorsection && (
                                    <p className="text-danger mt-1">Please select section</p>
                                )}
                            </div>

                            <input
                                name='file'
                                type="file"
                                className="form-control mb-3"
                                onChange={onFileChange}
                                accept=".xlsx, .xls/*"

                            />
                            {errorfile && (
                                <p className="text-danger mt-1">Please upload file</p>
                            )}
                            <div className='text-center'>
                                <button type="submit" className="btn btn-primary">
                                    Submit
                                </button></div>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    );
};
export default BulkUpload;