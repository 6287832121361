

import React from "react";
import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import $ from "jquery";

// import { Link } from "iconsax-react";
import { Link } from "react-router-dom";
import { Logout } from "iconsax-react";

import "../../assets/css/owl.theme.default.min.css";
import "../../assets/css/owl.carousel.min.css";
import "../../assets/css/animate.min.css";
import "../../assets/css/footer.css";
import "../../assets/css/sidebar-menu.css";
import loginlogo from "../../assets/images/max-e-light.png";
import Header from "./Header";
import { Decrypt, getUserDetails, getPrivilege } from "../../services/CommonService";
import Global from "../../services/Global";
import { AppContext } from "../../services/ContextProvider";
function SideBar() {

  const [isActive, setIsActive] = useState(false);
  const [priviledge, setpriviledge] = useState("");
  const [defaultActiveItem, setDefaultActiveItem] = useState("Dashboard");

  const { getCookies, setCookies, showLoading, hideLoading, cookies, tempModulePrivileage } =
    useContext(AppContext);
  useEffect(() => {

    var ModulePrivileges =
      getCookies(Global.COOKIES.modulePrivileage) != null
        ? getCookies(Global.COOKIES.modulePrivileage)
        : "";

    let timeout = setTimeout(setpriviledge(ModulePrivileges), 3000);

  }, [getPrivilege()]);
  let color;

  const navigate = useNavigate();
  function Logout() {
    localStorage.clear();
    navigate("/Login");
  }
  // const [sidebaritems, setsidebaritems] = useState([
  //   {
  //     PartentName: "Dashboard",
  //     IsPartent: true,
  //     Icon: "home",
  //     IsParentActive: true,
  //     priviledge: "DASHVIEW",
  //     Linkpath: "/DashBoard",
  //     isChild: [
  //       {
  //         Linkdisplayname: "DashBoard",
  //         Linkpath: "/DashBoard",
  //         priviledge: "DASHVIEW",
  //         isActive: false,

  //       }
  //     ]
  //   }, {
  //     PartentName: "ExamModule",
  //     IsPartent: true,
  //     Icon: "slideshow",
  //     priviledge: "QUESVIEW",
  //     IsParentActive: false,
  //     isChild: [
  //       {
  //         Linkdisplayname: "Question Bank",
  //         Linkpath: "/ViewQuestion",
  //         priviledge: "EXAMVIEW",
  //         isActive: false,

  //       },
  //       {
  //         Linkdisplayname: "Question Bank",
  //         Linkpath: "/QuestionBank",
  //         priviledge: "EXAMVIEW",
  //         isActive: false,

  //       },
  //       {
  //         Linkdisplayname: "Exam",
  //         Linkpath: "/Exams",
  //         priviledge: "QUESVIEW",
  //         isActive: false

  //       },

  //       {
  //         Linkdisplayname: "Exam",
  //         Linkpath: "/ExamCreate",
  //         priviledge: "QUESVIEW",
  //         isActive: false

  //       },

  //       {
  //         Linkdisplayname: "Candidate",
  //         Linkpath: "/AdminCanditate",
  //         priviledge: "CANDIDATEVIEW",
  //         isActive: false
  //       },
  //       {
  //         Linkdisplayname: "Employee",
  //         Linkpath: "/EmpList",
  //         priviledge: "EMPVIEW",
  //         isActive: false
  //       }
  //     ]
  //   }, {
  //     PartentName: "AppModule",
  //     IsPartent: true,
  //     Icon: "cog",
  //     priviledge: "GCONVIEW",
  //     IsParentActive: false,
  //     isChild: [
  //       {
  //         Linkdisplayname: "Application Configuration",
  //         Linkpath: "/ApplicationConfig",
  //         priviledge: "APPVIEW",
  //         isActive: false
  //       },
  //       {
  //         Linkdisplayname: "General Configuration",
  //         Linkpath: "/GeneralConfig",
  //         priviledge: "GCONVIEW",
  //         isActive: false
  //       },
  //       // {
  //       //   Linkdisplayname: "Email Configuration",
  //       //   Linkpath: "Dashboard",

  //       //   isActive: false
  //       // },
  //       {
  //         Linkdisplayname: "Email Template",
  //         Linkpath: "/EmailTemplate",
  //         priviledge: "EMAILVIEW",
  //         isActive: false,
  //       },

  //       {
  //         Linkdisplayname: "Email Template",
  //         Linkpath: "/EmailTemplateCreate",
  //         priviledge: "EMAILVIEW",
  //         isActive: false,
  //       },
  //       {
  //         Linkdisplayname: "Role Configuration",
  //         Linkpath: "/RoleConfig",
  //         priviledge: "ROLEVIEW",
  //         isActive: false
  //       },
  //     ]

  //   }

  // ]);

  const [sidebaritems, setsidebaritems] = useState([
    {
      id: 1, // Unique identifier for the menu item
      PartentName: "Dashboard",
      IsPartent: true,
      Icon: "home",
      IsParentActive: true,
      priviledge: "DASHVIEW",
      Linkpath: "/DashBoard",
      isChild: [
        {
          id: 11, // Unique identifier for the child menu item
          Linkdisplayname: "DashBoard",
          Linkpath: "/DashBoard",
          priviledge: "DASHVIEW",
          isActive: false,
        }
      ]
    },
    {
      id: 2, // Unique identifier for the menu item
      PartentName: "ExamModule",
      IsPartent: true,
      Icon: "slideshow",
      priviledge: "QUESVIEW",
      IsParentActive: false,
      isChild: [
        {
          id: 21, // Unique identifier for the child menu item
          Linkdisplayname: "Question Bank",
          Linkpath: "/ViewQuestion",
          priviledge: "QUESVIEW",
          isActive: false,
        },
        {
          id: 22, // Unique identifier for the child menu item
          Linkdisplayname: "Question Bank",
          Linkpath: "/QuestionBank",
          priviledge: "QUESVIEW",
          isActive: false,
        },
        {
          id: 23,
          Linkdisplayname: "Exam",
          Linkpath: "/Exams",
          priviledge: "EXAMVIEW",
          isActive: false

        },

        {
          id: 24,
          Linkdisplayname: "Exam",
          Linkpath: "/ExamCreate",
          priviledge: "EXAMVIEW",
          isActive: false

        },
        {
          id: 28,
          Linkdisplayname: "Exam",
          Linkpath: "/ExamSummary",
          priviledge: "EXAMVIEW",
          isActive: false

        },
        {
          id: 29,
          Linkdisplayname: "Exam",
          Linkpath: "/ExamHistoryForExam",
          priviledge: "EXAMVIEW",
          isActive: false

        },
        {
          id: 30,
          Linkdisplayname: "Exam",
          Linkpath: "/ExamStatistics",
          priviledge: "EXAMVIEW",
          isActive: false

        },
        {
          id: 31,
          Linkdisplayname: "Exam",
          Linkpath: "/MeritList",
          priviledge: "EXAMVIEW",
          isActive: false

        },
        {
          id: 32,
          Linkdisplayname: "Exam",
          Linkpath: "/questionPaper",
          priviledge: "EXAMVIEW",
          isActive: false

        },
        {
          id: 25,
          Linkdisplayname: "Candidate",
          Linkpath: "/AdminCanditate",
          priviledge: "CANDIDATEVIEW",
          isActive: false
        },
        {
          id: 26,
          Linkdisplayname: "Employee",
          Linkpath: "/EmpList",
          priviledge: "EMPVIEW",
          isActive: false
        },
        {
          id: 27,
          Linkdisplayname: "Employee",
          Linkpath: "/EmpCreate",
          priviledge: "EMPVIEW",
          isActive: false
        }
      ]
    },
    {
      id: 3, // Unique identifier for the menu item
      PartentName: "AppModule",
      IsPartent: true,
      Icon: "cog",
      priviledge: "GCONVIEW",
      IsParentActive: false,
      isChild: [
        {
          id: 31, // Unique identifier for the child menu item
          Linkdisplayname: "Application Configuration",
          Linkpath: "/ApplicationConfig",
          priviledge: "APPVIEW",
          isActive: false
        },
        {
          id: 32, // Unique identifier for the child menu item
          Linkdisplayname: "General Configuration",
          Linkpath: "/GeneralConfig",
          priviledge: "GCONVIEW",
          isActive: false
        },

        {
          id: 33,
          Linkdisplayname: "Email Template",
          Linkpath: "/EmailTemplate",
          priviledge: "EMAILVIEW",
          isActive: false,
        },

        {
          id: 34,
          Linkdisplayname: "Email Template",
          Linkpath: "/EmailTemplateCreate",
          priviledge: "EMAILVIEW",
          isActive: false,
        },
        {
          id: 35,
          Linkdisplayname: "Role Configuration",
          Linkpath: "/RoleConfig",
          priviledge: "ROLEVIEW",
          isActive: false
        },
      ]
    }
  ]);



  const updateParentActiveState = () => {

    const updatedSidebar = sidebaritems.map((parent) => {
      const anyChildActive = parent.isChild.some((child) => child.isActive);
      parent.IsParentActive = anyChildActive;
      return parent;
    });

    setsidebaritems(updatedSidebar);
  };

  const HandleClick = (e, Linkpath, PartentName) => {
    const updatedSidebar = sidebaritems.map((i) => {
      if (i.PartentName === PartentName) {
        i.IsParentActive = true;
        i.isChild.forEach((x) => {
          if (x.Linkpath === Linkpath) {
            x.isActive = true;
          }
        });
      } else {
        i.IsParentActive = false;
      }
      return i;
    });

    setsidebaritems([...updatedSidebar]);
    updateParentActiveState(); // Update the parent's active state
  };


  useEffect(() => {
    const currentPath = window.location.pathname;
    const updatedSidebar = sidebaritems.map((parent) => {
      parent.isChild.forEach((child) => {
        child.isActive = child.Linkpath === currentPath;
        // Check if any item is active, and if not, set "Dashboard" as active
        if (child.isActive) {
          setDefaultActiveItem(parent.PartentName);
        }
      });
      return parent;
    });

    setsidebaritems(updatedSidebar);
    updateParentActiveState();
  }, []);


  useEffect(() => {
    // Add a click event listener to the hamburger menu
    $('.burger-menu').on('click', function () {
      $(this).toggleClass('active');
      $('.main-content').toggleClass('hide-sidemenu-area');
      $('.sidebars-menu').toggleClass('toggle-sidemenu-area');
      $('.navbar').toggleClass('toggle-navbar-area');
    });
    $('.responsive-burger-menu').on('click', function () {
      $('.responsive-burger-menu').toggleClass('active');
      $('.sidebars-menu').toggleClass('active-sidemenu-area');
    });

  }, []);


  return (
    <>
      <Header></Header>
      <nav className="sidebars-menu">
        <ul
          className="list-group flex-column d-inline-block first-menu"
          data-simplebar="init"
          id="static"
        >
          <div
            className="simplebar-wrapper"
            style={{ margin: "0px 0px -180px" }}
          >
            <div className="simplebar-height-auto-observer-wrapper">
              <div className="simplebar-height-auto-observer"></div>
            </div>
            <div className="simplebar-mask">
              <div
                className="simplebar-offset"
                style={{ right: "0px", bottom: "0px" }}
              >
                <div
                  className="simplebar-content-wrapper"
                  style={{ height: "100%", overflow: " hidden" }}
                >
                  <div
                    className="simplebar-content"
                    style={{ padding: "0px 0px 180px" }}
                  >
                    {sidebaritems?.map((i, index) => {
                      if (priviledge.includes(i.priviledge)) {
                        return <li className={i.IsParentActive ? "list-group-item main-grid active" : "list-group-item main-grid"}>
                          <div
                            className="icon"

                          >
                            <box-icon
                              name={i.Icon}
                              animation='tada-hover'
                              style={{ margin: "10% 0% 0% 0%" }}
                              color={i.IsParentActive || color == "white" ? "white" : "black"}
                            ></box-icon>
                          </div>
                          {i.IsPartent ?
                            <ul className="list-group flex-column d-inline-block submenu">
                              {i.isChild
                                ?.filter((child, index, self) =>
                                  priviledge.includes(child.priviledge) &&
                                  self.findIndex((c) => c.Linkdisplayname === child.Linkdisplayname) === index
                                )
                                .map((x) => (
                                  <li className={x.isActive ? 'list-group-item active' : 'list-group-item'} key={x.id}>
                                    <a href={x.Linkpath} onClick={(e) => HandleClick(e, x.Linkpath, i.PartentName)}>
                                      {x.Linkdisplayname}
                                    </a>
                                  </li>
                                ))}
                            </ul>
                            : ""
                          }

                        </li>
                      }
                    })}



                  </div>
                </div>
              </div>
            </div>

          </div>
        </ul>
      </nav>

      {/* <!-- End Sidebar Menu Area --> */}
    </>
  );
}
export default SideBar;
