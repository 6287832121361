import React from "react";
import { useEffect } from "react";
import { FaFlag } from 'react-icons/fa';
import CandidateQuestionViewServices from "../../services/CandidateQuestionViewService";
import { useState } from "react";
import { Decrypt } from "../../services/CommonService";

var candidateQuestionServ = new CandidateQuestionViewServices();

const ExamPreviewSideNav = ({ questionList, handleQuestionClick, currentQuestionId, CorrectAnswerIds, WrongAnswerIds }) => {

    return (
        <nav className="side-menu-area style-two">
            <nav className="sidebar-nav" data-simplebar="init">
                <div className="simplebar-wrapper" style={{ margin: '0px' }}>
                    <div className="simplebar-height-auto-observer-wrapper">
                        <div className="simplebar-height-auto-observer" />
                    </div>
                    <div className="simplebar-mask">
                        <div className="simplebar-offset" style={{ right: '-16.8px', bottom: '0px' }}>
                            <div className="simplebar-content-wrapper" style={{ height: '100%', overflow: 'hidden scroll' }}>
                                <div className="simplebar-content" style={{ padding: '0px' }}>
                                    <ul id="sidebar-menu" className="sidebar-menu metismenu" style={{ marginTop: '18px' }}>
                                        {questionList.map((question, index) => {

                                            const isActive = index === currentQuestionId;
                                            let isCorrectAnswer = "grey";
                                            if (CorrectAnswerIds.includes(question.questionID)) {
                                                isCorrectAnswer = "green";
                                            }
                                            else if (WrongAnswerIds.includes(question.questionID)) {
                                                isCorrectAnswer = "red";
                                            }

                                            return (
                                                <li key={index} className={isActive ? 'mm-active' : ''} style={{ zIndex: '1' }}>

                                                    <a href="#" className="box-style d-flex align-items-center" onClick={() => handleQuestionClick(index + 1)} style={{ textDecoration: 'none' }}>
                                                        <div className="icon" style={{ backgroundColor: isCorrectAnswer, color: 'white' }}>
                                                            {index + 1}
                                                        </div>
                                                        <FaFlag id={`questionno${index + 1}`} style={{ display: "none", color: "red" }} />
                                                    </a>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="simplebar-placeholder" style={{ width: 'auto', height: '775px' }} />
                </div>
                <div className="simplebar-track simplebar-horizontal" style={{ visibility: 'hidden' }}>
                    <div className="simplebar-scrollbar" style={{ transform: 'translate3d(0px, 0px, 0px)', display: 'none' }} />
                </div>
                <div className="simplebar-track simplebar-vertical" style={{ visibility: 'visible' }}>
                    <div className="simplebar-scrollbar" style={{ height: '657px', transform: 'translate3d(0px, 0px, 0px)', display: 'block' }} />
                </div>
            </nav>
        </nav>
    );
};

export default ExamPreviewSideNav;
