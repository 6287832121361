import React from 'react';
import { Document, Page, Text, View, PDFViewer } from '@react-pdf/renderer';
import { Doughnut } from 'react-chartjs-2';
import { saveAs } from 'file-saver';
import ReactDOM from 'react-dom'; // Import ReactDOM

const MyPDF = () => {
    const styles = {
        page: {
            flexDirection: 'row',
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1,
        },
    };

    const generatePdfBlob = async () => {
        const pdfBlob = await pdf(<MyPDF />);
        return pdfBlob;
    };

    // Create a function to download the PDF
    const downloadPdf = async () => {
        const pdfBlob = await generatePdfBlob();
        saveAs(pdfBlob, 'scorecard.pdf');
    };
    // Replace the placeholders with your dynamic data
    const examName = 'Your Exam Name Here';
    const startTime = 'Start Time Data'; // Example start time data
    const endTime = 'End Time Data'; // Example end time data
    const examDuration = '120'; // Example exam duration data
    const correctPercentage = '75'; // Example correct percentage data
    const sampleData = {
        labels: ['Correct', 'Partially Correct', 'Incorrect', 'Unanswered'],
        datasets: [
            {
                data: [70, 10, 15, 5], // Replace with your actual data
                backgroundColor: ['#03C04A', '#FFA500', '#FF0000', '#CCCCCC'], // Colors for each segment
            },
        ],
    };

    const sampleOptions = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            display: true,
            position: 'bottom',
        },
    };
    return (
        <>
            <Document>
                <Page size="A4" style={styles.page}>
                    <View id="reviewexamcontent" style={styles.section}>
                        <View className="container mt-3">
                            <Text>Vaanam Technologies Private Limited</Text>
                            <Text>Score Card | {examName}</Text>

                            <div className="card mt-2" style={{ width: "100%" }}>
                                <table className="table table-borderless">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Start Time</th>
                                            <th>End Time</th>
                                            <th>Exam Duration</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{examName}</td>
                                            <td>{startTime}</td>
                                            <td>{endTime}</td>
                                            <td>{examDuration} Minutes</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className='row justify-content-center mt-2'>
                                <h5 className="card-title" style={{ textAlign: 'center' }}>
                                    Score
                                </h5>
                                <div className='col-6'>
                                    <div className="progress" style={{ height: '300%' }}>
                                        <div
                                            className={`progress-bar w-`}
                                            role="progressbar"
                                            aria-label="Basic example"

                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                        >
                                            <span className="label label-warning"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row justify-content-center' style={{ marginTop: '60px' }}>
                                <div className="col-lg-12 col-sm-12 mt-5">
                                    <div className='row'>
                                        <div className='col-4 reviewreportcard reviewreportcardleftprint'>
                                            <p>Total Questions</p>
                                            <span>Total Questions Data</span>
                                            <hr />
                                            <p>Marks</p>
                                            <span>Marks Data</span>
                                            <hr />
                                            <p>Negative Marks</p>
                                            <span>Negative Marks Data</span>
                                            <hr />
                                            <p>Time Taken</p>
                                            <span>Time Taken Data</span>
                                            <hr />
                                        </div>
                                        <div className='col-4'>
                                            <div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        height: '290px',
                                                    }}
                                                    className='doughnut-chart-container'
                                                >
                                                    <div style={{ width: '300px', position: 'relative' }}>
                                                        <Doughnut data={sampleData} options={sampleOptions} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-4 reviewreportcard reviewreportcardrightprint'>
                                            <p>Correct</p>
                                            <span>Correct Data</span>
                                            <hr />
                                            <p>Partially Correct</p>
                                            <span>Partially Correct Data</span>
                                            <hr />
                                            <p>Incorrect</p>
                                            <span>Incorrect Data</span>
                                            <hr />
                                            <p>Unanswered</p>
                                            <span>Unanswered Data</span>
                                            <hr />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row justify-content-center'>
                                <div className='col-11'>
                                    <table className="table align-middle mt-2">
                                        <thead>
                                            <tr>
                                                <th>Section</th>
                                                <th className='centerContent'>% Correct</th>
                                                <th className='centerContent'>Correct</th>
                                                <th className='centerContent'>Partially</th>
                                                <th className='centerContent'>Incorrect</th>
                                                <th className='centerContent'>Unanswered</th>
                                                <th className='centerContent'>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* Add your table rows with dynamic data here */}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <p className='text-center mt-4' style={{ marginBottom: '1%' }}>
                                {"Report generated on "}
                                <label>{new Date().toLocaleDateString()}</label>
                            </p>
                            <h5 className='text-center'>----------End of the Report-----------</h5>
                        </View>
                    </View>
                </Page>
            </Document>

            <button className='btn btn-primary' onClick={downloadPdf}>Download PDF</button>

        </>
    );
};
const pdf = async (component) => {
    return new Promise((resolve) => {
        const container = document.createElement('div');
        ReactDOM.render(component, container); // Use ReactDOM.render

        const blob = container.toBlob((blob) => {
            resolve(blob);
        });
    });
};


const PDFViewerComponent = () => {
    return (
        <div>
            <PDFViewer width="100%" height={800}>
                <MyPDF />
            </PDFViewer>
        </div>
    );
};
export default MyPDF;
