import React from "react";
import AxiosService from "../../services/AxiosService";

const Auth = new AxiosService();

class EmployeeService {
    EmployeePost = async (data) => {

        try {
            return Auth.post('Employee/EmpCreate', data);

        } catch (error) {
            console.log(error);
        }
    }

    EmployeeView = async () => {
        try {
            return Auth.get('Employee/GetEmployeeList');

        } catch (error) {
            console.log(error);
        }
    }
    DeleteEmployee = async (empId) => {
        try {
            return Auth.get(`/Employee/RemoveEmployee?empId=${empId}`)
        } catch (error) {
            console.log(error);

        }
    }

    GetEmpListById = async (empId) => {
        try {
            return Auth.get(`/Employee/GetEmpListById?empId=${empId}`)

        } catch (error) {
            console.log(error);
        }
    }

    EmployeeDropdown = async () => {
        try {
            return Auth.get(`/Employee/EmployeeDropdown`)
        }
        catch (err) {
            console.log(err);
        }
    }
}

export default EmployeeService;