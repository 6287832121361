import Axios from "./AxiosService";

const axios = new Axios();

export default class ConfigurationService {
 
  Create_Role(roleID) {
    return axios.get("Role/Create_Role?roleID=" + roleID);
  }
  GetPrivilegeList() {
    return axios.get("Role/GetPrivilegeList");
  }
  Register(data) {
    return axios.post("Role/Register", data).then((res)=>{
      return res;
    }).catch((e)=>{
      console.log(e);
    });
  }
  RoleList() {
    return axios.get("Role/RoleList");
  }
  DeleteRole(roleID, empid) {
    return axios.get(`Role/DeleteRole?roleId=${roleID}&employeeId=${empid}`)
  }

}