import React, { useRef } from "react";
import DashBoard from "../Dashboard/DashBoard";
import { useNavigate } from "react-router";
import loginlogo from "../../assets/images/loginlogo.png";
import LoginService from "./AuthService";
import { useState, useContext, useEffect } from "react";
import { COLORS, GetNumberOfDaysInSec } from "../../services/CommonService";
import { AppContext } from "../../services/ContextProvider";
import { showErrorMsg } from "../../Component/ToastMsg";
import Global from "../../services/Global";
import { Link } from "react-router-dom";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import useThirdPartyCookiesEnabled from '../Layout/Thirdparty';
import Swal from "sweetalert2";
import {
  Encrypt,
  Decrypt,
} from "../../services/CommonService";
import { event } from "jquery";
export default function ForgotPassword()
{
    const[error,setError]=useState({});
    const Username = useRef();
    const _loginService = new LoginService();
    const currentYear = new Date().getFullYear();

    const { showLoading, hideLoading, setCookies, tempModulePrivileage, isAuth, SetisAuth } =
    useContext(AppContext);
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
    const onChange=(value)=>{
        var myerror = error;
        if(value==='' || value===null)
        {
           
            
               myerror.Username='Please enter username';
        }
        else{
            myerror.Username='';
        }
        setError({ ...myerror });
    };
    const onSubmit=(e)=>
    {
        
        e.preventDefault();
        var flag=true;
        var myerror = error;
        if(Username.current?.value==='' || Username.current?.value===null)
        {
           
               flag=false;
               myerror.Username='Please enter username';
        }
        else{
            myerror.Username='';
        }
        setError({ ...myerror });
        if(flag===true)
        {    
            showLoading();
            _loginService.ForgotPassword
  (Username.current?.value)
  .then((response) => {
    hideLoading();
    console.log(response)

     if(response==0)
    {
      Toast.fire({
        icon: "warning",
        title: "Invalid Username",
      });
     
    }
    else if(response==1){
      Toast.fire({
        icon: "success",
        title: "New password send to your Email",
      });

  }
    
  else if(response==2){
    Toast.fire({
      icon: "warning",
      title: "Something went't wrong",
    });

}
  })
  .catch((exception) => {
    console.log(exception);
    Toast.fire({
      icon: "warning",
      title: "Something went't wrong",
    });
  });
        }

    };
    return (
        <>
          <div className="LoginDiv" style={{ background: "linear-gradient(90deg, #1765FD,#4FCB8D)" }}>
            <div className="account-area" >
              <div className="d-table">
                <div className="d-table-cell">
                  <div className="container">
                    <div className="account-content">
                      <div className="account-header">
                        <a href="https://www.vaanamtech.com/">
                          <img src={loginlogo} alt="main-logo" style={{ width: "50%" }} />
                        </a>
          
                      </div>
    
                      <form className="account-wrap" onSubmit={onSubmit}>
            
                        <div className="form-group mb-24 icon">
                        <i className="fa fa-user" style={{ margin: " -7px 0px 0px 0px" }}></i>
                          <input type="text" ref={Username} className="form-control" placeholder="Username"
                          onChange={(event)=>onChange(event.target.value)} />
                         <span className="text-danger">{error.Username}</span>
                        </div>
    
    
                        
                        <div className="form-group mb-24">
                          <button type="submit" className="default-btn" >Reset Password</button>
                        </div>
                       
                        <p class="text-center mt-lg">
                        Remembered? <a href="/">Log In</a>

                    </p>
                    <div className="text-center mt-lg"><small class="" style={{textAlign:"center"}}>© Copyright {currentYear}. All rights reserved.</small></div>
                      </form>
                      {/* <div className="form-group mb-24" style={{ display: "none" }}>
                        <Link id="Redirect" to="/DashBoard"></Link>
                      </div> */}
                  
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
}