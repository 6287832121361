import React, { useState, useEffect, useContext, useRef } from "react";

import ApplicationConfigService from "../../services/ApplicationConfigService";

import deleteImage from "../../assets/images/icon/trash.svg";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { Decrypt, Encrypt } from "../../services/CommonService";
import Swal from "sweetalert2";
import { AppContext } from "../../services/ContextProvider";
import { hide } from "@popperjs/core";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import MUIDataTable from "mui-datatables";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
// import Tooltip from "@material-ui/core/Tooltip";
import { TableCell, Tooltip } from '@mui/material';
import "react-tabs/style/react-tabs.css";
import SectionConfigPopup from "./SectionConfigPopup";
import { useNavigate } from "react-router-dom";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  customTableCell: {
    '&.MuiTableCell-root': { // Use a more specific selector
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '12ch',
      textDecoration: 'none',
      borderBottom: 'none',
      paddingLeft: '0% !important', // Add the !important flag
    },
  },
}));

export default function ApplicationConfig() {
  const navigate = useNavigate();
  const [showPopupModal, setShowPopupModal] = useState(false);
  const _applicationService = new ApplicationConfigService();
  const [parameterList, setParameterList] = useState([])
  const [parameterAgainstValues, setParameterAgainstValues] = useState([])
  const [showTable, setShowTable] = useState(false)
  const [parameterValueID, setParameterValueID] = useState(0)
  const [errors, setErrors] = useState({});
  const [categoryList, setcategoryList] = useState([])
  const [categoryName, setCategoryName] = useState('');
  const textBoxRef = useRef(null);
  const classes = useStyles();
  const [isContainerFluid, setIsContainerFluid] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0); // Initialize with the default selected tab (e.g., 0 for the first tab)


  const { showLoading, hideLoading } =
    useContext(AppContext);
  useEffect(() => {
    showLoading();
    _applicationService.GetParameterList()
      .then((response) => {
        hideLoading();
        if (response != null && response.length > 0) {


          setParameterList(response)

          GetParameterValuesAgainstParameters();




        }
      })
      .catch((exception) => {
        console.log(exception);
      });
    GetCategoryList();
  }, []);

  useEffect(() => {
    if (showPopupModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showPopupModal]);

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  const GetCategoryList = () => {
    showLoading();
    _applicationService.GetCategoryList(Decrypt(localStorage.getItem("CompanyID")))
      .then((response) => {
        hideLoading();
        if (response != null && response.length > 0) {


          setcategoryList(response)





        }

      })
      .catch((exception) => {
        console.log(exception);
      });
  };
  const loadSectionList = () => {

    setParameterValueID(0)
    setParameterAgainstValues([]);
    document.getElementById("selectValues").value = "";
    _applicationService.GetSectionParameters()
      .then((response) => {
        if (response != null && response.length > 0) {


          setParameterAgainstValues(response)


          setShowTable(true);



        }
      })
      .catch((exception) => {
        console.log(exception);
      });
  }
  const loadParameterValuesList = () => {
    setParameterAgainstValues([])
    setParameterValueID(0)
      ;
    document.getElementById("selectValues").value = "";
    const id = document.getElementById("Parameters").value
    _applicationService.GetParameterValuesAgainstParameters(id)
      .then((response) => {
        if (response != null && response.length > 0) {


          setParameterAgainstValues(response)


          setShowTable(true);



        }
      })
      .catch((exception) => {
        console.log(exception);
      });






  }
  const GetParameterValuesAgainstParameters = (event) => {
    textBoxRef.current.style.borderColor = 'lightgrey';
    if (event.target.id === "Parameters") {
      var myerror = errors;
      document.getElementById("selectValues").value = "";
      setParameterValueID(0)
      const id = document.getElementById("Parameters").value
      const text = document.getElementById("Parameters").options[document.getElementById("Parameters").selectedIndex].text
      if (id === "") {
        myerror.parameter = "Please Select Parameter";
        setShowTable(false);
      }
      else {
        myerror.parameter = "";
        if (text === "Sections") {
          loadSectionList();

        }
        else {
          loadParameterValuesList();
        }
        setErrors({ ...myerror });

      }



    }
    else {
      var myerror = errors;

      if (event.target.value === "" || event.target.value === null) {
        myerror.value = "Please enter value";
      }
      else {
        myerror.value = "";
      }
      setErrors({ ...myerror });
    }

  }
  const editValue = (value, id) => {
    setParameterValueID(id)
    document.getElementById("selectValues").value = value;
    setErrors({})
    window.scrollTo(0, 0);
    textBoxRef.current.focus();
    textBoxRef.current.style.borderColor = 'red';

  }
  const saveUpdate = (e) => {
    e.preventDefault();
    var myerror = errors;
    const id = document.getElementById("Parameters").value
    const text = document.getElementById("Parameters").options[document.getElementById("Parameters").selectedIndex].text
    if (id === "") {
      myerror.parameter = "Please Select Parameter";
    }
    else {
      myerror.parameter = "";
      if (text === "Sections") {
        var flag = true;
        var data = {
          Value: document.getElementById("selectValues").value,
          ParameterValueID: parameterValueID,

          CreatedBy: Decrypt(localStorage.getItem("EmployeeID")),
        }
        if (data.Value === "" || data.Value === null) {
          myerror.value = "Please enter value";
          flag = false;
        }
        else {
          myerror.value = "";
        }
        if (flag === true) {
          showLoading();
          _applicationService.SettingsSaveSection(data).then((res) => {

            if (res.data > 0) {
              hideLoading();
              textBoxRef.current.style.borderColor = 'lightgrey';
              Toast.fire({
                icon: "success",
                title: "Saved Success",
              });
              loadSectionList();
            } else {
              Toast.fire({
                icon: "error",
                title: "Failed to Save",
              });
            }
          }).catch((err) => {
            console.log(err);
          });
        }
      }
      else {
        var flag = true;
        var data = {
          Value: document.getElementById("selectValues").value,
          ParameterValueID: parameterValueID,
          ParameterID: id,
          CreatedBy: Decrypt(localStorage.getItem("EmployeeID")),

        }
        if (data.Value === "" || data.Value === null) {
          myerror.value = "Please enter value";
          flag = false;
        }
        else {
          myerror.value = "";
        }
        if (flag === true) {
          showLoading();
          _applicationService.SettingsSaveParameterValues(data).then((res) => {

            if (res.data > 0) {
              hideLoading();
              textBoxRef.current.style.borderColor = 'lightgrey';
              Toast.fire({
                icon: "success",
                title: "Saved Success",
              });
              loadParameterValuesList()
            } else {
              Toast.fire({
                icon: "error",
                title: "Failed to Save",
              });
            }
          }).catch((err) => {
            console.log(err);
          });
        }
      }
    }
    setErrors({ ...myerror });
  }
  const Delete = (id) => {

    const text = document.getElementById("Parameters").options[document.getElementById("Parameters").selectedIndex].text
    if (text === "Sections") {
      Swal.fire({
        title: "Are you sure?",
        text: "Do You Want to Delete this Value?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          showLoading();
          _applicationService.DeleteSection(id).then((res) => {

            if (res > 0) {
              hideLoading();
              Toast.fire({
                icon: "success",
                title: "Delete Success",
              });
              loadSectionList()
            } else {
              Toast.fire({
                icon: "error",
                title: "Delete Failed!",
              });
            }
          }).catch((err) => {
            console.log(err);
          });
        }
      });

    }
    else {
      Swal.fire({
        title: "Are you sure?",
        text: "Do You Want to Delete this Value?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          showLoading();
          _applicationService.DeleteParameterValues(id).then((res) => {

            if (res > 0) {
              hideLoading();
              Toast.fire({
                icon: "success",
                title: "Delete Success",
              });
              loadParameterValuesList()
            } else {
              Toast.fire({
                icon: "error",
                title: "Delete Failed!",
              });
            }
          }).catch((err) => {
            console.log(err);
          });
        }
      });
    }
  }

  const DeleteCategory = (id) => {



    Swal.fire({
      title: "Are you sure?",
      text: "Do You Want to Delete this Category and Sections?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        showLoading();
        _applicationService.DeleteCategory(id).then((res) => {
          hideLoading();
          if (res > 0) {
            GetCategoryList();
            Toast.fire({
              icon: "success",
              title: "Delete Success",
            });

          } else {
            Toast.fire({
              icon: "error",
              title: "Delete Failed!",
            });
          }
        }).catch((err) => {
          console.log(err);
        });
      }
    });

  }


  const editCategory = (value) => {
    setCategoryName('');
    setCategoryName(value);
    showLoading();
    setShowPopupModal(true);

  };
  const columns = [
    { name: "value", label: "Values" },

    {
      label: "OPTIONS",
      name: "Options",

      options: {
        filter: false,
        sort: false,
        empty: true,

        responsive: true,

        customBodyRender: (values, tableMeta) => {
          const parameterValueID = parameterAgainstValues[tableMeta.rowIndex].parameterValueID;

          const value = parameterAgainstValues[tableMeta.rowIndex].value;
          return (
            <div className="ico-sec">
              <Tooltip title="Edit Value">
                <IconButton
                  type="button"
                  title=""
                  className="text-primary"
                  data-original-title="Edit"
                  data-toggle="modal"
                  data-target="#addRowModal"
                  // style={{ outlineColor: "green" }}
                  onClick={() => editValue(value, parameterValueID)}
                >
                  <FaEdit className="edit-icon" />{" "}
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete Value">
                <IconButton
                  className="text-danger"
                  aria-label="Delete"
                  onClick={() => Delete(parameterValueID)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>

              {/* <TrashFill   /> */}
            </div>
          );
        },
      },
    },
  ];
  const columns2 = [
    { name: "ParentSectionName", label: "Category" },
    {
      name: "Description", label: "Description",

      options: {
        filter: false,
        sort: true,
        empty: true,
        responsive: true,
        customBodyRender: (value) => (
          <Tooltip title={value}>
            <TableCell className={classes.customTableCell}>
              {value}
            </TableCell>
          </Tooltip>

        ),
      },
    },
    {
      name: "Sections", label: "Sections",

      options: {
        filter: false,
        sort: true,
        empty: true,

        responsive: true,
        customBodyRender: (value) => (
          <Tooltip title={value}>
            <TableCell className={classes.customTableCell}>
              {value}
            </TableCell>
          </Tooltip>

        ),
      },

    },
    {
      label: "OPTIONS",
      name: "Options",

      options: {
        filter: false,
        sort: false,
        empty: true,

        responsive: true,

        customBodyRender: (values, tableMeta) => {
          const ParentSectionName = categoryList[tableMeta.rowIndex].ParentSectionName;

          const ParentSectionID = categoryList[tableMeta.rowIndex].ParentSectionID;
          return (
            <div className="ico-sec">
              <Tooltip title="Edit Category">
                <IconButton
                  type="button"
                  title=""
                  className="text-primary"
                  data-original-title="Edit"
                  data-toggle="modal"
                  data-target="#addRowModal"
                  // style={{ outlineColor: "green" }}
                  onClick={() => editCategory(ParentSectionName)}
                >
                  <FaEdit className="edit-icon" />{" "}
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete Category">
                <IconButton
                  className="text-danger"
                  aria-label="Delete"
                  onClick={() => DeleteCategory(ParentSectionID)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>

              {/* <TrashFill   /> */}
            </div>
          );
        },
      },
    },
  ];

  const toggleContainerClass = () => {
    setIsContainerFluid((prevState) => !prevState);
  };
  return (
    <>
      <main className="main-content-wrap" >
        <div className="contact-list-area">
          <div className='container-fluid'  >

            <div className="row">
              <div className={`${isContainerFluid ? "col-md-12" : "col-md-9"}`}>
                <div className="card-box-style animatedwidth" >
                  <div className="fullwidthbuttonbox " onClick={toggleContainerClass}>
                    <span className="fullwidthbutton" >
                      {isContainerFluid ? <i className="fa fa-angle-double-left"></i> : <i className="fa fa-angle-double-right"></i>}

                    </span>
                  </div>
                  <div className="AppConfig">
                    <Tabs>
                      <TabList>
                        <Tab onClick={() => setSelectedTab(0)}><h3 style={{ fontWeight: "normal" }} className={selectedTab === 0 ? "selected-tab-content" : ""}>Application Configuration</h3></Tab>
                        <Tab onClick={() => setSelectedTab(1)}><h3 style={{ fontWeight: "normal" }} className={selectedTab === 1 ? "selected-tab-content" : ""}>Section Configuration</h3></Tab>

                      </TabList>


                      <TabPanel>
                        <form className="row g-3" style={{ marginTop: "5px", padding: "15px" }} autoComplete="off" onSubmit={saveUpdate}>


                          <div className="col-md-6">
                            <label className="form-label">
                              Dropdown <span className="text-danger"> *</span>
                            </label>


                            <select

                              class="form-select form-control"
                              id="Parameters"
                              onChange={(event) => GetParameterValuesAgainstParameters(event)}

                            >
                              <option value={""}>Select</option>
                              {parameterList.map((option) => (
                                <option
                                  key={option.parameterID}
                                  value={option.parameterID}

                                >
                                  {option.parameterName}
                                </option>
                              ))}

                            </select>
                            {errors.parameter && (
                              <span className="text-danger">
                                {errors.parameter}
                              </span>
                            )}
                          </div>
                          <div className="col-md-6">
                            <label className="form-label">
                              Values <span className="text-danger"> *</span>
                            </label>

                            <input type="text" class="form-control" ref={textBoxRef} id="selectValues" onChange={(event) => GetParameterValuesAgainstParameters(event)} />
                            {errors.value && (
                              <span className="text-danger">
                                {errors.value}
                              </span>
                            )}

                          </div>
                          <div className="col-12 text-end">
                            <button type="submit" className="btn btn-primary" >
                              Save
                            </button>

                          </div>
                          {showTable ? (
                            <MUIDataTable
                              data={parameterAgainstValues}

                              columns={columns}
                              options={{
                                responsive: true,
                                download: false,
                                print: false,
                                filter: false,
                                rowsPerPageOptions: [10, 50, 100],
                                customToolbar: () => (
                                  <>

                                  </>
                                ),
                                selectableRows: false,

                                viewColumns: false,
                              }}
                            />
                            //                     <table className="table align-middle mb-0">
                            //                     <thead>
                            //                       <tr>
                            //                         <th>Values</th>


                            //                         <th>Action(s)</th>
                            //                       </tr>
                            //                     </thead>
                            //                     <tbody>
                            //                       {parameterAgainstValues != null && parameterAgainstValues.length > 0 ? (
                            //                         parameterAgainstValues.map((v, i) => {

                            //                           return (
                            //                             <tr key={i}>
                            //                               {" "}
                            //                               <td >{v.value}</td>


                            //  <td > 
                            //                                 <span className="location row">
                            //                                   <button
                            //                                     type="button"
                            //                                     title=""
                            //                                     class="btn btn-link  btn-lg col-2"
                            //                                     onClick={()=>editValue(v.value,v.parameterValueID)}
                            //                                   >


                            //                                       <FaEdit className="edit-icon" color="gray"/>

                            //                                   </button>

                            //                                   <button
                            //                                     type="button"
                            //                                     title=""
                            //                                     class="btn btn-link btn-lg col-2"
                            //                                     onClick={()=>Delete(v.parameterValueID)}

                            //                                   >
                            //                                     <FaTrashAlt color="red" />
                            //                                   </button>


                            //                                 </span>

                            //                               </td>


                            //                             </tr>
                            //                           );
                            //                         })
                            //                       ) : (
                            //                         <>No Items </>
                            //                       )}
                            //                     </tbody>

                            //                   </table>
                          ) : <></>}


                        </form>
                      </TabPanel>
                      <TabPanel>
                        <form className="row g-3" style={{ marginTop: "5px", padding: "15px" }} autoComplete="off" >

                          {/* <div class="col-11" style={{position:"absolute"}}>
                       <a
                              className="col-1  btn btn-link "
                             
                            style={{float:"right"}}>
                              <i className=" fa fa-plus" onClick={()=>{
                                  setCategoryName('');
                                 setShowPopupModal(true)
                              }}></i>
                            </a>
          </div> */}
                          {/* <table className="table align-middle mb-0">
                   <thead>
                     <tr>
                       <th>Category</th>
                       <th>Description</th>
                       <th>Sections</th>

                       <th>Action(s)</th>
                     </tr>
                   </thead>
                   <tbody>
                     {categoryList != null && categoryList.length > 0 ? (
                       categoryList.map((v, i) => {
                       
                         return (
                           <tr key={i}>
                             {" "}
                             <td >{v.ParentSectionName}</td>
                 
                             <td >{v.Description}</td>
                             <td >{v.Sections}</td>                      
<td > 
                               <span className="location row">
                                 <button
                                   type="button"
                                   title=""
                                   class="btn btn-link  btn-lg col-2"
                                   onClick={()=>editCategory(v.ParentSectionName)}
                                 >
                                 
                                  
                                     <FaEdit className="edit-icon" color="gray"/>
                                
                                 </button>

                                 <button
                                   type="button"
                                   title=""
                                   class="btn btn-link btn-lg col-2"
                                   onClick={()=>DeleteCategory(v.ParentSectionID)}
                                  
                                 >
                                   <FaTrashAlt color="red" />
                                 </button>
                                  
                                 
                               </span>
                             
                             </td>
                            
                          
                           </tr>
                         );
                       })
                     ) : (
                       <>No Items </>
                     )}
                   </tbody>
                   
                 </table> */}
                          <MUIDataTable
                            data={categoryList}

                            columns={columns2}
                            options={{
                              responsive: true,
                              download: false,
                              print: false,
                              rowsPerPageOptions: [10, 50, 100],
                              filter: false,
                              customToolbar: () => (
                                <>
                                  <Tooltip title="Add Category">
                                    <button
                                      type="button"
                                      title=""
                                      class="btn btn-link"
                                      data-original-title="Edit"
                                      data-toggle="modal"
                                      data-target="#addRowModal"
                                      style={{
                                        outlineColor: "green",
                                        color: "rgba(0, 0, 0, 0.54)",
                                        background: "#FFFFFF",
                                      }}
                                      onClick={() => {
                                        setCategoryName('');
                                        setShowPopupModal(true)
                                      }}
                                    >
                                      <AddIcon className="edit-icon" />{" "}
                                    </button>
                                  </Tooltip>
                                </>
                              ),
                              selectableRows: false,

                              viewColumns: false,
                            }}
                          />


                          {showPopupModal && (
                            <SectionConfigPopup

                              setShowPopupModal={setShowPopupModal}
                              GetCategoryList={GetCategoryList}
                              categoryName={categoryName}

                            />
                          )}


                        </form>
                      </TabPanel>
                    </Tabs>
                  </div>

                </div>
              </div>
              {!isContainerFluid ? <div className="col-md-3">

                {/* <div className="card-box-style"></div> */}
              </div> : null}
            </div>

          </div>
        </div>
      </main>
    </>
  );
}


