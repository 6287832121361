import React, { useEffect, useState, useContext, useRef } from "react";
import CandidateQuestionViewServices from "../../services/CandidateQuestionViewService";
import { FaEraser, FaFlag } from 'react-icons/fa';
import Header from "../Candidate_QuestionView/Header";
import "../../assets/js/custom.js";
import '../../assets/css/customizeSideMenu.css';
import '../../assets/css/metismenu.min.css';
import '../../assets/css/simplebar.min.css';
import '../../../node_modules/metismenu/dist/metisMenu.min.js'
import '../../../node_modules/simplebar/dist/simplebar.min.js';
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../../services/ContextProvider";
import { boolean } from "yup";
import { IMAGE_URL } from '../../env';
import ExamService from "../Exam/ExamService";
import QuestionBankService from '../../services/QuestionBankService';


const _questionBankService = new QuestionBankService();
var candidateQuestionServ = new CandidateQuestionViewServices();
const _examservice = new ExamService();
export default function QuestionPreview({ questList, questID, setPreview, sectionID }) {
    console.log(sectionID);
    const { showLoading, hideLoading, setCookies, tempModulePrivileage, isAuth, SetisAuth } =
        useContext(AppContext);
    const [questionList, setQuestionList] = useState({});
    const [answerLists, setAnswerList] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [currentAnswers, setcurrentAnswers] = useState([]);
    const [currentQuestion, setcurrentQuestion] = useState({});
    const [currentQuestionId, setcurrentQuestionId] = useState(0);
    const location = useLocation();
    const [questionImageURL, setQuestionImageURL] = useState();
    const [questionID, setQuestionID] = useState(location.state?.qId ?? 0);
    const navigate = new useNavigate();
    useEffect(() => {
        // GetQuestionList();
        questionPreview();
    }, [questID]);

    const onCloseModal = () => {
        setPreview(false);
    }
    const questionPreview = () => {
        showLoading();
        if (questID > 0) {

            setQuestionList(questList);
            if (questID > 0) {
                const filteredQuestions = questList.filter(v =>
                    v.questionID === questID
                );
                const matchedIndex = questList.indexOf(filteredQuestions[0]);
                setcurrentQuestion(questList[matchedIndex]);

                setCurrentQuestionIndex(matchedIndex);
                var currentQues = questList[matchedIndex].questionID;
                newFunction(currentQues);
            }
        }
        hideLoading();

    }

    function newFunction(questionID) {

        _questionBankService
            .GetQuestionbankById(questionID)
            .then((result) => {
                setAnswerList(result?.data);
                const curroption = result?.data.filter((answer) => answer.questionID === questionID);
                setcurrentAnswers([...curroption]);
                setQuestionImageURL(curroption[0].questionImageURL);

            }).catch((err) => {
                console.log(err);
            });

    }


    const getPlainText = (htmlString) => {
        const element = document.createElement("div");
        element.innerHTML = htmlString;
        return element.innerText;
    };


    const showNextQuestion = () => {

        const nextQuestionIndex = currentQuestionIndex + 1;
        if (nextQuestionIndex < questionList?.length) {
            setcurrentQuestion(questionList[nextQuestionIndex]);
            setCurrentQuestionIndex(nextQuestionIndex);
            const nextQuestionID = questionList[nextQuestionIndex].questionID;
            newFunction(nextQuestionID);
        } else {
            console.log("something went wrong");
        }
    };

    const showPrevQuestion = () => {
        const prevquesIndex = currentQuestionIndex - 1;
        setcurrentQuestion(questionList[prevquesIndex]);
        setCurrentQuestionIndex(prevquesIndex);
        var currentQues = questionList[prevquesIndex].questionID;
        newFunction(currentQues);
        setcurrentQuestionId(currentQues);


    };


    const navigateToedit = (qId) => {
        debugger;
        navigate("/QuestionBank", {
            state: { qId: qId, sId: sectionID },
        });
    }
    return (
        <div className="modal fade show" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-modal="true" role="dialog" style={{ display: 'flex', alignItems: 'center' }}>
            <div className="modal-dialog modal-xl" style={{ margin: 'auto' }}>
                <div className="modal-content my-auto">
                    <div className="modal-header">
                        <h5 className="modal-title MuiTypography-h6" id="staticBackdropLabel">Question Preview</h5>

                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            fdprocessedid="ttcp2q"
                            onClick={onCloseModal}
                        />
                    </div>

                    <div className="modal-body ">

                        <div className="overview-content-area">
                            <div className="row">
                                <div className="col-10">

                                </div>
                                {/* <div className="col-2">
                            <button className="btn btn-primary" type="buttom" value="Back" onClick={onClick} > Back</button>
                        </div> */}
                            </div>


                            <div className="fixed-content questionandsectionheader">
                                <div className="row align-items-center">
                                    <div className="col-lg-6 col-sm-6">
                                        <div className="page-title">
                                            <h4 style={{ paddingLeft: '25px', fontWeight: 'bold' }}>Question {currentQuestionIndex + 1} / {questionList.length}</h4>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-6">
                                        <span style={{ paddingLeft: '35%' }}>Section: {currentQuestion.sectionName}</span>

                                        {/* <input type="button" value="Back" title="Back" className="back-button backbutton-end" style={{ float: 'right', marginBottom: '13px' }} onClick={() => onClick(tab)} /> */}

                                    </div>
                                </div>
                            </div>

                            <div className="card-box-style">
                                <div className="" style={{ marginBottom: '0px' }}>
                                    <h5>{getPlainText(currentQuestion && currentQuestion.question)}</h5>
                                    {
                                        questionImageURL != "" && questionImageURL != undefined ? <img src={IMAGE_URL + questionImageURL} style={{ width: '300px', height: 'auto' }} /> : <></>}
                                </div>
                                <div className="row mt-2">
                                    {currentAnswers?.map((option, index) => {
                                        return <div className="col-lg-12 col-sm-12" key={index}>
                                            <div className={`single-audience d-flex justify-content-between align-items-center  `} >
                                                <div className={`audience-content`}>
                                                    <label>
                                                        <input
                                                            disabled={true}
                                                            type="radio"
                                                            value={option.questionOptionID}

                                                        />
                                                        {' '}  {getPlainText(option.answerValue)}
                                                        {option?.answerImageURL ?
                                                            <img
                                                                src={IMAGE_URL + option?.answerImageURL}
                                                                alt="Image"
                                                                style={{ maxWidth: '100%', height: '40px' }}
                                                                className="text-end"
                                                            />
                                                            : <></>}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="modal-footer">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-7">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" fdprocessedid="t27w74" onClick={onCloseModal}>
                                        Close
                                    </button>
                                </div>
                                <div className="col-md-5 text-end">
                                    {currentQuestionIndex > 0 && (
                                        <button
                                            className="btn btn-sm btn-primary"
                                            onClick={showPrevQuestion}
                                            style={{ marginRight: '10px' }}
                                        >
                                            « Prev
                                        </button>
                                    )}
                                    {currentQuestionIndex < questionList.length - 1 && (
                                        <button
                                            className="btn btn-sm btn-primary"
                                            onClick={() => showNextQuestion()}
                                            style={{ marginRight: '10px' }}
                                        >
                                            Next »
                                        </button>
                                    )}
                                    <button
                                        className="btn btn-sm btn-primary"
                                        onClick={() => navigateToedit(currentQuestion.questionID)}

                                    >
                                        Edit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    );
}



