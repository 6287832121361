import React, { useEffect, useContext } from 'react';
import { AppContext } from '../../services/ContextProvider';
import { Doughnut } from 'react-chartjs-2';
import $ from "jquery";
import ExamService from '../Exam/ExamService';
import axios from 'axios';
import Swal from 'sweetalert2';

const _examservice = new ExamService();
const MyPDF = ({
    ReportList, getReportCurrentTime, sectionCount, CorrectCount, PartialCorrectCount, InCorrectCount,
    unAnsweredCount, dataforPie, options, precentage, TotalCount, negativeMarkPercentage, marks, showpdf, result, centerText,
    setShowPdf, main, setMain
}) => {
    const { showLoading, hideLoading, isAuth } =
        useContext(AppContext);

    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });

    useEffect(() => {
        if (showpdf) {
            setTimeout(() => {  
                exportPDF();
                // sharepdf();
            }, 2500);
        }
        if (main?.length > 0) {
            setTimeout(() => {
                sharepdf();
            }, 2500);
        }
    }, []);
    const exportPDF = () => {
        // Convert the DOM element to a drawing using kendo.drawing.drawDOM
        kendo.drawing
            .drawDOM($("#reviewexamcontent"))
            .then(function (group) {
                // Render the result as a PDF file
                return kendo.drawing.exportPDF(group, {
                    paperSize: "A3",
                    margin: "1cm"
                });
            })
            .done(function (data) {
                // Save the PDF file
                kendo.saveAs({
                    dataURI: data,
                    fileName: `${ReportList[0]?.candidateName}_Scorecard.pdf`,
                    proxyURL: "https://demos.telerik.com/kendo-ui/service/export"
                });
            });
        hideLoading();



        setShowPdf(false);
        Toast.fire({
            icon: "success",
            title: "Pdf downloaded Successfully",
        });
    };
    const sharepdf = async () => {

        try {
            const formData = new FormData();
            const pdfBlob = await kendo.drawing
                .drawDOM($("#reviewexamcontent"))
                .then(function (group) {
                    // Render the result as a PDF file
                    return kendo.drawing.exportPDF(group, {
                        paperSize: "auto",
                        margin: "1cm"
                    });
                });

            // Create a Blob from the PDF data
            const byteCharacters = atob(pdfBlob.split(",")[1]);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: "application/pdf" });

            // Append the PDF file with a custom filename to FormData
            formData.append('attachment', blob, `${ReportList[0]?.candidateName}_Scorecard.pdf`);
            formData.append('fileName', `${ReportList[0]?.candidateName}_Scorecard.pdf`);
            formData.append('email', ReportList && ReportList.length > 0 ? ReportList[0]?.email : '');
            formData.append('body', main[2]);
            formData.append('subject', main[1]);

            // Send the FormData in a POST request
            const response = await axios.post('Report/UploadBlob', formData);

            if (response.status === 200 && response.data === "Success") {
                hideLoading();
                setMain([]);
                Toast.fire({
                    icon: "success",
                    title: "Mail Sent Successfully",
                });

                // Update the state variable (show) if needed
                // Example: setShow(false);
            } else {
                hideLoading();
                // Handle errors or display appropriate messages
                console.error('Failed to send email.');
            }
        } catch (error) {
            hideLoading();
            // Handle any exceptions or errors here
            console.error('An error occurred:', error);
        }
    }

    return (


        <div>
            {showpdf ? <div id="reviewexamcontent" style={{ width: "55%" }}>
                <div className="container mt-3">
                    <div className='row justify-content-center'>
                        <div className='col-md-12'>
                            <h4>Vaanam Technologies Private Limited</h4>
                            <h4>Score Card |{ReportList && ReportList.length > 0 ? ReportList[0].exam : ''}</h4>
                        </div>
                        <div className='col-md-12'>
                            <div className="card mt-2" style={{ width: "95%" }}>
                                <table className="table table-borderless">
                                    <thead>
                                        <tr>
                                            <th ><p style={{ fontWeight: "600", fontSize: '14px' }}>{ReportList && ReportList[0]?.candidateName
                                            }</p></th>
                                            <th><p style={{ fontWeight: "600", fontSize: '14px' }}>{ReportList[0]?.examStarted && new Date(ReportList[0].examStarted.split("T")[0]).toLocaleDateString('en-US', {
                                                day: '2-digit',
                                                month: 'short',
                                                year: 'numeric'
                                            }).replace(/,/g, '')}

                                                &nbsp;
                                                {ReportList[0]?.examStarted && new Date(ReportList[0].examStarted).toLocaleTimeString('en-US', {
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    hour12: true
                                                })}</p></th>
                                            <th ><p style={{ fontWeight: "600", fontSize: '14px' }}>
                                                {ReportList[0]?.examEnd && new Date(ReportList[0].examEnd.split("T")[0]).toLocaleDateString('en-US', {
                                                    day: '2-digit',
                                                    month: 'short',
                                                    year: 'numeric'
                                                }).replace(/,/g, '')}
                                                &nbsp;
                                                {ReportList[0]?.examEnd && new Date(ReportList[0].examEnd).toLocaleTimeString('en-US', {
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    hour12: true
                                                })}
                                            </p>
                                            </th>
                                            <th ><p style={{ fontWeight: "600", fontSize: '14px' }}>

                                                {_examservice.MillisecondsToMinutes(ReportList[0]?.duration)} Minutes
                                            </p>

                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><span style={{ fontSize: "10px" }}>Training 2023</span></td>
                                            <td>
                                                <span style={{ fontSize: "10px" }}>Start Time</span>
                                            </td>
                                            <td>
                                                <span style={{ fontSize: "10px" }}> End Time</span> </td>
                                            <td>

                                                <span style={{ fontSize: "10px" }}> Exam Duration</span> </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>


                    <div className='row justify-content-center mt-4 me-4'>
                        <h5 className="card-title" style={{ textAlign: 'center' }}>
                            Score  <span>{Math.round(precentage)}%</span>
                        </h5>
                        <div className='col-6'>
                            <      div class="progress" style={{ height: '250%' }}>
                                <div className={`progress-bar w-${Math.round(precentage).toString()}`} role="progressbar" aria-label="Basic example" aria-valuenow={Math.round(precentage).toString()} aria-valuemin="0" aria-valuemax="100" style={{ backgroundColor: result == true ? '#FF0000' : '#03C04A', width: Math.round(precentage).toString() + "%" }}>


                                    <span class="label label-warning">{result == true ? 'Fail' : 'Pass'}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-center me-4'>
                        <div className="col-lg-12 col-sm-12 mt-5">
                            <div className='row'>


                                <div className='col-4 reviewreportcard reviewreportcardleftprint'>
                                    <p>{TotalCount}</p>
                                    <span>Total Questions</span>
                                    <hr></hr>

                                    <p>{marks}</p>
                                    <span>Marks</span>
                                    <hr></hr>
                                    <p>{negativeMarkPercentage}</p>
                                    <span>Negative Marks</span>
                                    <hr></hr>
                                    <p>
                                        {ReportList.length > 0 ? ReportList[0]?.timeTaken >= 60
                                            ? _examservice.convertSecondsToMinutes(ReportList[0].timeTaken) + ' Minutes'
                                            : ReportList[0].timeTaken + ' Seconds' : ''}
                                    </p>
                                    <span>Time Taken</span>
                                    <hr></hr>

                                </div>
                                <div className='col-4'>
                                    {ReportList.map((rep) => (
                                        <div key={rep.candidateid}>

                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '290px' }} className='doughnut-chart-container'>
                                                <div style={{ width: '300px', position: 'relative' }}>
                                                    <Doughnut data={dataforPie} options={options} />
                                                    {centerText.display && (
                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                top: '40%',
                                                                left: '50%',
                                                                transform: 'translate(-50%, -50%)',
                                                                fontSize: '24px',
                                                                fontWeight: 'bold',
                                                                color: '#000',
                                                            }}
                                                        >
                                                            {centerText.text}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                    ))}

                                </div>
                                <div className='col-4 reviewreportcard reviewreportcardrightprint'>
                                    <p>{CorrectCount}</p>
                                    <span>Correct</span>
                                    <hr></hr>

                                    <p>{PartialCorrectCount}</p>
                                    <span>Partially Correct</span>
                                    <hr></hr>
                                    <p>{InCorrectCount}</p>
                                    <span>Incorrect</span>
                                    <hr></hr>
                                    <p>{unAnsweredCount}</p>
                                    <span>Unanswered</span>
                                    <hr></hr>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class='row justify-content-center'>
                        <div className='col-md-12'>
                            <table className="table align-middle mt-2">
                                <thead>
                                    <tr>
                                        <th>Section</th>
                                        <th className='centerContent'>% Correct</th>
                                        <th className='centerContent'>Correct</th>
                                        <th className='centerContent'>Partially</th>
                                        <th className='centerContent'>Incorrect</th>
                                        <th className='centerContent'>Unanswered</th>
                                        <th className='centerContent'>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sectionCount.map((info, index) => (
                                        <tr key={info.candidateid}>
                                            <td style={{ width: '55%' }}>
                                                <div className="info">
                                                    <h6>{info.sectionName}</h6>
                                                </div>
                                            </td>
                                            <td className='centerContent'>

                                                {info.correctPercentage != null && info.correctPercentage != "" ? parseFloat(info.correctPercentage).toFixed(2) : <></>}

                                            </td>
                                            <td className='centerContent'>
                                                {info.correctCount}
                                            </td>
                                            <td className='centerContent'>
                                                {info.partialCorrectCount}
                                            </td>

                                            <td className='centerContent'>
                                                {info.incorrectCount}
                                            </td>
                                            <td className='centerContent'>
                                                {info.unAnsweredCount}
                                            </td>
                                            <td className='centerContent'>
                                                {info.totalCount}
                                            </td>
                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <p className='text-center mt-4' style={{ fontSize: "12px" }}>
                        {"Report generated on "}
                        <label >{getReportCurrentTime}</label>
                    </p>
                    <p className='text-center' style={{ fontSize: "10px" }}>----------End of the Report-----------</p>
                </div>
            </div>
                : main.length > 0 ? <div id="reviewexamcontent" style={{ width: "55%" }}>
                    <div className="container mt-3">
                        <div className='row justify-content-center'>
                            <div className='col-md-12'>
                                <h4>Vaanam Technologies Private Limited</h4>
                                <h4>Score Card |{ReportList && ReportList.length > 0 ? ReportList[0].exam : ''}</h4>
                            </div>
                            <div className='col-md-12'>
                                <div className="card mt-2" style={{ width: "95%" }}>
                                    <table className="table table-borderless">
                                        <thead>
                                            <tr>
                                                <th style={{ fontWeight: "600" }}><p >{ReportList && ReportList[0]?.candidateName
                                                }</p></th>
                                                <th style={{ fontWeight: "600" }}><p>{ReportList[0]?.examStarted && new Date(ReportList[0].examStarted.split("T")[0]).toLocaleDateString('en-US', {
                                                    day: '2-digit',
                                                    month: 'short',
                                                    year: 'numeric'
                                                }).replace(/,/g, '')}

                                                    &nbsp;
                                                    {ReportList[0]?.examStarted && new Date(ReportList[0].examStarted).toLocaleTimeString('en-US', {
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                        hour12: true
                                                    })}</p></th>
                                                <th style={{ fontWeight: "600" }}><p>
                                                    {ReportList[0]?.examEnd && new Date(ReportList[0].examEnd.split("T")[0]).toLocaleDateString('en-US', {
                                                        day: '2-digit',
                                                        month: 'short',
                                                        year: 'numeric'
                                                    }).replace(/,/g, '')}
                                                    &nbsp;
                                                    {ReportList[0]?.examEnd && new Date(ReportList[0].examEnd).toLocaleTimeString('en-US', {
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                        hour12: true
                                                    })}
                                                </p>
                                                </th>
                                                <th style={{ fontWeight: "600" }}><p >

                                                    {_examservice.MillisecondsToMinutes(ReportList[0]?.duration)} Minutes
                                                </p>

                                                </th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><span>Training 2023</span></td>
                                                <td>
                                                    <span>Start Time</span>
                                                </td>
                                                <td>
                                                    <span> End Time</span> </td>
                                                <td>

                                                    <span> Exam Duration</span> </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>


                        <div className='row justify-content-center mt-4 me-4'>
                            <h5 className="card-title" style={{ textAlign: 'center' }}>
                                Score  <span>{Math.round(precentage)}%</span>
                            </h5>
                            <div className='col-6'>
                                <      div class="progress" style={{ height: '250%' }}>
                                    <div className={`progress-bar w-${Math.round(precentage).toString()}`} role="progressbar" aria-label="Basic example" aria-valuenow={Math.round(precentage).toString()} aria-valuemin="0" aria-valuemax="100" style={{ backgroundColor: result == true ? '#FF0000' : '#03C04A', width: Math.round(precentage).toString() + "%" }}>


                                        <span class="label label-warning">{result == true ? 'Fail' : 'Pass'}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row justify-content-center me-4'>
                            <div className="col-lg-12 col-sm-12 mt-5">
                                <div className='row'>


                                    <div className='col-4 reviewreportcard reviewreportcardleftprint'>
                                        <p>{TotalCount}</p>
                                        <span>Total Questions</span>
                                        <hr></hr>

                                        <p>{marks}</p>
                                        <span>Marks</span>
                                        <hr></hr>
                                        <p>{negativeMarkPercentage}</p>
                                        <span>Negative Marks</span>
                                        <hr></hr>
                                        <p>
                                            {ReportList.length > 0 ? ReportList[0]?.timeTaken >= 60
                                                ? _examservice.convertSecondsToMinutes(ReportList[0].timeTaken) + ' Minutes'
                                                : ReportList[0].timeTaken + ' Seconds' : ''}
                                        </p>
                                        <span>Time Taken</span>
                                        <hr></hr>

                                    </div>
                                    <div className='col-4'>
                                        {ReportList.map((rep) => (
                                            <div key={rep.candidateid}>

                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '290px' }} className='doughnut-chart-container'>
                                                    <div style={{ width: '300px', position: 'relative' }}>
                                                        <Doughnut data={dataforPie} options={options} />
                                                        {centerText.display && (
                                                            <div
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: '40%',
                                                                    left: '50%',
                                                                    transform: 'translate(-50%, -50%)',
                                                                    fontSize: '24px',
                                                                    fontWeight: 'bold',
                                                                    color: '#000',
                                                                }}
                                                            >
                                                                {centerText.text}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                        ))}

                                    </div>
                                    <div className='col-4 reviewreportcard reviewreportcardrightprint'>
                                        <p>{CorrectCount}</p>
                                        <span>Correct</span>
                                        <hr></hr>

                                        <p>{PartialCorrectCount}</p>
                                        <span>Partially Correct</span>
                                        <hr></hr>
                                        <p>{InCorrectCount}</p>
                                        <span>Incorrect</span>
                                        <hr></hr>
                                        <p>{unAnsweredCount}</p>
                                        <span>Unanswered</span>
                                        <hr></hr>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class='row justify-content-center'>
                            <div className='col-md-12'>
                                <table className="table align-middle mt-2">
                                    <thead>
                                        <tr>
                                            <th>Section</th>
                                            <th className='centerContent'>% Correct</th>
                                            <th className='centerContent'>Correct</th>
                                            <th className='centerContent'>Partially</th>
                                            <th className='centerContent'>Incorrect</th>
                                            <th className='centerContent'>Unanswered</th>
                                            <th className='centerContent'>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sectionCount.map((info, index) => (
                                            <tr key={info.candidateid}>
                                                <td style={{ width: '55%' }}>
                                                    <div className="info">
                                                        <h6>{info.sectionName}</h6>
                                                    </div>
                                                </td>
                                                <td className='centerContent'>

                                                    {info.correctPercentage != null && info.correctPercentage != "" ? parseFloat(info.correctPercentage).toFixed(2) : <></>}

                                                </td>
                                                <td className='centerContent'>
                                                    {info.correctCount}
                                                </td>
                                                <td className='centerContent'>
                                                    {info.partialCorrectCount}
                                                </td>

                                                <td className='centerContent'>
                                                    {info.incorrectCount}
                                                </td>
                                                <td className='centerContent'>
                                                    {info.unAnsweredCount}
                                                </td>
                                                <td className='centerContent'>
                                                    {info.totalCount}
                                                </td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <p className='text-center mt-4' style={{ fontSize: "12px" }}>
                            {"Report generated on "}
                            <label >{getReportCurrentTime}</label>
                        </p>
                        <p className='text-center' style={{ fontSize: "12px" }}>----------End of the Report-----------</p>
                    </div>
                </div> : <></>}




        </div>

    );
};

export default MyPDF;
