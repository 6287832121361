
import React, { useEffect, useState, useContext, useRef } from "react";
import CandidateQuestionViewServices from "../../services/CandidateQuestionViewService";
import { FaEraser, FaFlag } from 'react-icons/fa';
import Header from "./ExamPreviewHeader";
import SideNav from "../Candidate_QuestionView/Sidenav";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../../services/ContextProvider";
import FlagCount from "../Candidate_QuestionView/FlagCount";
import { IMAGE_URL } from '../../env';
import ExamService from "../Exam/ExamService";

import CandidatepreviewHeader from "../Candidate_QuestionView/CandidatePreviewHeader";

var candidateQuestionServ = new CandidateQuestionViewServices();
var examServicesobj = new ExamService();

export default function ExamPreview() {

    const { showLoading, hideLoading, setCookies, tempModulePrivileage, isAuth, SetisAuth, removeCookies } =
        useContext(AppContext);
    const [questionList, setQuestionList] = useState([]);
    const [answerLists, setAnswerList] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [currentAnswers, setcurrentAnswers] = useState([]);
    const [selectedAnswers, setSelectedAnswers] = useState([]);
    const [currentQuestion, setcurrentQuestion] = useState({});
    const [currentQuestionId, setcurrentQuestionId] = useState(0);
    const location = useLocation();
    const [candidateID, setCandidateID] = useState(location.state?.candidateID ?? 0);
    const [CandidateName, setCandidateName] = useState(location.state?.canditateName ?? null);
    const [examID, setexamID] = useState(location.state?.examID ?? 0);
    const navigate = new useNavigate();
    const questionidRef = useRef(null);
    const [answer, setAnswer] = useState('');
    const [redCount, setredCount] = useState(0);
    const [greenCount, setgreenCount] = useState(0);
    const [progress, setProgress] = useState(0);
    const [answerCount, setanswercount] = useState([]);
    const [examType, setExamtype] = useState('One question per page');
    const [scoreResult, setScoreResult] = useState('');
    const [QuestionNav, setQuestionNav] = useState(false);
    const [AttendAllQuestion, setAttenAllQuestion] = useState(false);
    const [SubmitBefortime, setSubmitBefortime] = useState(false);
    const [negativeMarkScore, setnegativeMarkScore] = useState('');
    const [questionID, setQuestionID] = useState(location.state?.questionID ?? 0)
    let examAttendID = 0;

    var questionDisplayId;
    var displayResultID;

    useEffect(() => {
        showLoading();

        const fetchData = async () => {

            try {
                GetQuestionList();
            } catch (err) {
                console.log(err);
            }

        };
        fetchData();

    }, []);
    useEffect(() => {
        candidateQuestionServ.NegativeMarks_Score(examID).then((x) => {
            if (x != null) {

                candidateQuestionServ.Candidate_Exam_List(examID).then((res) => {

                    if (res != null) {
                        questionDisplayId = res?.data[0]?.QuestionDisplayID;
                        displayResultID = res?.data[0]?.DisplayResultID;
                        const AttendAllQuestion = res?.data[0]?.AttendAllQuestion;

                    }
                });
                const NegativeMarkID = x?.data[0]?.NegativeMarkID;
                examServicesobj.GetExamParameterList().then((res) => {
                    if (res != null) {
                        const candidateGroup = res?.parameterList?.filter(
                            (option) => option.parameterValueID == NegativeMarkID
                        );
                        setnegativeMarkScore(candidateGroup[0].value);

                        const examType = res?.parameterList.filter(
                            (option) => option.parameterValueID == questionDisplayId
                        );
                        setExamtype(examType[0]?.value);
                        const DisplayType = res?.parameterList.filter(
                            (option) => option.parameterValueID == displayResultID
                        );
                    }
                });
                candidateQuestionServ.Candidate_ExamOption(examID).then((res) => {
                    if (res != null) {

                        setAttenAllQuestion(res?.data[0]?.AttendAllQuestion);
                        setQuestionNav(res?.data[0]?.QuestionNav);
                        setSubmitBefortime(res?.data[0]?.SubmitBefortime)
                        console.log(QuestionNav);
                    }
                });
            }
        }).catch((err) => {
            console.log(err);
        });
    }, []);



    const GetQuestionList = () => {

        if (examID > 0) {
            candidateQuestionServ.questionList(candidateID, examID, examAttendID).then((res) => {
                console.log(res)
                const { questionList, answerList } = res.data;

                if (questionList?.length > 0) {
                    setQuestionList([...questionList]);

                    if (questionID > 0) {
                        const filteredQuestions = questionList.filter(v =>
                            v.questionID === questionID
                        );
                        const matchedIndex = questionList.indexOf(filteredQuestions[0]);
                        setcurrentQuestion(questionList[matchedIndex])
                        setCurrentQuestionIndex(matchedIndex);
                        var currentQues = questionList[matchedIndex].questionID;
                        if (answerList?.length > 0) {
                            setAnswerList([...answerList]);
                            const curroption = answerList.filter((answer) => answer.questionID === currentQues);
                            setcurrentAnswers([...curroption]);
                        }
                    } else {
                        setcurrentQuestion(questionList[currentQuestionIndex])
                        var currentQues = questionList[currentQuestionIndex].questionID;
                        if (answerList?.length > 0) {
                            setAnswerList([...answerList]);
                            const curroption = answerList.filter((answer) => answer.questionID === currentQues);
                            setcurrentAnswers([...curroption]);
                        }
                    }

                }
                hideLoading();

            }).catch((err) => {
                hideLoading();
                console.log(err);
            });

            candidateQuestionServ.getAnswerHistory(candidateID, examID, examAttendID).then((res) => {
                if (res?.data?.length > 0) {
                    setSelectedAnswers(res.data);
                    const selectedAnswers = res.data.map((answer) => parseInt(answer.finalAnswer));
                    setSelectedAnswers(selectedAnswers);

                }
            }).catch((err) => {
                console.log(err);
            });
        }

        else {
            navigate("*");
        }
    }


    const getPlainText = (htmlString) => {
        // const element = document.createElement("div");
        // element.innerHTML = htmlString;
        return `${htmlString}`;
    };


    //Next page and validation
    const showNextQuestion = () => {
        var color;
        const element = document.getElementById(`background${currentQuestion.questionID}`);
        if (answer != null && selectedAnswers[currentQuestionIndex]?.finalAnswer) {
            answercount(currentQuestion.questionID, selectedAnswers[currentQuestionIndex]?.finalAnswer);
            color = element.style.background = '#4fcb8d';
            element.style.color = 'white';
        }
        else {
            color = element.style.background = 'gray';
            element.style.color = 'white';
        }
        setAnswer('');
        console.log(currentQuestion.questionID)
        const nextQuestionIndex = currentQuestionIndex + 1;
        if (nextQuestionIndex < questionList?.length) {
            setcurrentQuestion(questionList[nextQuestionIndex]);
            setCurrentQuestionIndex(nextQuestionIndex);
            const nextQuestionID = questionList[nextQuestionIndex].questionID;
            const currentOptions = answerLists.filter(
                (answer) => answer.questionID === nextQuestionID
            );
            setcurrentAnswers([...currentOptions]);
        } else {
            console.log("something went wrong");
        }
    };

    //previous Page
    const showPrevQuestion = () => {
        var color;
        const element = document.getElementById(`background${currentQuestion.questionID}`);
        if (answer != null && selectedAnswers[currentQuestionIndex]?.finalAnswer) {
            color = element.style.background = '#4fcb8d';
            element.style.color = 'white';
            answercount(currentQuestion.questionID, selectedAnswers[currentQuestionIndex]?.finalAnswer);
        }
        else {
            color = element.style.background = 'gray';
            element.style.color = 'white';
        }
        setAnswer('');
        const prevquesIndex = currentQuestionIndex - 1;
        setcurrentQuestion(questionList[prevquesIndex]);
        setCurrentQuestionIndex(prevquesIndex);
        const prevQuestionID = questionList[prevquesIndex].questionID;
        const curroption = answerLists.filter((answer) => answer.questionID === prevQuestionID);
        setcurrentAnswers([...curroption]);
    };

    //sidenav click and navigate to the question    
    const handleQuestionClick = (questionIndex) => {
        setCurrentQuestionIndex(questionIndex - 1);
        setcurrentQuestion(questionList[questionIndex - 1]);
        var currentQues = questionList[questionIndex - 1].questionID;
        const curroption = answerLists.filter((answer) => answer.questionID === currentQues);
        setcurrentAnswers([...curroption]);
    };

    const handleAnswerChange = (questionID, eve) => {
        setAnswer(eve);
        setSelectedAnswers((prevAnswers) => {
            const updatedAnswers = [...prevAnswers];
            updatedAnswers[currentQuestionIndex] = {
                questionID: questionID,
                finalAnswer: eve,
            };
            return updatedAnswers;
        });
    };

    //Clear the Answer
    const handleClearAnswer = () => {
        setSelectedAnswers((prevAnswers) => {
            const updatedAnswers = [...prevAnswers];
            updatedAnswers[currentQuestionIndex] = {
                questionID: currentQuestion.questionID,
                finalAnswer: null,
            };
            return updatedAnswers;
        });

        answercount(currentQuestion.questionID, null);
        const element = document.getElementById(`background${currentQuestion.questionID}`);
        element.style.background = 'gray';
        element.style.color = 'white';
        handleAnswerChange(currentQuestion.questionID, null);
    };



    const setFlag = (flag) => {
        var flagcode = "NULL";
        if (questionidRef.current) {
            const id = currentQuestion.questionID;
            const element = document.getElementById(`questionno${id}`);
            if (element) {
                const display = window.getComputedStyle(element).display;
                const color = window.getComputedStyle(element).color;
                if (flag === 'F') {
                    if (display === 'none') {
                        element.style.display = 'block';
                        element.style.color = "red";
                        flagcode = 'F';
                        setredCount((prevCount) => prevCount + 1);
                    } else if (display === 'block' && color === 'rgb(0, 128, 0)') {
                        element.style.color = "red";
                        flagcode = 'F';
                        setredCount((prevCount) => prevCount + 1);
                        setgreenCount((prevCount) => prevCount - 1);
                    } else if (display === 'block') {
                        element.style.display = 'none';
                        flagcode = "NULL";
                        setredCount((prevCount) => prevCount - 1);
                    }
                } else if (flag === 'G') {

                    if (display === 'none') {
                        element.style.display = 'block';
                        element.style.color = "green";
                        flagcode = 'G';
                        setgreenCount((prevCount) => prevCount + 1);
                    } else if (display === 'block' && color === 'rgb(255, 0, 0)') {
                        element.style.color = "green";
                        flagcode = 'G';
                        setgreenCount((prevCount) => prevCount + 1);
                        setredCount((prevCount) => prevCount - 1);
                    } else if (display === 'block') {
                        element.style.display = 'none';
                        flagcode = "NULL";
                        setgreenCount((prevCount) => prevCount - 1);
                    }
                }
            }
        }
    };

    const answercount = (Qid, finalAnswer) => {
        let length = 0;
        if (answerCount.length === 0) {
            setanswercount([{ 'questionid': Qid, 'answer': finalAnswer }]);
            length = finalAnswer === null ? 0 : 1;
        } else {
            const filteredAnswers = answerCount.filter((e) => e.questionid === Qid);
            if (filteredAnswers.length > 0) {
                const updatedAnswerCount = answerCount.map((e) =>
                    e.questionid === Qid ? { ...e, answer: finalAnswer } : e
                );
                const filteredUpdatedAnswers = updatedAnswerCount.filter((e) => e.answer !== null);
                length = filteredUpdatedAnswers.length;
                setanswercount(updatedAnswerCount);
            } else {
                const newAnswer = { 'questionid': Qid, 'answer': finalAnswer };
                setanswercount([...answerCount, newAnswer]);
                length = finalAnswer === null ? answerCount.length : answerCount.length + 1;
            }
        }
        setValue(length, questionList?.length);
    };


    const setValue = (l, questioncount) => {
        const nextProgress = (l / questioncount) * 100;
        const roundnumber = Math.round(nextProgress);
        setProgress(roundnumber);
    };


    return (
        <>
            {examType === "One question per page" ? (
                <div className="all-section-area" id="oneQuestion">
                    <CandidatepreviewHeader />
                    <SideNav
                        questionList={questionList}
                        handleQuestionClick={handleQuestionClick}
                        currentQuestionIndex={currentQuestionIndex}
                        candidateID={candidateID}
                        examID={examID}
                        examAttendID={examAttendID}
                        currentQuestionID={currentQuestionId}
                    />
                    <main className="main-content-wrap style-two" style={{ overflow: 'auto', display: 'block' }}>
                        <div className="overview-content-area">
                            <div className="container-fluid">
                                {/* <div className="fixed-content"> */}
                                <div className="row align-items-center">
                                    <div className="col-8">
                                        <div className="page-title">
                                            <h4 style={{ paddingLeft: '25px', fontWeight: 'bold' }}>Question {currentQuestionIndex + 1} / {questionList.length}</h4>
                                        </div>
                                    </div>
                                    <div className="col-4 ">
                                        <div className="col-12">
                                            <span style={{ fontWeight: 'bold' }}>Section: {currentQuestion.sectionName}</span>
                                            <input type="button" value="Back" title="Back" className="back-button backbutton-end" style={{ float: 'right', marginBottom: '13px' }} onClick={() => navigate(-1)} />

                                        </div>
                                    </div>
                                </div>
                                {/* </div> */}

                                <div className="card-box-style" >
                                    <div className="" style={{ marginBottom: '0px' }}>
                                        <pre>
                                            <h5 id={`${currentQuestionIndex + 1}`} dangerouslySetInnerHTML={{ __html: getPlainText(currentQuestion.question) }}></h5>
                                        </pre>
                                        {currentQuestion?.questionImageURL ? <img src={IMAGE_URL + currentQuestion?.questionImageURL} alt="Image" style={{ maxWidth: '100%', height: 'auto' }} /> : <></>}
                                    </div>
                                    <div className="row mt-2">
                                        {currentAnswers?.map((option, index) => {

                                            var result = selectedAnswers?.length > 0 && selectedAnswers?.filter((e) => e?.questionID == currentQuestion?.questionID);
                                            return (<div className="col-lg-12 col-sm-12" key={index}>
                                                <div className='single-audience d-flex justify-content-between align-items-center' onClick={(eve) => handleAnswerChange(currentQuestion.questionID, option.questionOptionID)} >
                                                    <div className="audience-content">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                name={`answer-${currentQuestion.questionID}`}
                                                                id={`answer-${currentQuestion.questionID}-${index}`}
                                                                value={option.questionOptionID}
                                                                checked={option.questionOptionID == result[0]?.finalAnswer}
                                                                onChange={() => { }}
                                                            />
                                                            {' '}  {getPlainText(option.answerValue)}
                                                            {option?.imageURL ?
                                                                <img
                                                                    src={IMAGE_URL + option?.imageURL}
                                                                    alt="Image"
                                                                    style={{ maxWidth: '100%', height: '40px' }}
                                                                />
                                                                : <></>}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            );
                                        })}

                                    </div>
                                    <div className="OptionSection">
                                        <div className="row">
                                            <div className="col-2">
                                                <div className="NextPrevClass">
                                                    {currentQuestionIndex > 0 && (
                                                        <button
                                                            className="btn btn-sm btn-primary"
                                                            style={{ marginRight: '10px' }}
                                                            onClick={() => showPrevQuestion()}
                                                        >
                                                            « Prev
                                                        </button>
                                                    )}

                                                    {currentQuestionIndex === questionList.length - 1 ? (
                                                        <button
                                                            className="btn btn-sm btn-primary"
                                                            style={{ marginLeft: '10px' }}
                                                        >
                                                            Submit
                                                        </button>
                                                    ) : (
                                                        <button
                                                            className="btn btn-sm btn-primary"
                                                            style={{ marginLeft: '10px' }}
                                                            onClick={() => showNextQuestion()}
                                                        >
                                                            Next »
                                                        </button>
                                                    )}
                                                </div>

                                            </div>
                                            <div className="col-8 mt-2">
                                                <div className="progress ">
                                                    <div className="progress-bar progress-bar-striped progress-bar-animated " aria-valuemin={20} aria-valuemax={20} style={{ width: `${progress}%`, background: '#49c199' }}>
                                                        {progress}%
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <div className="ClearClass" style={{ float: "right" }}>
                                                    <button
                                                        className="btn btn-primary shadow bg-white rounded"
                                                        style={{ marginRight: '1px', cursor: 'pointer' }}
                                                        onClick={() => handleClearAnswer()}
                                                        title="Clear"
                                                    >
                                                        <FaEraser color="black" />
                                                    </button>

                                                    <button
                                                        className="btn  btn-primary shadow bg-white rounded"
                                                        style={{ color: 'red', marginRight: '1px', cursor: 'pointer' }}
                                                        title="Doubtful"
                                                        onClick={() => setFlag('F')}
                                                    >
                                                        <span><FlagCount count={redCount} /></span>

                                                    </button>

                                                    <button
                                                        className="btn  btn-primary shadow bg-white rounded"
                                                        style={{ marginRight: '1px', color: 'green', cursor: 'pointer' }}
                                                        title="Confirmed"
                                                        onClick={() => setFlag('G')}
                                                    >
                                                        <span><FlagCount count={greenCount} /></span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    {/* <MalpracticeActivity examID={examID} candidateID={candidateID} handleSubmit={handleSubmit} /> */}
                    {/* <RejoinTest handleSubmit={handleSubmit} /> */}
                </div>
            ) : ""
            }
            {examType === "All question" ?
                (

                    <div className="all-section-area" id="allQuestion">
                        <main className="main-content-wrap" style={{ height: '0px', overflow: 'auto', display: 'block' }}>
                            <div className="overview-content-area">
                                <div className="container-fluid" style={{ marginRight: '50px' }}>
                                    {/* Display all questions */}
                                    <div className="row align-items-center">
                                        <div className="col-8">
                                            <div className="page-title ">
                                                <h4 style={{ paddingLeft: '100px', fontWeight: 'bold' }}>Total Questions :{questionList.length}</h4>
                                            </div>
                                        </div>
                                        <div className="col-4 ">
                                            <div className="col-12">
                                                <span style={{ fontWeight: 'bold' }}></span>
                                                <input type="button" value="Back" title="Back" className="back-button backbutton-end" style={{ float: 'right', marginBottom: '13px' }} onClick={() => navigate(-1)} />

                                            </div>
                                        </div>
                                    </div>
                                    {questionList.map((currentQuestion, index) => (
                                        <div key={index} className="card-box-style mt-1" style={{ marginRight: '100px', marginLeft: '100px', padding: '24px', marginBottom: '0px' }}>
                                            <div className="" style={{ marginBottom: '0px' }}>
                                                <h5 id={`${index + 1}`} ref={questionidRef}>
                                                    {`${index + 1}) ${currentQuestion.question != null ? getPlainText(currentQuestion.question) : ''}`}
                                                </h5>
                                                {currentQuestion?.questionImageURL ? (
                                                    <img
                                                        src={IMAGE_URL + currentQuestion?.questionImageURL}
                                                        alt="Image"
                                                        style={{ maxWidth: '100%', height: 'auto' }}
                                                    />
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                            <div className="row mt-2">
                                                {answerLists
                                                    .filter((option) => option.questionID === currentQuestion.questionID)
                                                    .map((option, index) => {
                                                        const correctAnswerCount = currentAnswers?.filter((answer) =>
                                                            answer.questionID === currentQuestion.questionID &&
                                                            answer.isCorrectAnswer === "Yes"
                                                        );
                                                        const isMultipleAnswer = correctAnswerCount.length > 1;
                                                        var result = selectedAnswers?.length > 0 && selectedAnswers?.filter((e) => e?.questionID == currentQuestion?.questionID);
                                                        var check = String(result[0]?.finalAnswer).split(',').map(item => item.trim()).includes(option.questionOptionID.toString()) ?? false;

                                                        return (
                                                            <div className="col-lg-12 col-sm-12" key={index}>
                                                                <div className='single-audience d-flex justify-content-between align-items-center' onClick={(eve) => handleAnswerChange(eve, currentQuestion.questionID, option.questionOptionID)}>
                                                                    <div className="audience-content">
                                                                        <label>
                                                                            <input
                                                                                type="radio"
                                                                                name={`answer-${currentQuestion.questionID}`}
                                                                                id={`answer-${currentQuestion.questionID}-${index}`}
                                                                                value={option.questionOptionID}
                                                                                checked={option.questionOptionID == result[0]?.finalAnswer ? true : false}
                                                                                onChange={() => { }}
                                                                            />
                                                                            {' '}
                                                                            {getPlainText(option.answerValue)}
                                                                            {option?.imageURL ? (
                                                                                <img
                                                                                    src={IMAGE_URL + option?.imageURL}
                                                                                    alt="Image"
                                                                                    style={{ maxWidth: '100%', height: '40px' }}
                                                                                />
                                                                            ) : (
                                                                                <></>
                                                                            )}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                            </div>
                                        </div>
                                    ))}


                                    {/* Submit button */}
                                    <div className="row" style={{ marginTop: '-20px' }}>
                                        <div className="col-12 text-center" style={{ marginBottom: '5px' }}>
                                            <button
                                                className="btn btn-sm btn-primary"
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>

                    </div>
                ) : ""}
        </>
    );

}



