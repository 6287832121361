import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ExamService from "./ExamService";
import EmailTemplate from "../EmailTemplate/ExamEmailTemplate";
import "./Exam.css";


export default function ExamSummary() {


  const location = useLocation();
  debugger;
  const examSummaryModel = location.state?.examSummaryModel ?? null;
  const _examService = new ExamService();
  const [expanded, setExpanded] = useState(false);
  const textBoxRef = useRef(null);
  const [isContainerFluid, setIsContainerFluid] = useState(true);


  const visibleSectionLists = expanded ? examSummaryModel?.sectionLists :
    examSummaryModel?.sectionLists?.slice(0, 2);
  const [checkShareGroup, setCheckShareGroup] = useState(false);
  const [showPopupModal, setShowPopupModal] = useState(false);
  debugger;
  const [examLink, setExamLink] = useState(examSummaryModel !== null ? examSummaryModel?.ExamLink : '');
  const [ExamName, setexamName] = useState(examSummaryModel !== null ? examSummaryModel?.examName : '');
  const [examName, setExamName] = useState(examSummaryModel !== null ? examSummaryModel?.ExamName : '');
  const navigate = useNavigate();
  const back = location.state?.navigate ?? -1
  const handleClick = (event) => {

    event.preventDefault();
    setCheckShareGroup(true);
    setShowPopupModal(true);
  };
  const handleExpand = () => {
    setExpanded(true);
  };

  const handleHideExpand = () => {
    setExpanded(false);
  };



  const handleCopyClick = () => {

    textBoxRef.current.select();
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
  };
  useEffect(() => {
    if (showPopupModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showPopupModal]);
  const toggleContainerClass = () => {
    setIsContainerFluid((prevState) => !prevState);
  };
  return (
    <>

      <main class="main-content-wrap">
        <div className="contact-list-area ">
          <div className='container-fluid'  >

            <div className="row">
              <div className={`${isContainerFluid ? "col-md-12" : "col-md-9"}`}>
                <div className="card-box-style animatedwidth">
                  <div className="fullwidthbuttonbox " onClick={toggleContainerClass}>
                    <span className="fullwidthbutton" >
                      {isContainerFluid ? <i className="fa fa-angle-double-left"></i> : <i className="fa fa-angle-double-right"></i>}

                    </span>
                  </div>
                  <div className="row">
                    <div className="col-6" style={{ color: '#2279e7' }}><h3>You've Updated Exam</h3></div>
                    <div className="col-6">
                      <input type="button" value="Back" title="Back" style={{ float: 'right' }} className="back-button backbutton-end" onClick={() => navigate(back)} />
                    </div>

                  </div>

                  <div className="row" >
                    <div className="col-md-12"><label for="basic-url" class="form-label">Your Test Link</label></div>
                    <div className="col-md-6"> <input type="text" class="form-control" ref={textBoxRef} value={examSummaryModel?.ExamLink} aria-describedby="basic-addon2" /></div>
                    <div className="col-md-1"> <span class="input-group-text" id="basic-addon2" style={{ justifyContent: "center", cursor: "pointer" }} onClick={() => handleCopyClick()}>Copy</span></div>
                    <div className="col-md-1"> <span class="input-group-text" id="basic-addon2" style={{ justifyContent: "center", cursor: "pointer" }} onClick={(event) => handleClick(event)} >Share Test</span>   </div>

                  </div>


                  <div className="row" >
                    <div className="col-md-12">
                      <table className="table align-middle mt-3">

                        <tbody>

                          <tr >
                            <td>  Exam Type </td><td>{examSummaryModel?.examType}</td>
                          </tr>
                          <tr >
                            <td>  Duration</td><td>{_examService.MillisecondsToMinutes(
                              examSummaryModel?.duration
                            )} Minutes</td>
                          </tr>
                          <tr >
                            <td>  Show Result in  </td><td>{examSummaryModel?.showResultIn}</td>
                          </tr>
                          <tr style={{
                            display: (examSummaryModel?.startTime === null || examSummaryModel?.startTime === "") && (examSummaryModel?.endTime === null || examSummaryModel?.endTime === "") ? "none" : "content"
                          }}>
                            <td>  Exam Availability </td><td>{examSummaryModel?.startTime} to {examSummaryModel?.endTime}</td>
                          </tr>
                          <tr >
                            <td>  Display Results  </td><td>{examSummaryModel?.displayResults}</td>
                          </tr>
                          <tr>
                            <td>  Sections  </td>
                            <td>
                              {visibleSectionLists != null && visibleSectionLists.length > 0 ? (
                                visibleSectionLists.map((v, i) => {
                                  return (
                                    <>

                                      <div className="row">
                                        <div className="col-md-12">
                                          <span className="location">{v.sectionName} - {v.noQuestion} Questions</span></div>
                                      </div>
                                    </>

                                  );
                                })
                              ) : (
                                <></>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <div className="showmoreRow row">
                              <td className="col-md-6 textend">

                                <span className="location">   {expanded ? (

                                  <label onClick={handleHideExpand}>
                                    Hide Details
                                  </label>

                                ) : (
                                  examSummaryModel?.sectionLists.length > 2 && !expanded && (
                                    <label onClick={handleExpand}>
                                      Show {examSummaryModel?.sectionLists.length - 2} other(s)
                                    </label>
                                  )
                                )}</span>


                              </td>
                              <td>

                                <span className="location"><button className="btn btn-secondary">Total Questions {examSummaryModel?.totalQuestion}</button></span>


                              </td>

                            </div>

                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

              </div>
              {!isContainerFluid ? <div className="col-md-3">

                <div className="card-box-style"></div>
              </div> : null}
            </div>


          </div>
        </div>
      </main>

      {showPopupModal && (
        <EmailTemplate

          setShowPopupModal={setShowPopupModal}
          examLink={examLink}
          examName={examName}
          ExamName={ExamName}
          checkShareGroup={checkShareGroup}
        />
      )}
    </>
  );
}
