import React, { useState, useEffect, useContext } from 'react';

import '../Exam/Exam.css';
import ApplicationConfigService from "../../services/ApplicationConfigService";
import { FaTrashAlt } from "react-icons/fa";
import Swal from "sweetalert2";
import Autocomplete from 'react-autocomplete';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DeleteIcon from "@mui/icons-material/Delete";
import { Decrypt, Encrypt } from "../../services/CommonService";
import { AppContext } from "../../services/ContextProvider";
import { IconButton, Tooltip } from "@material-ui/core";
export default function SectionConfigPopup({ setShowPopupModal, GetCategoryList, categoryName }) {
  const _applicationService = new ApplicationConfigService();
  const [value, setValue] = useState('');
  const [matchedValues, setMatchedValues] = useState([]);
  const [ParentSectionModel, setParentSectionModel] = useState({});
  const [sectionModelList, setSectionModelList] = useState([]);
  const [categoryDescription, setcategoryDescription] = useState('');
  const [sectionErrors, setSectionErrors] = useState([]);
  const [errors, setErrors] = useState({});
  const { showLoading, hideLoading } =
    useContext(AppContext);
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });


  const onCloseModal = () => {

    setShowPopupModal(false);
  };
  useEffect(() => {

    if (categoryName != "" && categoryName != null) {
   
      CheckAlreadyExistsCategory(categoryName);
      handleSelect(categoryName);
     setTimeout(() => {
      hideLoading();
      }, 200);
      
    }
  }, []);

  const handleSelect = (value) => {
    
    setErrors({})
    setSectionErrors([])
    setSectionModelList([]);
    setcategoryDescription('');
    setValue(value);
    _applicationService.GetCategoryAgainstSectionList(value)
      .then((response) => {
        
        if (response != null) {


          setcategoryDescription(response.Table[0].Description)

          setSectionModelList(response.Table1)
          setSectionModelList((prevDataFieldList) => {
            return prevDataFieldList.map((item, i) => {
              return {
                ...item,
                isDeleted: 0,
                index: i,
              };
            });
          });





        }
      })
      .catch((exception) => {
        console.log(exception);
      });
  };

  const CheckAlreadyExistsCategory = (value) => {
    setMatchedValues([]);
    setValue(value)
    var myerror = errors;

    if (value === '' || value === null) {
      myerror.category = "Please enter category";
    }
    else {
      myerror.category = "";
      _applicationService.CheckAlreadyExistsCategory(value)
        .then((response) => {
          if (response != null && response.length > 0) {
            setMatchedValues(response);

          }



        })
        .catch((exception) => {
          console.log(exception);
        });
    }
    setErrors({ ...myerror });
  }
  const handleChangeCategoryDescription = (e) => {
  
    var myerror = errors;

    if (e.target.value === '' || e.target.value === null) {
      myerror.categoryDescription = "Please enter Category Description";
    }
    else {
      myerror.categoryDescription = "";
  
    }
    setErrors({ ...myerror });
  }
  const sectionCreate = (i) => {

    const newModel = {
      SectionId: 0, SectionName
        : "", Description: "", isDeleted: 0, index: i
    };
    setSectionModelList((prevList) => [...prevList, newModel]);


  };
  function assignValuesToSectionModel(index, event) {


    const { name, value } = event.target;
    const updatedErrors = [...sectionErrors];

    if (name === "sectionName") {
      if (value === "" || value === null) {

        updatedErrors[index] = {
          ...updatedErrors[index],
          sectionName: "Please enter section"
        };
        sectionModelList[index].SectionName = value;
      }
      else {
        updatedErrors[index] = {
          ...updatedErrors[index],
          sectionName: ""
        };
        sectionModelList[index].SectionName = value;
      }



    }
    else if (name === "sectionDescription") {




      sectionModelList[index].Description = value;




    }

    setSectionErrors(updatedErrors);
    setSectionModelList([...sectionModelList]);


  }

  const deleteSection = (index, sectionID) => {

    if (sectionID > 0) {
      Swal.fire({
        title: "Are you sure?",
        text: "Do You Want to Delete this Section?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          showLoading();
          _applicationService.DeleteSection(sectionID).then((res) => {

            if (res > 0) {
              hideLoading();
              Toast.fire({
                icon: "success",
                title: "Delete Success",
              });
              document.getElementById(index).style.display = 'none';

              const updatedItems = sectionModelList.map((item, i) => {
                if (i === index) {
                  return {
                    ...item,
                    isDeleted: 1
                  };
                }
                return item;
              });

              setSectionModelList(updatedItems);
            } else {
              Toast.fire({
                icon: "error",
                title: "Delete Failed!",
              });
            }
          }).catch((err) => {
            console.log(err);
          });
        }
      });

    }
    else {

      document.getElementById(index).style.display = 'none';

      const updatedItems = sectionModelList.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            isDeleted: 1
          };
        }
        return item;
      });

      setSectionModelList(updatedItems);

    }






  };
  const CreateUpdateSection = (res) => {
    res.preventDefault();
    
    var myerror = errors;
    const updatedErrors = [...sectionErrors];


    var flag = true;

    const filteredValues = matchedValues.filter((item) => item.ParentSectionName.replace(/\s/g, '').toLowerCase() === value.replace(/\s/g, '').toLowerCase());



    var data = {
      ParentSectionID: filteredValues.length > 0 ? filteredValues[0].ParentSectionID : 0,
      ParentSectionName: value,
      Description: document.getElementById("categoryDescription").value,
      SectionList: sectionModelList.filter((item) => item.isDeleted === 0),
      CompanyID: Decrypt(localStorage.getItem("CompanyID")),
      CreatedBy: Decrypt(localStorage.getItem("EmployeeID")),
    }
    if (data.ParentSectionName === '' || data.ParentSectionName === null) {
      myerror.category = "Please enter category";
      flag = false;
     
    }

    else {
      myerror.category = "";
    } 
    if (data.Description === '' || data.Description === null) {
      myerror.categoryDescription = "Please enter category description";
      flag = false;
    
    }

    else {
      myerror.categoryDescription = "";
    } 
    
    if (data.SectionList.length === 0) {
      Toast.fire({
        icon: "warning",
        title: "Please add atleast one section",
      });
      flag = false;

    }
    else {
      
      {
        data.SectionList && data.SectionList.length > 0 && (
          data.SectionList.map((item, index) => (

            item.SectionName === "" ? (
              flag = false,


              updatedErrors[item.index] = {
                ...updatedErrors[item.index],
                sectionName: "Please enter section"
              }
            ) : (
              updatedErrors[item.index] = {
                ...updatedErrors[item.index],
                sectionName: ""
              }
            )

          ))
        )
      }


    }
    setErrors({ ...myerror });
    setSectionErrors(updatedErrors);
    if (flag === true) {
      showLoading();
      _applicationService.CreateUpdateSection(data).then((res) => {

        if (res != null) {
          hideLoading();
          onCloseModal();
          Toast.fire({
            icon: "success",
            title: "Saved Success",
          });

          GetCategoryList();

        } else {
          Toast.fire({
            icon: "error",
            title: "Failed to Save  ",
          });
        }

      });
    }

  }
  return (
    <>
      <form className="row g-3" id="sectionCreateUpdate" autoComplete="off"  >
        <div className="modal fade show" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-modal="true" role="dialog" style={{ display: 'flex', alignItems: 'center' }}>
          <div className="modal-dialog modal-lg" style={{ margin: 'auto' }}>
            <div className="modal-content my-auto">
              <div className="modal-header">
                <h5 className="modal-title MuiTypography-h6" id="staticBackdropLabel">Add Section</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" fdprocessedid="ttcp2q" onClick={onCloseModal}
                />
              </div>
              <div className="modal-body">






                <div className="row">
                  <div class="col-md-6 ">
                    <label  
                      htmlFor="formGroupExampleInput"
                      className="form-label"
                    >
                      Category
                      <span className="text-danger"> *</span>
                    </label>

                    <div class="input-group categorytxtbox mb-3">
                      <Autocomplete

                        items={matchedValues}
                        value={value}

                        getItemValue={(item) => item.ParentSectionName}
                        onChange={(event) => CheckAlreadyExistsCategory(event.target.value)}
                        onSelect={handleSelect}
                        renderMenu={(children) => <div>{children}</div>}
                        renderItem={(item, isHighlighted) => (
                          <div class="form-control"
                            key={item.ParentSectionID}
                            style={{ background: isHighlighted ? 'lightgray' : 'white' }}
                          >
                            {item.ParentSectionName}
                          </div>
                        )}
                      />
                    </div>
                    {errors.category && (
                      <span className="text-danger">
                        {errors.category}
                      </span>
                    )}
                  </div>
                  <div className='col-md-6'>
                    <label
                      htmlFor="formGroupExampleInput"
                      className="form-label"
                    >
                      Description
                      <span className="text-danger"> * </span>
                    </label>
                    <div class="input-group mb-3">
                      <textarea defaultValue={categoryDescription}  class="form-control" id="categoryDescription"  onChange={handleChangeCategoryDescription}/>
                    </div>
                    {errors.categoryDescription && (
                      <span className="text-danger">
                        {errors.categoryDescription}
                      </span>
                    )}
                  </div>

                </div>

                <table className="table align-middle mb-0">
                  <thead>
                    <tr>
                      <th style={{ verticalAlign: "-webkit-baseline-middle" }}>
                        Section  <span className="text-danger">
                          *
                        </span></th>

                      <th style={{ verticalAlign: "-webkit-baseline-middle" }}>Description</th>

                      <th>
                        <div className="row">

                          <div className='col-md-9'>

                            Action
                          </div>





                          <div className='col-md-1'>  <a
                            className="btn btn-link "

                          >
                            <i className=" fa fa-plus" onClick={() => sectionCreate(sectionModelList.length)}></i>
                          </a></div>



                        </div></th>

                    </tr>
                  </thead>
                  <tbody>
                    {sectionModelList != null &&
                      sectionModelList.length > 0 ? (
                      sectionModelList.map((v, i) => {

                        return (
                          <tr key={i} id={i} >
                            {" "}

                            <td>

                              <input
                                defaultValue={v.SectionName
                                }
                                type="text"
                                class="form-control"
                                name="sectionName"
                                id="sectionName"
                                onChange={(event) => assignValuesToSectionModel(i, event)}

                                aria-describedby="basic-addon2"

                              />

                              {sectionErrors[i] && sectionErrors[i].sectionName && (
                                <span className="text-danger">
                                  {sectionErrors[i].sectionName}
                                </span>
                              )}

                            </td>
                            <td>

                              <textarea defaultValue={v.Description} class="form-control" name="sectionDescription"
                                id="sectionDescription"
                                onChange={(event) => assignValuesToSectionModel(i, event)} />
                            </td>
                            <td>
<Tooltip title='Delete Section'>

               <IconButton
                    className="text-danger col-2"
                    aria-label="Delete"
                  
                    onClick={() => deleteSection(i, v.SectionId)}
                  >
                    <DeleteIcon />
                  </IconButton>
                  </Tooltip>
             

                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>




              </div>
              <div className="modal-footer mailtemplatefooter">
                <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={onCloseModal} fdprocessedid="t27w74">Close</button>
                <button type="button" className="btn btn-primary" fdprocessedid="nu3mc7" onClick={CreateUpdateSection}>
                  Save
                </button>
              </div>

            </div>
          </div>
        </div>

      </form>

    </>
  );
}