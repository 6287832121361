import React from "react";
import AxiosService from "./AxiosService";

const Auth = new AxiosService();

class CanditateServices {
    view = async (ExamId) => {

        try {
            return Auth.get(`/Canditate/CanditateTextView?ExamId=${ExamId}`);

        } catch (error) {
            console.log(error);
        }
    };

    post = async (Canditate) => {
        try {
            return Auth.post('/Canditate/SaveCanditate', Canditate);

        } catch (error) {
            console.log(error);

        }
    }

    canditateList = async () => {
        try {
            return Auth.get('/Canditate/GetCanditateList');

        } catch (error) {
            console.log(error);

        }
    }

    DeleteCanditate = async (CanditateId) => {
        try {
            return Auth.get(`/Canditate/RemoveCanditate?CanditateId=${CanditateId}`);
        } catch (error) {
            console.log(error);

        }
    }

    getListById = async (CanditateId) => {
        try {
            return Auth.get(`/Canditate/GetListById?CanditateId=${CanditateId}`);

        } catch (error) {
            console.log(error);
        }
    }

    getGenderDropdown = async () => {
        try {
            return Auth.get(`/Canditate/getGenderDropdown`);
        }
        catch (err) {
            console.log(err);
        }
    }

    Exam_Rejoincode = async (data) => {
        try {
            return Auth.post('/Canditate/Exam_Rejoincode', data)
        }
        catch (err) {
            console.log(err);
        }

    }

    Exam_RejoincodeMatch = async (LinkGenreated, ExamID) => {
        try {
            return Auth.post(`/Canditate/Candidate_RejoinCodeMatch?LinkGenreated=${LinkGenreated}&ExamID=${ExamID}`);
        }
        catch (err) {
            console.log(err);
        }   
    }

    SendMailtoCandidate(emailId, rejoincode, candidatename) {
        return Auth
            .get(`Canditate/SendMailtoCandidate?emailId=${emailId}&rejoincode=${rejoincode}&candidatename=${candidatename}`)
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                console.log(err);
            });

    }


    CandidateUploadFile = async (data) => {
        try {
            return Auth.post('/Canditate/CandidateUploadFile', data)
        }
        catch (err) {
            console.log(err);
        }
    }

    Candidate_RejoinDelete = async (CandidateID, ExamID) => {
        try {
            return Auth.post(`/Canditate/Candidate_RejoinDelete?CandidateID=${CandidateID}&ExamID=${ExamID}`)
        }
        catch (err) {
            console.log(err);
        }
    }

    BulkSaveCanditate = async (data) => {
        try {
            return Auth.post('/Canditate/BulkSaveCanditate', data)
        }
        catch (err) {
            console.log(err);
        }
    }
    DownloadCandidateTemplate = async () => {
        return Auth.get('Canditate/CandidateDownloadFile', { responseType: 'blob' }).then(
            (res) => {
                return res;
            }
        ).catch(
            (error) => console.log(error)
        );
    };

    Candidate_DataDownload = async (candidateGroup) => {
        try {
            return Auth.get(`/Canditate/Candidate_Download?candidateGroup=${candidateGroup}`)
        }
        catch (err) {
            console.log(err);
        }
    }

}
export default CanditateServices;