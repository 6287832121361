import React from "react";
import { useState, useEffect } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import CandidateQuestionViewServices from "../../services/CandidateQuestionViewService";

var candidateQuestionServ = new CandidateQuestionViewServices();
function Thanks() {
    const location = useLocation();
    const [examID, setexamID] = useState(location.state?.examID ?? 0);
    const [candidateID, setCandidateID] = useState(location.state?.candidateID ?? 0);
    const [candidateName, setCandidateName] = useState(location.state?.candidateName ?? null);

    const [examAttendID, setexamAttendID] = useState(location.state?.examAttendID ?? 0);

    const resultType = location.state?.resultType ?? "";
    const [score, setScore] = useState(null);
    const [totalQuestion, setTotalQuestion] = useState(null);
    const [isFinalAnswer, setIsFinalAnswer] = useState(0);
    const [show, setShow] = useState(false);
    const navigate = new useNavigate();

    useEffect(() => {
        candidateQuestionServ.get_Score(examID, candidateID, examAttendID).then((res) => {
            if (res != null) {
                debugger;
                setIsFinalAnswer(res?.data[0].IsFinalAnswerShown)
                setScore(res?.data[0]?.Score);
                setTotalQuestion(res.data[0]?.QuestionCount);
            }
        });
    }, []);

    const navigatetoAnswershow = () => {
        debugger;
        navigate("/finalAnswer", {
            state: { CandidateId: candidateID, examID: examID, ExamAttendID: examAttendID, candidateName: candidateName },
        });


    }

    return (
        <>
            <div class="error-area">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="error-content card-box-style">
                                <h1><FaCheckCircle color="green" /></h1>
                                <h2>Thank You</h2>
                                <p style={{ lineHeight: '20px', marginBottom: '15px' }}>Your exam has been successfully submitted.</p>
                                {resultType.trim() === 'Score only' ? <p>Score: {score}/{totalQuestion}</p> :
                                    <p style={{ lineHeight: '20px' }}>Results will be announced Soon.</p>}

                                <button className="btn btn-sm btn-danger" onClick={() => navigate(-1)} style={{ cursor: 'pointer' }}>Back to Home</button>
                                <div className="col-md-12 mt-3">
                                    {isFinalAnswer == true ?
                                        <button className="btn btn-sm btn-primary" onClick={navigatetoAnswershow} >Answer Show</button>
                                        : <></>
                                    }
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}
export default Thanks;