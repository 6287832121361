import React from "react";
import AxiosService from "./AxiosService";

const Auth = new AxiosService();

class CandidateQuestionViewServices {

    questionList = async (CandidateID, ExamId, ExamAttendID) => {
        try {
            return Auth.get(`CandidateQuestionView/Candidate_QuestionList?CandidateID=${CandidateID}&ExamID=${ExamId}&ExamAttendID=${ExamAttendID}`)
        }
        catch (err) {
            console.log(err);
        }
    }
    candidateAnswer = async (Canditate) => {
        try {
            return Auth.post('CandidateQuestionView/CandidateAnswer', Canditate)
        }
        catch (err) {
            console.log(err);
        }
    }

    getAnswerHistory = async (CandidateID, ExamID, ExamAttendID) => {
        try {
            return Auth.get(`/CandidateQuestionView/getAnswerHistory?CandidateID=${CandidateID}&ExamID=${ExamID}&ExamAttendID=${ExamAttendID}`)
        } catch (error) {
            console.log(error);
        }
    }

    CandidateAnsFlag = async (data) => {
        try {
            return Auth.post('CandidateQuestionView/CandidateAnsFlag', data);
        } catch (error) {
            console.log(error);
        }
    }
    // getCandidateFlag = async (CandidateID, ExamID, ExamAttendID) => {
    //     try {
    //         return Auth.get(`/CandidateQuestionView/getCandidateFlag?CandidateID=${CandidateID}&ExamID=${ExamID}&ExamAttendID=${ExamAttendID}`)
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    CandidateSumbitAnswer = async (data) => {
        try {
            return Auth.post('/CandidateQuestionView/CandidateSumbitAnswer', data);
        } catch (error) {
            console.log(error);
        }
    }


    getFlagcolor = async (CandidateID, ExamID, ExamAttendID) => {
        try {
            return Auth.get(`/CandidateQuestionView/getCandidateFlag?CandidateID=${CandidateID}&ExamID=${ExamID}&ExamAttendID=${ExamAttendID}`);
        } catch (error) {
            console.log(error);
        }
    }
    CandidateFlagButton = async (data) => {
        try {
            return Auth.post('CandidateQuestionView/Candidate_FlagButton', data);
        } catch (error) {
            console.log(error);
        }
    }

    getFlagCount = async (CandidateID, ExamID, ExamAttendID) => {
        try {
            return Auth.get(`/CandidateQuestionView/CandidateFlagCount?CandidateID=${CandidateID}&ExamID=${ExamID}&ExamAttendID=${ExamAttendID}`);
        } catch (error) {
            console.log(error);
        }
    }
    UpdateRemainingTime = async (examID, candidateID, remainingTime, examAttendID) => {
        try {
            return Auth.get(`/CandidateQuestionView/UpdateRemainingTime?examID=${examID}&candidateID=${candidateID}&remainingTime=${remainingTime}&examAttendID=${examAttendID}`);
        } catch (error) {
            console.log(error);
        }
    }

    NegativeMarks_Score = async (examID) => {
        try {
            return Auth.get(`/CandidateQuestionView/NegativeMarks_Score?ExamID=${examID}`);
        } catch (error) {
            console.log(error);
        }
    }

    EachQuestion_TimeTaken = async (TimeTaken, QuestionID, CandidateID, ExamID, ExamAttendID) => {
        try {
            return Auth.post(`/CandidateQuestionView/EachQuestion_TimeTaken?TimeTaken=${TimeTaken}&QuestionID=${QuestionID}&CandidateID=${CandidateID}&ExamID=${ExamID}&ExamAttendID=${ExamAttendID}`);
        } catch (error) {
            console.log(error)
        }
    }

    Candidate_ExamOption = async (examID) => {
        try {
            return Auth.get(`/CandidateQuestionView/Candidate_ExamOption?ExamID=${examID}`);
        } catch (error) {
            console.log(error);
        }
    }

    Candidate_Exam_RejoinCountCheck = async (examID, CandidateID, examAttendID) => {
        try {
            return Auth.get(`/CandidateQuestionView/Exam_RejoinCountCheck?ExamID=${examID}&CandidateID=${CandidateID}&examAttendID=${examAttendID}`);
        } catch (error) {
            console.log(error);
        }
    }

    Candidate_Exam_List = async (examID) => {
        try {
            return Auth.get(`/CandidateQuestionView/Exam_List?ExamID=${examID}`);
        } catch (error) {
            console.log(error);
        }
    }

    get_Score = async (examID, CandidateID, ExamAttendID) => {
        try {
            return Auth.get(`/CandidateQuestionView/Get_Score?ExamID=${examID}&CandidateID=${CandidateID}&ExamAttendID=${ExamAttendID}`);
        } catch (error) {
            console.log(error);
        }
    }


    Candidate_SuspendCheck = async (examID, CandidateID, ExamAttendID) => {
        try {
            return Auth.get(`/CandidateQuestionView/Candidate_SuspendCheck?ExamID=${examID}&CandidateID=${CandidateID}&ExamAttendID=${ExamAttendID}`);
        } catch (error) {
            console.log(error);
        }
    }
}
export default CandidateQuestionViewServices;