import { useNavigate } from "react-router";
import React, { useEffect, useState, useContext } from "react";
import MUIDataTable from "mui-datatables";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@material-ui/core";
import UserRoleConfig from "./UserRoleConfig";
import ConfigurationService from "../../services/configurationService";
import $ from "jquery";
import { AppContext } from "../../services/ContextProvider";
import { FaEdit, FaCog, FaTrashAlt } from "react-icons/fa";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@material-ui/core/Tooltip";
import Swal from "sweetalert2";
export const RoleConfig = () => {
  const { showLoading, hideLoading, setCookies, tempModulePrivileage, isAuth, SetisAuth, getCookies } =
    useContext(AppContext);
  const ConfigurationServiceobj = new ConfigurationService();

  //  const { cookies, setCookie, removeCookie } = useContext(AuthContext);

  const [RoleData, SetRoleData] = useState([]);
  const [FilteredRoleData, SetFilteredRoleData] = useState([]);
  const [Refresh, SetRefresh] = useState(0);
  const [RoleID, setRoleID] = useState(0);
  const navigate = useNavigate();
  const [modelRefresh, setmodelRefresh] = useState(0);
  const [isContainerFluid, setIsContainerFluid] = useState(true);

  const onKeyDown = (event) => {
    if (event.code === "Space") event.preventDefault();
  };
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  useEffect(() => {
    getRoleData();
  }, [Refresh]);

  const getRoleData = () => {
    showLoading();
    ConfigurationServiceobj.RoleList()
      .then((response) => {
        SetRoleData(response.data);
        SetFilteredRoleData(response.data);
        hideLoading();
      })
      .catch((error) => {
        hideLoading();
      });
  };

  const EditRole = (val) => {

    setRoleID(val);
    setmodelRefresh(modelRefresh + 1);
    ConfigurationServiceobj.Create_Role(val)
      .then((res) => {

        // Toast("Role Deleted Successfully", "success");
      })
      .catch((error) => {
        hideLoading();
        //  Toast("Something Went Wrong", "error");
      });


  };
  const DeleteRole = (val) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do You Want to Delete this Role?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        showLoading();
        ConfigurationServiceobj.DeleteRole(val, Number(getCookies("EmployeeID")))
          .then((res) => {

            hideLoading();
            // Toast("Role Deleted Successfully", "success");
          })
          .catch((error) => {
            hideLoading();
            //  Toast("Something Went Wrong", "error");
          });
        SetRefresh(Refresh + 1);

      }
    });


  };


  const HeaderElements = () => (
    <>
      <Tooltip title="Add Role">
        <label htmlFor="icon-button-file">
          <IconButton className="HoverDefaultIcon" data-bs-toggle="modal" data-bs-target="#Role" component="span" onClick={() => EditRole(0)}>
            <AddIcon > </AddIcon>

          </IconButton>
        </label>
      </Tooltip>

    </>
  );
  const columns = [
    { name: "roleName", label: "Role Name" },
    { name: "description", label: "Description" },
    {
      label: "OPTIONS",
      name: "roleID",

      options: {
        filter: false,
        sort: false,
        empty: true,
        responsive: true,


        customBodyRender: (values, tableMeta) => {
          const value = tableMeta.rowData[2];
          return (
            <div className="ico-sec">
              <Tooltip title="Edit Role">
                <IconButton
                  aria-label="Edit"
                  data-bs-toggle="modal"
                  data-bs-target="#Role"
                  onClick={() => EditRole(value)}
                >
                  <FaEdit style={{ color: "#0d6efd" }} />{" "}
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete Role">
                <IconButton aria-label="Delete"
                  className="text-danger"
                  onClick={() => DeleteRole(value)}
                >

                  <DeleteIcon />

                </IconButton>
              </Tooltip>
              {/* <TrashFill   /> */}
            </div>
          );
        },
      },
    },
  ];

  function tablerefresh(id) {
    SetRefresh(Refresh + 1);
  }
  const toggleContainerClass = () => {
    setIsContainerFluid((prevState) => !prevState);
  };

  return (
    <>
      <main class="main-content-wrap">
        <div className="contact-list-area ">
          <div className='container-fluid'  >

            <div className="row">
              <div className={`${isContainerFluid ? "col-md-12" : "col-md-9"}`}>
                <div className="card-box-style animatedwidth">
                  <div className="fullwidthbuttonbox " onClick={toggleContainerClass}>
                    <span className="fullwidthbutton" >
                      {isContainerFluid ? <i className="fa fa-angle-double-left"></i> : <i className="fa fa-angle-double-right"></i>}

                    </span>
                  </div>
                  <MUIDataTable
                    data={FilteredRoleData}
                    title="Role List"
                    columns={columns}
                    options={{
                      responsive: true,
                      download: false,
                      print: false,
                      rowsPerPageOptions: [10, 50, 100],
                      filter: false,
                      rowsPerPageOptions: [10, 50, 100], // Set the available rows per page options

                      customToolbar: () => (
                        <>
                          <HeaderElements />
                          {/* <h3>
                                                                    <input type="button" value="Back" title="Back" className="back-button" onClick={() => navigate(-1)} />
</h3> */}

                        </>
                      ),
                      selectableRows: false,

                      viewColumns: false,
                    }}
                  />
                </div>

              </div>
              {!isContainerFluid ? <div className="col-md-3">

                {/* <div className="card-box-style"></div> */}
              </div>

                : null}
            </div>

          </div>
        </div>
      </main>

      <div
        id="Role"
        className="modal custom-modal fade"
        role="dialog"
        data-bs-backdrop="static"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          id="mymodel"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title MuiTypography-h6">Add Role</h5>
              <button
                type="button"
                className="closeModal"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <UserRoleConfig
                roleID={RoleID}
                modelRefresh={modelRefresh}
                tablerefresh={tablerefresh}
              />
            </div>
          </div>
        </div>
      </div>
    </>

  );
};
