import React from "react";
import AxiosService from "./AxiosService";
import { error } from "jquery";

const Auth = new AxiosService();

class QuestionBankService {
    CreateUpdate = async (model) => {
        return Auth.post('QuestionBank/CreateUpdateQuestion', model).then(
            (res) => { return res.data }
        ).catch(
            (error) => console.log(error)
        );
    }
    GetQuestionbank = async (CompanyID) => {
        return Auth.get(`QuestionBank/GetQuestionbank?CompanyID=${CompanyID}`).then(
            (res) => { return res.data }
        ).catch(
            (error) => console.log(error)
        );
    }
    QuestionBankGetParentSectionList = async (CompanyID) => {
        return Auth.get(`QuestionBank/QuestionBankGetParentSectionList?companyID=${CompanyID}`).then(
            (res) => { return res.data }
        ).catch(
            (error) => console.log(error)
        );
    }
    GetSectionList = async (CompanyID) => {
        return Auth.get(`QuestionBank/GetSectionList?CompanyID=${CompanyID}`).then(
            (res) => { return res.data }
        ).catch(
            (error) => console.log(error)
        );
    };
    GetQuestionbankById = async (qId) => {
        return Auth.get(`QuestionBank/GetQuestionbankById?qId=${qId}`);
    }
    RemoveQuestion = async (selectedQuestionId) => {
        return Auth.get(`QuestionBank/RemoveQuestion?questionID=${selectedQuestionId}`);

    }
    GetNegavtiveMarkPercentageList = async () => {
        return Auth.get('QuestionBank/GetNegavtiveMarkPercentageList').then(
            (res) => { return res.data }
        ).catch(
            (error) => console.log(error)
        );
    };
    UploadFile = async (formData) => {
        // try {
        //     ;
            return await Auth.post('QuestionBank/UploadFile', formData);
        //     ;
        //     return response;
        // } catch (error) {
        //     ;
        //     console.error(error);
       
        // }
    };

    // const response = await axios.post('QuestionBank/UploadFile', formData);
    // return response.data;

DownloadQuestionTemplate=async()=>{
    return Auth.get('QuestionBank/download',{responseType : 'blob'}).then(
        (res) => { 
           return res;
         }
    ).catch(
        (error) => console.log(error)
    );
};
UploadImageQuestion = async (formData) => {
    return Auth.post('QuestionBank/UploadImageQuestion', formData).then(
        (res) => { return res.data }
    ).catch(
        (error) => console.log(error)
    );
}
UploadImageOptions = async (formData) => {
    return Auth.post('QuestionBank/UploadImageOptions', formData).then(
        (res) => { return res.data }
    ).catch(
        (error) => console.log(error)
    );
}
}
export default QuestionBankService;