import logo from "./logo.svg";
import "./App.css";
import "./assets/css/style.css";
import "./assets/css/owl.theme.default.min.css";
import "./assets/css/animate.min.css";
import "./assets/css/remixicon.css";
import "./assets/css/metismenu.min.css";
import "./assets/css/simplebar.min.css";
import "./assets/css/calendar.css";
import "./assets/css/sweetalert2.min.css";
import "./assets/css/jbox.all.min.css";
import "./assets/css/editor.css";
import "./assets/css/loaders.css";
import ExamStatistics from "./Component/Exam/ExamStatistics";
import "./assets/css/responsive.css";
import "../node_modules/boxicons/css/boxicons.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import Login from "./Component/Login/Login";
import WithoutNav from "./Component/Layout/withoutNav";
import WithNav from "./Component/Layout/withNav";
import DashBoard from "./Component/Dashboard/DashBoard";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "./services/ContextProvider";
import ExamCreate from "./Component/Exam/Create";
import Error from "./Component/Layout/Error";
import "boxicons";
import "bootstrap";
import Canditate from "./Component/Canditate/Canditate";
import AdminCanditate from "./Component/Canditate/AdminCanditate";
import "react-toastify/dist/ReactToastify.css";
import QuestionBank from "./Component/QuestionBank/Question";
import ViewQuestion from "./Component/QuestionBank/ViewQuestion";
import ViewQuestionByID from "./Component/QuestionBank/ViewQuestionById";
import ExamList from "./Component/Exam/View";
import GeneralConfig from "./Component/Settings/GeneralConfig";
import ExamSummary from "./Component/Exam/ExamSummary";
import ApplicationConfig from "./Component/Settings/ApplicationConfig";
import Thanks from "./Component/Layout/Thanks";
import CandidateQuestionView from "./Component/Candidate_QuestionView/CandidateQuestionView";
import EmpCreate from "./Component/Employee/Employee";
import ExamHistory from "./Component/Exam/ExamHistory";
import ReviewExam from "./Component/Exam/ReviewExam";
import ExamHistoryForExam from "./Component/Exam/ExamHistoryForExam";
import Emplist from "./Component/Employee/EmployeeView";
import "./assets/css/customizeHeader.css";
import EmailTemplate from "./Component/EmailTemplate/EmailTemplate";
import EmailTemplateCreate from "./Component/EmailTemplate/EmailTemplateCreate";
import ExamLive from "./Component/Exam/ExamLive";
import { RoleConfig } from "./Component/RoleConfig/RoleConfig";
import UserRoleConfig from "./Component/RoleConfig/UserRoleConfig";
import ExamPreview from "./Component/Exam/ExamPreview";
import MeritList from "./Component/Exam/MeritList";
import ForgotPassword from './Component/Login/ForgotPassword';
import CandidatePreview from './Component/Candidate_QuestionView/CandidatePreview';
import CandidateError from './Component/Layout/CandidateError';
import Terminate from "./Component/Candidate_QuestionView/Terminate";
import DetailReport from "./Component/Candidate_QuestionView/DetailReport";
import MyPDF from "./Component/Exam/ScoreCard";
import ContentToPDF from "./Component/Dashboard/Pdf";
import "./assets/kendo-ui/styles/kendo.common.min.css";
import "./assets/kendo-ui/styles/kendo.material.min.css";
import "./assets/kendo-ui/js/kendo.all.min.js";
import FinalAnswerShow from "./Component/Candidate_QuestionView/FinalAnswerShow";
import QuestionPaperDownload from "./Component/Exam/QuestionPaperDownload";
import InvalidLink from "./Component/Layout/InvalidLink";
import Network from "./Component/Layout/Networkcheck";
function App() {
  const { showLoading, hideLoading, getCookies, isAuth, SetisAuth } = useContext(AppContext);

  useEffect(() => {
    showLoading();
    if (localStorage.getItem("IsAuth") != null) {
      SetisAuth(true);
    } else {
      SetisAuth(false);
    }
    hideLoading();
  }, [localStorage.getItem("IsAuth")]);


  // const isAuth = ;
  return (
    <>

      <Router>
        <Routes>
          {isAuth && (
            <Route element={<WithNav />}>
              <Route path="/EmpCreate" element={<EmpCreate />} />
              <Route exact path="/" element={<DashBoard />} />
              <Route path="/DashBoard" element={<DashBoard />} />
              <Route path="/ExamCreate" element={<ExamCreate />} />
              <Route path="/AdminCanditate" element={<AdminCanditate />} />
              <Route path="/QuestionBank" element={<QuestionBank />} />
              <Route path="/ViewQuestion" element={<ViewQuestion />} />
              <Route path="/ViewQuestionByID" element={<ViewQuestionByID />} />
              <Route path="/Exams" element={<ExamList />} />
              <Route
                path="/GeneralConfig"
                element={<GeneralConfig></GeneralConfig>}
              />
              {/* <Route path="/QuestionPreview" element={<QuestionPreview/>}/> */}
              <Route
                path="/pdf"
                element={<MyPDF />}
              />
              <Route path="/ExamStatistics" element={<ExamStatistics />} />
              <Route
                path="/ApplicationConfig"
                element={<ApplicationConfig></ApplicationConfig>}
              />
              <Route path="/ExamSummary" element={<ExamSummary />} />
              <Route path="/ExamHistory" element={<ExamHistory />} />
              <Route path="/ReviewExam" element={<ReviewExam />} />
              <Route
                path="/ExamHistoryForExam"
                element={<ExamHistoryForExam />}
              />
              <Route path="/EmailTemplate" element={<EmailTemplate />} />
              <Route
                path="/EmailTemplateCreate"
                element={<EmailTemplateCreate />}
              />
              <Route
                path="/ExamLive"
                element={<ExamLive />}
              />
              <Route path="/EmpList" element={<Emplist />} />
              <Route path="/RoleConfig" element={<RoleConfig />} />
              <Route path="/UserRoleConfig" element={<UserRoleConfig />} />
              <Route path="/ExamPreview" element={<ExamPreview />} />
              <Route path="/MeritList" element={<MeritList />} />
              <Route path="*" element={<Error />} />
              <Route path="/CandidatePreview" element={<CandidatePreview />} />
              <Route path="/questionPaper" element={<QuestionPaperDownload />} />
            </Route>
          )}

          <Route element={<WithoutNav />}>
            <Route path="/pdf" element={<ContentToPDF />} />
            <Route path="/" element={<Login />} />
            <Route path="/Login" element={<Login />} />
            {/* <Route path="*" element={<Login />} /> */}
            <Route path="/Thanks" element={<Thanks />} />
            <Route
              path="/CandidateQuestion"
              element={<CandidateQuestionView />}
            />
            <Route path="/Terminate" element={<Terminate />} />
            <Route path="/error" element={<CandidateError />} />
            <Route path="/ForgotPassword" element={<ForgotPassword />} />
            <Route path="/Canditate" element={<Canditate />} />
            <Route path="/DetailReport" element={<DetailReport />} />
            <Route path="/finalAnswer" element={<FinalAnswerShow />} />
            <Route path="/invalid" element={<InvalidLink />} />
            <Route path="/network" element={<Network />} />

          </Route>


        </Routes>

      </Router>
    </>
  );
}


export default App;
