import React, { useEffect, useState, useContext, useRef, useReducer } from "react";
import CandidateQuestionViewServices from "../../services/CandidateQuestionViewService";
import { FaTimes, FaEraser, FaFlag } from 'react-icons/fa';
import Header from "./Header";
import SideNav from "./Sidenav";
import "../../assets/js/custom.js";
import '../../assets/css/customizeSideMenu.css';
import '../../assets/css/metismenu.min.css';
import '../../assets/css/simplebar.min.css';
import '../../../node_modules/metismenu/dist/metisMenu.min.js'
import '../../../node_modules/simplebar/dist/simplebar.min.js';
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../../services/ContextProvider";
import MalpracticeActivity from "./MalpracticeActivity";
import { Refresh } from "iconsax-react";
import CanditateServices from "../../services/CanditateService";
import FlagCount from "./FlagCount";
import { IMAGE_URL } from '../../env';
import ExamService from "../Exam/ExamService";
import RejoinTest from "../Canditate/RejoinTest";
import DashBoardService from "../Dashboard/DashBoardService";
import './CandidateExam.css';
import Swal from "sweetalert2";


var candidateQuestionServ = new CandidateQuestionViewServices();
var candidateservice = new CanditateServices();
var examServicesobj = new ExamService();
export default function CandidateQuestionView({ cID, eID, action }) {
    const { showLoading, hideLoading, setCookies, tempModulePrivileage, isAuth, SetisAuth, removeCookies } =
        useContext(AppContext);
    const [questionList, setQuestionList] = useState([]);
    const [answerLists, setAnswerList] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [currentAnswers, setcurrentAnswers] = useState([]);
    const [selectedAnswers, setSelectedAnswers] = useState([]);
    const [currentQuestion, setcurrentQuestion] = useState({});
    const [currentQuestionId, setcurrentQuestionId] = useState(0);
    const location = useLocation();
    const [candidateID, setCandidateID] = useState(location.state?.candidateID ?? cID);
    const [CandidateName, setCandidateName] = useState(location.state?.canditateName ?? null);
    const [examAttendID, setexamAttendID] = useState(location.state?.examAttendID ?? 0);
    const [email, setEmail] = useState(location.state?.emailID ?? null);
    const [examID, setexamID] = useState(location.state?.examID ?? eID);
    const navigate = new useNavigate();
    const questionidRef = useRef(null);
    const [flag, setflag] = useState(false);
    const [answer, setAnswer] = useState('');
    const [Refesh, setRefesh] = useReducer((x) => x + 1, 0);
    const [redCount, setredCount] = useState(0);
    const [greenCount, setgreenCount] = useState(0);
    const [progress, setProgress] = useState(0);
    const [percentageCount, setPercentageCount] = useState(2);
    const [answerCount, setanswercount] = useState([]);
    const [negativeMarkScore, setnegativeMarkScore] = useState('');
    const [counter, setCounter] = useState(0);
    const [examType, setExamtype] = useState('');
    const [scoreResult, setScoreResult] = useState();
    const [QuestionNav, setQuestionNav] = useState(false);
    const [AttendAllQuestion, setAttenAllQuestion] = useState(false);
    const [SubmitBefortime, setSubmitBefortime] = useState(false);
    const [visiblePercentage, setvisiblePercentage] = useState();
    var questionDisplayId;
    var displayResultID;
    var visiblePercentageID;
    var result;
    var percentage;
    var Questioncount;
    var EmailID;
    const [isOnline, setIsOnline] = useState(true); // Default to online
    const [consecutiveOnlineCount, setConsecutiveOnlineCount] = useState(0);
    const alertThreshold = 10; // Set the threshold for consecutive "true" results
    const [count, setCount] = useState(0);
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });
    useEffect(() => {
        setTimeout(() => setCounter(counter + 1), 1000);

    }, [counter]);

    const checkOnlineStatus = async () => {
        try {
            const response = await fetch('https://www.google.com', { mode: 'no-cors' });
            return response.status === 0;
        } catch (error) {
            return false;
        }
    };


    useEffect(() => {
        let Count = 0;
        const interval = setInterval(async () => {
            const result = await checkOnlineStatus();
            setIsOnline(result);

            if (result) {
                setConsecutiveOnlineCount(0);
            } else {


                Count += 1;
                setConsecutiveOnlineCount(Count);
                if (Count === 4) {


                    alert("Check your internet connection");

                } else if (Count === 8) {


                    alert("You may be disconnected in 5 seconds ");

                } else if (Count >= alertThreshold && Count === alertThreshold) {

                    setConsecutiveOnlineCount(0);
                    Count = 0;
                    navigate("/network", { replace: true });
                    clearInterval(interval);
                }
            }
        }, 3000);

        return () => clearInterval(interval);
    }, []);


    useEffect(() => {
        const fetchData = async () => {
            try {
                showLoading();
                debugger;
                // Fetch data from various sources in parallel
                const [questioncount, answercount, negativeMarks, examList, examOptions, flagCounts] = await Promise.all([
                    Getlist(),
                    getanswerlist(),
                    candidateQuestionServ.NegativeMarks_Score(examID),
                    candidateQuestionServ.Candidate_Exam_List(examID),
                    candidateQuestionServ.Candidate_ExamOption(examID),
                    candidateQuestionServ.getFlagCount(candidateID, examID, examAttendID)
                ]);

                // Update answer and question counts
                setValue(answercount, questioncount);

                // Inside your fetchData function
                if (negativeMarks != null) {
                    const NegativeMarkID = negativeMarks?.data[0]?.NegativeMarkID;
                    const questionDisplayId = examList?.data[0]?.QuestionDisplayID;
                    const displayResultID = examList?.data[0]?.DisplayResultID;
                    const visiblePercentageID = examOptions?.data[0]?.VisiblePercentage;

                    setAttenAllQuestion(examOptions?.data[0]?.AttendAllQuestion);
                    setQuestionNav(examOptions?.data[0]?.QuestionNav);
                    setSubmitBefortime(examOptions?.data[0]?.SubmitBefortime);
                    // Update red and green flag counts
                    setredCount(flagCounts?.data?.RedCount[0]?.RedCount);
                    setgreenCount(flagCounts?.data?.GreenCount[0]?.GreenCount);

                    // Fetch exam parameter data
                    const examParameterData = await examServicesobj.GetExamParameterList();

                    // Find the corresponding parameter values using array.find()
                    const negativeMarkScoreValue = examParameterData?.parameterList.find(
                        (option) => option.parameterValueID == NegativeMarkID
                    )?.value;

                    const examTypeValue = examParameterData?.parameterList.find(
                        (option) => option.parameterValueID == questionDisplayId
                    )?.value;

                    const scoreResultValue = examParameterData?.parameterList.find(
                        (option) => option.parameterValueID == displayResultID
                    )?.value.trim();

                    const visiblePercentageValue = examParameterData?.parameterList.find(
                        (option) => option.parameterValueID == visiblePercentageID
                    )?.value;

                    // Update the state using the extracted values
                    setnegativeMarkScore(negativeMarkScoreValue);
                    setExamtype(examTypeValue);
                    setScoreResult(scoreResultValue);
                    setvisiblePercentage(visiblePercentageValue);
                    percentage = parseInt(visiblePercentageValue.replace("Apply", "").replace("%", "").trim()) / 100;
                    if (questioncount != null) {
                        var noofVisibleQuest = Math.floor(questioncount != 0 ? questioncount : localStorage.getItem('questionCount')) * percentage;
                        if (noofVisibleQuest < 1) {
                            noofVisibleQuest = 1;
                        }
                        setPercentageCount(noofVisibleQuest)
                    }

                    // ... rest of your code
                }

                hideLoading();
            } catch (err) {
                console.log(err);
                hideLoading();
            }
        };

        fetchData();
    }, []);
    useEffect(() => {
        function checkForDuplicateTab() {
            const currentUrl = window.location.href;
            const tabs = window.opener ? window.opener.window.frames : window.frames;
            for (let i = 0; i < tabs.length; i++) {
                try {
                    const tabUrl = tabs[i].location.href;
                    console.log(tabs.length)
                    if (tabUrl === currentUrl) {
                        tabs[i].focus();
                        window.close();
                        return true;
                    }
                } catch (e) { }
            }
            return false;
        }

        checkForDuplicateTab();
    }, []);


    useEffect(() => {
        getanswerlist();
        getQuestionColor();

    }, [Refesh]);

    const handleSuspendCheck = async (examId, CandidateID, ExamAttendID) => {

        await candidateQuestionServ.Candidate_SuspendCheck(examId, CandidateID, ExamAttendID).then((res) => {
            if (res != null) {

                let suspend = res.data[0]?.Suspend;
                EmailID = res.data[0]?.Email;
                let IsReTake = res.data[0]?.IsReTake;

                if (suspend === 1 && (IsReTake == null || IsReTake == 0)) {
                    handleSubmit('terminate');
                }
            }
        }).catch((err) => {
            console.log(err);
        });
    }
    const Getlist = async () => {
        debugger;
        if (candidateID > 0 && examID > 0) {

            await candidateQuestionServ.questionList(candidateID, examID, examAttendID).then((res) => {
                if (res != null) {
                    debugger;
                    console.log(res.data.questionList);
                    localStorage.setItem('questionCount', res?.data?.questionList);
                    const { questionList, answerList } = res?.data;
                    Questioncount = res?.data?.questionList.length;
                    if (questionList?.length > 0) {
                        debugger;
                        setQuestionList([...questionList]);
                        setcurrentQuestion(questionList[currentQuestionIndex]);
                        var currentQues = questionList[currentQuestionIndex].questionID;
                        if (answerList?.length > 0) {
                            setAnswerList([...answerList]);
                            const curroption = answerList.filter((answer) => answer.questionID === currentQues);
                            setcurrentAnswers([...curroption]);
                        }

                    }

                }

            }).catch((err) => {
                console.log(err);
            });
            return Questioncount;
        }
        else {
            navigate("/invalid", { replace: true });
        }
    };

    const getanswerlist = async () => {
        let Answercount = 0;
        await candidateQuestionServ.getAnswerHistory(candidateID, examID, examAttendID).then((res) => {
            if (res != null) {
                const mappedData = res?.data.map(model => ({
                    questionid: model.questionID,
                    answer: model.finalAnswer
                }));
                setanswercount(mappedData);
                setSelectedAnswers(res?.data);
                Answercount = mappedData?.length;
            }
        }).catch((err) => {
            console.log(err);
        });
        return Answercount;
    };

    const getQuestionColor = async (currentQuestionID, currentFlag) => {
        const data = {
            candidateID: candidateID,
            examID: examID,
            questionID: currentQuestionID,
            isSeen: null,
            flag: currentFlag,
        };
        await candidateQuestionServ.CandidateAnsFlag(data).then((res) => {
            if (res.data > 0) {
                console.log(currentFlag);
            }
        }).catch((res) => {
            console.log(res);
        });
    };

    const getPlainText = (htmlString) => {
        // const element = document.createElement("div");
        // element.innerHTML = htmlString;
        return `${htmlString}`;
    };

    const EachQuestion_TimeTaken = async (TimeTaken, QuestionID, CandidateID, ExamID, ExamAttendID) => {

        candidateQuestionServ.EachQuestion_TimeTaken(TimeTaken, QuestionID, CandidateID, ExamID, ExamAttendID).then((res) => {
            if (res.data === 1) {
                console.log('success');
            } else {
                console.log('Failure');
            }
        }).catch((err) => {
            console.log(err);
        });
    };
    //Next page and validation
    const showNextQuestion = () => {
        // var temp = percentageCount;
        // temp += percentageCount;
        // setPercentageCount(temp);

        if (examAttendID != null || examAttendID > 0) {
            handleSuspendCheck(examID, candidateID, examAttendID);
        }
        EachQuestion_TimeTaken(counter, currentQuestion.questionID, candidateID, examID, examAttendID);
        setCounter(0);
        var color;
        const element = document.getElementById(`background${currentQuestion.questionID}`);
        if (answer !== "" && answer != null || selectedAnswers.some(answer => answer.questionID === currentQuestion?.questionID)) {
            answercount(currentQuestion.questionID, selectedAnswers[currentQuestionIndex]?.finalAnswer);
            color = element.style.background = '#4fcb8d';
            element.style.color = 'white';
        }
        else {
            color = element.style.background = 'gray';
            element.style.color = 'white';
        }
        getQuestionColor(currentQuestion.questionID, color);
        setAnswer('');
        console.log(currentQuestion.questionID)
        const nextQuestionIndex = currentQuestionIndex + 1;
        if (nextQuestionIndex < questionList?.length) {
            setcurrentQuestion(questionList[nextQuestionIndex]);
            setCurrentQuestionIndex(nextQuestionIndex);
            const nextQuestionID = questionList[nextQuestionIndex].questionID;
            const currentOptions = answerLists.filter(
                (answer) => answer.questionID === nextQuestionID
            );
            setcurrentAnswers([...currentOptions]);
        } else {
            console.log("something went wrong");
        }
    };

    //previous Page
    const showPrevQuestion = () => {

        if (examAttendID != null || examAttendID > 0) {
            handleSuspendCheck(examID, candidateID, examAttendID);
        }

        var color;
        const element = document.getElementById(`background${currentQuestion.questionID}`);
        if (answer !== "" && answer != null || selectedAnswers.some(answer => answer.questionID === currentQuestion.questionID)) {
            color = element.style.background = '#4fcb8d';
            element.style.color = 'white';
            answercount(currentQuestion.questionID, selectedAnswers[currentQuestionIndex]?.finalAnswer);
        }
        else {
            color = element.style.background = 'gray';
            element.style.color = 'white';
        }
        getQuestionColor(currentQuestion.questionID, color);
        setAnswer('');
        const prevquesIndex = currentQuestionIndex - 1;
        setcurrentQuestion(questionList[prevquesIndex]);
        setCurrentQuestionIndex(prevquesIndex);
        const prevQuestionID = questionList[prevquesIndex].questionID;
        const curroption = answerLists.filter((answer) => answer.questionID === prevQuestionID);
        setcurrentAnswers([...curroption]);
    };

    // //sidenav click and navigate to the question    
    // const handleQuestionClick = (questionIndex) => {
    //     if (QuestionNav === true) {
    //         EachQuestion_TimeTaken(counter, currentQuestion.questionID, candidateID, examID);
    //         setCounter(0);
    //         setcurrentQuestionId(currentQuestion.questionID);
    //         setCurrentQuestionIndex(questionIndex - 1);
    //         setcurrentQuestion(questionList[questionIndex - 1]);
    //         var currentQues = questionList[questionIndex - 1].questionID;
    //         const curroption = answerLists.filter((answer) => answer.questionID === currentQues);
    //         setcurrentAnswers([...curroption]);
    //     }

    // };

    const handleAnswerChange = (e, questionID, eve) => {

        if (eve != null) {
            answercount(currentQuestion.questionID, eve);

        }
        if (examAttendID != null || examAttendID > 0) {
            handleSuspendCheck(examID, candidateID, examAttendID);
        }

        setAnswer(eve);
        const inputElement = e.target.tagName && e.target.tagName === 'INPUT' ? e.target : e.target.querySelector('input[type="checkbox"], input[type="radio"]');
        const inputName = inputElement?.getAttribute('id');
        const checkbox = document.getElementById(inputName);
        const inputType = inputElement ? inputElement.type : null;


        if (inputType === 'checkbox') {
            checkbox.checked = !checkbox.checked;
        } else if (inputType === 'radio') {
            checkbox.checked = true;
        }

        setSelectedAnswers((updatedAnswers) => {
            const oldAnswer = updatedAnswers.find((answer) => answer && answer.questionID === questionID);
            const finalAnswer = oldAnswer ? String(oldAnswer.finalAnswer) : '';
            const a = String(eve);

            if (inputType === 'checkbox') {
                const optionIDs = finalAnswer?.split(",").map((id) => id.trim());
                const isIDExists = optionIDs?.includes(a.trim());

                if (isIDExists) {
                    const newOptionIDs = optionIDs?.filter((id) => id.trim() !== a.trim());
                    const newAnswer = newOptionIDs?.join(",");
                    updatedAnswers[currentQuestionIndex] = {
                        questionID: questionID,
                        finalAnswer: newAnswer,
                    };
                } else {
                    const newAnswer = finalAnswer ? `${finalAnswer},${eve}` : eve;
                    updatedAnswers[currentQuestionIndex] = {
                        questionID: questionID,
                        finalAnswer: newAnswer,
                    };
                }
                console.log(updatedAnswers);
            } else if (inputType === 'radio') {
                updatedAnswers[currentQuestionIndex] = {
                    questionID: questionID,
                    finalAnswer: eve,
                };
            }


            const data = {
                candidateID: candidateID,
                examID: examID,
                sectionID: currentQuestion.sectionId,
                questionID: questionID,
                finalAnswer: updatedAnswers[currentQuestionIndex]?.finalAnswer || null,
                examAttendID: examAttendID
            };

            candidateQuestionServ.candidateAnswer(data)
                .then((res) => {
                    if (res.data > 0) {
                        console.log("Success");
                    } else {
                        console.log("Failed to save");
                    }
                })
                .catch((err) => {
                    console.log(err);
                });

            if (AttendAllQuestion) {
                const totalQuestions = questionList.length;
                const answeredQuestionsCount = selectedAnswers.filter((answer) => answer?.finalAnswer !== null).length;
                const areAllQuestionsAnswered = answeredQuestionsCount === totalQuestions;
                const check = areAllQuestionsAnswered ? false : true;
                setAttenAllQuestion(check);
            }

            setRefesh();

            return updatedAnswers;
        });
    };

    const handleClearAnswer = (e) => {
        // Uncheck all the checkboxes for the current question
        if (examAttendID != null || examAttendID > 0) {
            handleSuspendCheck(examID, candidateID, examAttendID);
        }
        const checkboxes = document.querySelectorAll(`input[type="checkbox"][name="answer-${currentQuestion.questionID}"]:checked`);
        checkboxes.forEach((checkbox) => {
            checkbox.checked = false;
        });

        setSelectedAnswers((prevAnswers) => {
            const updatedAnswers = prevAnswers.filter(
                (answer) => answer.questionID !== currentQuestion.questionID
            );

            console.log(updatedAnswers);
            return updatedAnswers;
        });



        setAnswer('');
        answercount(currentQuestion.questionID, null);
        const element = document.getElementById(`background${currentQuestion.questionID}`);
        element.style.background = 'gray';
        element.style.color = 'white';

        const clear = {
            candidateID: candidateID,
            examID: examID,
            sectionID: currentQuestion.sectionId,
            questionID: currentQuestion.questionID,
            finalAnswer: null,
            examAttendID: examAttendID
        };

        candidateQuestionServ.candidateAnswer(clear)
            .then((res) => {
                if (res.data >= 0) {
                    console.log("Success");
                } else {
                    console.log("Failed to save");
                }
            })
            .catch((err) => {
                console.log(err);
            });

        getQuestionColor(currentQuestion.questionID, 'gray');
    };

    const handleSubmit = (terminate) => {

        EachQuestion_TimeTaken(counter, currentQuestion.questionID, candidateID, examID, examAttendID);
        setcurrentQuestionId(currentQuestion.questionID);
        var color;
        const element = document.getElementById(`background${currentQuestion.questionID}`);
        if (answer !== "" || selectedAnswers[currentQuestionIndex]?.finalAnswer) {
            if (element) {
                color = element.style.background = '#4fcb8d';
                element.style.color = 'white';
            }
        }
        else {
            if (element) {
                color = element.style.background = 'gray';
                element.style.color = 'white';
            }
        }
        getQuestionColor(currentQuestion.questionID, color);

        if (examAttendID != undefined) {
            const Data = {
                examID: examID,
                candidateID: candidateID,
                score: 0,
                negativeMarks: negativeMarkScore === 'Apply 10% negative marks for this exam' ? 10 : negativeMarkScore === 'Apply 12.5% negative marks for this exam' ? 12.5
                    : negativeMarkScore === 'Apply 20% negative marks for this exam' ? 20 : negativeMarkScore === 'Apply 25% negative marks for this exam' ? 25 : negativeMarkScore === 'Apply 33% negative marks for this exam' ? 33 :
                        negativeMarkScore === 'Apply 50% negative marks for this exam' ? 50 : negativeMarkScore === 'Apply 75% negative marks for this exam' ? 75 : negativeMarkScore === 'Apply 100% negative marks for this exam' ? 100 : 1,
                email: EmailID != null ? EmailID : email,
                examAttendID: examAttendID
            }

            if (candidateID > 0) {

                showLoading();

                candidateQuestionServ.CandidateSumbitAnswer(Data).then((res) => {
                    if (res != null) {
                        localStorage.removeItem('Rejoincode');
                        hideLoading();
                        if (terminate === 'terminate') {
                            navigate('/Terminate', { replace: true });
                        }
                        else {
                            hideLoading();

                            if (scoreResult === "Do not disclose" || scoreResult === "Score only") {

                                navigate("/Thanks", {
                                    state: { candidateID: candidateID, examID: examID, resultType: scoreResult, examAttendID: examAttendID, candidateName: CandidateName },
                                    replace: true,
                                });
                            } else {
                                navigate("/DetailReport", {
                                    state: { cId: candidateID, examID: examID },
                                    replace: true
                                });
                            }
                        }
                        // candidateservice.Candidate_RejoinDelete(candidateID, examID).then((res) => {
                        //     if (res.data > 0) {
                        //         console.log('delete success rejoin code');
                        //     }

                        // }).catch((err) => {
                        //     console.log(err);
                        // })
                    }
                }).catch((err) => {

                    console.log(err);
                });
            }
        }

    };

    const setFlag = (flag) => {
        var flagcode = "NULL";
        if (questionidRef.current) {
            const id = currentQuestion.questionID;
            const element = document.getElementById(`questionno${id}`);
            if (element) {
                const display = window.getComputedStyle(element).display;
                const color = window.getComputedStyle(element).color;
                if (flag === 'F') {
                    if (display === 'none') {
                        element.style.display = 'block';
                        element.style.color = "red";
                        flagcode = 'F';
                        setredCount((prevCount) => prevCount + 1);
                    } else if (display === 'block' && color === 'rgb(0, 128, 0)') {
                        element.style.color = "red";
                        flagcode = 'F';
                        setredCount((prevCount) => prevCount + 1);
                        setgreenCount((prevCount) => prevCount - 1);
                    } else if (display === 'block') {
                        element.style.display = 'none';
                        flagcode = "NULL";
                        setredCount((prevCount) => prevCount - 1);
                    }
                } else if (flag === 'G') {

                    if (display === 'none') {
                        element.style.display = 'block';
                        element.style.color = "green";
                        flagcode = 'G';
                        setgreenCount((prevCount) => prevCount + 1);
                    } else if (display === 'block' && color === 'rgb(255, 0, 0)') {
                        element.style.color = "green";
                        flagcode = 'G';
                        setgreenCount((prevCount) => prevCount + 1);
                        setredCount((prevCount) => prevCount - 1);
                    } else if (display === 'block') {
                        element.style.display = 'none';
                        flagcode = "NULL";
                        setgreenCount((prevCount) => prevCount - 1);
                    }
                }
            }
        }
        const Data = {
            examID: examID,
            candidateID: candidateID,
            questionID: currentQuestion.questionID,
            flagSymbol: flagcode,
            examAttendID: examAttendID
        }

        if (candidateID > 0) {
            candidateQuestionServ.CandidateFlagButton(Data).then((res) => {
                if (res != null) {
                    setflag(res?.data);
                }
            }).catch((err) => {
                console.log(err);
            });
        }
    };

    const answercount = (Qid, finalAnswer) => {
        let updatedAnswerCount = [...answerCount];
        let length = 0;

        const existingAnswerIndex = answerCount.findIndex((e) => e.questionid === Qid);

        if (finalAnswer === null) {
            if (existingAnswerIndex !== -1) {
                updatedAnswerCount.splice(existingAnswerIndex, 1); // Remove the record
            }
        } else {
            if (existingAnswerIndex !== -1) {
                updatedAnswerCount[existingAnswerIndex].answer = finalAnswer;
            } else {
                updatedAnswerCount.push({ 'questionid': Qid, 'answer': finalAnswer });
            }
        }

        setanswercount(updatedAnswerCount);

        // Calculate length based on non-null answers
        const filteredUpdatedAnswers = updatedAnswerCount.filter((e) => e.answer !== null);
        length = filteredUpdatedAnswers.length;

        setValue(length, questionList?.length);

        console.log(length);
    };

    const setValue = (l, questioncount) => {
        const nextProgress = (l / questioncount) * 100;
        const roundnumber = Math.round(nextProgress);
        setProgress(roundnumber);
    };

    return (
        <>
            {examType === "One question per page" ? (
                <div className="all-section-area" id="oneQuestion">
                    <Header
                        ExamId={examID}
                        handleSubmit={handleSubmit}
                        candidateName={CandidateName}
                        candidateID={candidateID}
                        examAttendID={examAttendID}
                        SubmitBefortime={SubmitBefortime}
                    />
                    <SideNav
                        questionList={questionList}
                        // handleQuestionClick={handleQuestionClick}
                        currentQuestionIndex={currentQuestionIndex}
                        candidateID={candidateID}
                        examID={examID}
                        examAttendID={examAttendID}
                        currentQuestionID={currentQuestionId}
                    />
                    <main className="main-content-wrap style-two" style={{ overflow: 'auto', display: 'block' }}>
                        <div className="overview-content-area">
                            <div className="container-fluid">
                                {/* <div className="fixed-content"> */}
                                <div className="row align-items-center">
                                    <div className="col-8">
                                        <div className="page-title">
                                            <h4 style={{ paddingLeft: '25px', fontWeight: 'bold' }}>Question {currentQuestionIndex + 1} / {questionList.length}</h4>
                                        </div>
                                    </div>
                                    <div className="col-4 ">
                                        <div className="col-12">
                                            <span style={{ fontWeight: 'bold' }}>Section: {currentQuestion.sectionName}</span>
                                        </div>
                                    </div>
                                </div>
                                {/* </div> */}

                                <div className="card-box-style mt-2" >
                                    <div className="" style={{ marginBottom: '20px' }}>
                                        <pre>
                                            <h5 id={`${currentQuestionIndex + 1}`} ref={questionidRef} dangerouslySetInnerHTML={{ __html: getPlainText(currentQuestion.question) }}></h5>
                                        </pre>
                                        {currentQuestion?.questionImageURL ? <img src={IMAGE_URL + currentQuestion?.questionImageURL} alt="Image" style={{ maxWidth: '100%', height: 'auto' }} /> : <></>}
                                    </div>
                                    <div className="row mt-2">
                                        {currentAnswers?.map((option, index) => {
                                            const correctAnswerCount = currentAnswers?.filter((answer) =>
                                                answer.questionID === currentQuestion.questionID &&
                                                answer.isCorrectAnswer === "Yes"
                                            );

                                            const isMultipleAnswer = correctAnswerCount.length > 1;
                                            result = selectedAnswers?.length > 0 && selectedAnswers?.filter((e) => e?.questionID == currentQuestion?.questionID);
                                            var check = String(result[0]?.finalAnswer).split(',').map(item => item.trim()).includes(option.questionOptionID.toString()) ?? false;

                                            return (<div className="col-lg-12 col-sm-12" key={index}>
                                                <div className='single-audience d-flex justify-content-between align-items-center' onClick={(eve) => handleAnswerChange(eve, currentQuestion.questionID, option.questionOptionID)} >
                                                    <div className="audience-content">
                                                        {isMultipleAnswer ? (
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    name={`answer-${currentQuestion.questionID}`}
                                                                    id={`answer-${currentQuestion.questionID}-${index}`}
                                                                    value={option.questionOptionID}
                                                                    checked={check}
                                                                    onChange={() => { }}
                                                                />
                                                                {' '}
                                                                {getPlainText(option.answerValue)}
                                                                {option?.imageURL ? (
                                                                    <img
                                                                        src={IMAGE_URL + option?.imageURL}
                                                                        alt="Image"
                                                                        style={{ maxWidth: '100%', height: '40px' }}
                                                                    />
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </label>
                                                        ) : (
                                                            <label>
                                                                <input
                                                                    type="radio"
                                                                    name={`answer-${currentQuestion.questionID}`}
                                                                    id={`answer-${currentQuestion.questionID}-${index}`}
                                                                    value={option.questionOptionID}
                                                                    checked={option.questionOptionID == result[0]?.finalAnswer ? true : false}
                                                                    onChange={() => { }}
                                                                />
                                                                {' '}
                                                                {getPlainText(option.answerValue)}
                                                                {option?.imageURL ? (
                                                                    <img
                                                                        src={IMAGE_URL + option?.imageURL}
                                                                        alt="Image"
                                                                        style={{ maxWidth: '100%', height: '40px' }}
                                                                    />
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </label>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            );
                                        })}

                                    </div>
                                    <div className="OptionSection">
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="NextPrevClass">
                                                    {currentQuestionIndex > 0 && QuestionNav === true && (
                                                        <button
                                                            className="btn btn-sm btn-primary"
                                                            id='prev'
                                                            style={{ marginRight: '10px' }}
                                                            onClick={() => showPrevQuestion()}
                                                        >
                                                            « Prev
                                                        </button>
                                                    )}
                                                    {currentQuestionIndex === questionList.length - 1 ? (

                                                        <button
                                                            className="btn btn-sm btn-primary"
                                                            id="submit"
                                                            onClick={() => handleSubmit()}
                                                            style={{ marginLeft: '10px' }}
                                                            disabled={AttendAllQuestion}
                                                        >
                                                            Submit
                                                        </button>
                                                    ) : (

                                                        <button
                                                            className="btn btn-sm btn-primary"
                                                            style={{ marginLeft: '10px' }}
                                                            onClick={() => showNextQuestion()}
                                                            disabled={(((currentQuestionIndex + 1) - answerCount.length) < percentageCount) ? false : true}
                                                        >
                                                            Next »
                                                        </button>)
                                                    }
                                                </div>
                                                {/* <div>{currentQuestionIndex < percentageCount || answerCount == percentageCount ? (
                                                    <button
                                                        className="btn btn-sm btn-primary"
                                                        style={{ marginLeft: '10px' }}
                                                        onClick={() => showNextQuestion()}
                                                    >
                                                        Next »
                                                    </button>) : <p>no next</p>}</div> */}
                                            </div>
                                            <div className="col-4 mt-2">
                                                <div className="progress ">
                                                    <div className="progress-bar progress-bar-striped progress-bar-animated " aria-valuemin={20} aria-valuemax={20} style={{ width: `${progress}%`, background: '#49c199' }}>
                                                        {progress}%
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="ClearClass" style={{ float: "right" }}>
                                                    <button
                                                        name="eraser"
                                                        className="btn btn-primary shadow bg-white rounded"
                                                        style={{ marginRight: '1px', cursor: 'pointer' }}
                                                        onClick={(e) => handleClearAnswer(e)}
                                                        title="Clear"
                                                    >
                                                        <FaEraser color="black" />
                                                    </button>

                                                    <button
                                                        className="btn  btn-primary shadow bg-white rounded"
                                                        style={{ color: 'red', marginRight: '1px', cursor: 'pointer' }}
                                                        title="Doubtful"
                                                        onClick={() => setFlag('F')}
                                                    >
                                                        <span><FlagCount count={redCount} /></span>

                                                    </button>

                                                    <button
                                                        className="btn  btn-primary shadow bg-white rounded"
                                                        style={{ marginRight: '1px', color: 'green', cursor: 'pointer' }}
                                                        title="Confirmed"
                                                        onClick={() => setFlag('G')}
                                                    >
                                                        <span><FlagCount count={greenCount} /></span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <MalpracticeActivity examID={examID} candidateID={candidateID} ExamAttendID={examAttendID} handleSubmit={handleSubmit} />

                </div>
            ) : ""}
            {examType === "All question" ? (
                <div className="all-section-area" id="allQuestion">
                    <Header
                        ExamId={examID}
                        handleSubmit={handleSubmit}
                        candidateName={CandidateName}
                        candidateID={candidateID}
                        SubmitBefortime={SubmitBefortime}
                    />
                    <main className="main-content-wrap" style={{ height: '0px', overflow: 'auto', display: 'block' }}>
                        <div className="overview-content-area">
                            <div className="container-fluid" style={{ marginRight: '50px' }}>
                                {/* Display all questions */}
                                <div className="row align-items-center">
                                    <div className="col-8">
                                        <div className="page-title">
                                            <h4 style={{ paddingLeft: '100px', fontWeight: 'bold' }}>Total Questions :{questionList.length}</h4>
                                        </div>
                                    </div>
                                    {/* <div className="col-4 ">
                                        <div className="col-12">
                                            <span style={{ fontWeight: 'bold' }}>Section: {currentQuestion.sectionName}</span>
                                        </div>
                                    </div> */}
                                </div>
                                {questionList.map((currentQuestion, index) => (
                                    <div key={index} className="card-box-style mt-1" style={{ marginRight: '100px', marginLeft: '100px', padding: '24px', marginBottom: '0px' }}>
                                        <div className="" style={{ marginBottom: '0px' }}>
                                            <h5 id={`${index + 1}`} ref={questionidRef}>
                                                {`${index + 1}) ${currentQuestion.question != null ? getPlainText(currentQuestion.question) : ''}`}
                                            </h5>
                                            {currentQuestion?.questionImageURL ? (
                                                <img
                                                    src={IMAGE_URL + currentQuestion?.questionImageURL}
                                                    alt="Image"
                                                    style={{ maxWidth: '100%', height: 'auto' }}
                                                />
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                        <div className="row mt-2">
                                            {answerLists
                                                .filter((option) => option.questionID === currentQuestion.questionID)
                                                .map((option, index) => {
                                                    const correctAnswerCount = currentAnswers?.filter((answer) =>
                                                        answer.questionID === currentQuestion.questionID &&
                                                        answer.isCorrectAnswer === "Yes"
                                                    );
                                                    const isMultipleAnswer = correctAnswerCount.length > 1;
                                                    var result = selectedAnswers?.length > 0 && selectedAnswers?.filter((e) => e?.questionID == currentQuestion?.questionID);
                                                    var check = String(result[0]?.finalAnswer).split(',').map(item => item.trim()).includes(option.questionOptionID.toString()) ?? false;

                                                    return (
                                                        <div className="col-lg-12 col-sm-12" key={index}>
                                                            <div className='single-audience d-flex justify-content-between align-items-center' onClick={(eve) => handleAnswerChange(eve, currentQuestion.questionID, option.questionOptionID)}>
                                                                <div className="audience-content">
                                                                    <label>
                                                                        <input
                                                                            type="radio"
                                                                            name={`answer-${currentQuestion.questionID}`}
                                                                            id={`answer-${currentQuestion.questionID}-${index}`}
                                                                            value={option.questionOptionID}
                                                                            checked={option.questionOptionID == result[0]?.finalAnswer ? true : false}
                                                                            onChange={() => { }}
                                                                        />
                                                                        {' '}
                                                                        {getPlainText(option.answerValue)}
                                                                        {option?.imageURL ? (
                                                                            <img
                                                                                src={IMAGE_URL + option?.imageURL}
                                                                                alt="Image"
                                                                                style={{ maxWidth: '100%', height: '40px' }}
                                                                            />
                                                                        ) : (
                                                                            <></>
                                                                        )}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    </div>
                                ))}


                                {/* Submit button */}
                                <div className="row" style={{ marginTop: '-20px' }}>
                                    <div className="col-12 text-center" style={{ marginBottom: '5px' }}>
                                        <button
                                            className="btn btn-sm btn-primary"
                                            onClick={() => handleSubmit()}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>

                    <MalpracticeActivity examID={examID} candidateID={candidateID} handleSubmit={handleSubmit} />
                </div>
            ) : ""}
        </>
    );

}



