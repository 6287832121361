import AxiosService from "./AxiosService";
import {
  Encrypt,
  Decrypt,
} from "./CommonService";
const axios = new AxiosService();

export default class ApplicationConfigService {
  GetParameterList() {
    return axios
      .get("Settings/GetParameterList")
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
  GetParameterValuesAgainstParameters(id) {
    return axios
      .get(`Settings/GetParameterValuesAgainstParameters?id=${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
  GetSectionParameters() {
    ;
    return axios
      .get("Settings/GetSectionParameters")
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
  SettingsSaveSection = async (data) => {
    return axios.post(`Settings/SettingsSaveSection`, data);
  };
  SettingsSaveParameterValues = async (data) => {
    return axios.post(`Settings/SettingsSaveParameterValues`, data);
  };
  DeleteSection(id) {
    return axios
      .get(`Settings/DeleteSection?id=${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
  DeleteParameterValues(id) {
    return axios
      .get(`Settings/DeleteParameterValues?id=${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  CheckAlreadyExistsCategory(value) {
    var CompanyID= Decrypt(localStorage.getItem("CompanyID"));
    return axios
      .get(`Settings/CheckAlreadyExistsCategory?categoryName=${value}&CompanyID=${CompanyID}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }


  CreateUpdateSection = async (data) => {
      return axios.post(`Settings/CreateUpdateSection`, data).then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
    };
  
      
    GetCategoryAgainstSectionList(value) {
      var CompanyID= Decrypt(localStorage.getItem("CompanyID"));
      return axios
        .get(`Settings/GetCategoryAgainstSectionList?categoryName=${value}&CompanyID=${CompanyID}`)
        .then((response) => {
          
          return response.data;
          
        })
        .catch((err) => {
          console.log(err);
        });
    }
    GetCategoryList(CompanyID) {
      return axios
        .get(`Settings/GetCategoryList?CompanyID=${CompanyID}`)
        .then((response) => {
          
          return response.data;
          
        })
        .catch((err) => {
          console.log(err);
        });
    }
    DeleteCategory(id) {
      return axios
        .get(`Settings/DeleteCategoryandAgainstSections?id=${id}`)
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    }


  Get_EmailTemplateList() {
    return axios
      .get(`EmailTemplate/GetEMailPartial`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
  DeleteTemplate(val, ModifiedBy) {
    ;
    return axios
      .get(`EmailTemplate/EmailDelete?id=${val}&ModifiedBy=${ModifiedBy}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  Get_EmailTemplateById(id) {
    return axios
      .get(`EmailTemplate/Index?id=${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  Get_ModuleFunctionality(id) {
    return axios
      .get(`EmailTemplate/ModuleNameChanged?id=${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  Get_SubjectAndBodyById(id) {
    return axios
      .get(`EmailTemplate/ModuleFuncNameChanged?id=${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  EmailTemplateCreateUpdate = async (obj) => {
    ;
    try {
      return axios.post("EmailTemplate/CreateUpdateEmailtemplate", obj);
    } catch (error) {
      console.log(error);
    }
  };

}
