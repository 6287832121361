import React from "react";
import network from '../../assets/images/network.png';
import { FaExclamationTriangle } from 'react-icons/fa';

function Network() {


    return (
        <div class="error-area">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="error-content card-box-style">
                            {/* <h1><FaExclamationTriangle color="red" /></h1> */}
                            <img src={network} alt="Network connection issue: ⚠️" />

                            <h4>Network Connection Error.</h4>
                            <p>Please fix your internet connection and then rejoin the exam by using the Rejoin code.</p>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};


export default Network;