import React, { useState, useEffect, useRef, useContext } from "react";
import { useLocation } from "react-router-dom";
import { AppContext } from "../../services/ContextProvider";

import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import $ from "jquery";
import ApplicationConfigService from "../../services/ApplicationConfigService";
import { useNavigate } from "react-router-dom";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import { showErrorMsg } from "../ToastMsg";
import { getDate } from "date-fns";

const ApplicationConfiguration = (props) => {
  const _applicationService = new ApplicationConfigService();
  const location = useLocation();
  const emid = location.state;
  const [isContainerFluid, setIsContainerFluid] = useState(true);

  const { getCookies, setCookies, cookies, tempModulePrivileage, showLoading, hideLoading } =
    useContext(AppContext);

  const { quill, quillRef } = useQuill();
  const [funLists, SetfunDownList] = useState([
    {
      text: "No Data",
      value: "",
      moduleFunctionalityID: 0,
      moduleFunctionalityName: "",
    },
  ]);

  const [DropdownConfigvalues, setDropDownList] = useState({
    value: "",
    text: "",
    DropdownDetailID: 0,
  });
  const navigate = useNavigate();

  const SubmitsaveemaiilCreate = (e) => {
    e.preventDefault();

    const data = new FormData(e.currentTarget);

    const text = quill.root.innerHTML;

    const obj = {
      EmailTemplateID: JSON.parse(data.get("EmailTemplateID")),
      TemplateName: "",
      Subject: data.get("Subject"),
      Body: text,

      ModuleID: Number(data.get("DropdownmoduleIDCode")),
      ModuleName: "",
      ModuleFunctionalityID: Number(data.get("DropdownFunctionalityCode")),
      ModuleFunctionalityName: "",
      TemplateColumnID: 0,
      ColumnName: "",
      ModuleGroup: "",
      DisplayName: "",
      CCEmailList: data.get("CCEmailList"),
      ToEmail: "",
      CreatedBy: 0,
      ModifiedBy: 0,
      CreatedDate: new Date().toLocaleString(),
      ModifiedDate: new Date().toLocaleString(),
    };

    if (obj.ModuleID == null || obj.ModuleID == 0) {
      setEmailtemplateCreate({ errCategoryID: "Select Module " });
      return false;
    }
    if (obj.ModuleFunctionalityID == null || obj.ModuleFunctionalityID == 0) {
      setEmailtemplateCreate({
        errModuleFunctionalityID: "Select Functionality ",
      });
      return false;
    }

    if (obj.Subject == null || obj.Subject == 0) {
      setEmailtemplateCreate({ errSubject: "Enter Subject " });
      return false;
    }

    if (obj.Body == null || obj.Body == 0) {
      setEmailtemplateCreate({ errBody: "Select Functionality " });
      return false;
    }
    if (obj.CategoryID != null || obj.CategoryID != 0) {
      setEmailtemplateCreate({ errCategoryID: " " });
      // return false;
    }

    if (obj.Body != null || obj.Body != 0) {
      setEmailtemplateCreate({ errBody: " " });
      // return false;
    }

    if (obj.ModuleFunctionalityID != null || obj.ModuleFunctionalityID != 0) {
      setEmailtemplateCreate({ errModuleFunctionalityID: "" });
    }

    if (obj.Subject == null || obj.Subject == 0) {
      setEmailtemplateCreate({ errSubject: " " });
    }

    _applicationService
      .EmailTemplateCreateUpdate(obj)
      .then((res) => {
        if (res.data > 0) {
          navigate("/EmailTemplate");
        } else {
          showErrorMsg();
        }
      })
      .catch((error) => {
        showErrorMsg();
      });
    // ConfigNewCategoryConfigclick(obj);
    // fetch(
    //   process.env.REACT_APP_API + "EmailTemplate/CreateUpdateEmailtemplate",
    //   {
    //     method: "POST",
    //     body: JSON.stringify(obj),
    //     mode: "cors",
    //     headers: {
    //       Accept: "application/json",
    //       "Content-type": "application/json",
    //       Authorization: `Bearer ${
    //         JSON.parse(localStorage.getItem("UserDetails")).token
    //       }`,
    //     },
    //   }
    // )
    //   .then((response) => {
    //     return response.json();
    //   })
    //   .then((data) => {
    //     navigate("/Emailtemplate");
    //   })

    //   .catch((err) => {
    //     // 
    //     return alert(err);
    //   });
  };

  const [ModuleFunctionalityConfig, setModuleFunctionalityConfigvalues] =
    useState({
      value: "",
      text: "",
      dropdownDetailID: 0,
    });
  const [DropdownCode, setDrropdownCode] = useState("No Data");

  // const [bodytextappended, setbodytextappended] = useState(

  //  funsetbodytextappended()

  // );
  const [ApplicationData, SetApplicationData] = useState([]);

  const [ModuleFunctionalityList, SetDDLModuleFunctionalityList] = useState([
    {
      text: "No Data",
      value: "",
    },
  ]);

  const [EmailtemplateCreate, setEmailtemplateCreate] = useState({
    moduleID: 0,
    categoryID: 0,
    moduleFunctionalityID: "",
    subject: "",
    body: "",
    ccEmailList: "",
    emailTemplateID: 0,
    errCategoryID: "",
    errModuleFunctionalityID: "",
    errSubject: "",
    errBody: "",
    errCCEmailList: "",
    errEmailTemplateID: "",
    text: "No Data",
    value: "",
  });

  const [ModuleIDCreate, setModuleIDCreate] = useState({
    moduleID: 0,
    moduleName: "",
  });

  const [SubjectbodyList, SetSubjectbodyList] = useState([
    {
      text: "No Data",
      value: "",
    },
  ]);

  useEffect(() => {
    module();
  }, []);

  const module = () => {

    // console.log(emid);
    var EmpID = emid;
    if (emid == null || emid == 0) {
      EmpID = 0;
    }
    _applicationService
      .Get_EmailTemplateById(EmpID)
      .then((response) => {
        hideLoading();

        setDropDownList(response.ddlModuleList);
        setEmailtemplateCreate(response);
        SetSubjectbodyList(response.ddlTempColList);
        SetDDLModuleFunctionalityList(response.ddlModuleFunctionalityList);
        SetfunDownList(response.ddlModuleFunctionalityList);
        console.log(response.ddlModuleFunctionalityList);
        setModuleIDCreate(response);

        document.getElementById("CCEmailList").value = response.ccEmailList;
        subjectRef.current.querySelector("#Subject").value = response.subject;

        var div = document.getElementById("w1-Body");
        $("#DropdownFunctionalityCode").val(response.moduleFunctionalityID);


        const updatedFunLists = funLists.map((item) => {

          if (item.value === response.moduleFunctionalityID) {
            return {
              ...item,
              value: response.moduleFunctionalityID,
            };
          }

          return item;
        });
        SetfunDownList(updatedFunLists);

        subjectRef.current.querySelector("#DropdownmoduleIDCode").value =
          response.moduleID;

        var bodyinner = response.body;

        const vid = response.moduleFunctionalityID;

        funLists.moduleFunctionalityID = vid;

        funLists.moduleFunctionalityID = $(".ql-editor").html(bodyinner);
      })
      .catch((error) => {
        console.log(error);
        hideLoading();
      });
  };

  const funtionality = (DropdownCode) => {


    var id = DropdownCode > 0 ? DropdownCode : 0;

    _applicationService
      .Get_ModuleFunctionality(id)
      .then((response) => {
        debugger;
        if (response.ddlModuleFunctionalityList != null)
          SetDDLModuleFunctionalityList(response.ddlModuleFunctionalityList);
      })
      .catch((error) => console.log(error));

    console.log(ModuleFunctionalityList);
  };
  const ReplaceBodyTextFunction = (e) => {


    // var SampleText = this.state.Subject.toString();
    var SampleText = document.getElementById("Subject").value;
    var modulename = document.getElementById(
      "DropdownmoduleIDCode"
    ).textContent;
    const text = quill.root.innerHTML;
    var NewText = text + "{" + modulename + "." + e + "}";
    quill.root.innerHTML = NewText;
    // this.setState({ SampleText: NewText });
  };

  const ReplaceTextFunction = (e) => {
    // 

    // var SampleText = this.state.Subject.toString();
    var SampleText = document.getElementById("Subject").value;
    var modulename = document.getElementById(
      "DropdownmoduleIDCode"
    ).textContent;
    var NewText = SampleText + "{" + modulename + "." + e + "}";

    document.getElementById("Subject").value = NewText;
    // this.setState({ SampleText: NewText });
  };

  const sub = (funLists) => {
    var Ids;
    if (funLists != 0) Ids = funLists;

    _applicationService
      .Get_SubjectAndBodyById(Ids)
      .then((response) => {
        if (response.ddlTempColList != null)
          SetSubjectbodyList(response.ddlTempColList);
      })
      .catch((error) => console.log(error));
  };

  const subjectRef = useRef();
  const toggleContainerClass = () => {
    setIsContainerFluid((prevState) => !prevState);
  };
  return (
    <>
      <main className="main-content-wrap">
        <div className="contact-list-area ">

          <div className='container-fluid'>
            <div className="row">
              <div className={`${isContainerFluid ? "col-md-12" : "col-md-9"}`}>
                <div className="card-box-style animatedwidth">

                  <div className="fullwidthbuttonbox " onClick={toggleContainerClass}>
                    <span className="fullwidthbutton" >
                      {isContainerFluid ? <i className="fa fa-angle-double-left"></i> : <i className="fa fa-angle-double-right"></i>}

                    </span>
                  </div>
                  <div className="others-title row">
                    <h3 className="col-6"> {emid > 0 ? 'Edit' : 'Add'}  Email Template
                    </h3>
                    <span className="col-6">
                      <input type="button" value="Back" title="Back" style={{ float: 'right' }} className="back-button backbutton-end" onClick={() => navigate(-1)} />

                    </span>
                  </div>
                  <div
                    className="col-auto float-end ms-auto"
                    style={{ marginTop: "-71px", background: "#1868f9" }}
                  >
                    {/* <a
              href="/Emailtemplate"
              className="btn add-btn"
              style={{ color: "white" }}
            >
              <i className="fa fa-backward"></i> Back to List
            </a> */}
                  </div>
                  <form onSubmit={SubmitsaveemaiilCreate} ref={subjectRef}>
                    <div className="row ">
                      <div className="col-sm-9 ">
                        <div className="card">
                          <div className="card-body">
                            <div className="col-md-12" style={{ margin: "1%" }}>
                              <label htmlFor="emailID">
                                Module <span className="text-danger">*</span>
                              </label>
                              <FormControl fullWidth>
                                {/* <InputLabel id="demo-simple-select-label">
                          Module
                        </InputLabel> */}
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="DropdownmoduleIDCode"
                                  name="DropdownmoduleIDCode"
                                  // label="Module"
                                  // style={{ margin: "1%" }}
                                  // defaultValue={EmailtemplateCreate.ModuleID}
                                  value={ModuleIDCreate.moduleID}
                                  onChange={(e) => {
                                    setModuleIDCreate({
                                      moduleID: e.target.value,
                                      moduleName: e.target.name,
                                    });

                                    funtionality(e.target.value);
                                    return true;
                                  }}
                                >
                                  {DropdownConfigvalues != null &&
                                    DropdownConfigvalues.length > 0 ? (
                                    DropdownConfigvalues.map((item, indexs) => {
                                      return (
                                        <MenuItem key={indexs} value={item.value}>
                                          {item.text}
                                        </MenuItem>
                                      );
                                    })
                                  ) : (
                                    <option value=""></option>
                                  )}
                                </Select>
                                <span style={{ color: "red" }}>
                                  {EmailtemplateCreate.errCategoryID}
                                </span>
                              </FormControl>
                            </div>

                            <div className="col-md-12" style={{ margin: "1%" }}>
                              <label htmlFor="emailID">
                                Functionality <span className="text-danger">*</span>
                              </label>
                              <FormControl fullWidth>
                                {emid == 0 || emid == null ? (
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="DropdownFunctionalityCode"
                                    name="DropdownFunctionalityCode"
                                    label="Functionality"
                                    value={funLists.moduleFunctionalityID}
                                    onChange={(e) => {
                                      SetfunDownList({
                                        value: e.target.value,
                                        text: e.target.name,
                                      });

                                      return sub(e.target.value);
                                    }}
                                  >
                                    {ModuleFunctionalityList.map((item, indexs) => {
                                      return (
                                        <MenuItem key={indexs} value={item.value}>
                                          {item.text}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                ) : (
                                  <>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="DropdownFunctionalityCode"
                                      name="DropdownFunctionalityCode"
                                      label="Functionality"
                                      value={EmailtemplateCreate.moduleFunctionalityID}
                                      onChange={(e) => {

                                        setEmailtemplateCreate({
                                          moduleFunctionalityID: e.target.value,
                                          moduleFunctionalityName: e.target.name,
                                        });

                                        return sub(e.target.value);
                                      }}
                                    >
                                      {ModuleFunctionalityList.map((item, indexs) => {
                                        return (
                                          <MenuItem key={indexs} value={item.value}>
                                            {item.text}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </>
                                )}
                              </FormControl>
                            </div>

                            <div className="col-md-12" style={{ margin: "1%" }}>
                              <label htmlFor="emailID">
                                Subject <span className="text-danger">*</span>
                              </label>
                              <TextField
                                // style={{ margin: "1%" }}
                                fullWidth
                                id="Subject"
                                name="Subject"
                                variant="outlined"
                              />
                              <span style={{ color: "red" }}>
                                {EmailtemplateCreate.errSubject}
                              </span>
                            </div>

                            <div className="col-md-12" style={{ margin: "1%" }}>
                              <label htmlFor="emailID">
                                CC
                              </label>
                              <TextField
                                // style={{ margin: "1%" }}
                                fullWidth
                                id="CCEmailList"
                                // label="Cc"
                                name="CCEmailList"
                                variant="outlined"
                              />

                              <span style={{ color: "red" }}>
                                {EmailtemplateCreate.errCCEmailList}
                              </span>

                              <TextField
                                fullWidth
                                id="EmailTemplateID"
                                label="EmailTemplateID"
                                name="EmailTemplateID"
                                variant="outlined"
                                value={EmailtemplateCreate.emailTemplateID}
                                style={{ display: "none", margin: "1%" }}
                                onChange={(e) =>
                                  this.setState({
                                    Configs: {
                                      ...this.state.EmailtemplateCreate,
                                      ccEmailList: e.target.value,
                                    },
                                  })
                                }
                              />
                            </div>
                            {/* 
                    <div className="form-group">
                      <TextField
                        style={{ margin: "1%" }}
                        fullWidth
                        id="CCEmailList"
                        label="Cc"
                        name="CCEmailList"
                        variant="outlined"
                      />

                      <span style={{ color: "red" }}>
                        {EmailtemplateCreate.errCCEmailList}
                      </span>

                      <TextField
                        fullWidth
                        id="EmailTemplateID"
                        label="EmailTemplateID"
                        name="EmailTemplateID"
                        variant="outlined"
                        value={EmailtemplateCreate.emailTemplateID}
                        style={{ display: "none", margin: "1%" }}
                        onChange={(e) =>
                          this.setState({
                            Configs: {
                              ...this.state.EmailtemplateCreate,
                              ccEmailList: e.target.value,
                            },
                          })
                        }
                      />
                    </div> */}
                            <div className="form-group">
                              <div
                                style={{
                                  width: "100%",
                                  height: "200px",
                                  marginBottom: "75px",
                                }}
                              >
                                <div ref={quillRef} id="w1-Body" />

                                {/* <div ref={quillRef} /> */}

                                <span style={{ color: "red" }}>
                                  {EmailtemplateCreate.errBody}
                                </span>
                              </div>
                            </div>
                            {/* <div class="form-group">
                  <label>
                    Body <span className="text-danger">*</span>
                  </label>
                  <Editor
                    name="textbody"
                    id="textbody"
                    // value={EmailtemplateCreate.Body}
                    tools={[
                      [Bold, Italic, Underline],
                      [Undo, Redo],
                      [Link, Unlink],
                      [AlignLeft, AlignCenter, AlignRight],
                      [OrderedList, UnorderedList, Indent, Outdent],
                    ]}
                    contentStyle={{
                      height: 320,
                    }}
                    defaultContent={content}
                  />
                </div> */}

                            <div className=" float-end ms-auto">
                              <button type="Submit" className="btn btn-primary">
                                Save
                              </button>
                              {/* <button className="btn btn-primary me-2" type="submit">Submit</button> */}
                              {/* <button className="btn btn-secondary me-2">Clear</button> */}
                              {/* <button className="btn btn-danger">Cancel</button> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-3" style={{ position: 'fixed', right: '0' }}>
                        <div className="card" style={{ marginBottom: "0px" }}>
                          <div className="card-body">
                            <h3 className="card-title">Placeholder</h3>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-body">
                            <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                              <li className="nav-item">
                                <a
                                  className="nav-link active"
                                  href="#bottom-tab1"
                                  data-bs-toggle="tab"
                                >
                                  Subject
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className="nav-link"
                                  href="#bottom-tab2"
                                  data-bs-toggle="tab"
                                >
                                  Body
                                </a>
                              </li>
                            </ul>
                            <div className="tab-content">
                              <div className="tab-pane show active " id="bottom-tab1">
                                <ul className="list-group list-group-flush col-12">
                                  {SubjectbodyList != null && SubjectbodyList.length > 0 ? (
                                    SubjectbodyList
                                      .filter(item => !(item.value === "ExamLink" && item.text === "ExamLink") && !(item.value === "ForgotPasswordCode" && item.text === "Forgot Password Code"))
                                      .map((item, index) => (
                                        <li
                                          key={index}
                                          className="list-group-item"
                                          onClick={() => ReplaceTextFunction(item.value)}
                                        >
                                          {item.text}
                                        </li>
                                      ))
                                  ) : (
                                    <li className="list-group-item">NO Data</li>
                                  )}

                                </ul>
                              </div>
                              <div className="tab-pane" id="bottom-tab2">
                                <ul className="list-group list-group-flush col-12">
                                  {SubjectbodyList != null &&
                                    SubjectbodyList.length > 0 ? (
                                    SubjectbodyList.map((item, indexs) => {
                                      return (
                                        <li
                                          className="list-group-item"
                                          onClick={(e) =>
                                            ReplaceBodyTextFunction(item.value)
                                          }
                                        >
                                          {item.text}
                                        </li>
                                      );
                                    })
                                  ) : (
                                    <li className="list-group-item">NO Data</li>
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {!isContainerFluid ? <div className="col-md-3">

                {/* <div className="card-box-style"></div> */}
              </div> : null}
            </div>

          </div>
        </div>
      </main>
    </>
  );
};
export default ApplicationConfiguration;
