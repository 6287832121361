import React from "react";
import { useEffect } from "react";
import CandidateQuestionViewServices from "../../services/CandidateQuestionViewService";
import { useState } from "react";
import { Decrypt } from "../../services/CommonService";
import { FaFlag } from "react-icons/fa";

var candidateQuestionServ = new CandidateQuestionViewServices();

const ExamLiveSideNav = ({ questionList, handleQuestionClick, currentQuestionIndex, examID, candidateID, reload, examAttendID }) => {
    const [FlagList, setFlagList] = useState([]);
    useEffect(() => {
        candidateQuestionServ.getFlagcolor(candidateID, examID, examAttendID).then((res) => {
            if (res != null) {
                setFlagList(res?.data);
            }
        });
    }, [reload]);

    return (
        <nav className="side-menu-area style-two" >
            <nav className="sidebar-nav" data-simplebar="init">
                <div className="simplebar-wrapper" style={{ margin: '0px' }}>
                    <div className="simplebar-height-auto-observer-wrapper">
                        <div className="simplebar-height-auto-observer" />
                    </div>
                    <div className="simplebar-mask">
                        <div className="simplebar-offset" style={{ right: '-16.8px', bottom: '0px' }}>
                            <div className="simplebar-content-wrapper" style={{ height: '100%', overflow: 'hidden scroll' }}>
                                <div className="simplebar-content" style={{ padding: '0px' }}>
                                    <ul id="sidebar-menu" className="sidebar-menu metismenu" style={{ marginTop: '18px' }}>
                                        {questionList.map((question, index) => {

                                            const isActive = index === currentQuestionIndex;
                                            const isFlag = FlagList.find((e) => e.questionID === question.questionID);
                                            const flagQuestion = FlagList.find((e) => e.questionID === question.questionID && e.flagSymbol === 'F');
                                            const flaggreenquestion = FlagList.find((e) => e.questionID === question.questionID && e.flagSymbol === 'G');

                                            return (
                                                <li key={index} className={isActive ? 'mm-active' : ''} style={{ zIndex: '1' }}>
                                                    <a className="box-style d-flex align-items-center" onClick={() => handleQuestionClick(index + 1)} style={{ textDecoration: 'none' }}>

                                                        <div className="icon" id={`background${question.questionID}`} style={{ backgroundColor: isFlag ? isFlag.flag : 'white', color: isFlag && (isFlag.flag === "#4fcb8d" || isFlag.flag === "gray") ? 'white' : 'black' }}>
                                                            {index + 1}
                                                        </div>
                                                        {flagQuestion ?

                                                            <FaFlag id={`questionno${question.questionID}`} style={{ display: flagQuestion ? "block" : "none", color: "red" }} />
                                                            : <FaFlag id={`questionno${question.questionID}`} style={{ display: "none", color: "red" }} />
                                                        }
                                                        {flaggreenquestion ? <FaFlag id={`questionno${question.questionID}`} style={{ display: flaggreenquestion ? "block" : "none", color: "green" }} />
                                                            : <FaFlag id={`questionno${question.questionID}`} style={{ display: "none", color: "green" }} />}
                                                    </a>
                                                </li>
                                            );

                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="simplebar-placeholder" style={{ width: 'auto', height: '775px' }} />
                </div>
                <div className="simplebar-track simplebar-horizontal" style={{ visibility: 'hidden' }}>
                    <div className="simplebar-scrollbar" style={{ transform: 'translate3d(0px, 0px, 0px)', display: 'none' }} />
                </div>
                <div className="simplebar-track simplebar-vertical" style={{ visibility: 'visible' }}>
                    <div className="simplebar-scrollbar" style={{ height: '657px', transform: 'translate3d(0px, 0px, 0px)', display: 'block' }} />
                </div>
            </nav>
        </nav>
    );
};

export default ExamLiveSideNav;
