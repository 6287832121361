import React from 'react';
import { useEffect, useState, useContext, useRef } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { AppContext } from "../../services/ContextProvider";
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import 'chartjs-plugin-datalabels';
import ExamService from './ExamService';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import ShareScoreCard from '../EmailTemplate/ShareScoreCard';
import MyPDF from '../Dashboard/Pdf';



const _examservice = new ExamService();
const ReviewExam = () => {

    const [showPopupModal, setShowPopupModal] = useState(false);
    const [showpdf, setShowPdf] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [cId, setcId] = useState(location.state?.cId ?? 0);
    const { showLoading, hideLoading, isAuth } =
        useContext(AppContext);
    const [ReportList, setReportList] = useState([]);
    const [selectedcandidateinfo, setselectedcandidateinfo] = useState([]);
    // const [mark, setmark] = useState([]);
    const [ansChangeCount, setansChangeCount] = useState([]);
    const [sectionCount, setsectionCount] = useState([]);
    const [CorrectCount, setCorrectCount] = useState(0);
    const [InCorrectCount, setInCorrectCount] = useState(0);
    const [TotalCount, setTotalCount] = useState(0);
    const [Examid, setExamid] = useState(location.state?.examID ?? 0);
    const [ExamAttendID, setExamAttendID] = useState(location.state?.ExamAttendID ?? 0);
    const [UnusualActivities, setUnusualActivities] = useState([]);
    const [examAttendBrowserDetails, setExamAttendBrowserDetails] = useState({});
    const reviewExamContentRef = useRef(null);
    const [show, setshow] = useState(false);
    const [PartialCorrectCount, setPartialCorrectCount] = useState(0)
    const [unAnsweredCount, setunAnsweredCount] = useState(0);
    const [marks, setMarks] = useState(0);
    const [negativeMarkPercentage, setNegativeMarkPercenatge] = useState(0);
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.toLocaleString('default', { month: 'short' });
    const day = currentDate.getDate();
    const gethours = currentDate.getHours();
    const getminutes = currentDate.getMinutes();
    const ampm = gethours >= 12 ? 'pm' : 'am';
    const currenthours = gethours % 12 || 12;
    const currentminutes = getminutes.toString().padStart(2, '0');
    const [email, setEmail] = useState();
    const [examName, setExamName] = useState();
    const getReportCurrentTime = month + " " + day + " " + year + " " + currenthours + ":" + currentminutes + " " + ampm;
    var file;
    var loader;
    const [isContainerFluid, setIsContainerFluid] = useState(true);
    const [main, setMain] = useState([]);



    useEffect(() => {
        // loader = document.querySelector(".loader-div");
        showLoading();
        debugger;
        _examservice.GetReportDetails(cId, Examid, ExamAttendID).then((res) => {
            debugger;
            setReportList(res);
            setEmail(res[0]?.email);
            setExamName(res[0]?.exam);
            setMarks(res[0]?.score === null ? 0 : res[0]?.score + '/' + res[0]?.totalScore)
            console.log(res[0]?.score === null ? 0 : res[0]?.score + '/' + res[0]?.totalScore);


        })
        // _examservice.GetReportDetailsExam(Examid).then((res) => {

        // })
        _examservice.GetReportDetailsforSelectedCandidate(cId, Examid, ExamAttendID).then((res) => {
            debugger;
            setselectedcandidateinfo(res);
        })
        // _examservice.GetReportDetailsforSelectedCandidateMark(cId, Examid).then((res) => {

        //     setmark(res);
        // })
        _examservice.GetReportDetailsforSelectedCandidateMSection(cId, Examid, ExamAttendID).then((res) => {

            if (Array.isArray(res) && res.length > 0) {
                const percentageRegex = /\d+(\.\d+)?%/;
                const match = res[0]?.value.match(percentageRegex);

                const extractedPercentage = match ? match[0] : '0%';
                setNegativeMarkPercenatge(extractedPercentage);
            }

            let correntcount = 0;
            let incorrentcount = 0;
            let Noquestion = 0;
            let partialCorrectCount = 0;
            let unAnsweredCount = 0;
            res.map((i) => {
                correntcount = correntcount + i.correctCount;
                incorrentcount = incorrentcount + i.incorrectCount;
                Noquestion = Noquestion + i.totalCount;
                partialCorrectCount = partialCorrectCount + i.partialCorrectCount;
                unAnsweredCount = unAnsweredCount + i.unAnsweredCount;
            })
            setInCorrectCount(incorrentcount);
            setCorrectCount(correntcount);
            setTotalCount(Noquestion);
            setsectionCount(res);
            setPartialCorrectCount(partialCorrectCount);
            setunAnsweredCount(unAnsweredCount)
        })
        _examservice.GetChangedAnswerByID(cId, Examid).then((res) => {
            console.log(res, 1);
            setansChangeCount(res);
        })

        _examservice.GetUnusualActivitiesByCandidateID(cId, Examid, ExamAttendID).then((res) => {

            console.log(res.Table1, 'T');

            setUnusualActivities(res.Table);
            debugger;
            setExamAttendBrowserDetails(res.Table1);
        })
        hideLoading();
    }, []);
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });


    const precentage = ReportList.map((res) => res.percentage);
    const point = (Number(ReportList[0]?.score) / Number(ReportList[0]?.totalScore)) * 100
    const result = ReportList.every((res) => res.result === 'Fail');
    const labels = result ? ['Fail'] : ['Pass'];
    const durationInMs = ReportList[0]?.duration;
    const durationInMinutes = Math.floor(durationInMs / (1000 * 60));
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = durationInMinutes % 60;
    const sectionLabels = sectionCount.map((res) => res.sectionId);
    const sectiondata = sectionCount.map((res) => res.totalCount);
    const sectionCorrectCount = sectionCount.map((res) => res.correctCount);
    const sectionInCorrectCount = sectionCount.map((res) => res.incorrectCount);
    const sectionNames = sectionCount.map((res) => res.sectionName);

    const dataSection = {
        labels: sectionNames,
        datasets: [
            {
                barPercentage: 0.9,
                barThickness: 60,
                maxBarThickness: 70,
                minBarLength: 2,
                label: 'Correct',
                backgroundColor: 'rgba(34,139,34,0.5)',
                stack: 'Stack 1', // Add a stack identifier for stacking bars
                data: sectionCorrectCount, // Replace with your correct data counts
            },
            {
                barPercentage: 0.9,
                barThickness: 60,
                maxBarThickness: 70,
                minBarLength: 2,
                label: 'Incorrect',
                backgroundColor: 'rgba(255,0,0,0.5)',
                stack: 'Stack 1', // Add the same stack identifier to stack bars together
                data: sectionInCorrectCount, // Replace with your incorrect data counts
            },
        ],
    };

    const optionsSection = {
        scales: {
            x: {
                stacked: true, // Enable stacking on the X-axis
            },
            y: {
                stacked: true,
                beginAtZero: true, // Ensure the Y-axis starts at 0
                // Enable stacking on the Y-axis
            },
        },
        plugins: {
            legend: {
                display: true,
            },
        },
    };

    const data = {
        labels: labels,
        datasets: [
            {
                data: [Number(precentage).toFixed(2), 100],
                backgroundColor: result == true ? '#FF0000' : '#03C04A',
                hoverBackgroundColor: result == true ? '#FF6384' : '#36A2EB',
                barThickness: 180,
            },
        ],
    };
    const dataforPie = {
        labels: ['Correct', 'Incorrect', 'Unanswered'],
        datasets: [
            {
                data: [CorrectCount, InCorrectCount, unAnsweredCount],
                backgroundColor: ['#03C04A', '#FF0000', '#FA8128'],
                hoverBackgroundColor: ['#03C04A', '#FF0000', '#FA8128'],
                borderWidth: 0, // Add this line to remove the border
            },
        ],
    };

    const chartData = {
        labels: sectionNames,
        datasets: [
            {
                // label: 'Historical Data',
                // data: [65, 59, 80, 81, 56, 55],
                data: sectiondata,
                fill: false,
                backgroundColor: '#03C04A',
                barThickness: 100,
            },
        ],
    };
    const Baroptions = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    display: false,
                },
            },
            y: {
                grid: {
                    display: false,
                },
                ticks: {
                    display: false,
                },
            },
        },
    };


    const options = {
        responsive: true,

        plugins: {
            legend: {
                position: 'bottom',
            },
            datalabels: {

                formatter: (value, ctx) => {
                    let sum = 0;
                    let dataArr = ctx.chart.data.datasets[0].data;
                    dataArr.map((data) => {
                        sum += data;
                        return data;
                    });
                    let percentage = ((value * 100) / sum).toFixed(2) + '%';
                    return percentage;
                },
                labels: {
                    title: null,
                },
            },
        },
        cutoutPercentage: 70,
    };
    const centerText = {
        display: true,
        text: Math.round(precentage) + "%", // Replace this with your desired center text
    };
    const Sectionoptions = {
        plugins: {
            legend: {
                display: false,
            },
            tooltips: {
                enabled: false,
            },
            datalabels: {
                anchor: 'end',
                align: 'end',
                color: 'black',
                font: {
                    weight: 'bold',
                },
                formatter: function (value, context) {
                    const dataIndex = context.dataIndex;
                    const sectionName = sectionNames[dataIndex];
                    return sectionName;
                },
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                grid: {
                    color: 'rgba(0, 0, 0, 0.1)',
                },
            },
        },
    };



    // const getColorBasedOnPercentage = (percentage) => {
    //     return percentage >= 80 ? 'green' : 'red';
    // };

    const downloadAsPDF = async () => {

        setShowPdf(true);
        showLoading();

    };

    const OpenPopUp = () => {

        setShowPopupModal(true);

    }

    // const shareScorecard = async (event) => {
    //     const element = reviewExamContentRef.current;
    //         const id = document.getElementById("reviewexamcontent");
    //         id.style.visibility = "visible";
    //         id.style.height = "auto";
    //         id.style.width = "auto";
    //         id.style.overflow = "auto"
    //     try {

    //         file = await html2pdf().from(element).outputPdf("blob")
    //             .then(function (pdfObject) {
    //                 console.log("PDF File Object:", pdfObject);
    //                 return (pdfObject);
    //             });
    //         const fileName = `${ReportList[0]?.candidateName}_Scorecard.pdf`;
    //         const formData = new FormData();
    //         formData.append('attachment', file);
    //         formData.append('fileName', fileName);
    //         formData.append('email', ReportList && ReportList.length > 0 ?  ReportList[0]?.email : '');
    //         formData.append('body', 'Please Find the Attachement');
    //         formData.append('subject', 'Share Score Card');
    //         const response = await axios.post('Report/UploadBlob', formData);
    //         id.style.visibility = "hidden";
    //         id.style.visibility = "visible";
    //         id.style.height = "0";
    //         id.style.width = "0";
    //         id.style.overflow = "hidden";
    //         document.body.style.overflow = 'auto';

    //         if (response.data == "Success") {
    //             Toast.fire({
    //                 icon: "Success",
    //                 title: "Mail Sent Successfully",
    //             });
    //         }
    //         console.log(response.data);
    //     } catch (error) {
    //         // Handle errors if the API call fails
    //         console.error('Error uploading the blob:', error);
    //     }
    // };
    const navigatetopreview = (cId, Examid, Qid, timetaken, ExamAttendID) => {

        navigate("/CandidatePreview", {
            state: { CandidateId: cId, examID: Examid, qid: Qid, TimeTaken: timetaken, ExamAttendID: ExamAttendID },
        });
    }
    const toggleContainerClass = () => {
        setIsContainerFluid((prevState) => !prevState);
    };

    return (
        <>

            <main className="main-content-wrap">
                <div className="contact-list-area">
                    <div className='container-fluid'>

                        <div className="row">
                            <div className={`${isContainerFluid ? "col-md-12" : "col-md-9"}`}>
                                <div className='card-box-style animatedwidth'>
                                    <div className="fullwidthbuttonbox " onClick={toggleContainerClass}>
                                        <span className="fullwidthbutton" >
                                            {isContainerFluid ? <i className="fa fa-angle-double-left"></i> : <i className="fa fa-angle-double-right"></i>}

                                        </span>
                                    </div>
                                    <div className="row align-items-center">
                                        <div className="other-title row">
                                            <h4 className="col-12">Vaanam Technologies Private Limited
                                            </h4>
                                            <h4 className="col-6">Score Card |{ReportList && ReportList.length > 0 ? ReportList[0].exam : ''}
                                            </h4>
                                            <span className="col-6 hide-in-pdf">
                                                <input type="button" value="Back" title="Back" id='backButton' className="back-button backbutton-end" style={{ float: 'right' }} onClick={() => navigate(-1)} />
                                            </span>
                                        </div>
                                    </div>


                                    <div className="card" style={{ margin: '10px' }}>
                                        <div className="card-body">
                                            {ReportList && ReportList.length > 0 ? (
                                                <div className="row" key={ReportList[0].candidateid}>
                                                    <div className="col">
                                                        <p>Name:</p>
                                                        <p>Email:</p>
                                                        <p>Time Taken:</p>

                                                    </div>
                                                    <div className="col">
                                                        {ReportList[0].candidateName ? (
                                                            <p>{ReportList[0].candidateName}</p>
                                                        ) : (
                                                            <p></p>
                                                        )} {ReportList[0].email ? (
                                                            <p>{ReportList[0].email}</p>
                                                        ) : (
                                                            <p></p>
                                                        )}
                                                        {ReportList[0].timeTaken ? (
                                                            <p>                                       {ReportList[0].timeTaken >= 60
                                                                ? _examservice.convertSecondsToMinutes(ReportList[0].timeTaken) + ' Minutes'
                                                                : ReportList[0].timeTaken + ' Minutes'}
                                                            </p>

                                                        ) : (
                                                            <p></p>
                                                        )}


                                                    </div>
                                                    <div className="col">
                                                        <p>Start Time:</p>
                                                        <p>End Time:</p>
                                                        <p>Exam Duration:</p>
                                                    </div>
                                                    <div className="col mg-3">
                                                        <div className='d-flex '>
                                                            <p style={{ fontWeight: 'bold' }}>
                                                                {ReportList[0]?.examStarted && new Date(ReportList[0].examStarted.split("T")[0]).toLocaleDateString('en-US', {
                                                                    day: '2-digit',
                                                                    month: 'short',
                                                                    year: 'numeric'
                                                                }).replace(/,/g, '')}
                                                            </p>
                                                            <span className='ms-2 mt-1' >
                                                                {ReportList[0]?.examStarted && new Date(ReportList[0].examStarted).toLocaleTimeString('en-US', {
                                                                    hour: '2-digit',
                                                                    minute: '2-digit',
                                                                    hour12: true
                                                                })}
                                                            </span>
                                                        </div>
                                                        <div className='d-flex '>
                                                            <p style={{ fontWeight: 'bold' }}>
                                                                {ReportList[0]?.examEnd && new Date(ReportList[0].examEnd.split("T")[0]).toLocaleDateString('en-US', {
                                                                    day: '2-digit',
                                                                    month: 'short',
                                                                    year: 'numeric'
                                                                }).replace(/,/g, '')}
                                                            </p>
                                                            <span className='ms-2 mt-1'>
                                                                {ReportList[0]?.examEnd && new Date(ReportList[0].examEnd).toLocaleTimeString('en-US', {
                                                                    hour: '2-digit',
                                                                    minute: '2-digit',
                                                                    hour12: true
                                                                })}
                                                            </span>
                                                        </div>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <p style={{ margin: '0' }}>{_examservice.MillisecondsToMinutes(ReportList[0]?.duration)} Minutes</p>
                                                        </div>
                                                    </div>




                                                </div>
                                            ) : (
                                                <p>No candidate information available.</p>
                                            )}

                                        </div>
                                    </div>

                                    {ReportList && ReportList[0]?.resultType === 0 ? (


                                        <div className='row justify-content-center'>
                                            <h5 className="card-title" style={{ textAlign: 'center' }}>
                                                Score  <span>{(ReportList[0]?.score === null ? 0 : ReportList[0]?.score) + '/' + ReportList[0]?.totalScore}</span>
                                            </h5>
                                            <div className='col-6 mt-1'>
                                                <      div class="progress" style={{ height: '200%' }}>
                                                    <div className={`progress-bar w-${point}`} role="progressbar" aria-label="Basic example" aria-valuenow={point} aria-valuemin="0" aria-valuemax="100" style={{ backgroundColor: '#03C04A', width: point + "%", alignItems: "center", display: "flex" }}>

                                                        <span style={{ visibility: "hidden" }}> {point}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    ) : (
                                        <div className='row justify-content-center'>
                                            <h5 className="card-title" style={{ textAlign: 'center' }}>
                                                Score  <span>{Math.round(precentage)}%</span>
                                            </h5>
                                            <div className='col-6'>
                                                <      div class="progress" style={{ height: '300%' }}>
                                                    <div className={`progress-bar w-${Math.round(precentage).toString()}`} role="progressbar" aria-label="Basic example" aria-valuenow={Math.round(precentage).toString()} aria-valuemin="0" aria-valuemax="100" style={{ backgroundColor: result == true ? '#FF0000' : '#03C04A', width: Math.round(precentage).toString() + "%", alignItems: "center", display: "flex" }}>


                                                        <span class="label bg-warning" style={{ width: result == true ? "25%" : "15%", borderRadius: "3px" }}>{result == true ? 'Fail' : 'Pass'}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="row justify-content-center">
                                        <div className="col-lg-12 col-sm-12 mt-5">
                                            <div className='row'>


                                                <div className='col-4 reviewreportcard reviewreportcardleft'>
                                                    <div className='card-box-style'>


                                                        <p>{TotalCount}</p>
                                                        <span>Total Questions</span>
                                                        <hr></hr>

                                                        <p>{marks}</p>
                                                        <span>Marks</span>
                                                        <hr></hr>
                                                        <p>{negativeMarkPercentage}</p>
                                                        <span>Negative Marks</span>
                                                        <hr></hr>
                                                        <p>
                                                            {ReportList.length > 0 ? ReportList[0]?.timeTaken >= 60
                                                                ? _examservice.convertSecondsToMinutes(ReportList[0].timeTaken) + ' Minutes'
                                                                : ReportList[0].timeTaken + ' Minutes' : ''}
                                                        </p>
                                                        <span>Time Taken</span>
                                                        <hr></hr>
                                                    </div>
                                                </div>
                                                <div className='col-4'>
                                                    {ReportList.map((rep) => (
                                                        <div key={rep.candidateid}>

                                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '290px' }} className='doughnut-chart-container'>
                                                                <div style={{ width: '300px', position: 'relative' }}>
                                                                    <Doughnut data={dataforPie} options={options} />
                                                                    {centerText.display && (
                                                                        <div
                                                                            style={{
                                                                                position: 'absolute',
                                                                                top: '40%',
                                                                                left: '50%',
                                                                                transform: 'translate(-50%, -50%)',
                                                                                fontSize: '24px',
                                                                                fontWeight: 'bold',
                                                                            }}
                                                                        >
                                                                            {centerText.text}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    ))}

                                                </div>
                                                <div className='col-4 reviewreportcard reviewreportcardright'>
                                                    <div className='card-box-style'>


                                                        <p>{CorrectCount}</p>
                                                        <span>Correct</span>
                                                        <hr></hr>

                                                        <p>{PartialCorrectCount}</p>
                                                        <span>Partially Correct</span>
                                                        <hr></hr>
                                                        <p>{InCorrectCount}</p>
                                                        <span>Incorrect</span>
                                                        <hr></hr>
                                                        <p>{unAnsweredCount}</p>
                                                        <span>Unanswered</span>
                                                        <hr></hr>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div class="html2pdf__page-break"></div> */}


                                        <div className="card" style={{ border: 'none' }}>
                                            <div
                                                className="simplebar-content"
                                                style={{ padding: 30 }}
                                            >
                                                <div className="others-title col-12 row hide-in-pdf">
                                                    <h3 className="col-6">Attend Questions</h3>

                                                </div>
                                                <table className="table align-middle mt-2 hide-in-pdf">
                                                    <thead>
                                                        <tr>
                                                            <th>S.No</th>
                                                            <th>QID</th>
                                                            <th>Status</th>
                                                            <th>Difficulty</th>
                                                            <th>Marks</th>
                                                            <th>Section</th>
                                                            <th>Time Spent</th>
                                                            <th>Review</th>
                                                            {/* <th>Time Spent</th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {selectedcandidateinfo.map((info, index) => (
                                                            <tr key={info.questionID}>
                                                                <td>{index + 1}</td> {/* Add S.No column */}

                                                                <td>
                                                                    <div className="info">
                                                                        <h6>{info.questionID}</h6>
                                                                    </div>
                                                                </td>
                                                                <td>

                                                                    {
                                                                        info.isCorrect === 'Yes' ? <i className="fa fa-check-circle" style={{ color: 'green' }} />
                                                                            : <i className="fa fa-times-circle" style={{ color: 'red' }} />
                                                                    }


                                                                </td>
                                                                <td>
                                                                    <span className="location">{info.difficultLevel}</span>
                                                                </td>
                                                                <td>
                                                                    <p>
                                                                        {info.mark}
                                                                    </p>
                                                                </td>
                                                                <td>
                                                                    <p>{info.sectionName}</p>
                                                                </td>
                                                                <td>


                                                                    <p>  {info.timeTaken >= 60
                                                                        ? _examservice.convertSecondsToMinutes(info.timeTaken) + ' Minutes'
                                                                        : info.timeTaken + ' Seconds'}</p>
                                                                </td>
                                                                <td>
                                                                    <p style={{ cursor: "pointer" }} onClick={() => navigatetopreview(cId, Examid, info.questionID, info.timeTaken >= 60
                                                                        ? _examservice.convertSecondsToMinutes(info.timeTaken) + ' Minutes'
                                                                        : info.timeTaken + ' Seconds', ExamAttendID)}>Review</p>

                                                                </td>
                                                            </tr>
                                                        ))}

                                                    </tbody>
                                                </table>
                                                <div class='card border border-dark mt-2 hide-in-pdf' style={{ border: 'none' }}>
                                                    <div class='card-body'>
                                                        <div class='row' >
                                                            <div class='col'>
                                                                <p><span class='highlight'>correct</span> <span class='arrow'>&#8594;</span> <span class='highlight highlight-inverse'>incorrect</span></p>
                                                                {ansChangeCount && (
                                                                    <p>{ansChangeCount.crtCrtCount}</p>
                                                                )}
                                                            </div>
                                                            <div class='col'>
                                                                <p><span class='highlight highlight-inverse'>Incorrect</span> <span class='arrow'>&#8594;</span> <span class='highlight'>correct</span></p>
                                                                {ansChangeCount && (
                                                                    <p>{ansChangeCount.incorrectCrtCount}</p>
                                                                )}
                                                            </div>
                                                            <div class='col'>
                                                                <p><span class='highlight highlight-inverse'>Incorrect</span> <span class='arrow'>&#8594;</span> <span class='highlight highlight-inverse'>Incorrect</span></p>
                                                                {ansChangeCount && (
                                                                    <p>{ansChangeCount.incorrectIncorrectCount}</p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='card rounded mt-2 hide-in-pdf' style={{ border: 'none' }}>
                                                    <div className='card-body' >
                                                        <div style={{ height: '350px', display: 'flex', justifyContent: 'center' }}>
                                                            <Bar data={dataSection} options={optionsSection} />
                                                        </div>
                                                    </div>

                                                </div>



                                                <div className="others-title col-12 row">
                                                    <h3 className="col-6" style={{ display: 'none' }}>Attened Questions</h3>

                                                </div>
                                                <table className="table align-middle mt-2">
                                                    <thead>
                                                        <tr>
                                                            <th>Section</th>
                                                            <th className='centerContent'>% Correct</th>
                                                            <th className='centerContent'>Correct</th>
                                                            <th className='centerContent'>Partially</th>
                                                            <th className='centerContent'>Incorrect</th>
                                                            <th className='centerContent'>Unanswered</th>
                                                            <th className='centerContent'>Total</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {sectionCount.map((info, index) => (
                                                            <tr key={info.candidateid}>
                                                                {/* <td>{index + 1}</td>  */}
                                                                <td style={{ width: '35%' }}>
                                                                    <div className="info">
                                                                        <h6>{info.sectionName}</h6>
                                                                    </div>
                                                                </td>
                                                                <td className='centerContent'>

                                                                    {info.correctPercentage != null && info.correctPercentage != "" ? parseFloat(info.correctPercentage).toFixed(2) : <></>}

                                                                </td>
                                                                <td className='centerContent'>
                                                                    {info.correctCount}
                                                                </td>
                                                                <td className='centerContent'>
                                                                    {info.partialCorrectCount}
                                                                </td>

                                                                <td className='centerContent'>
                                                                    {info.incorrectCount}
                                                                </td>
                                                                <td className='centerContent'>
                                                                    {info.unAnsweredCount}
                                                                </td>
                                                                <td className='centerContent'>
                                                                    {info.totalCount}
                                                                </td>
                                                            </tr>
                                                        ))}

                                                    </tbody>
                                                </table>
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-lg-12 examissuebox" style={{ position: "relative", minHeight: "1px", paddingLeft: "0px", paddingRight: "0px" }}>
                                                            <h3 style={{ fontSize: '18px', marginTop: '0px', marginBottom: '20px', color: '#2782dd' }}>Security Log for this exam</h3>
                                                            <div id="examissuebox" className="col-lg-12">
                                                                <div className="col-lg-12">
                                                                    <div className="col-lg-12 issuebox">
                                                                        <div className="issuedatetimebox">
                                                                            <span className="time"> {ReportList[0]?.examStarted && new Date(ReportList[0].examStarted.split("T")[0]).toLocaleDateString('en-US', {
                                                                                day: '2-digit',
                                                                                month: 'short',
                                                                                year: 'numeric'
                                                                            }).replace(/,/g, '')}</span>
                                                                            <span className="date">{ReportList[0]?.examStarted && new Date(ReportList[0].examStarted).toLocaleTimeString('en-US', {
                                                                                hour: '2-digit',
                                                                                minute: '2-digit',
                                                                                hour12: true
                                                                            })}</span>
                                                                        </div>
                                                                        <div className="issueotherdetails">
                                                                            <div className="issuecircle" />
                                                                            <div className="issuetext">
                                                                                <span>Exam Started</span>
                                                                            </div>
                                                                            <div className="issueotherdetailsinner">
                                                                                <span className="text">
                                                                                    {examAttendBrowserDetails[0]?.BrowserName === '' || examAttendBrowserDetails[0]?.BrowserName === 'undefined' || examAttendBrowserDetails[0]?.BrowserName === 'null' ? '' : examAttendBrowserDetails[0]?.BrowserName}
                                                                                </span>
                                                                                <span className="text">
                                                                                    {examAttendBrowserDetails[0]?.IPAddress === '' || examAttendBrowserDetails[0]?.IPAddress === 'undefined' || examAttendBrowserDetails[0]?.IPAddress === 'null' ? '' : examAttendBrowserDetails[0]?.IPAddress}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {UnusualActivities.length > 0 ? UnusualActivities.map((v, index) => (
                                                                        <div className="col-lg-12 issuebox">
                                                                            <div className="issuedatetimebox">
                                                                                <span className="time">
                                                                                    {v.UnUsualtime && new Date(v.UnUsualtime.split("T")[0]).toLocaleDateString('en-US', {
                                                                                        day: '2-digit',
                                                                                        month: 'short',
                                                                                        year: 'numeric'
                                                                                    }).replace(/,/g, '')}
                                                                                </span>

                                                                                <span className="date">
                                                                                    {v.UnUsualtime && new Date(v.UnUsualtime).toLocaleTimeString('en-US', {
                                                                                        hour: '2-digit',
                                                                                        minute: '2-digit',
                                                                                        hour12: true
                                                                                    })}
                                                                                </span>
                                                                            </div>

                                                                            <div className="issueotherdetails">
                                                                                <div className="issuecircle" />
                                                                                <div className="issuetext">
                                                                                    <span>{v.Activity || v.RejoinTime}</span>
                                                                                </div>
                                                                                <div className="issueotherdetailsinner">
                                                                                    <span className="text">{v.BrowserName === '' || v.BrowserName === 'undefined' || v.BrowserName === 'null' ? '' : v.BrowserName}</span>
                                                                                    <span className="text">{v.IPAddress === '' || v.IPAddress === 'undefined' || v.IPAddress === 'null' ? '' : v.IPAddress}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )) : (<></>)}


                                                                    {examAttendBrowserDetails[0]?.RejoinTime ? (
                                                                        <div className="col-lg-12 issuebox">
                                                                            <div className="issuedatetimebox">
                                                                                <span className="time">
                                                                                    {examAttendBrowserDetails[0]?.RejoinTime && new Date(examAttendBrowserDetails[0]?.RejoinTime.split("T")[0]).toLocaleDateString('en-US', {
                                                                                        day: '2-digit',
                                                                                        month: 'short',
                                                                                        year: 'numeric'
                                                                                    }).replace(/,/g, '')}</span>

                                                                                <span className="date"> {examAttendBrowserDetails[0]?.RejoinTime && new Date(examAttendBrowserDetails[0]?.RejoinTime).toLocaleTimeString('en-US', {
                                                                                    hour: '2-digit',
                                                                                    minute: '2-digit',
                                                                                    hour12: true
                                                                                })}</span>
                                                                            </div>

                                                                            <div className="issueotherdetails">
                                                                                <div className="issuecircle" />
                                                                                <div className="issuetext">
                                                                                    <span>Rejoin Exam</span>
                                                                                </div>
                                                                                <div className="issueotherdetailsinner">
                                                                                    <span className="text">{examAttendBrowserDetails[0]?.BrowserName === '' || examAttendBrowserDetails[0]?.BrowserName === 'undefined' || examAttendBrowserDetails[0]?.BrowserName === 'null' ? '' : examAttendBrowserDetails[0]?.BrowserName}</span>
                                                                                    <span className="text">{examAttendBrowserDetails[0]?.IPAddress === '' || examAttendBrowserDetails[0]?.IPAddress === 'undefined' || examAttendBrowserDetails[0]?.IPAddress === 'null' ? '' : examAttendBrowserDetails[0]?.IPAddress}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ) : (<></>)}

                                                                    {ReportList[0]?.examEnd &&
                                                                        <div className="col-lg-12 issuebox">
                                                                            <div className="issuedatetimebox">
                                                                                <span className="time">
                                                                                    {ReportList[0]?.examEnd && new Date(ReportList[0].examEnd.split("T")[0]).toLocaleDateString('en-US', {
                                                                                        day: '2-digit',
                                                                                        month: 'short',
                                                                                        year: 'numeric'
                                                                                    }).replace(/,/g, '')}
                                                                                </span>
                                                                                <span className="date"> {ReportList[0]?.examEnd && new Date(ReportList[0].examEnd).toLocaleTimeString('en-US', {
                                                                                    hour: '2-digit',
                                                                                    minute: '2-digit',
                                                                                    hour12: true
                                                                                })}</span>
                                                                            </div>

                                                                            <div className="issueotherdetails">
                                                                                <div className="issuecircle" />
                                                                                <div className="issuetext">
                                                                                    <span>{ReportList[0]?.unusal === "Terminated" ? "Exam Terminated" : ReportList[0]?.unusal === "Suspend" ? "Exam Suspended" : "Exam Finished"}</span>
                                                                                </div>
                                                                                <div className="issueotherdetailsinner">
                                                                                    <span className="text"> {examAttendBrowserDetails[0]?.BrowserName === '' || examAttendBrowserDetails[0]?.BrowserName === 'undefined' || examAttendBrowserDetails[0]?.BrowserName === 'null' ? '' : examAttendBrowserDetails[0]?.BrowserName}</span>
                                                                                    <span className="text"> {examAttendBrowserDetails[0]?.IPAddress === '' || examAttendBrowserDetails[0]?.IPAddress === 'undefined' || examAttendBrowserDetails[0]?.IPAddress === 'null' ? '' : examAttendBrowserDetails[0]?.IPAddress}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className=''>
                                                        <div className="timeline card-box-style timelineReviewExam">
                                                            <div className="others-title">
                                                                <h3>Security Log for this exam</h3>
                                                            </div>
                                                            <div className="timeline-wrap">
                                                                <div className="timeline-item">
                                                                    <div className="text">
                                                                        <h3>Exam Started</h3>
                                                                        <p>
                                                                            {examAttendBrowserDetails[0]?.BrowserName === '' || examAttendBrowserDetails[0]?.BrowserName === 'undefined' || examAttendBrowserDetails[0]?.BrowserName === 'null' ? '' : examAttendBrowserDetails[0]?.BrowserName}
                                                                            <br />
                                                                            {examAttendBrowserDetails[0]?.IPAddress === '' || examAttendBrowserDetails[0]?.IPAddress === 'undefined' || examAttendBrowserDetails[0]?.IPAddress === 'null' ? '' : examAttendBrowserDetails[0]?.IPAddress}
                                                                        </p>

                                                                    </div>
                                                                    <div className="icon">
                                                                        <div>
                                                                            <i className="bx bx-badge-check" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="time">
                                                                        <time> {ReportList[0]?.examStarted && new Date(ReportList[0].examStarted.split("T")[0]).toLocaleDateString('en-US', {
                                                                            day: '2-digit',
                                                                            month: 'short',
                                                                            year: 'numeric'
                                                                        }).replace(/,/g, '')}

                                                                            <br />

                                                                            {ReportList[0]?.examStarted && new Date(ReportList[0].examStarted).toLocaleTimeString('en-US', {
                                                                                hour: '2-digit',
                                                                                minute: '2-digit',
                                                                                hour12: true
                                                                            })}
                                                                        </time>
                                                                    </div>
                                                                </div>
                                                                {UnusualActivities.length > 0 ? UnusualActivities.map((v, index) => (
                                                                    <div className="timeline-item">
                                                                        <div className="text">
                                                                            <h3 className='bg-warning malpracticearrow' >{v.Activity || v.RejoinTime}</h3>

                                                                            <p>
                                                                                {v.BrowserName === '' || v.BrowserName === 'undefined' || v.BrowserName === 'null' ? '' : v.BrowserName}
                                                                                <br />
                                                                                {v.IPAddress === '' || v.IPAddress === 'undefined' || v.IPAddress === 'null' ? '' : v.IPAddress}
                                                                            </p>

                                                                        </div>
                                                                        <div className="icon">
                                                                            <div>
                                                                                <i className="bx bx-badge-check" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="time">
                                                                            <time>

                                                                                {v.UnUsualtime && new Date(v.UnUsualtime.split("T")[0]).toLocaleDateString('en-US', {
                                                                                    day: '2-digit',
                                                                                    month: 'short',
                                                                                    year: 'numeric'
                                                                                }).replace(/,/g, '')}

                                                                                <br />

                                                                                {v.UnUsualtime && new Date(v.UnUsualtime).toLocaleTimeString('en-US', {
                                                                                    hour: '2-digit',
                                                                                    minute: '2-digit',
                                                                                    hour12: true
                                                                                })}

                                                                            </time>
                                                                        </div>
                                                                    </div>
                                                                )) : (<></>)}


                                                                {examAttendBrowserDetails[0]?.RejoinTime ? <div className="timeline-item">
                                                                    <div className="text">
                                                                        <h3 className='bg-danger Rejoinarrow'>Rejoin Time</h3>
                                                                        <p>
                                                                            {examAttendBrowserDetails[0]?.BrowserName === '' || examAttendBrowserDetails[0]?.BrowserName === 'undefined' || examAttendBrowserDetails[0]?.BrowserName === 'null' ? '' : examAttendBrowserDetails[0]?.BrowserName}
                                                                            <br />
                                                                            {examAttendBrowserDetails[0]?.IPAddress === '' || examAttendBrowserDetails[0]?.IPAddress === 'undefined' || examAttendBrowserDetails[0]?.IPAddress === 'null' ? '' : examAttendBrowserDetails[0]?.IPAddress}
                                                                        </p>
                                                                    </div>
                                                                    <div className="icon">
                                                                        <div>
                                                                            <i className="bx bx-badge-check" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="time">
                                                                        <time>

                                                                            {examAttendBrowserDetails[0]?.RejoinTime && new Date(examAttendBrowserDetails[0]?.RejoinTime.split("T")[0]).toLocaleDateString('en-US', {
                                                                                day: '2-digit',
                                                                                month: 'short',
                                                                                year: 'numeric'
                                                                            }).replace(/,/g, '')}
                                                                            <br />
                                                                            {examAttendBrowserDetails[0]?.RejoinTime && new Date(examAttendBrowserDetails[0]?.RejoinTime).toLocaleTimeString('en-US', {
                                                                                hour: '2-digit',
                                                                                minute: '2-digit',
                                                                                hour12: true
                                                                            })}
                                                                        </time>
                                                                    </div>
                                                                </div> : <></>}
                                                                {ReportList[0]?.examEnd && (
                                                                    <div className="timeline-item">
                                                                        <div className="text">
                                                                            <h3>Exam Finished</h3>
                                                                            <p>
                                                                                {examAttendBrowserDetails[0]?.BrowserName === '' || examAttendBrowserDetails[0]?.BrowserName === 'undefined' || examAttendBrowserDetails[0]?.BrowserName === 'null' ? '' : examAttendBrowserDetails[0]?.BrowserName}
                                                                                <br />
                                                                                {examAttendBrowserDetails[0]?.IPAddress === '' || examAttendBrowserDetails[0]?.IPAddress === 'undefined' || examAttendBrowserDetails[0]?.IPAddress === 'null' ? '' : examAttendBrowserDetails[0]?.IPAddress}
                                                                            </p>
                                                                        </div>
                                                                        <div className="icon">
                                                                            <div>
                                                                                <i className="bx bx-badge-check" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="time">
                                                                            <time>

                                                                                {ReportList[0]?.examEnd && new Date(ReportList[0].examEnd.split("T")[0]).toLocaleDateString('en-US', {
                                                                                    day: '2-digit',
                                                                                    month: 'short',
                                                                                    year: 'numeric'
                                                                                }).replace(/,/g, '')}
                                                                                <br />
                                                                                {ReportList[0]?.examEnd && new Date(ReportList[0].examEnd).toLocaleTimeString('en-US', {
                                                                                    hour: '2-digit',
                                                                                    minute: '2-digit',
                                                                                    hour12: true
                                                                                })}
                                                                            </time>
                                                                        </div>
                                                                    </div>)}
                                                            </div>
                                                        </div>

                                                    </div> */}


                                            </div>
                                        </div>

                                    </div>
                                    <div className='row ps-5 mb-2 hide-in-pdf'>
                                        <div className='col-3'>
                                            <button id="export-pdf" className="back-button backbutton-end" onClick={downloadAsPDF}>Download Scorecard</button>
                                        </div>
                                        <div className='col-2'>
                                            <button id="shareButton" onClick={OpenPopUp} className="back-button backbutton-end">Share Scorecard</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            {!isContainerFluid ? <div className="col-md-3">

                                {/* <div className="card-box-style"></div> */}
                            </div> : null}
                        </div>

                    </div>
                </div>
            </main >

            {showPopupModal && (
                <ShareScoreCard email={email} examName={examName} setShowPopupModal={setShowPopupModal} setMain={setMain} />

            )
            }

            {
                showpdf &&

                < MyPDF ReportList={ReportList} getReportCurrentTime={getReportCurrentTime} sectionCount={sectionCount}
                    CorrectCount={CorrectCount} PartialCorrectCount={PartialCorrectCount} InCorrectCount={InCorrectCount}
                    unAnsweredCount={unAnsweredCount} dataforPie={dataforPie} options={options}
                    precentage={precentage} TotalCount={TotalCount} negativeMarkPercentage={negativeMarkPercentage}
                    marks={marks} result={result} centerText={centerText} showpdf={showpdf} setShowPdf={setShowPdf} main={main} setMain={setMain} />
            }
            {
                main.length > 0 &&

                < MyPDF ReportList={ReportList} getReportCurrentTime={getReportCurrentTime} sectionCount={sectionCount}
                    CorrectCount={CorrectCount} PartialCorrectCount={PartialCorrectCount} InCorrectCount={InCorrectCount}
                    unAnsweredCount={unAnsweredCount} dataforPie={dataforPie} options={options}
                    precentage={precentage} TotalCount={TotalCount} negativeMarkPercentage={negativeMarkPercentage}
                    marks={marks} result={result} centerText={centerText} showpdf={showpdf} setShowPdf={setShowPdf} main={main} setMain={setMain} />
            }
        </>
    );

};
export default ReviewExam;