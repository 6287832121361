import React, { useState, useEffect, useContext } from 'react';
import QuestionBankService from '../../services/QuestionBankService';
import { showErrorMsg, showSaveSuccess } from '../ToastMsg';
import Examservice from '../Exam/ExamService';
import { useParams } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useRef } from 'react';
import { event } from 'jquery';
import { AppContext } from "../../services/ContextProvider";
import { Decrypt } from '../../services/CommonService';


const _questionBankService = new QuestionBankService();
const _examservice = new Examservice();

const QuestionBank = () => {

    const { } = useParams();
    const location = useLocation();

    const navigate = useNavigate();
    const navigateToView = () => {
        navigate('/ViewQuestion');
    }
    const [isContainerFluid, setIsContainerFluid] = useState(true);

    const explanationRef = useRef(null);
    const quillRef = useRef(null);
    const confirmValue = useRef(null);
    const { showLoading, hideLoading, isAuth } =
        useContext(AppContext);
    const [qId, setqId] = useState(location.state?.qId ?? 0);
    const [parametervalue, setparametervalue] = useState([]);
    const [questionType, setQuestionType] = useState('42');
    const [sectionType, setsectionType] = useState('');
    const [Answerparametervalue, setAnswerparametervalue] = useState([]);
    const [answerType, setAnswerType] = useState('46');
    const [answer, setAnswer] = useState([]);
    const [answerCount, setAnswerCount] = useState(1); // Number of textboxes for multi-textbox answer type
    const [sectionList, setSectionList] = useState([]);
    const [confirmAns, setconfirmAns] = useState('');
    const [optionModel, setOptionModels] = useState([]);
    const [confirmAns1, setConfirmAns1] = useState([]);
    const [DifficultLevelparametervalue, setDifficultLevelparametervalue] = useState([]);
    const [initialValueShown, setInitialValueShown] = useState(false);
    const [initialExpandtionValueShown, setinitialExpandtionValueShown] = useState(false);
    const [initialAnswerOptionShown, setinitialAnswerOptionShown] = useState(false);
    const [confirmAnsShown, setconfirmAnsShown] = useState(false);
    const [negativeList, setnegativeList] = useState([]);
    const [AnswerOptionparametervalue, setAnswerOptionparametervalue] = useState([]);
    const [initialAnswerTypeShown, setInitialAnswerTypeShown] = useState(false);
    const [question, setQuestion] = useState(confirmAns1.length > 0 ? confirmAns1[0].question : '');
    const [Explanation, setExplanation] = useState('');
    const [AnwerOpt, setAnwerOpt] = useState('');
    const [Markss, setMarkss] = useState('');
    const [negativeMarks, setnegativeMarks] = useState('');
    const [difficultlevel, setdifficultlevel] = useState('');
    const [explanation, setexplanation] = useState('');
    const [confirmAnsError, setConfirmAnsError] = useState('');
    const [questionError, setQuestionError] = useState('');
    const [answerError, setAnswerError] = useState('');


    useEffect(() => {
        _questionBankService.GetSectionList(Decrypt(localStorage.getItem("CompanyID"))).then((res) => {

            console.log(res);

            setSectionList(res);
        }).catch((exception) => {
            console.log(exception);
        });
        _questionBankService.GetNegavtiveMarkPercentageList().then((res) => {
            console.log(res);
            setnegativeList(res);
        })
        _examservice
            .GetExamParameterList()
            .then((response) => {
                console.log(response)
                const parametervalue = response.parameterList.filter(
                    (option) => option.parameterCode === "QUESTIONTYPE",

                );
                setparametervalue(parametervalue);

                const Answerparametervalue = response.parameterList.filter(
                    (option) => option.parameterCode === "ANSWERTYPE"
                );
                setAnswerparametervalue(Answerparametervalue);
                const AnswerOptionparametervalue = response.parameterList.filter(
                    (option) => option.parameterCode === "ANSWEROPTION"
                );
                setAnswerOptionparametervalue(AnswerOptionparametervalue);
                const DifficultLevelparametervalue = response.parameterList.filter(
                    (option) => option.parameterCode === "DIFFICULTYLEVEL"
                );
                setDifficultLevelparametervalue(DifficultLevelparametervalue);
            })
            .catch((exception) => {
                console.log(exception);
            });
        _questionBankService
            .GetQuestionbankById(qId)
            .then((result) => {

                console.log(result.data);
                setConfirmAns1(result.data);
                if (result.data.length > 0) {
                    setsectionType(result.data[0].sectionId);
                    setAnwerOpt(result.data[0].answerOptionId);
                    setMarkss(result.data[0].marks);
                    setnegativeMarks(result.data[0].negativeMarks);
                    setdifficultlevel(result.data[0].difficultLevelID)
                    result.data.map((item) => {
                        if (item.isCorrectAnswer === 'Yes') {
                            setconfirmAns(item.answerValue);
                        }
                        return null;
                    });
                }
            })
            .catch((exception) => {
                console.log(exception);
            });
        if (parametervalue.length > 0) {
            setQuestionType(parametervalue[0].parameterValueID);
        }

    }, []);

    const handleQuestionTypeChange = (event) => {
        setQuestionType(event.target.value);
    };
    const handlesectionTypeChange = (event) => {
        // confirmAns1.length > 0 && confirmAns1[0].sectionId
        setsectionType(event.target.value);
    };
    const handleAnsValueChange = (event) => {
        setAnswerType(event.target.value);
    };

    const handleQuestionChange = (value) => {
        setQuestion(value);
        setInitialValueShown(true);
    };

    const handleExplanationChange = (value) => {
        setExplanation(value);
        setinitialExpandtionValueShown(true);
    }

    const handleAnswerOption = (event) => {
        setAnwerOpt(event.target.value);
    }
    const handleConfirmAnsChange = (value) => {
        setconfirmAns(value);
        setInitialAnswerTypeShown(true);
    };
    const handleMarkschange = (event) => {
        setMarkss(event.target.value);
    }
    const handleNagativeMarks = (event => {
        setnegativeMarks(event.target.value);
    })
    const handleDifficultLevel = (event) => {
        setdifficultlevel(event.target.value);
    }
    const handleAnswerChange = (value, index) => {
        const updatedConfirmAns1 = confirmAns1.map((ans, i) => {
            if (i === index) {
                return {
                    ...ans,
                    answerValue: value
                };
            }
            return ans;
        });

        setConfirmAns1(updatedConfirmAns1);

        const updatedOptionModels = optionModels.map((option, i) => {
            if (i === index) {
                return {
                    ...option,
                    AnswerValue: value
                };
            }
            return option;
        });

        setOptionModels(updatedOptionModels);
    };



    const handleAnswerTypeChange = (event) => {
        setAnswerType(event.target.value);
        setAnswer([]); // Reset the answers array
        if (event.target.value === 'multiple-option') {
            setAnswerCount(1); // Set initial answer count to 1
        }
    };

    const handleAnswerCountChange = (event) => {
        const count = parseInt(event.target.value);
        if (isNaN(count) || count < 1) {
            setAnswerCount('');
            setConfirmAns1([]);
        } else {
            setAnswerCount(count);
            const updatedConfirmAns1 = [...confirmAns1];
            if (count < confirmAns1.length) {
                updatedConfirmAns1.splice(count);
            } else {
                for (let i = confirmAns1.length; i < count; i++) {
                    updatedConfirmAns1.push({ answerValue: '' });
                }
            }
            setConfirmAns1(updatedConfirmAns1);
        }
    };


    const validateQuestion = () => {
        if (question.trim() === '<p><br></p>') {
            setQuestionError('Question is required');
            return false;
        }
        setQuestionError('');
        return true;
    };
    const validateAnswer = () => {
        if (answer === '<p><br></p>') {
            setAnswerError('Answer is required');
            return false;
        } else if (answer[0]?.trim() === '') {
            setAnswerError('Answer is required');
            return false;
        }
        setAnswerError('');
        return true;
    };


    const renderInputField = () => {

        if (questionType === '42') {
            return (
                <div>
                    <ReactQuill
                        id="QuestionBox"
                        className="form-control"
                        rows="5"
                        ref={quillRef}
                        value={initialValueShown ? question : confirmAns1.length > 0 ? confirmAns1[0].question : ''}
                        onChange={handleQuestionChange}
                    /></div>
            );
        } else if (questionType === '43') {
            return (
                <input
                    type="file"
                    className="form-control-file"
                    accept="image/*"
                />
            );
        } else {
            return null;
        }
    };

    const renderAnswerField = () => {

        if (answerType === '44') {
            return (
                <div className="mb-3">
                    <ReactQuill
                        id="answer"
                        className="form-control"
                        rows="5"
                        value={confirmAnsShown ? answer : confirmAns1.length > 0 ? confirmAns1[0].answerValue : ''}

                        // value={answer[0] || ''}
                        onChange={(value) => handleAnswerChange(value, 0)}
                    />
                </div>
            );
        } else if (answerType === '45') {
            return <div><input type="file" className="form-control-file" accept="image/*" /></div>
        } else if (answerType === '46') {
            return (
                <div>
                    <div className="">
                        <label htmlFor="answerCount" className="form-label mb-2">
                            Number of Textboxes: {confirmAns1.length}
                        </label>
                        <input
                            type="number"
                            id="answerCount"
                            className="form-control mb-3" answerCount

                            value={initialAnswerOptionShown ? answerCount : confirmAns1.length > 0 ? confirmAns1.length : ''}
                            onChange={handleAnswerCountChange}
                            min="1"
                        />
                    </div>
                    <div className="row">
                        {confirmAns1.map((ans, index) => (
                            <div className="col-md-6 mb-3" key={index}>
                                <label htmlFor={`answer-${index}`} className="form-label">
                                    Answer {index + 1}:
                                </label>
                                <ReactQuill
                                    id={`answer-${index}`}
                                    className="form-control"
                                    rows="5"
                                    // value={initialAnswerTypeShown ? answer : confirmAns1.length > 0 ? confirmAns1.answerValue : ''}

                                    value={ans.answerValue || ''}
                                    onChange={(event) => handleAnswerChange(event, index)}
                                />
                            </div>
                        ))}
                    </div>
                </div>


            );
        }
        else {
            return null;
        }
    };

    const optionModels = confirmAns1.map((ans, index) => ({
        QuestionOptionID: ans.questionOptionID,
        AnswerValue: ans.answerValue || '',
        IsCorrectAnswer: ans.answerValue === confirmAns ? 'Yes' : 'No',
        // IsCorrectAnswer: ans.answerValue === confirmAns == '' ? confirmValue.current.value : confirmAns ? 'Yes' : 'No',
        // confirmValue.current.value
        Status: 'AC',
        // Assign other properties as needed
    }));

    const validateConfirmAnswer = (index) => {
        if (confirmAns === '<p><br></p>') {
            setConfirmAnsError('Confirm Answer is required');
            return false;
        } else {
            let isAnyCorrectAnswer = false;
            for (let i = 0; i < optionModels.length; i++) {
                if (optionModels[i].IsCorrectAnswer === 'Yes') {
                    isAnyCorrectAnswer = true;
                    break;
                }
            }
            if (!isAnyCorrectAnswer) {
                setConfirmAnsError('Confirm Answer Does not match any Options');
                return false;
            }
        }
        setConfirmAnsError('');
        return true;
    };



    const Questionsubmit = (e) => {

        const isConfirmAnswerValid = validateConfirmAnswer();
        const isQuestionValid = validateQuestion();
        const isAnswerValid = validateAnswer();
        e.preventDefault();
        var flag = true;

        if (isConfirmAnswerValid && isQuestionValid && isAnswerValid) {

            var data = {
                QuestionID: confirmAns1[0].questionID,
                SectionId: document.getElementById('section').value,
                // Question: document.getElementById('QuestionBox').value,
                marks: document.getElementById('Marks').value,
                Question: quillRef.current.value,
                SectionName: document.getElementById("section").options[
                    document.getElementById("section").selectedIndex
                ].text,
                AddExplanation: explanationRef.current.value = "" ? "NULL" : explanationRef.current.value,
                Status: 'AC',
                CreatedBy: Decrypt(localStorage.getItem("EmployeeID")),
                QuestionTypeID: questionType,
                AnswerTypeID: answerType,
                DepartmentID: 1,
                CompanyID: 1,
                AnswerOptionId: document.getElementById('AnsweroptionTypeSelect').value,
                Marks: document.getElementById('Marks').value,
                NegativeMarks: document.getElementById('NegativeMarkSelect').value,
                DifficultLevelID: document.getElementById('DifficultLevelID').value,
                optionModels: optionModels
            };

            if (flag) {
                console.log(data);
            }

            const formData = new FormData();
            try {
                showLoading();
                _questionBankService.CreateUpdate(data).then((res) => {
                    hideLoading();
                    if (res > 0) {
                        navigateToView();
                        showSaveSuccess();

                        setTimeout(() => {
                            window.location.reload(); // Reload the page
                        }, 1000);
                    } else {
                        showErrorMsg();
                    }
                });
            }
            catch (error) {
                console.log(error);
            }
        }
    };
    const toggleContainerClass = () => {
        setIsContainerFluid((prevState) => !prevState);
    };
    return (
        <main class="main-content-wrap">
            <div className="contact-list-area ">
                <div className={`${isContainerFluid ? 'container-fluid' : 'container-sm'} animatedwidth`}>

                    <div className="fullwidthbuttonbox hidden-sm hidden-xs" onClick={toggleContainerClass}>
                        <span className="fullwidthbutton" >
                            <i className="fa fa-angle-double-right"></i>
                        </span>
                    </div>    <div className="form-layouts-area">
                        <div className="container-fluid">
                            <div className="card-box-style">
                                <form id="QuestionBank" onSubmit={Questionsubmit}>

                                    <div className="form-row">
                                        <div className="form-group col-md-5">
                                            <label htmlFor="question-type" className="label">
                                                Question Type:
                                            </label>
                                            <select
                                                id="questionTypeSelect"
                                                className="form-select form-control"
                                                value={questionType}
                                                onChange={handleQuestionTypeChange}
                                            >
                                                <option value=''>Select</option>
                                                {parametervalue.length > 0 && parametervalue.map((option) => (
                                                    <option
                                                        key={option.parameterValueID}
                                                        value={option.parameterValueID}
                                                    >
                                                        {option.value}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="form-group col-md-5">
                                            <label htmlFor="section" className="label">
                                                Section:
                                            </label>
                                            <select
                                                id="section"
                                                className="form-select form-control"
                                                value={sectionType}
                                                onChange={handlesectionTypeChange}
                                            >
                                                <option value="">Select</option>
                                                {sectionList.length > 0 && sectionList.map((section) => (
                                                    <option key={section.sectionId} value={section.sectionId}>
                                                        {section.sectionName}
                                                    </option>
                                                ))}
                                            </select>

                                        </div>
                                    </div>

                                    <div className="pb-24">
                                        <label htmlFor="question" className="form-label">
                                            Question:
                                        </label>
                                        {renderInputField()}
                                        {questionError && <span className="text-danger">{questionError}</span>}
                                    </div>

                                    <div className="form-group ">
                                        <label htmlFor="answer-type" className="label mb-2">
                                            Answer Type:
                                        </label>
                                        <select
                                            id="inputState"
                                            className="form-select form-control mb-3"
                                            value={answerType}
                                            onChange={handleAnswerTypeChange}
                                        >
                                            <option value="">Select</option>
                                            {Answerparametervalue.length > 0 && Answerparametervalue.map((option) => (
                                                <option
                                                    key={option.parameterValueID}
                                                    value={option.parameterValueID}
                                                >
                                                    {option.value}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="">
                                        {answerType != '46' && (
                                            <label htmlFor="answer" className="form-label">
                                                Answer:
                                            </label>)}
                                        {renderAnswerField()}
                                        {answerError && <span className="text-danger">{answerError}</span>}
                                    </div>
                                    <div className="pb-24">
                                        <label htmlFor="question" className="form-label">
                                            Confirm Answer:
                                        </label>
                                        {confirmAns1.map((ans) => {
                                            if (ans.isCorrectAnswer === 'Yes') {
                                                return (
                                                    <ReactQuill

                                                        id={`Confirm-Answer`}
                                                        className="Confirm_value"
                                                        ref={confirmValue}
                                                        rows="5"
                                                        value={initialAnswerTypeShown ? confirmAns : confirmAns1.length > 0 ? ans.answerValue : ''}

                                                        // value={ans.answerValue}
                                                        onChange={handleConfirmAnsChange}
                                                    />

                                                );
                                            }
                                            return null;
                                        })}
                                        {confirmAnsError && <span className="text-danger">{confirmAnsError}</span>}
                                    </div>

                                    <div className="accordion" id="accordionExample">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingOne">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseOne"
                                                    aria-expanded="false"
                                                    aria-controls="collapseOne"
                                                >
                                                    Add Explanation
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseOne"
                                                className="accordion-collapse collapse"
                                                aria-labelledby="headingOne"
                                                data-bs-parent="#accordionExample"
                                                style={{}}
                                            >
                                                <div className="accordion-body">
                                                    {/* Start Form Layouts Area */}

                                                    <div className="form-layouts-area">
                                                        <div className="container-fluid">
                                                            <ReactQuill
                                                                id='Explanation'
                                                                value={explanation}
                                                                ref={explanationRef}
                                                                onChange={handleExplanationChange}
                                                            />
                                                        </div>
                                                    </div>

                                                    {/* End Form Layouts Area */}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingTwo">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="collapseTwo"
                                                >
                                                    Advance Options
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseTwo"
                                                className="accordion-collapse collapse"
                                                aria-labelledby="headingTwo"
                                                data-bs-parent="#accordionExample"
                                                style={{}}
                                            >
                                                <div className="accordion-body">
                                                    {/* Start Form Layouts Area */}

                                                    <div className="form-layouts-area">
                                                        <div className="container-fluid">
                                                            <div className="pb-24">

                                                                <div className="mb-3">
                                                                    <div className="row">
                                                                        <div className="form-row">
                                                                            <div className="form-group col-md-5">
                                                                                <label htmlFor="question-type" className="label">
                                                                                    Answer Option:
                                                                                </label>
                                                                                <select
                                                                                    id="AnsweroptionTypeSelect"
                                                                                    className="form-select form-control"
                                                                                    value={AnwerOpt}
                                                                                    onChange={handleAnswerOption}
                                                                                >
                                                                                    <option value="">Select</option>
                                                                                    {AnswerOptionparametervalue.length > 0 &&
                                                                                        AnswerOptionparametervalue.map((option) => (
                                                                                            <option
                                                                                                key={option.parameterValueID}
                                                                                                value={option.parameterValueID}
                                                                                            >
                                                                                                {option.value}
                                                                                            </option>
                                                                                        ))}
                                                                                </select>

                                                                            </div>
                                                                            <div className="form-group col-md-5">
                                                                                <label htmlFor="question-type" className="Marks">
                                                                                    Marks:
                                                                                </label>

                                                                                <div className='form-group marks'>
                                                                                    <input type='text' id='Marks' className='form-control'
                                                                                        value={Markss}
                                                                                        onChange={handleMarkschange}
                                                                                    ></input>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group col-md-5">
                                                                                <label htmlFor="Negative" className="label">
                                                                                    Negavtive Marks:
                                                                                </label>
                                                                                <select
                                                                                    id="NegativeMarkSelect"
                                                                                    className="form-select form-control"
                                                                                    value={negativeMarks}
                                                                                    onChange={handleNagativeMarks}
                                                                                >
                                                                                    <option value=''>Select</option>
                                                                                    {negativeList.length > 0 && negativeList.map((option) => (
                                                                                        <option
                                                                                            key={option.parameterValueID}
                                                                                            value={option.parameterValueID}
                                                                                        >
                                                                                            {option.value}
                                                                                        </option>
                                                                                    ))}
                                                                                </select>
                                                                                {/* {sectionError && <span className="text-danger">{sectionError}</span>} */}
                                                                            </div>
                                                                            <div className="form-group col-md-5">
                                                                                <label htmlFor="Difficultylevel" className="label">
                                                                                    Difficulty Level:
                                                                                </label>
                                                                                <select
                                                                                    id="DifficultLevelID"
                                                                                    className="form-select form-control"
                                                                                    value={difficultlevel}
                                                                                    // value={questionType}
                                                                                    onChange={handleDifficultLevel}
                                                                                >
                                                                                    <option value=''>Select</option>
                                                                                    {DifficultLevelparametervalue.length > 0 &&
                                                                                        DifficultLevelparametervalue.map((option) => (
                                                                                            <option
                                                                                                key={option.parameterValueID}
                                                                                                value={option.parameterValueID}
                                                                                            >
                                                                                                {option.value}
                                                                                            </option>
                                                                                        ))}
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group col-md-8">
                                                                            <label htmlFor="question-type" className="label">
                                                                                Tags:
                                                                            </label>

                                                                            <div className='form-group Tags' style={{ display: 'flex' }}>
                                                                                <input type='text' className='form-control'></input>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* End Form Layouts Area */}
                                                </div>
                                            </div>
                                        </div>



                                    </div>

                                    <button type="submit" className="btn btn-primary">
                                        Submit
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default QuestionBank;
