
import React, { useState, useEffect, useContext } from "react";
import ExamService from "./ExamService";
import { AppContext } from "../../services/ContextProvider";
import { useLocation, useNavigate } from "react-router-dom";
export default function MeritList() {


  const [meritList, setMeritList] = useState([]);
  const location = useLocation();
  const [examID, setexamID] = useState(location.state?.examID ?? 0);
  const [isContainerFluid, setIsContainerFluid] = useState(true);

  const navigate = new useNavigate();
  const { showLoading, hideLoading } =
    useContext(AppContext);
  const _examService = new ExamService();

  useEffect(() => {
    if (examID > 0) {
      showLoading();
      _examService
        .GetExamMeritListByID(examID)
        .then((response) => {

          if (response != null) {
            setMeritList(response);

            hideLoading();
          }
        })

        .catch((exception) => {
          console.log(exception);
        });
    }

    else {
      navigate("*");
    }

  }, []);
  const toggleContainerClass = () => {
    setIsContainerFluid((prevState) => !prevState);
  };
  return (
    <>
      <main class="main-content-wrap">
        <div className="contact-list-area ">
          <div className='container-fluid'  >

            <div className="row">
              <div className={`${isContainerFluid ? "col-md-12" : "col-md-9"}`}>
                <div className="card-box-style animatedwidth">

                  <div className="fullwidthbuttonbox " onClick={toggleContainerClass}>
                    <span className="fullwidthbutton" >
                      {isContainerFluid ? <i className="fa fa-angle-double-left"></i> : <i className="fa fa-angle-double-right"></i>}

                    </span>
                  </div>
                  <div className="others-title  row">
                    <h3 className="col-6">Merit List({meritList.length})
                    </h3>
                    <span className="col-6">
                      <input type="button" value="Back" title="Back" style={{ float: 'right' }} className="back-button backbutton-end" onClick={() => navigate(-1)} />
                    </span>
                  </div>
                  <table className="table align-middle mb-0">
                    <thead>
                      <tr>
                        <th>Rank</th>
                        <th>Name</th>
                        <th>Att.No</th>

                        <th>Que</th>
                        <th>Cor</th>
                        <th>Par</th>
                        <th>Inc</th>

                        <th>Una</th>
                        <th>%</th>

                        <th>Time Taken</th>
                      </tr>
                    </thead>
                    <tbody>
                      {meritList != null && meritList.length > 0 ? (
                        meritList.map((v, i) => {
                          return (
                            <tr key={i}>
                              {" "}
                              <td>{i + 1}</td>
                              <td>
                                {v.CandidateName}
                              </td>
                              <td title="No Of Attempt">
                                {v.NoOfAttempt}
                              </td>
                              <td>
                                {v.QuestionCount}
                              </td>
                              <td>
                                {v.CorrectAnswerCount}
                              </td>
                              <td>
                                {v.PartialCorrectAnswerCount}
                              </td>
                              <td>
                                {v.InCorrectAnswerCount}
                              </td>
                              <td>
                                {v.UnAnsweredCount}
                              </td>
                              <td>
                                {v.Percentage}
                              </td>
                              <td>
                                {v.TimeTaken === null || v.TimeTaken === '' ? "" : v.TimeTaken >= 60
                                  ? _examService.convertSecondsToMinutes(v.TimeTaken) + ' Minutes'
                                  : v.TimeTaken + ' Seconds'}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              {!isContainerFluid ? <div className="col-md-3">

                <div className="card-box-style"></div>
              </div> : null}
            </div>

          </div>
        </div>
      </main>

    </>
  );
}