import React, { useEffect, useState, useContext, useRef } from "react";
import CandidateQuestionViewServices from "../../services/CandidateQuestionViewService";
import { FaEraser, FaFlag } from 'react-icons/fa';
import Header from "../Candidate_QuestionView/Header";
import SideNav from "./CanidatePreviewSideNav";
import "../../assets/js/custom.js";
import '../../assets/css/customizeSideMenu.css';
import '../../assets/css/metismenu.min.css';
import '../../assets/css/simplebar.min.css';
import '../../../node_modules/metismenu/dist/metisMenu.min.js'
import '../../../node_modules/simplebar/dist/simplebar.min.js';
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../../services/ContextProvider";
import { boolean } from "yup";
import { IMAGE_URL } from '../../env';
import ExamService from "../Exam/ExamService";
import CandidatepreviewHeader from "./CandidatePreviewHeader";
import FinalAnswerShowHeader from "./finalAnswerheader";

var candidateQuestionServ = new CandidateQuestionViewServices();
const _examservice = new ExamService();
export default function FinalAnswerShow() {
    const { showLoading, hideLoading, setCookies, tempModulePrivileage, isAuth, SetisAuth } =
        useContext(AppContext);
    const [questionList, setQuestionList] = useState([]);
    const [answerLists, setAnswerList] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [currentAnswers, setcurrentAnswers] = useState([]);
    const [selectedAnswers, setSelectedAnswers] = useState([]);
    const [currentQuestion, setcurrentQuestion] = useState({});
    const [currentQuestionId, setcurrentQuestionId] = useState(0);
    const [questionIndex, setquestionIndex] = useState(0);
    const location = useLocation();
    const [candidateID, setCandidateID] = useState(location.state?.CandidateId ?? 0);

    const [ExamAttendID, setExamAttendID] = useState(location.state?.ExamAttendID ?? 0);
    const questionidRef = useRef(null);
    const [CandidateName, setCandidateName] = useState(location.state?.canditateName ?? null);
    const [examID, setexamID] = useState(location.state?.examID ?? 0);
    const [questionID, setQuestionID] = useState(location.state?.qid ?? 0);
    const [timetaken, setTimeTaken] = useState(location.state?.TimeTaken ?? null);

    const [tab, setTab] = useState(location.state?.tab ?? null);
    const navigate = new useNavigate();
    const [correctAnswerIds, setCorrectAnswerIds] = useState([]);
    const [wrongAnswerIds, setWrongAnswerIds] = useState([]);
    const [progress, setProgress] = useState(0);
    var match;
    var time;
    useEffect(() => {
        GetQuestionList();
    }, []);

    const GetQuestionList = () => {
        showLoading();
        candidateQuestionServ.questionList(candidateID, examID, ExamAttendID).then((res) => {
            const { questionList, answerList } = res.data;

            if (questionList?.length > 0) {
                setQuestionList([...questionList]);
                if (questionID > 0) {
                    const filteredQuestions = questionList.filter(v =>
                        v.questionID === questionID
                    );
                    const matchedIndex = questionList.indexOf(filteredQuestions[0]);
                    setcurrentQuestion(questionList[matchedIndex])
                    setCurrentQuestionIndex(matchedIndex);
                    var currentQues = questionList[matchedIndex].questionID;
                    if (answerList?.length > 0) {
                        setAnswerList([...answerList]);
                        const curroption = answerList.filter((answer) => answer.questionID === currentQues);
                        setcurrentAnswers([...curroption]);
                    }
                } else {
                    setcurrentQuestion(questionList[currentQuestionIndex])
                    var currentQues = questionList[currentQuestionIndex].questionID;
                    if (answerList?.length > 0) {
                        setAnswerList([...answerList]);
                        const curroption = answerList.filter((answer) => answer.questionID === currentQues);
                        setcurrentAnswers([...curroption]);
                    }
                }

            }
            hideLoading();

        }).catch((err) => {
            console.log(err);
        });
    }

    useEffect(() => {
        candidateQuestionServ.getAnswerHistory(candidateID, examID, ExamAttendID).then((res) => {
            if (res != null) {
                setSelectedAnswers(res?.data);
                const correctIds = getCorrectAnswerIds(res.data);
                setCorrectAnswerIds(correctIds);
                const wrongIds = getWrongAnswerIds(res.data);
                setWrongAnswerIds(wrongIds);
            }
        }).catch((err) => {
            console.log(err);
        });
    }, []);


    useEffect(() => {
        const totalQuestions = questionList.length;
        const progressPercentage = (progress / totalQuestions) * 100;
        setProgress(progressPercentage);
    }, [progress]);


    const getCorrectAnswerIds = (answers) => {
        return answers
            .filter((answer) => answer.isCorrectAnswer === 'Yes')
            .map((answer) => answer.questionID);
    };

    const getWrongAnswerIds = (answers) => {
        return answers
            .filter((answer) => answer.isCorrectAnswer === 'No')
            .map((answer) => answer.questionID);
    };
    const getPlainText = (htmlString) => {
        const element = document.createElement("div");
        element.innerHTML = htmlString;
        return element.innerText;
    };


    const showNextQuestion = () => {
        const nextQuestionIndex = currentQuestionIndex + 1;
        if (nextQuestionIndex < questionList?.length) {
            setcurrentQuestion(questionList[nextQuestionIndex]);
            setCurrentQuestionIndex(nextQuestionIndex);
            const nextQuestionID = questionList[nextQuestionIndex].questionID;
            const currentOptions = answerLists.filter(
                (answer) => answer.questionID === nextQuestionID
            );
            setcurrentAnswers([...currentOptions]);
        } else {
            console.log("something went wrong");
        }
    };

    const showPrevQuestion = () => {
        const prevquesIndex = currentQuestionIndex - 1;
        setcurrentQuestion(questionList[prevquesIndex]);
        var currentQues = questionList[prevquesIndex].questionID;
        setcurrentQuestionId(currentQues);
        const curroption = answerLists.filter((answer) => answer.questionID === currentQues);
        setcurrentAnswers([...curroption]);
        setCurrentQuestionIndex(prevquesIndex);
    };

    const handleQuestionClick = (questionIndex) => {

        setCurrentQuestionIndex(questionIndex - 1);
        setcurrentQuestion(questionList[questionIndex - 1]);
        var currentQues = questionList[questionIndex - 1].questionID;
        const curroption = answerLists.filter((answer) => answer.questionID === currentQues);
        setcurrentAnswers([...curroption]);
    };

    const onClick = (tab) => {
        if (questionID != 0 || tab == null) {
            navigate(-1);
        } else {
            navigate('/AdminCanditate', { state: { tab: tab }, replace: true });

        }

    }
    return (
        <div className="all-section-area ">
            {/* <CandidatepreviewHeader /> */}
            <FinalAnswerShowHeader CandidateName={CandidateName} />
            <SideNav
                questionList={questionList}
                handleQuestionClick={handleQuestionClick}
                currentQuestionId={currentQuestionIndex}
                candidateID={candidateID}
                examID={examID}
                CorrectAnswerIds={correctAnswerIds}
                WrongAnswerIds={wrongAnswerIds}
            />
            <main className="main-content-wrap style-two">
                <div className="overview-content-area">
                    <div className="row">
                        <div className="col-10">

                        </div>
                        {/* <div className="col-2">
                            <button className="btn btn-primary" type="buttom" value="Back" onClick={onClick} > Back</button>
                        </div> */}
                    </div>
                    <div className="container-fluid">

                        <div className="fixed-content questionandsectionheader">
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-sm-6">

                                    <div className="page-title">
                                        <h4 style={{ paddingLeft: '25px', fontWeight: 'bold' }}>Question {currentQuestionIndex + 1} / {questionList.length}</h4>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6">
                                    <span style={{ paddingLeft: '35%' }}>Section: {currentQuestion.sectionName}</span>

                                    <input type="button" value="Back" title="Back" className="back-button backbutton-end" style={{ float: 'right', marginBottom: '13px' }} onClick={() => onClick(tab)} />

                                </div>
                            </div>
                        </div>

                        <div className="card-box-style">
                            <div className="" style={{ marginBottom: '0px' }}>
                                <h5 id={`${currentQuestionIndex + 1}`}>{getPlainText(currentQuestion.question)}</h5>
                                {currentQuestion?.questionImageURL ? <img src={IMAGE_URL + currentQuestion?.questionImageURL} alt="Image" style={{ maxWidth: '100%', height: 'auto' }} /> : <></>}

                            </div>
                            <div className="row mt-2">
                                {currentAnswers?.map((option, index) => {

                                    let borderStyle = "2px solid #80808057";
                                    let checked = false;
                                    const filteredModels = selectedAnswers.filter(model => parseInt(model.questionID) === parseInt(option.questionID));
                                    match = filteredModels.map(model => parseInt(model?.timeTaken ? model.timeTaken : 0));
                                    time = match !== null && match?.length > 0 ? match[0] >= 60 ? _examservice.convertSecondsToMinutes(match[0]) + ' Minutes'
                                        : match[0] + ' Seconds' : 0;
                                    const matchedModelNames = filteredModels.map(model => parseInt(model.finalAnswer));
                                    if (option.isCorrectAnswer === "Yes" && option.questionOptionID === matchedModelNames[0]) {

                                        borderStyle = "2px solid green";
                                        checked = true;
                                    }
                                    else if (
                                        option.questionOptionID === matchedModelNames[0]) {

                                        borderStyle = "2px solid red";
                                        checked = true;

                                    }
                                    else if (option.isCorrectAnswer === "Yes") {
                                        borderStyle = "2px solid green";
                                        checked = true;

                                    }
                                    // var result = selectedAnswers?.length > 0 && selectedAnswers?.filter((e) => e.questionID === currentQuestion.questionID);
                                    // var isCorrectAnswer = result?.length > 0 && result?.some((e) => e.finalAnswer == option.questionOptionID && option.isCorrectAnswer === 'Yes');
                                    // var radioClass = isCorrectAnswer ? 'correct-answer' : 'incorrect-answer';
                                    return <div className="col-lg-12 col-sm-12" key={index}>
                                        <div className={`single-audience d-flex justify-content-between align-items-center  `} style={{ border: borderStyle }}>
                                            <div className={`audience-content`}>
                                                <label>
                                                    <input
                                                        disabled={true}
                                                        type="radio"
                                                        name={`answer-${option.questionOptionID}`}
                                                        id={`answer-${currentQuestionId}`}
                                                        value={option.questionOptionID}
                                                        checked={checked}

                                                    />
                                                    {' '}  {getPlainText(option.answerValue)}
                                                    {option?.imageURL ?
                                                        <img
                                                            src={IMAGE_URL + option?.imageURL}
                                                            alt="Image"
                                                            style={{ maxWidth: '100%', height: '40px' }}
                                                        />
                                                        : <></>}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                })}
                            </div>
                            <div className="OptionSection">
                                <div className="row">

                                    <div className="NextPrevClass col-6">
                                        {currentQuestionIndex > 0 && (
                                            <button
                                                className="btn btn-sm btn-primary"
                                                onClick={showPrevQuestion}
                                                style={{ marginRight: '10px' }}
                                            >
                                                « Prev
                                            </button>
                                        )}

                                        {currentQuestionIndex === questionList.length - 1 ? (

                                            <></>
                                        ) : (
                                            <button
                                                className="btn btn-sm btn-primary"
                                                style={{ marginLeft: '10px' }}
                                                onClick={() => showNextQuestion()}
                                            >
                                                Next »
                                            </button>
                                        )}
                                    </div>
                                    <div className="col-6 text-end" style={{ fontWeight: 'bold' }}>TimeTaken :{time ? time : 0}</div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </div>
    );
}



