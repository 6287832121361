import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { AppContext } from "../../services/ContextProvider";
import ApplicationConfigService from "../../services/ApplicationConfigService";
import { Decrypt, Encrypt } from "../../services/CommonService";
import { FaTrashAlt, FaEdit } from "react-icons/fa";
import MUIDataTable from "mui-datatables";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@material-ui/core/Tooltip";
import Global from "../../services/Global";
function EmailTemplate() {
  // var UserValidate = JSON.parse(localStorage.getItem("UserDetails"));
  const _applicationService = new ApplicationConfigService();
  const [Refresh, SetRefresh] = useState(0);
  const [priviledge, setpriviledge] = useState("");
  const [isContainerFluid, setIsContainerFluid] = useState(true);

  const { getCookies, setCookies, cookies, tempModulePrivileage, showLoading, hideLoading } =
    useContext(AppContext);
  const DeleteSolution = (val) => {

    var ModifiedBy = Decrypt(localStorage.getItem("EmployeeID"));

    if (ModifiedBy == null || ModifiedBy == "") ModifiedBy = 0;

    Swal.fire({
      title: "Are you sure?",
      text: "Do You Want to Delete this Template?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        _applicationService.DeleteTemplate(val, ModifiedBy).then((res) => {

          if (res.data == 1) {
            Toast.fire({
              icon: "success",
              title: "Delete Success",
            });
            setTimeout(function () {
              window.location.reload();
            }, 750);
          } else {
            Toast.fire({
              icon: "success",
              title: "Delete Success",
            });
            setTimeout(function () {
              window.location.reload();
            }, 750);
          }
        });
      }
    });
  };

  let redriect = useNavigate();

  const navigateToContacts = (val) => {
    showLoading();
    redriect("/EmailTemplateCreate", { state: val });
  };

  const [isLoading, setIsLoading] = React.useState(true);
  const [data, setData] = React.useState([]);

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 750,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  useEffect(() => {
    showLoading();
    _applicationService
      .Get_EmailTemplateList()
      .then((response) => {
        setData(response.emailTemplateList);
        hideLoading();
      })
      .catch((error) =>

        console.log(error));
    hideLoading();
    if (data.length !== 0) {
      setIsLoading(false);
    }
    var ModulePrivileges =
      getCookies(Global.COOKIES.modulePrivileage) != null
        ? getCookies(Global.COOKIES.modulePrivileage)
        : "";

    let timeout = setTimeout(setpriviledge(ModulePrivileges), 3000);
    hideLoading();
  }, []);

  const HeaderElements = () => (
    <>
      {priviledge.includes("EMAILCREATE") ?
        <Tooltip title="Add Temaplate">
          <label htmlFor="icon-button-file">
            <IconButton onClick={() => navigateToContacts(0)}>
              <AddIcon> </AddIcon>
            </IconButton>
            <a className="btn add-btn" onClick={() => navigateToContacts(0)}>
              <i className="fa fa-plus"></i>Add Temaplate
            </a>
          </label>
        </Tooltip> : ""}
    </>
  );

  const columns = [
    { name: "moduleName", label: "Module Name" },
    { name: "moduleFunctionalityName", label: "Functionality Name" },
    { name: "subject", label: "Subject" },
    {
      label: "OPTIONS",
      name: "emailTemplateID",

      options: {
        filter: true,
        sort: false,
        empty: true,

        responsive: true,

        customBodyRender: (values, tableMeta) => {
          const value = tableMeta.rowData[3];
          return (
            <>
              <div className="ico-sec">
                {priviledge.includes("EMAILEDIT") ?
                  <Tooltip title="Edit Template">
                    <IconButton
                      type="button"
                      title=""
                      className="text-primary"
                      data-original-title="Edit"
                      data-toggle="modal"
                      data-target="#addRowModal"
                      // style={{ outlineColor: "green" }}
                      onClick={() => navigateToContacts(value)}
                    >
                      <FaEdit className="edit-icon" />{" "}
                    </IconButton>
                  </Tooltip> : ""
                }
                {priviledge.includes("EMAILDELETE") ?
                  <Tooltip title="Delete Template">
                    <IconButton
                      className="text-danger"
                      aria-label="Delete"
                      onClick={() => DeleteSolution(value)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip> : ""
                }
                {/* <TrashFill   /> */}
              </div>
            </>
          );
        },
      },
    },
  ];

  function tablerefresh(id) {
    SetRefresh(Refresh + 1);
  }

  const toggleContainerClass = () => {
    setIsContainerFluid((prevState) => !prevState);
  };

  return (
    <>
      <main class="main-content-wrap">
        <div className="contact-list-area ">
          <div className='container-fluid'  >

            <div className="row">
              <div className={`${isContainerFluid ? "col-md-12" : "col-md-9"}`}>
                <div className="card-box-style animatedwidth">

                  <div className="fullwidthbuttonbox " onClick={toggleContainerClass}>
                    <span className="fullwidthbutton" >
                      {isContainerFluid ? <i className="fa fa-angle-double-left"></i> : <i className="fa fa-angle-double-right"></i>}

                    </span>
                  </div>
                  <MUIDataTable
                    data={data}
                    title="Email Template"
                    columns={columns}
                    options={{
                      responsive: true,
                      download: false,
                      print: false,
                      rowsPerPageOptions: [10, 50, 100],
                      customToolbar: () => (
                        <>
                          <Tooltip title="Add  Template">
                            <button
                              type="button"
                              title=""
                              class="btn btn-link"
                              data-original-title="Edit"
                              data-toggle="modal"
                              data-target="#addRowModal"
                              style={{
                                outlineColor: "green",
                                color: "rgba(0, 0, 0, 0.54)",
                                background: "#FFFFFF",
                              }}
                              onClick={() => navigateToContacts(0)}
                            >
                              <AddIcon className="edit-icon" />{" "}
                            </button>
                          </Tooltip>
                          {/* <h3>
                                                                    <input type="button" value="Back" title="Back" className="back-button" onClick={() => redriect(-1)} />
</h3> */}

                        </>
                      ),
                      selectableRows: false,
                      filter: false,
                      viewColumns: false,
                    }}
                  />
                </div>
              </div>
              {!isContainerFluid ? <div className="col-md-3">
                {/* 
                <div className="card-box-style"></div> */}
              </div> : null}
            </div>

          </div>
        </div>
      </main>
    </>

  );
}

export default EmailTemplate;
