import React, { useEffect, useState, useContext } from "react";
import ExamService from "./ExamService";
import deleteImage from "../../assets/images/icon/trash.svg";
import { AppContext } from "../../services/ContextProvider";
import "./Exam.css";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from 'react-bootstrap';
import Swal from "sweetalert2";
import { Decrypt, Encrypt } from "../../services/CommonService";
import { FaEdit, FaCog, FaTrashAlt, FaQuestionCircle, FaCircle } from "react-icons/fa";
import { useHistory } from 'react-router-dom';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@material-ui/core/Tooltip";
import EmailTemplate from "../EmailTemplate/ExamEmailTemplate";
import { Cookies } from "react-cookie";
import MUIDataTable from "mui-datatables";
import ReExam from "./ReExam";
import QuestionPaperDownload from "./QuestionPaperDownload";

export default function ExamList() {
  const [examList, setExamList] = useState([]);
  const navigate = new useNavigate();
  const [examConfigModel, setExamConfigModel] = useState({});
  const _examService = new ExamService();
  const [refresh, setRefresh] = useState(true);
  const [showPopupModal, setShowPopupModal] = useState(false);
  const [checkShareGroup, setCheckShareGroup] = useState(false);
  const [examLink, setExamLink] = useState('');
  const [reExam, setReExam] = useState(false);
  const [examID, setExamID] = useState();
  const [examName, setExamName] = useState();
  const [candidateCounts, setCandidateCounts] = useState(0); // Initialize as an empty object
  const [showQuestionPaper, setShowQuestionPaper] = useState(false);
  const [isContainerFluid, setIsContainerFluid] = useState(true);
  const [getData, setData] = useState({});
  const { showLoading, hideLoading, getCookies } =
    useContext(AppContext);

  useEffect(() => {
    showLoading();
    _examService
      .GetExamList(Decrypt(localStorage.getItem("CompanyID")))
      .then((response) => {
        hideLoading();
        if (response != null) {

          setExamList(response);

        }
      })

      .catch((exception) => {
        console.log(exception);
      });


  }, [refresh]);


  useEffect(() => {
    if (showPopupModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showPopupModal]);

  const EditExam = (examID, examConfigModel) => {

    navigate("/ExamCreate", {
      state: {
        examConfigModel: examConfigModel,
        examID: examConfigModel.examID
      },
    });


  };
  const QuestionPaper = (examID, examName) => {
    // debugger;
    // navigate("/questionPaper", {
    //   state: {
    //     examID: examID,
    //     examName: examName
    //   },
    // });
    setShowQuestionPaper(true);
    setExamID(examID);
    setExamName(examName);
  }
  const columns = [
    {
      name: "S.NO", label: "S.NO",
      options: {
        filter: true,
        sort: true,
        empty: true,

        responsive: true,

        customBodyRender: (values, tableMeta) => {
          const Sno = tableMeta.rowIndex; // Assuming duration is at index 1

          return (
            <span>{Sno + 1} </span>
          );
        },
      },
    },
    { name: "examName", label: "NAME" },
    {
      name: "duration", label: "DURATION",
      options: {
        filter: true,
        sort: true,
        empty: true,

        responsive: true,

        customBodyRender: (values, tableMeta) => {
          const durationInMilliseconds = tableMeta.rowData[2]; // Assuming duration is at index 1
          const Duration = _examService.MillisecondsToMinutes(durationInMilliseconds);
          return (
            <span>{Duration} Minutes</span>
          );
        },
      },

    },
    { name: "noQuestion", label: "NO OF QUESTIONS" },
    {
      label: "OPTIONS",
      name: "Options",

      options: {
        filter: true,
        sort: false,
        empty: true,

        responsive: true,

        customBodyRender: (values, tableMeta) => {

          const examID = examList[tableMeta.rowIndex].examID;
          const examName = examList[tableMeta.rowIndex].examName;
          const i = tableMeta.rowIndex;
          const examConfigModel = examList[tableMeta.rowIndex];

          const candidateCount = examList[tableMeta.rowIndex].candidateCount;


          return (
            <div className="ico-sec">
              <Tooltip title="Edit  Exam">
                <IconButton
                  type="button"
                  title=""
                  className="text-primary"
                  data-original-title="Edit"
                  data-toggle="modal"
                  data-target="#addRowModal"
                  // style={{ outlineColor: "green" }}
                  onClick={() => EditExam(examID, examConfigModel)}
                >
                  <FaEdit className="edit-icon" />{" "}
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete  Exam">
                <IconButton
                  className="text-danger"
                  aria-label="Delete"
                  onClick={() => deleteExam(examID)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>

              <Dropdown key={i} show={isDropdownOpen[i]} onToggle={() => toggleDropdown(i)}>
                <Dropdown.Toggle id={i} onClick={() => toggleDropdown(i)}>
                  <FaCog style={{ color: "#67748e" }} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => ExamPreview(examID)}>Exam Preview</Dropdown.Item>
                  <Dropdown.Item onClick={() => ExamSummary(examID, examName)}>Exam Summary</Dropdown.Item>
                  <Dropdown.Item onClick={() => ExamHistory(examID)}>Exam History <span class="historycountbox" data-count="4"><span class="badge badgeorange">{candidateCount}</span></span></Dropdown.Item>
                  <Dropdown.Item onClick={() => ExamStatistics(examID)}>Exam Statistics</Dropdown.Item>
                  <Dropdown.Item onClick={() => GetMeritList(examID)}>Merit List</Dropdown.Item>
                  <Dropdown.Item onClick={(event) => ShareExam(examID, event, examName)}>Share Test</Dropdown.Item>
                  <Dropdown.Item onClick={() => DuplicateEXam(examID)}>Duplicate</Dropdown.Item>
                  <Dropdown.Item onClick={(event) => ReExamPage(examID, event, examName)}>Re Exam</Dropdown.Item>
                  <Dropdown.Item onClick={() => Enable(examID)}>Enable</Dropdown.Item>
                  <Dropdown.Item onClick={() => EndExam(examID)}>Disable</Dropdown.Item>
                  <Dropdown.Item onClick={() => QuestionPaper(examID, examName)}>Question Paper Download</Dropdown.Item>

                </Dropdown.Menu>
              </Dropdown>
              {/* <TrashFill   /> */}
            </div>
          );
        },
      },
    },
  ];
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  const [isDropdownOpen, setIsDropdownOpen] = useState(Array(examList.length).fill(false));

  const examCreate = () => {

    if (examList != null && examList.length > 0) {
      const examConfigModel = examList.filter(
        (option) => option.defaultConfig === true
      );
      if (examConfigModel != null && examConfigModel.length > 0) {
        const examID = examConfigModel[0].examID;
        examConfigModel[0].examID = 0;
        examConfigModel[0].examName = "";
        navigate("/ExamCreate", {
          state: { examConfigModel: examConfigModel[0], examID: examID },
        });
      } else {
        navigate("/ExamCreate");
      }
    } else {
      navigate("/ExamCreate");
    }
  };
  const deleteExam = (examID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do You Want to Delete this Exam?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        _examService
          .DeleteExam(examID)
          .then((res) => {
            if (res > 0) {
              Toast.fire({
                icon: "success",
                title: "Delete Success",
              });
              setRefresh(!refresh);
            } else {
              Toast.fire({
                icon: "error",
                title: "Delete Failed!",
              });
            }
          })

          .catch((exception) => {
            console.log(exception);
          });
      }
    });

  };
  const ShareExam = (examID, event, examName) => {

    event.preventDefault();
    setExamName(examName);
    setCheckShareGroup(true);
    const examid = Encrypt(examID);


    const fullUrl = window.location.href;
    const url = new URL(fullUrl);
    const baseUrl = `${url.protocol}//${url.host}/`;
    const redirectUrl = `${baseUrl}Canditate?examid=${examid}`;
    setExamLink(redirectUrl);

    setShowPopupModal(true);
  };

  const ReExamPage = (examID, event, examName) => {
    debugger;
    event.preventDefault();
    _examService
      .GetExamSummaryByExamID(examID)
      .then((res) => {
        if (res != null) {
          debugger;
          const examid = Encrypt(examID);


          const fullUrl = window.location.href;
          const url = new URL(fullUrl);
          const baseUrl = `${url.protocol}//${url.host}/`;
          const redirectUrl = `${baseUrl}Canditate?examid=${examid}`;
          const reExam = { ...res, ExamLink: redirectUrl, examName: examName };

          setData(reExam);
          setReExam(true);
          setExamID(examID);

        }
      })

      .catch((exception) => {
        console.log(exception);
      });

  };

  const ExamSummary = (examID, examName) => {

    _examService
      .GetExamSummaryByExamID(examID)
      .then((res) => {
        if (res != null) {

          const examid = Encrypt(examID);


          const fullUrl = window.location.href;
          const url = new URL(fullUrl);
          const baseUrl = `${url.protocol}//${url.host}/`;
          const redirectUrl = `${baseUrl}Canditate?examid=${examid}`;
          const data = { ...res, ExamLink: redirectUrl, examName: examName };
          setData(data);
          navigate("/ExamSummary", {
            state: { examSummaryModel: data, navigate: -1 },
          });
        }
      })

      .catch((exception) => {
        console.log(exception);
      });
  };

  const EndExam = (examID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do You Want to disable this Exam?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {

        _examService
          .EndExam(examID, 'Disable')
          .then((res) => {
            if (res > 0) {

            }
          })

          .catch((exception) => {
            console.log(exception);
          });

      }
    });
  };
  const Enable = (examID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do You Want to enable this Exam?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {

        _examService
          .EndExam(examID, 'Enable')
          .then((res) => {
            if (res > 0) {

            }
          })

          .catch((exception) => {
            console.log(exception);
          });

      }
    });
  };
  const DuplicateEXam = (examID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do You Want to Duplicate this Exam?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        var CreatedBY =
          Decrypt(localStorage.getItem("EmployeeID"));
        _examService.DuplicateEXam(examID, CreatedBY)
          .then((res) => {
            if (res > 0) {
              Toast.fire({
                icon: "success",
                title: "Exam Duplicate Success",
              });
              setRefresh(!refresh);
            }
            else {
              Toast.fire({
                icon: "error",
                title: "Exam Duplicate Failed  ",
              });
            }
          })

          .catch((exception) => {
            console.log(exception);
          });

      }
    });
  };
  const GetMeritList = (examID) => {
    navigate("/MeritList", {
      state: { examID: examID },
    });
  };
  const toggleDropdown = (index) => {
    const updatedDropdowns = [...isDropdownOpen];
    updatedDropdowns[index] = !updatedDropdowns[index];
    setIsDropdownOpen(updatedDropdowns);
  };
  const ExamHistory = (examID) => {

    navigate("/ExamHistoryForExam", {
      state: { examID: examID },
    });

  };
  const ExamPreview = (examID) => {
    var canditateName = getCookies('UserName');

    navigate("/ExamPreview", {
      state: { examID: examID, canditateName: canditateName },
    });

  };
  const ExamStatistics = (id) => {

    navigate("/ExamStatistics", {
      state: { examID: id },
    });
  }

  const toggleContainerClass = () => {
    setIsContainerFluid((prevState) => !prevState);
  };

  return (
    <>

      <main class="main-content-wrap">
        <div className="contact-list-area ">

          <div className='container-fluid'  >

            <div className="row">
              <div className={`${isContainerFluid ? "col-md-12" : "col-md-9"}`}>

                <div className="card-box-style animatedwidth">

                  <div className="fullwidthbuttonbox " onClick={toggleContainerClass}>
                    <span className="fullwidthbutton" >
                      {isContainerFluid ? <i className="fa fa-angle-double-left"></i> : <i className="fa fa-angle-double-right"></i>}

                    </span>
                  </div>
                  <MUIDataTable
                    data={examList.length > 0 ? examList : []}
                    title="Exam List"
                    columns={columns}
                    options={{
                      customToolbar: () => (
                        <>
                          <Tooltip title="Add Exam">
                            <button
                              type="button"
                              title=""

                              class="btn btn-link"
                              data-original-title="Edit"
                              data-toggle="modal"
                              data-target="#addRowModal"
                              style={{
                                outlineColor: "green",
                                color: "rgba(0, 0, 0, 0.54)",
                                background: "#FFFFFF",


                              }}
                              onClick={() => examCreate()}
                            >
                              <AddIcon className="edit-icon" />{" "}
                            </button>
                          </Tooltip>
                        </>
                      ),
                      responsive: true,
                      download: false,
                      print: false,
                      rowsPerPageOptions: [10, 50, 100],

                      selectableRows: false,
                      filter: false,
                      viewColumns: false,
                    }}
                  />
                </div>
              </div>
              {/* {!isContainerFluid ? <div className="col-md-3">

                <div className="card-box-style"></div>
              </div> : null} */}
            </div>


          </div>


        </div>
      </main>

      {showPopupModal && (
        <EmailTemplate
          examName={examName}
          setShowPopupModal={setShowPopupModal}
          examLink={examLink}
          checkShareGroup={checkShareGroup}
        />
      )}

      {reExam && (
        <ReExam
          setReExam={setReExam}
          examID={examID}
          reExam={reExam}
          getData={getData}
        />
      )}

      {showQuestionPaper && (<QuestionPaperDownload
        examID={examID}
        examName={examName}
        setShowQuestionPaper={setShowQuestionPaper}
        showQuestionPaper={showQuestionPaper} />)}
    </>
  );
}
