import React, { useState, useEffect } from 'react';
import QuestionBankService from '../../services/QuestionBankService';
import { showErrorMsg, showSaveSuccess } from '../ToastMsg';
import Examservice from '../Exam/ExamService';
import { useNavigate, useLocation } from 'react-router-dom';
import ReactQuill from 'react-quill';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import RichTextEditor from 'react-rte';
import { useRef, useContext } from 'react';
import { AppContext } from "../../services/ContextProvider";
import { Decrypt } from '../../services/CommonService';
import AddIcon from "@mui/icons-material/Add";
import { CloudDownload } from '@mui/icons-material';
import Swal from "sweetalert2";
import { IMAGE_URL } from '../../env';


const _questionBankService = new QuestionBankService();
const _examservice = new Examservice();

const QuestionBank = () => {
    const location = useLocation();
    const [isAdnaveTableOpen, setisAdnaveTableOpen] = useState(false);
    const [isContainerFluid, setIsContainerFluid] = useState(true);
    const [secID, setSecID] = useState(location.state?.sId ?? 0);

    const [imageOptionmodelList, setimageOptionModelList] = useState([]);
    const [OptionsImageURLList, setOptionsImageURLList] = useState([]);
    const [QuestionImageURL, setQuestionImageURL] = useState(null)
    const navigate = useNavigate();
    const navigateToView = (qId) => {
        showLoading();
        navigate("/ViewQuestion", {
            state: { sId: qId, isback: true },
        });
        // navigate(-1);
    }
    const quillRef = useRef(null);

    const explanationRef = useRef(null);
    const [questionType, setQuestionType] = useState('42');
    const { showLoading, hideLoading, isAuth } =
        useContext(AppContext);
    const [parametervalue, setparametervalue] = useState([]);
    const [Answerparametervalue, setAnswerparametervalue] = useState([]);
    const [AnswerOptionparametervalue, setAnswerOptionparametervalue] = useState([]);
    const [DifficultLevelparametervalue, setDifficultLevelparametervalue] = useState([]);
    const [answerType, setAnswerType] = useState('44');
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [CorrectAnswer, setCorrectAnswer] = useState('');
    const [answerCount, setAnswerCount] = useState(1); // Number of textboxes for multi-textbox answer type
    const [sectionList, setSectionList] = useState([]);
    const [negativeList, setnegativeList] = useState([]);
    const [confirmAns, setconfirmAns] = useState('');
    const [explanation, setexplanation] = useState('');
    const [questionError, setQuestionError] = useState('');
    const [answerError, setAnswerError] = useState('');
    const [ansBoxError, setAnsBoxError] = useState('');
    const [ansOptionError, setansOptionError] = useState('');
    const [marksError, setmarksError] = useState('');
    const [NEgativemarksError, setNEgativemarksError] = useState('');
    const [difficultlvelError, setdifficultlvelError] = useState('');
    const [qusBoxError, setqusBoxError] = useState('');
    const [sectionError, setSectionError] = useState('');
    const [confirmAnsError, setConfirmAnsError] = useState('');
    const [ImageOptionErrors, setImageOptionErrors] = useState([]);
    const [imageQuestion, setImageQuestion] = useState("");
    debugger;
    const [qId, setqId] = useState(location.state?.qId ?? 0);
    const [QuestionBankList, setQuestionBankList] = useState([]);
    const [sectionID, setsectionID] = useState("");
    const [DifficultLevel, setDifficultLevel] = useState("")
    const [negativeMarks, setnegativeMarks] = useState("");
    const [AnwerOpt, setAnwerOpt] = useState("");
    const [tags, setTags] = useState("");
    const dropdownOptions = ['Option 1', 'Option 2', 'Option 3'];


    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });
    useEffect(() => {

        _questionBankService.GetSectionList(Decrypt(localStorage.getItem("CompanyID"))).then((res) => {
            setSectionList(res);
        }).catch((exception) => {
            console.log(exception);
        });
        _questionBankService.GetNegavtiveMarkPercentageList().then((res) => {
            setnegativeList(res);
        })
        _examservice
            .GetExamParameterList()
            .then((response) => {
                const parametervalue = response.parameterList.filter(
                    (option) => option.parameterCode === "QUESTIONTYPE",

                );
                setparametervalue(parametervalue);

                const Answerparametervalue = response.parameterList.filter(
                    (option) => option.parameterCode === "ANSWERTYPE"
                );
                setAnswerparametervalue(Answerparametervalue);
                const AnswerOptionparametervalue = response.parameterList.filter(
                    (option) => option.parameterCode === "ANSWEROPTION"
                );
                setAnswerOptionparametervalue(AnswerOptionparametervalue);

                // setAnswerparametervalue(Answerparametervalue);
                const DifficultLevelparametervalue = response.parameterList.filter(
                    (option) => option.parameterCode === "DIFFICULTYLEVEL"
                );
                setDifficultLevelparametervalue(DifficultLevelparametervalue);
            })
            .catch((exception) => {
                console.log(exception);
            });
        if (qId > 0) {
            _questionBankService
                .GetQuestionbankById(qId)
                .then((result) => {
                    debugger;
                    console.log(result.data, 'd');
                    setQuestionBankList(result.data);
                    setsectionID(result.data[0].sectionId);
                    setexplanation(result.data[0].addExplanation === "NULL" ? "" : result.data[0].addExplanation)
                    setQuestion(result.data[0].question);
                    var questionTypeID = result.data[0].questionTypeID;
                    setQuestionType(questionTypeID.toString());
                    setAnswerType(result.data[0].answerTypeID.toString())
                    setAnswerCount(result.data.length);
                    setDifficultLevel(result.data[0].difficultLevelID)
                    setAnswer([]);
                    setnegativeMarks(result.data[0].negativeMarks)
                    setAnwerOpt(result.data[0].answerOptionId);
                    setImageQuestion(result.data[0].questionImageURL);
                    setTags(result.data[0].tags);

                    const mappedList = result.data.map((item) => {
                        // Perform any necessary transformations or logic here
                        return item.answerValue; // Replace with your desired value from item
                    });
                    setAnswer([...mappedList,]);
                    if (result.data[0].answerTypeID.toString() === "45") {
                        result.data.map((item) => {
                            const newModel = {
                                image: item.answerImageURL,
                                option: item.answerValue === null ? "" : item.answerValue,
                                CorrectAnswer: item.isCorrectAnswer,
                            };

                            setimageOptionModelList((prevList) => [...prevList, newModel]);
                            // Perform any necessary transformations or logic here
                        });
                    }

                    //   var correctAnswers;

                    //   result.data.map((item,i) => {
                    //     if(item.isCorrectAnswer==="Yes")
                    //     {


                    //         handlesetAnswer(i);

                    //     }



                    //   });
                    //   result.data.map((item) => {
                    //     if (item.isCorrectAnswer === "Yes") {
                    //       correctAnswers.push(item.answerValue);
                    //     }
                    //   });

                    //   setconfirmAns([...correctAnswers,])


                    // setConfirmAns1(result.data);
                    // if (result.data.length > 0) {
                    //     setsectionType(result.data[0].sectionId);
                    //  
                    //     setMarkss(result.data[0].marks);
                    //     setnegativeMarks(result.data[0].negativeMarks);
                    //     setdifficultlevel(result.data[0].difficultLevelID)
                    //     result.data.map((item) => {
                    //         if (item.isCorrectAnswer === 'Yes') {
                    //             setconfirmAns(item.answerValue);
                    //         }
                    //         return null;
                    //     });
                    // }
                })
                .catch((exception) => {
                    console.log(exception);
                });

        }


    }, []);



    const validateQuestion = () => {

        if (question.trim() === '') {
            // setQuestionError('Question is required');
            Toast.fire({
                icon: "warning",
                title: "Question is required",
            });
            return false;
        }
        else if (question.trim() === "<p><br></p>") {
            // setQuestionError('Question is required');
            Toast.fire({
                icon: "warning",
                title: "Question is required",
            });
            return false;
        }
        setQuestionError('');
        return true;
    };
    const validateSection = () => {
        if (document.getElementById('section').value === '') {
            // setSectionError('Please select a section');
            Toast.fire({
                icon: "warning",
                title: "Please select a section",
            });
            return false;
        }
        setSectionError('');
        return true;
    };

    const handleSectionChange = (event) => {
        const selectedSection = event.target.value;
        setsectionID(selectedSection);
        if (selectedSection === '') {
            // setSectionError('Section is required');
            Toast.fire({
                icon: "warning",
                title: "Section is required",
            });
        } else {
            setSectionError('');
        }
    };

    const validateBox = () => {
        if (answerType === '') {
            // setAnsBoxError('Please Select a Answer Type');
            Toast.fire({
                icon: "warning",
                title: "Please Select a Answer Type",
            });
            return false;
        }
        setAnsBoxError('');
        return true;
    };
    const validateQuesBox = () => {
        if (questionType === '') {
            // setqusBoxError('Please Select a Question Type');
            Toast.fire({
                icon: "warning",
                title: "Please Select a Question Type",
            });
            return false;
        }
        setqusBoxError('');
        return true;
    };

    const validateAnswer = () => {
        if (answer === '') {
            // setAnswerError('Answer is required');
            Toast.fire({
                icon: "warning",
                title: "Answer is required",
            });
            return false;
        } else if (answer[0]?.trim() === '') {
            // setAnswerError('Answer is required');
            Toast.fire({
                icon: "warning",
                title: "Answer is required",
            });
            return false;
        }
        // setAnswerError('');
        return true;
    };

    const validateConfirmAnswer = () => {



        if (qId > 0) {

            let isAnyCorrectAnswer = false;
            for (let i = 0; i < optionModels.length; i++) {
                if (optionModels[i].IsCorrectAnswer === 'Yes') {
                    isAnyCorrectAnswer = true;
                    break;
                }
            }
            if (!isAnyCorrectAnswer) {
                Toast.fire({
                    icon: "warning",
                    title: "Confirm Answer Does not match any Options",
                });

                return false;
            }

            setConfirmAnsError('');
            return true;
        }
        else {
            if (confirmAns === '') {
                Toast.fire({
                    icon: "warning",
                    title: "Confirm Answer Does not match any Options",
                });
                return false;
            } else {
                let isAnyCorrectAnswer = false;
                for (let i = 0; i < optionModels.length; i++) {
                    if (optionModels[i].IsCorrectAnswer === 'Yes') {
                        isAnyCorrectAnswer = true;
                        break;
                    }
                }
                if (!isAnyCorrectAnswer) {
                    Toast.fire({
                        icon: "warning",
                        title: "Confirm Answer Does not match any Options",
                    });

                    return false;
                }
            }
            setConfirmAnsError('');
            return true;
        }
    };

    const handleTagChange = (event) => {
        setTags(event.target.value);
    }


    const handleQuestionTypeChange = (event) => {

        if (event.target.name === "questionTypeSelect") {
            setQuestionError("");

            setQuestionType(event.target.value);

        }

        if (event.target.name === "DifficultLevel") {
            setDifficultLevel(event.target.value);
        }

        // if (event.target.name === "NegativeMarkSelect") {
        //     setnegativeMarks(event.target.value);
        // }
        if (event.target.name === "AnsweroptionTypeSelect") {
            setAnwerOpt(event.target.value);
        }

    };

    const handleQuestionChange = (value) => {
        const questionvalue = value;
        setQuestion(questionvalue);
        if (value == '<p><br></p>') {
            // setQuestionError('Question is required');
            Toast.fire({
                icon: "warning",
                title: "Question is required",
            });
        } else {
            // setQuestionError('');
        }

    };
    const handleConfirmAnsChange = (value) => {
        setconfirmAns(value);
        if (value == '<p><br></p>') {
            // setConfirmAnsError('Confirm Answer is required');
            Toast.fire({
                icon: "warning",
                title: "Confirm Answer is required",
            });
        } else {
            setConfirmAnsError('');
        }
    };
    const handleExplanationChange = (value) => {
        setexplanation(value);
    };
    const showimageOption = (id) => {

        id = "imageOption" + id;
        document.getElementById(id).style.display = "block";
    }
    const handleAnswerChange = (value, index) => {
        setAnswer((prevAnswer) => {
            const updatedAnswer = [...prevAnswer]; // Create a copy of the answer state array
            updatedAnswer[index] = value; // Update the value at the specified index

            const element = document.getElementById("Checkoption-" + index);
            if (element) {
                element.style.display = "";
            }

            if (value === '<p><br></p>') {
                // setAnswerError('Answer is required');
                Toast.fire({
                    icon: "warning",
                    title: "Answer is required",
                });
            } else {
                // setAnswerError('');
            }

            return updatedAnswer; // Return the modified array
        });
    };


    const handlesetAnswer = (value, index) => {
        debugger;
        // Create a copy of the QuestionBankList array
        const updatedList = [...QuestionBankList];

        // Update the specific item's isCorrectAnswer property
        updatedList[index] = {
            ...updatedList[index],
            isCorrectAnswer: value.target.checked ? 'Yes' : 'No',
        };

        // Create a copy of the CorrectAnswer array (assuming CorrectAnswer corresponds to QuestionBankList)
        const updatedCorrectAnswer = [...CorrectAnswer];

        // Update the CorrectAnswer array at the same index
        updatedCorrectAnswer[index] = value.target.checked ? 'Yes' : 'No';

        // Update the state with the modified arrays
        setQuestionBankList(updatedList);
        setCorrectAnswer(updatedCorrectAnswer);

        // Update confirmAns if needed
        setconfirmAns(value.target.checked ? 'Yes' : 'No');
    };



    const addOptionImageList = (value) => {

        if (imageOptionmodelList.length === 0) {
            for (let i = 1; i <= value; i++) {
                const newModel = {
                    image: "",
                    option: "",
                    CorrectAnswer: "No"
                };

                setimageOptionModelList([...imageOptionmodelList, newModel]);
            }
        }
        else {
            var length = imageOptionmodelList.length;

            if (length < value) {
                var total = value - length;
                for (let i = 1; i <= total; i++) {
                    const newModel = {
                        image: "",
                        option: "",
                        CorrectAnswer: "No"
                    };

                    setimageOptionModelList([...imageOptionmodelList, newModel]);
                }
            }
            else if (NaN) {
                var length = imageOptionmodelList.length;
                setimageOptionModelList(prevList => prevList.slice(0, length))
            }
            else {
                var total = length - value;
                setimageOptionModelList(prevList => prevList.slice(0, total));
            }


        }

    }
    const CorrectAnswerChecked = (value) => {

        if (QuestionBankList.length === 0) {
            for (let i = 1; i <= value; i++) {
                const newModel = {

                    isCorrectAnswer: "No"
                };

                setQuestionBankList([...QuestionBankList, newModel]);
            }
        }
        else {
            var length = QuestionBankList.length;

            if (length < value) {
                var total = value - length;
                for (let i = 1; i <= total; i++) {
                    const newModel = {

                        isCorrectAnswer: "No"
                    };

                    setQuestionBankList([...QuestionBankList, newModel]);
                }
            }
            else if (NaN) {
                var length = QuestionBankList.length;
                setQuestionBankList(prevList => prevList.slice(0, length))
            }
            else {
                var total = length - value;
                setQuestionBankList(prevList => prevList.slice(0, total));
            }


        }

    }
    const handleAnswerTypeChange = (event) => {

        setAnswerError("");
        setAnswerType(event.target.value);
        setAnswer([]); // Reset the answers array
        setAnswerCount(1); // Set initial answer count to 1
        if (event.target.value === '46') {


            CorrectAnswerChecked(1)
        }

        else if (event.target.value === "45") {
            CorrectAnswerChecked(1);
            addOptionImageList(parseInt(answerCount))
        }
    };

    const handleAnswerCountChange = (event) => {

        setAnswerCount(parseInt(event.target.value));
        if (event.target.name === "imageanswerCount") {
            addOptionImageList(parseInt(event.target.value));
            CorrectAnswerChecked(parseInt(event.target.value));
        }
        else if (event.target.name === "multicorrectanscount") {
            CorrectAnswerChecked(parseInt(event.target.value));
        }
    };
    const handleImageQuestionChange = (e) => {
        if (e.target.value === "" || e.target.value === null) {
            setQuestionError("Question is required");
            setImageQuestion("");
        }
        else {

            //     const file = e.target.files[0];
            //     const reader = new FileReader();

            // reader.onload = () => {
            //   const blob = new Blob([reader.result], { type: file.type });
            //   setImageQuestion(blob);
            // };
            // reader.readAsArrayBuffer(file);
            setQuestionError("");
            setImageQuestion(e.target.files[0]);
        }



    }

    const handleImageAnswerChange = (e, i) => {
        debugger;
        console.log(imageOptionmodelList);
        document.getElementById("CheckImageoption-" + i).style.display = "";

        // Check if the element at index i exists in imageOptionmodelList
        if (i >= 0 && i < imageOptionmodelList.length) {
            const updatedErrors = [...ImageOptionErrors];
            if (e.target.value === "" || e.target.value === null) {
                Toast.fire({
                    icon: "warning",
                    title: "Option is required",
                });
                setimageOptionModelList(prevList => {
                    const updatedList = [...prevList];
                    updatedList[i].image = "";
                    return updatedList;
                });
            } else {
                updatedErrors[i] = {
                    ...updatedErrors[i],
                    optionError: ""
                };
                setimageOptionModelList(prevList => {
                    const updatedList = [...prevList];
                    updatedList[i].image = e.target.files[0];
                    return updatedList;
                });
            }
            setImageOptionErrors(updatedErrors);
        } else {
            console.error("Invalid index:", i);
        }
    };



    const handleImageAnswerOptionChange = (e, i) => {
        debugger;


        setimageOptionModelList(prevList => {

            const updatedList = [...prevList];

            updatedList[i].option
                = e.target.value;
            return updatedList;
        });

    }
    const showimageQuestion = () => {
        document.getElementById("imageQuestion").style.display = "block";

    }

    const renderInputField = () => {

        if (questionType === '42') {
            return (
                <ReactQuill
                    id="QuestionBox"
                    className="form-control"
                    rows="5"
                    ref={quillRef}
                    value={question}
                    onChange={handleQuestionChange}
                />
            );
        } else if (questionType === '43') {
            return (
                <>
                    <div className='row'>
                        <div className=" col-md-5">

                            <input
                                style={{ width: "100%" }}
                                type="file"
                                className="form-control-file"
                                accept=".jpeg, .jpg, .png, .gif"
                                onChange={(event) => handleImageQuestionChange(event)}

                            />


                        </div>
                        <div
                            className='col-md-1'>

                            <button
                                type="button"
                                title=""
                                class="btn btn-link "


                                style={{
                                    outlineColor: "green",
                                    color: "rgba(0, 0, 0, 0.54)",
                                    background: "#FFFFFF",
                                }}
                                onClick={() => showimageQuestion()}
                            >
                                <AddIcon className="edit-icon" />{" "}
                            </button>
                        </div>

                        <div className="col-md-6">

                            <input
                                type="text"
                                className="form-control"
                                id="imageQuestion"
                                defaultValue={question}
                                style={{ display: qId > 0 ? QuestionBankList[0]?.question !== "" && QuestionBankList[0]?.question !== null ? "block" : "none" : "none" }}
                            />
                        </div>

                    </div>
                </>

            );
        } else {
            return null;
        }
    };
    const handlesetImageCorrectOption = (e, i) => {

        debugger;
        const updatedList = [...QuestionBankList];
        updatedList[i] = {
            ...updatedList[i],
            isCorrectAnswer: e.target.checked ? 'Yes' : 'No',
        };

        const imageList = [...imageOptionmodelList];
        imageList[i] = {
            ...imageOptionmodelList[i],
            CorrectAnswer: e.target.checked ? 'Yes' : 'No'
        };

        setimageOptionModelList(imageList);
        setQuestionBankList(updatedList);
        console.log('Index received:', i);
    }
    const renderAnswerField = () => {
        if (answerType === '44') {
            return (
                <div className="mb-3">
                    <ReactQuill
                        id="answer"
                        className="form-control"
                        rows="5"
                        value={answer[0] || ''}
                        onChange={(event) => handleAnswerChange(event, 0)}
                    />
                </div>
            );
        } else if (answerType === '45') {

            return (
                <div>
                    <div className="">
                        <label htmlFor="answerCount" className="form-label mb-2">
                            Number of Options:
                        </label>
                        <input
                            type="number"
                            id="answerCount"
                            name="imageanswerCount"
                            className="form-control mb-3"
                            value={answerCount}
                            onChange={handleAnswerCountChange}
                            min="1"
                        />
                    </div>

                    {answerCount > 0 && (

                        <div className="row">
                            {[...Array(answerCount)].map((_, index) => (
                                <> <div className="col-md-5 mb-3" key={index} style={{ display: "grid" }}>
                                    <label htmlFor={`answer-${index}`} className="form-label">
                                        Answer {index + 1}:
                                        {qId > 0 && QuestionBankList[index]?.answerImageURL != "" ? (
                                            <button
                                                type="button"
                                                title=""
                                                className="btn btn-link"
                                                style={{
                                                    outlineColor: "green",
                                                    color: "rgba(0, 0, 0, 0.54)",
                                                    background: "#FFFFFF",
                                                    textDecoration: "none", // Remove underline from button
                                                }}
                                                onClick={() => handleDownloadClick(QuestionBankList[index]?.answerImageURL)} // Corrected the onClick handler
                                            >
                                                <CloudDownload fontSize="small" /> {/* Set the icon size */}
                                            </button>
                                        ) : (
                                            <></>
                                        )}
                                        <input
                                            class=""
                                            type="checkbox"
                                            value="0"
                                            style={{ margin: "1%", display: qId > 0 ? "inlineBlock" : "none" }}
                                            id={`CheckImageoption-${index}`}
                                            checked={QuestionBankList && QuestionBankList[index]?.isCorrectAnswer === "Yes" ? true : false}
                                            onChange={(e) => handlesetImageCorrectOption(e, index)}
                                        />
                                    </label>
                                    <input id={index} type="file"
                                        accept=".jpeg, .jpg, .png, .gif"
                                        className="form-control-file"

                                        onChange={(event) => handleImageAnswerChange(event, index)}
                                    />
                                    {ImageOptionErrors[index] && ImageOptionErrors[index].optionError && (
                                        <span className="text-danger">
                                            {ImageOptionErrors[index].optionError}
                                        </span>
                                    )}
                                    <input
                                        type="text"
                                        className="form-control"
                                        id={`imageOption${index}`}
                                        style={{ display: qId > 0 ? QuestionBankList[index]?.answerValue !== "" && QuestionBankList[index]?.answerValue !== null ? "block" : "none" : "none" }}
                                        defaultValue={qId > 0 ? QuestionBankList[index]?.answerValue : ""}
                                        onChange={(event) => handleImageAnswerOptionChange(event, index)}

                                    />

                                </div>
                                    <div className="col-md-1 mb-3" >
                                        <button
                                            type="button"
                                            title=""
                                            class="btn btn-link "


                                            style={{
                                                outlineColor: "green",
                                                color: "rgba(0, 0, 0, 0.54)",
                                                background: "#FFFFFF",
                                            }}
                                            onClick={() => showimageOption(index)}
                                        >
                                            <AddIcon className="edit-icon" />{" "}
                                        </button>
                                    </div>
                                </>

                            ))}
                        </div>
                    )}
                </div>
            );
        } else if (answerType === '46') {
            return (
                <div>
                    <div className="">
                        <label htmlFor="answerCount" className="form-label mb-2">
                            Number of Options:
                        </label>
                        <input
                            type="number"
                            id="answerCount"
                            name="multicorrectanscount"
                            className="form-control mb-3"
                            value={answerCount}
                            onChange={handleAnswerCountChange}
                            min="1"
                        />
                    </div>
                    {answerCount > 0 && (
                        <div className="row">
                            {[...Array(answerCount)].map((_, index) => (
                                <div className="col-md-6 mb-3" key={index}>
                                    <label htmlFor={`answer-${index}`} className="form-label" style={{ width: "100%" }}>
                                        Answer {index + 1}:
                                        <input
                                            class=""
                                            type="checkbox"
                                            value="0"
                                            style={{ margin: "1%", display: qId > 0 ? "inherit" : "none" }}
                                            id={`Checkoption-${index}`}
                                            checked={QuestionBankList && QuestionBankList[index]?.isCorrectAnswer === "Yes" ? true : false}
                                            onChange={(e) => handlesetAnswer(e, index)}
                                        />
                                    </label>
                                    <ReactQuill
                                        id={`answer-${index}`}
                                        className="form-control"
                                        rows="5"
                                        value={answer[index] || ''}
                                        onChange={(event) => handleAnswerChange(event, index)}
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            );
        } else {
            return null;
        }
    };


    // const optionModels = [...Array(answerCount)].map((_, index) => ({
    //     QuestionOptionID: index, // You may need to adjust the property names based on your actual AnswerOptionModel structure
    //     AnswerValue: answer[index] || '', // Get the value from the corresponding answer textarea
    //     // IsCorrectAnswer: answer[index] === confirmAns ? 'Yes' : 'No',
    //     IsCorrectAnswer: CorrectAnswer[index] ? CorrectAnswer[index] : 'No',
    //     Status: "AC",
    // }));
    debugger;
    const optionModels = Array.from({ length: answerCount }, (_, index) => ({
        QuestionOptionID: qId > 0 ? QuestionBankList[index]?.questionOptionID || 0 : 0,
        AnswerValue: answerType === "45" ? imageOptionmodelList[index]?.option || null : answer[index] || '',
        IsCorrectAnswer: qId > 0 ? answerType === "45" ? imageOptionmodelList && imageOptionmodelList[index]?.CorrectAnswer || 'No' : QuestionBankList && QuestionBankList[index]?.isCorrectAnswer
            : answerType === "45" ? imageOptionmodelList && imageOptionmodelList[index]?.CorrectAnswer || 'No' : QuestionBankList && QuestionBankList[index]?.isCorrectAnswer,
        Status: 'AC',
    }));


    useEffect(() => {
        console.log(QuestionBankList);
        console.log(optionModels);
    }, [optionModels, QuestionBankList])

    const validateImageConfirmAnswer = () => {

        let isAnyCorrectAnswer = false;
        for (let i = 0; i < optionModels.length; i++) {
            if (optionModels[i].IsCorrectAnswer === 'Yes') {
                isAnyCorrectAnswer = true;
                break;
            }
        }
        if (!isAnyCorrectAnswer) {
            Toast.fire({
                icon: "warning",
                title: "Confirm Answer Does not match any Options",
            });

            return false;
        }
        return true;
    }
    const validateImageQUestion = () => {

        if (qId > 0) {
            if (imageQuestion === "" || imageQuestion === null) {
                setImageQuestion(QuestionBankList[0].questionImageURL);
            }
            setQuestionError("");
            return true;
        }
        else {

        } if (imageQuestion === "" || imageQuestion === null) {
            // setQuestionError("Question is required");
            Toast.fire({
                icon: "warning",
                title: "Question is required",
            });

            return false;
        }
        else {
            // setQuestionError("");
            return true;
        }

    }
    const isFile = (value) => {

        return typeof value === 'object' && value instanceof File;
    };
    const validateImageOption = () => {
        if (qId > 0) {

            console.log(imageOptionmodelList);
            var flag = true;
            setAnswerError("");
            const updatedErrors = [...ImageOptionErrors];
            {
                imageOptionmodelList && imageOptionmodelList.length > 0 && (
                    imageOptionmodelList.map((item, index) => {
                        if (item.image === "" || item.image === null) {
                            var imgURL = QuestionBankList[index]?.answerImageURL;
                            if (imgURL === undefined) {

                                // updatedErrors[index] = {
                                //     ...updatedErrors[index],
                                //     optionError: "Option is required",
                                // };
                                Toast.fire({
                                    icon: "warning",
                                    title: "Option is required",
                                });
                                flag = false
                            } else {
                                imageOptionmodelList[index].image = imgURL;
                                updatedErrors[index] = {
                                    ...updatedErrors[index],
                                    optionError: "",
                                };
                            }
                        } else {
                            updatedErrors[index] = {
                                ...updatedErrors[index],
                                optionError: "",
                            };
                        }
                    })
                )
            }

            setImageOptionErrors(updatedErrors);

            return flag;
        }
        else {
            var flag = true;
            setAnswerError("");
            const updatedErrors = [...ImageOptionErrors];
            {
                imageOptionmodelList && imageOptionmodelList.length > 0 && (
                    imageOptionmodelList.map((item, index) => (

                        item.image === "" || item.image === null ? (
                            flag = false,

                            // updatedErrors[index] = {
                            //     ...updatedErrors[index],
                            //     optionError: "Option is required"
                            // }
                            Toast.fire({
                                icon: "warning",
                                title: "Option is required",
                            })
                        ) : (
                            updatedErrors[index] = {
                                ...updatedErrors[index],
                                optionError: ""
                            }
                        )

                    ))
                )
            }
            setImageOptionErrors(updatedErrors);

            return flag;
        }

    }
    const Questionsubmit = async (e) => {
        let sectionid;
        sectionid = document.getElementById('section').value;
        e.preventDefault();









        const isQuestionValid = questionType === '43' ? validateImageQUestion() : validateQuestion();
        const isAnswerValid = answerType === "45" ? validateImageOption() : validateAnswer();
        const isAnsBox = validateBox();
        const isConfirmAnswerValid = answerType === "45" ? validateImageConfirmAnswer() : answerType === "44" ? true : validateConfirmAnswer();
        const isQuesbox = validateQuesBox();
        const isSectionValid = validateSection();

        var questionImageURL = "";
        var optionsImageList;

        if (isQuestionValid && isAnswerValid && isConfirmAnswerValid && isAnsBox && isSectionValid && isQuesbox) {


            if (questionType === '43') {
                try {
                    if (qId > 0) {

                        if (isFile(imageQuestion)) {

                            const formData = new FormData();
                            formData.append('image', imageQuestion);
                            var res = await _questionBankService.UploadImageQuestion(formData);
                            questionImageURL = res;
                            setQuestionImageURL(res);
                        } else {

                            questionImageURL = imageQuestion === "" ? QuestionBankList[0].questionImageURL : QuestionBankList[0].questionImageURL;
                        }
                    }
                    else {
                        const formData = new FormData();
                        formData.append('image', imageQuestion);
                        var res = await _questionBankService.UploadImageQuestion(formData);
                        questionImageURL = res;
                        setQuestionImageURL(res);
                    }








                } catch (error) {
                    console.error(error);
                }
            }



            if (answerType === "45") {

                try {

                    if (qId > 0) {

                        const formData2 = new FormData();
                        const optionQuestionsImage = [];
                        var indexList = [];
                        imageOptionmodelList.forEach((model, index) => {
                            if (model.image) {
                                if (isFile(model.image)) {

                                    // optionQuestionsImage.push(model.image);
                                    indexList.push(index);
                                    formData2.append('image', model.image);
                                }


                            }
                        });

                        console.log(formData2);
                        if (formData2) {
                            var res = await _questionBankService.UploadImageOptions(formData2);


                            const returnURLlist = res.map((option, i) => {
                                return { url: option, index: indexList[i] };
                            });
                            console.log(returnURLlist);
                            optionsImageList = optionModels.map((option, i) => {
                                if (indexList.includes(i)) {
                                    const matchedIndex = returnURLlist.filter((item) => item.index === i);
                                    return {
                                        ...option, ImagePath: matchedIndex[0].url
                                    };
                                } else {
                                    return { ...option, ImagePath: imageOptionmodelList[i].image };
                                }
                            });
                            setOptionsImageURLList([]);
                            setOptionsImageURLList(res);
                            console.log(optionsImageList)
                        }

                        else {
                            optionsImageList = optionModels.map((option, i) => {
                                return { ...option, ImagePath: imageOptionmodelList[i].image };
                            });


                        }
                    }
                    else {

                        const formData2 = new FormData();
                        const optionQuestionsImage = [];

                        imageOptionmodelList.forEach((model) => {
                            if (model.image) {
                                optionQuestionsImage.push(model.image);
                                formData2.append('image', model.image);
                            }
                        });
                        var res = await _questionBankService.UploadImageOptions(formData2);

                        optionsImageList = optionModels.map((option, i) => {
                            return { ...option, ImagePath: res[i] };
                        });
                        setOptionsImageURLList([]);
                        setOptionsImageURLList(res);
                    }





                } catch (error) {
                    console.error(error);
                }

            }




            debugger;
            var data = {
                QuestionID: qId > 0 ? qId : 0,
                SectionId: document.getElementById('section').value,
                Question: questionType === '43' ? document.getElementById('imageQuestion').value : quillRef.current.value === null ? null : quillRef.current.value,
                // Question: question,
                AddExplanation: explanationRef.current.value === "" ? "NULL" : explanationRef.current.value,
                SectionName: document.getElementById("section").options[
                    document.getElementById("section").selectedIndex
                ].text,
                Status: 'AC',
                CreatedBy: Decrypt(localStorage.getItem("EmployeeID")),
                QuestionTypeID: questionType,
                AnswerTypeID: answerType,
                DepartmentID: 1,
                CompanyID: Decrypt(localStorage.getItem("CompanyID")),
                AnswerOptionId: document.getElementById('AnsweroptionTypeSelect').value == '' ? '47' : document.getElementById('AnsweroptionTypeSelect').value,
                Marks: 1,
                NegativeMarks: '0%',
                DifficultLevelID: document.getElementById('DifficultLevelID').value == '' ? '51' : document.getElementById('DifficultLevelID').value,
                QuestionImageURL: questionImageURL,
                tags: document.getElementById('tags').value = "" ? null : document.getElementById('tags').value,
                optionModels: answerType === "45" ? optionsImageList : optionModels

            };



            try {
                showLoading();



                debugger;

                _questionBankService.CreateUpdate(data).then((res) => {

                    if (res > 0) {

                        Toast.fire({
                            icon: "success",
                            title: "Saved Successfully",
                        });
                        navigateToView(Number(sectionid));


                        // setTimeout(() => {
                        //     window.location.reload(); // Reload the page
                        // }, 1000);
                    } else {
                        hideLoading();
                        showErrorMsg();
                    }
                });



            }

            catch (error) {
                hideLoading();
                console.log(error);
            }
        }
    };
    const handleDownloadClick = (url) => {

        const fileURL = IMAGE_URL + url;
        // Create a hidden anchor element
        const downloadAnchor = document.createElement('a');
        downloadAnchor.href = fileURL;
        downloadAnchor.target = '_blank'; // Open the link in a new tab
        downloadAnchor.download = ''; // Specify the desired filename for download

        // Append the anchor element to the document and trigger a click event
        document.body.appendChild(downloadAnchor);
        downloadAnchor.click();

        // Remove the anchor element after the download is initiated
        document.body.removeChild(downloadAnchor);
    };
    const toggleContainerClass = () => {
        setIsContainerFluid((prevState) => !prevState);
    };
    return (
        <main class="main-content-wrap">
            <div className="contact-list-area ">
                <div className='container-fluid'>
                    <div className='row'>
                        <div className={`${isContainerFluid ? "col-md-12" : "col-md-9"}`}>
                            <div className='card-box-style animatedwidth'>

                                <div className="fullwidthbuttonbox hidden-sm hidden-xs" onClick={toggleContainerClass}>
                                    <span className="fullwidthbutton" >
                                        <i className="fa fa-angle-double-right"></i>
                                    </span>
                                </div>
                                <div className="others-title col-12 row">
                                    <h3 className="col-6">{qId > 0 ? "Edit" : "Add"} Question Bank

                                    </h3>
                                    <div className='col-6 text-end'>
                                        <input type="button" value="Back" title="Back" className="back-button backbutton-end" onClick={() => navigateToView(secID)} />

                                    </div>
                                </div>
                                <div className="form-layouts-area">

                                    <form id="QuestionBank" onSubmit={Questionsubmit} autoComplete='off'>
                                        <div className="form-row">
                                            <div className="form-group col-md-2">
                                                <label htmlFor="question-type" className="form-label">
                                                    Question Type:
                                                </label>
                                                <select
                                                    id="questionTypeSelect"
                                                    name="questionTypeSelect"
                                                    className="form-select form-control"
                                                    value={questionType}
                                                    onChange={handleQuestionTypeChange}
                                                >
                                                    <option value=''>Select</option>
                                                    {parametervalue.length > 0 && parametervalue.map((option) => (
                                                        <option
                                                            key={option.parameterValueID}
                                                            value={option.parameterValueID}
                                                        >
                                                            {option.value}
                                                        </option>
                                                    ))}
                                                </select>
                                                {qusBoxError && <span className="text-danger">{qusBoxError}</span>}
                                            </div>
                                            <div className="form-group col-md-5">
                                                <label htmlFor="section" className="form-label">
                                                    Section:
                                                    {/* <h3> <input type="button" value="Back" title="Back" className="back-button backbutton-end" onClick={() => navigate(-1)} style={{position:"absolute",top:"0"}} />
                        </h3> */}
                                                </label>
                                                <select id="section" className="form-select form-control" name="section"
                                                    value={sectionID}
                                                    onChange={handleSectionChange} >
                                                    <option value="">Select</option>
                                                    {sectionList.length > 0 && sectionList?.map((section) => (
                                                        <option key={section.sectionId} value={section.sectionId}>
                                                            {section.sectionName}
                                                        </option>
                                                    ))}
                                                </select>
                                                {sectionError && <span className="text-danger">{sectionError}</span>}
                                            </div>
                                        </div>

                                        <div className="pb-24">
                                            <label htmlFor="question" className="form-label">
                                                Question:
                                                {qId > 0 && typeof imageQuestion === 'string' ? (
                                                    <button
                                                        type="button"
                                                        title=""
                                                        className="btn btn-link"
                                                        style={{
                                                            outlineColor: "green",
                                                            color: "rgba(0, 0, 0, 0.54)",
                                                            background: "#FFFFFF",
                                                            textDecoration: "none", // Remove underline from button
                                                        }}
                                                        onClick={() => handleDownloadClick(imageQuestion)} // Corrected the onClick handler
                                                    >
                                                        {imageQuestion != '' ? <CloudDownload fontSize="small" /> : <></>
                                                        }
                                                    </button>
                                                ) : (
                                                    <></>
                                                )}
                                            </label>
                                            {renderInputField()}
                                            {questionError && <span className="text-danger">{questionError}</span>}
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="answer" className="form-label mb-2">
                                                Answer Type:
                                            </label>
                                            <select
                                                id="inputState"
                                                className="form-select form-control mb-3"
                                                value={answerType}
                                                onChange={handleAnswerTypeChange}
                                            >
                                                <option value="">Select</option>
                                                {Answerparametervalue.length > 0 &&
                                                    Answerparametervalue.map((option) => (
                                                        <option
                                                            key={option.parameterValueID}
                                                            value={option.parameterValueID}
                                                        >
                                                            {option.value}
                                                        </option>
                                                    ))}
                                            </select>
                                            {ansBoxError && <span className="text-danger">{ansBoxError}</span>} </div>
                                        <div>
                                            {answerType != '46' && (
                                                <label htmlFor="answer" className="form-label mb-2">
                                                    Answer:
                                                </label>)}
                                            {renderAnswerField()}
                                            {answerError && <span className="text-danger">{answerError}</span>}

                                        </div>

                                        <div className="pb-24" style={{ display: "none" }}>
                                            <label htmlFor="question" className="form-label">
                                                Confirm Answer:
                                            </label>
                                            <ReactQuill
                                                id="Confirm-Answer"
                                                className="form-control"
                                                rows="5"
                                                value={confirmAns}
                                                onChange={handleConfirmAnsChange}
                                            />
                                            {confirmAnsError && <span className="text-danger">{confirmAnsError}</span>}
                                        </div>






                                        <div className="accordion" id="accordionExample">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingOne">
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseOne"
                                                        aria-expanded="false"
                                                        aria-controls="collapseOne"
                                                    >
                                                        Add Explanation
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseOne"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingOne"
                                                    data-bs-parent="#accordionExample"
                                                    style={{}}
                                                >
                                                    <div className="accordion-body">
                                                        {/* Start Form Layouts Area */}

                                                        <div className="form-layouts-area">
                                                            <div className="container-fluid">
                                                                <ReactQuill
                                                                    id='Explanation'
                                                                    value={explanation}
                                                                    ref={explanationRef}
                                                                    onChange={handleExplanationChange} />
                                                            </div>
                                                        </div>

                                                        {/* End Form Layouts Area */}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingTwo">
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseTwo"
                                                        aria-expanded="false"
                                                        aria-controls="collapseTwo"
                                                    >
                                                        Advance Options
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseTwo"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingTwo"
                                                    data-bs-parent="#accordionExample"
                                                    style={{}}
                                                >
                                                    <div className="accordion-body">
                                                        {/* Start Form Layouts Area */}

                                                        <div className="form-layouts-area">
                                                            <div className="container-fluid">
                                                                <div className="pb-24">
                                                                    {/* <label htmlFor="Attach-Diection" className="label mb-2">
                                        Attach Direction:
                                    </label>
                                    <select
                                        id="Attach-Diection"
                                        className="form-select form-control"

                                    >

                                    </select> */}
                                                                    <div className="mb-3">
                                                                        <div className="row">
                                                                            <div className="form-row">
                                                                                <div className="form-group col-md-5">
                                                                                    <label htmlFor="question-type" className="form-label mb-2">
                                                                                        Answer Option:
                                                                                    </label>
                                                                                    <select
                                                                                        id="AnsweroptionTypeSelect"
                                                                                        className="form-select form-control"
                                                                                        name="AnsweroptionTypeSelect"
                                                                                        value={AnwerOpt}
                                                                                        onChange={handleQuestionTypeChange}
                                                                                    >
                                                                                        <option value=''>Select</option>
                                                                                        {AnswerOptionparametervalue.length > 0 &&
                                                                                            AnswerOptionparametervalue.map((option) => (
                                                                                                <option
                                                                                                    key={option.parameterValueID}
                                                                                                    value={option.parameterValueID}
                                                                                                >
                                                                                                    {option.value}
                                                                                                </option>
                                                                                            ))}
                                                                                    </select>
                                                                                    {ansOptionError && <span className="text-danger">{ansOptionError}</span>}
                                                                                </div>
                                                                                {/* <div className="form-group col-md-5">
                                                                                    <label htmlFor="question-type" className="Marks">
                                                                                        Marks:
                                                                                    </label>

                                                                                    <div className='form-group marks'>
                                                                                        <input type='text' id='Marks' defaultValue={QuestionBankList && QuestionBankList[0]?.marks} className='form-control'></input>
                                                                                        {marksError && <span className="text-danger">{marksError}</span>}

                                                                                    </div>
                                                                                </div> */}
                                                                                {/* <div className="form-group col-md-5">
                                                                                    <label htmlFor="Negative" className="label">
                                                                                        Negative Marks:
                                                                                    </label>
                                                                                    <select
                                                                   back                     id="NegativeMarkSelect"
                                                                                        className="form-select form-control"
                                                                                        name="NegativeMarkSelect"
                                                                                        value={negativeMarks}
                                                                                        onChange={handleQuestionTypeChange}
                                                                                    >
                                                                                        <option value=''>Select</option>
                                                                                        {negativeList.length > 0 && negativeList.map((option) => (
                                                                                            <option
                                                                                                key={option.parameterValueID}
                                                                                                value={option.parameterValueID}
                                                                                            >
                                                                                                {option.value}
                                                                                            </option>
                                                                                        ))}
                                                                                    </select>
                                                                                    {NEgativemarksError && <span className="text-danger">{NEgativemarksError}</span>}
                                                                                </div> */}
                                                                                <div className="form-group col-md-5">
                                                                                    <label htmlFor="Difficultylevel" className="form-label mb-2">
                                                                                        Difficulty Level:
                                                                                    </label>
                                                                                    <select
                                                                                        id="DifficultLevelID"
                                                                                        className="form-select form-control"
                                                                                        name="DifficultLevel"
                                                                                        value={DifficultLevel}
                                                                                        // value={questionType}
                                                                                        onChange={handleQuestionTypeChange}
                                                                                    >
                                                                                        <option value=''>Select</option>
                                                                                        {DifficultLevelparametervalue.length > 0 &&
                                                                                            DifficultLevelparametervalue.map((option) => (
                                                                                                <option
                                                                                                    key={option.parameterValueID}
                                                                                                    value={option.parameterValueID}
                                                                                                >
                                                                                                    {option.value}
                                                                                                </option>
                                                                                            ))}
                                                                                    </select>
                                                                                    {difficultlvelError && <span className="text-danger">{difficultlvelError}</span>}
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group col-md-6">
                                                                                <label htmlFor="question-type" className="form-label mb-2" >
                                                                                    Tags:
                                                                                </label>

                                                                                <div className='form-group Tags' style={{ display: 'flex' }}>
                                                                                    <input type='text' value={tags != "" ? tags : ""} name='taging' onChange={handleTagChange} className='form-control' id='tags'></input>
                                                                                    {/* <input class="form-check-input" type="checkbox" id="gridCheck"></input>
                                        <label class="form-check-label" for="gridCheck">
                                            Check me out
                                        </label> */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* End Form Layouts Area */}
                                                    </div>
                                                </div>
                                            </div>



                                        </div>

                                        <div className='col-md-12 text-end examButton'>

                                            <button type="submit" className="btn btn-primary">
                                                Submit
                                            </button>
                                        </div>


                                    </form>

                                </div>
                            </div>
                        </div>
                        {!isContainerFluid ? <div className="col-md-3">

                            <div className="card-box-style"></div>
                        </div> : null}
                    </div>

                </div>
            </div>
        </main>

    );
};
export default QuestionBank;




